import { Typography, Box } from "@material-ui/core";
import {
    makeStyles,
    createStyles,
    Theme,
} from "@material-ui/core/styles";
import React, { useContext } from "react";
import { ThemeContext } from "../../blocks/notifications/src/Context/ThemeContext";
import { ArrowForwardIos, AddCircleOutline } from "@material-ui/icons"
import { Link } from "react-router-dom"
interface AddViewProps {
    viewAllUser: (index: string) => void,
    handleOpenAddUser: () => void;
    user_type: string;
    index: string;
    handleAddUserData: (name: string, value: string) => void;
    getAllData: (index: string) => void;
    getUserType: (value: string) => void;
}

const AddViewCard = ({ user_type, index, viewAllUser, handleOpenAddUser, handleAddUserData, getAllData, getUserType }: AddViewProps) => {
    const classes = useStyles();
    const { primaryColor } = useContext(ThemeContext)

    const handleClickAdd = () => {
        handleAddUserData("user_type", user_type);
        // handleOpenAddUser();
    }

    const handleClickView = () => {
        getUserType(user_type)
        getAllData(index);
        viewAllUser(index);

    }

    const addType = user_type.charAt(0).toUpperCase() + user_type.slice(1);

    return (
        <Box style={{ height: "250px", display: "flex", flexDirection: "column", gap: "30px" }}>
            <Link to={`/CreateUser?type=${user_type}`}
                style={{ textDecoration: "none", color: "black" }}
                className={classes.wrapper_add}
                onClick={() => handleClickAdd()} >
                <AddCircleOutline style={{ color: primaryColor }} />
                <Typography style={{ fontSize: "25px", color: primaryColor, fontFamily: "Rubik-Regular" }}>{"Add " + addType}</Typography>
            </Link>
            <Box onClick={() => handleClickView()} className={classes.wrapper_view}>
                <ArrowForwardIos style={{ color: primaryColor }} />
                <Typography style={{ fontSize: "25px", color: primaryColor, fontFamily: "Rubik-Regular" }}>View All</Typography>
            </Box>
        </Box>
    )
}
export default AddViewCard;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper_add: {
            width: "100%",
            backgroundColor: "#e7eaef",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "47%",
            padding: "10px 0",
            cursor: "pointer",
        },
        wrapper_view: {
            width: "100%",
            // backgroundColor: "#e7eaef",
            border: "1px dashed #a8b0b6",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "47%",
            padding: "10px 0",
            cursor: "pointer",
        },

    })
);
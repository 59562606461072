// Customizable Area Start
import React from "react";
import {
    Box,
    Typography,
    Button,
    Modal,
    IconButton,
    AppBar,
    Toolbar,
    Drawer,
    Badge,
    Menu,
    MenuItem
} from "@material-ui/core"
import ManageUsersController, { Props } from "./ManageUsersController";
import { withTheme, withStyles, createStyles, Theme } from "@material-ui/core/styles";
import ViewAll from "../../../components/src/ViewAll";
import AddUsersForm from "../../../components/src/AddUserForm";
import SideBar from "../../../components/src/SideBar.web";
import { notificationIcon } from "../../dashboard/src/assets";
import Loader from "../../../components/src/Loader.web";
import { ThemeContext } from "../../notifications/src/Context/ThemeContext";
import UserProfile from "../../../components/src/UserProfileAvatar";
import Footer from "../../../components/src/Footer.web";
const drawerWidth = 312;

// Customizable Area End

class ViewAllData extends ManageUsersController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    static contextType = ThemeContext
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { primaryColor, logo, count } = this.context;
        const { openAddUser, userDataLoading, anchorEl,
            viewAllData, responseMsg, responseStatus, userDataErrMsg } = this.state

        // Customizable Area End
        return (
            // Customizable Area Start
            <div>
                <AppBar
                    position="fixed"
                    className={classes.appBar}
                    color="transparent"
                >
                    <Toolbar>
                        <Box className={classes.grow} />
                        <Box>
                            <IconButton aria-label="show 4 new mails" color="inherit">
                                <Badge overlap="rectangular" badgeContent={count} color="error">
                                    <Box className={classes.notification_icon} onClick={this.gotoForgotNotification}>
                                        <img src={notificationIcon} width={18} />
                                    </Box>
                                </Badge>
                            </IconButton>
                            <IconButton onClick={this.handleClick}>
                                <UserProfile />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={this.handleClose}
                            >
                                <MenuItem onClick={this.logout}>Logout</MenuItem>
                            </Menu>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Drawer
                    className={classes.drawer}
                    variant="permanent"
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    anchor="left"
                >
                    <Toolbar className={classes.toolbar}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            px={2}
                            py={3}
                        >
                            <img src={logo} width="55%" />
                        </Box>
                    </Toolbar>
                    <SideBar />
                </Drawer>
                <Modal
                    open={openAddUser}
                    // onClose={() => this.closeModal()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box>
                        <AddUsersForm
                            user_type={viewAllData[0]?.attributes?.user_type}
                            handleAddUserData={this.handleAddUserData}
                            closeModal={this.closeModal}
                            addUserData={this.createUserData}
                            fieldValidation={this.fieldValidation}
                        />
                    </Box>
                </Modal>
                <Modal
                    open={responseStatus}
                    // onClose={() => this.closeModal()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className={classes.modalStyle}>
                        <Box style={{ textAlign: "center" }}>
                            <Typography style={{
                                fontFamily: "Rubik-Regular",
                                fontSize: "25px", color: primaryColor,
                                textAlign: "center",
                                marginBottom: "10px"
                            }}>
                                {
                                    responseMsg
                                }
                            </Typography>
                            <Button
                                data-testid="modalBtn"
                                onClick={() => this.closeModal()}
                                style={{
                                    backgroundColor: primaryColor,
                                    color: "#ffffff", textTransform: "inherit",
                                    minWidth: "90px", fontFamily: "Rubik-SemiBold"
                                }}>
                                OK
                            </Button>
                        </Box>
                    </Box>
                </Modal>
                <Loader loading={userDataLoading} />
                {
                    <ViewAll users={viewAllData}
                        errorMsg={userDataErrMsg}
                        handleOpenAddUser={this.handleOpenAddUser}
                        goToEditUserPage={this.goToEditUsersPage}
                        delteUserData={this.deleteUserData}
                        loading={userDataLoading}
                    />
                }
                <Box style={{ marginLeft: "312px", marginTop: "64px", padding: "20px", }}>
                    <Footer type="viewAll" />
                </Box>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// export default withStyles(styles)(ManageUsers);
export default withTheme(
    withStyles((theme: Theme) =>
        createStyles({
            divider: {
                height: "2px",
                width: "100%",
                backgroundColor: "#67a1a3",
            },
            appBar: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
                boxShadow: "0px 1px 1px #dbd7db",
                padding: 5,
                backgroundColor: "#ffffff",
            },
            grow: {
                flex: 1,
            },
            notification_icon: {
                backgroundColor: "#E7EAEE",
                borderRadius: "50%",
                padding: 10,
            },
            drawer: {
                width: drawerWidth,
                flexShrink: 0,
            },
            drawerPaper: {
                width: drawerWidth,
            },
            toolbar: theme.mixins.toolbar,
            content: {
                flexGrow: 1,
                backgroundColor: theme.palette.background.default,
                padding: theme.spacing(4),
            },
            modalStyle: {
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '400px',
                height: "auto",
                backgroundColor: '#ffffff',
                borderRadius: '6px',
                boxShadow: '24px',
                padding: '10px 20px 30px 20px',
            },

        }))(ViewAllData)
)

// Customizable Area End

export const staticPaymentsData = {
    id: "9999&&",
    type: "client_checklist",
    attributes: {
        id: "9999R",
        checklist_name: "Payments & Signatures",
        account_id: 11111,
        is_completed: false,
        sections: [
            {
                id: "999911R",
                status: "completed",
                section_name: "Payment Authorization Form",
                type: "documents"
            },
            {
                id: "999912R",
                status: "completed",
                section_name: "Servicing Disclosure",
                type: "documents"
            }
        ]
    }
}
import {
    Typography, Box, TextField,
    FormControl, Select, MenuItem,
    Checkbox, Radio, RadioGroup, FormControlLabel
} from "@material-ui/core";
import {
    makeStyles,
    createStyles,
    Theme,
} from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import React from "react";
import { QuestionModel } from "../../blocks/dashboard/src/Interface";
interface QuestionProps {
    questionSingle: QuestionModel;
    handleOptionChange: (ques_id: string, option: string, checked: boolean, multiple: boolean) => void;
    handleStringChange: (ques_id: string, optionName: string) => void;
}

const Question = ({ questionSingle, handleOptionChange, handleStringChange }: QuestionProps) => {
    const classes = useStyles();
    const { question_name, question_type, question_options, current_gathered_details, isError } = questionSingle?.attributes;

    const getOptionName = () => {
        const selectedOption = questionSingle?.attributes?.question_options?.filter((option) => option.isSelect === true)
        return selectedOption.length > 0 ? selectedOption[0].name : ""
    }

    const OptionType = (key: string) => {
        const OptionComponent: any = {
            "radio": (
                <>
                    {
                        <FormControl component="fieldset" className={classes.formControl}>

                            <RadioGroup aria-label="gender" name="gender1"
                                value={current_gathered_details.length > 0 ? current_gathered_details : getOptionName()}
                                onChange={(e) => handleOptionChange(questionSingle?.id, e.target.value, e.target.checked, false)}
                            >
                                {
                                    question_options.map((option: any) => (
                                        <FormControlLabel key={option?.name} value={option?.name}
                                            control={<Radio disabled={current_gathered_details.length > 0 ? true : false} />}
                                            label={option?.name} />
                                    ))
                                }
                            </RadioGroup>
                        </FormControl>


                    }
                    <Typography style={{ color: "red", fontFamily: "Rubik-Regular" }}>{isError}</Typography>
                </>
            ),
            "checkbox": (
                <>
                    {
                        <FormControl component="fieldset" className={classes.formControl}>
                            {question_options.map(option => (
                                <FormControlLabel
                                    key={option?.name}
                                    control={
                                        <Checkbox
                                            disabled={current_gathered_details.length > 0 ? true : false}
                                            checked={option?.isSelect}
                                            onChange={(e) => handleOptionChange(questionSingle?.id, option.name, e.target.checked, true)}
                                            value={option?.name}
                                        />
                                    }
                                    label={option?.name}
                                />
                            ))}
                        </FormControl>
                    }
                    <Typography style={{ color: "red", fontFamily: "Rubik-Regular" }}>{isError}</Typography>
                </>
            ),
            "multiselect": (
                <>
                    <FormControl style={{ minWidth: "150px", width: "100%" }} className={classes.formControl} >
                        <Select
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                },
                                PaperProps: {
                                    className: classes.paper,
                                    style: {
                                        maxHeight: 200,
                                        overflow: "auto",
                                    }
                                },
                                getContentAnchorEl: null
                            }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={current_gathered_details.length > 0 ? current_gathered_details : getOptionName()}
                            className={classes.select}
                            label="Sort"
                            onChange={(e) => handleOptionChange(questionSingle?.id, e.target.value as string, true, false)}
                            disableUnderline={true}
                            disabled={current_gathered_details.length > 0 ? true : false}
                            IconComponent={KeyboardArrowDownIcon}
                        >
                            {
                                question_options?.length > 0 ?
                                    question_options?.map((option: any) => (

                                        <MenuItem key={option?.name} className={classes.menuItemStyle} value={option?.name}>{option?.name}</MenuItem>

                                    ))
                                    :
                                    <MenuItem className={classes.menuItemStyle} value={""}>No Data Found</MenuItem>
                            }
                        </Select>
                        {/* <span className={classes.customArrow}></span> */}
                    </FormControl>
                    <Typography style={{ color: "red", fontFamily: "Rubik-Regular" }}>{isError}</Typography>
                </>
            ),
            "textfield": (
                <>
                    <TextField
                        style={{ width: "100%", marginBottom: 20 }}
                        placeholder="Answer"
                        variant="standard"
                        name="lastName"
                        type="text"
                        autoComplete="off"
                        onChange={(e) => handleStringChange(questionSingle?.id, e.target.value as string)}
                        // error={
                        //     emptyValueCheck?.old ? true : false
                        // }
                        // helperText={
                        //     emptyValueCheck?.old
                        // }
                        value={current_gathered_details.length > 0 ? current_gathered_details : questionSingle?.attributes?.answer}
                        InputProps={{
                            style: {
                                backgroundColor: "#e6eaee",
                                color: "black",
                                padding: "15px",
                                borderBottom: `1px solid ${localStorage.getItem("primaryColor") || "#67a1a3"}`,
                                fontFamily: "Rubik-Regular"
                            },
                            className: classes.formControl,
                            disableUnderline: true,
                        }}
                        disabled={current_gathered_details.length > 0 ? true : false}
                    />
                    <Typography style={{ color: "red", fontFamily: "Rubik-Regular" }}>{isError}</Typography>
                </>
            )
        };
        return OptionComponent[key];
    };


    return (
        <Box>
            <div dangerouslySetInnerHTML={{ __html: question_name }} />
            <Box>
                {OptionType(question_type)}
            </Box>
        </Box >
    )
}
export default Question;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            backgroundColor: "#ffffff",
            // height: "110px",
            border: "1px solid #ffffff",
            overflowY: "auto",
        },
        menuItemStyle: {
            textAlign: "right",
            color: '#000 !important',
            backgroundColor: '#ffffff',
            fontFamily: "Rubik-Regular"
        },
        formControl: {
            margin: theme.spacing(3),
        },
        customArrow: {
            position: "absolute",
            top: 0,
            right: 10,
            display: "block",
            backgroundColor: `${localStorage.getItem("primaryColor") || "#67a1a3"}`,
            // borderRight: "1px solid #ffffff",
            // borderRadius: "0 6px 6px 0",
            // height: '100%',
            width: "3rem",
            pointerEvents: 'none',
            color: "#ffffff"
        },
        select: {
            backgroundColor: "#e6eaee",
            color: "black",
            padding: "10px",
            borderBottom: `1px solid ${localStorage.getItem("primaryColor") || "#67a1a3"}`,
            fontFamily: "Rubik-Regular",
            // "& .MuiSelect-icon": {
            //     zIndex: 1,
            //     right: 10,
            //     // color: "#ffffff",
            // },
            // '& .MuiSelect-select.MuiSelect-select': {
            //     padding: "15px"
            // }
            // "& .MuiSelect-select": {
            //     color: "#fff !important",
            //     backgroundColor: "#67a1a3 !important",
            //     paddingLeft: "10px",
            // },

        },

    })
);
import {
    TextField, Typography, Box, Grid,
    Checkbox, IconButton, Button,
    FormControl,
    Select,
    MenuItem
} from "@material-ui/core";
import {
    makeStyles,
    createStyles,
    Theme,
} from "@material-ui/core/styles";
import React, { useContext } from "react";
import { deleteIcon } from "../../blocks/user-profile-basic/src/assets";
import { KeyboardArrowDown, AddCircleOutline } from "@material-ui/icons";
import { AddressType, UserInfoI } from "../../blocks/user-profile-basic/src/Interface";
import { UserError } from "../../blocks/user-profile-basic/src/EditUsersController";
import { ThemeContext } from "../../blocks/notifications/src/Context/ThemeContext";
interface EditProfileI {
    isCreateUserType: string,
    handleUserInfoChange: (e: any, name: string, id?: number) => void,
    updatedUserInfo: UserInfoI,
    addMoreAddress: () => void;
    deleteMoreAddress: (id: number) => void;
    changePrimaryAddress: (id: number, event: boolean) => void;
    handleProvideAccessBtn: (id: string) => void;
    getAccessPermission: () => void;
    errors: UserError
}



const EditProfileForm = ({ isCreateUserType, handleUserInfoChange, getAccessPermission, handleProvideAccessBtn, updatedUserInfo, addMoreAddress, deleteMoreAddress, changePrimaryAddress, errors }: EditProfileI) => {
    const classes = useStyles();
    const { primaryColor } = useContext(ThemeContext)
    const [individualType, setIndividualType] = React.useState("Spouse");


    let count = -1

    const handleIndTypeChange = (event: any) => {
        setIndividualType(event.target.value as string)
        handleUserInfoChange(event.target.value as string, "relationship")
    }
    React.useEffect(() => {
        if (updatedUserInfo && updatedUserInfo?.attributes) {
          const relationInitial = updatedUserInfo?.attributes?.relationship;
          if (relationInitial && !isCreateUserType) {
            setIndividualType(relationInitial);
          }
        }
      }, [updatedUserInfo]);


    return (
        <Box>
            <Box>
                {
                    updatedUserInfo?.type?.toLowerCase() === "client_businesses" || isCreateUserType === "business" ?
                        <Grid container spacing={3} justifyContent="space-between">
                            <Grid item md={8} xs={12}>
                                <Typography
                                    variant="h6"
                                    style={{
                                        fontFamily: "Rubik-Regular",
                                        fontSize: "18px",
                                        color: '#353535',
                                        marginBottom: "0.6rem"
                                    }}

                                >
                                    Business Name*
                                </Typography>
                                <TextField
                                    style={{ width: "100%", marginBottom: 20 }}
                                    placeholder="Business Name"
                                    variant="standard"
                                    name="firstName"
                                    className="email_input"
                                    autoComplete="off"
                                    onChange={(e) => handleUserInfoChange(e.target.value, "business_name")}
                                    error={
                                        errors?.business_name ? true : false
                                    }
                                    helperText={
                                        errors?.business_name
                                    }
                                    value={updatedUserInfo?.attributes?.business_name}
                                    InputProps={{
                                        style: {
                                            backgroundColor: "#e6eaee",
                                            color: "black",
                                            padding: "15px",
                                            borderBottom: `1px solid ${primaryColor}`,
                                            fontFamily: "Rubik-Regular"
                                        },
                                        disableUnderline: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                        :
                        <Grid container spacing={3} justifyContent="space-between">
                            <Grid item md={6} xs={12}>
                                <Typography
                                    variant="h6"
                                    style={{
                                        fontFamily: "Rubik-Regular",
                                        fontSize: "18px",
                                        color: '#353535',
                                        marginBottom: "0.6rem"
                                    }}

                                >
                                    First Name*
                                </Typography>
                                <TextField
                                    style={{ width: "100%", marginBottom: 20 }}
                                    placeholder="First Name"
                                    variant="standard"
                                    name="firstName"
                                    className="email_input"
                                    autoComplete="off"
                                    onChange={(e) => handleUserInfoChange(e.target.value.trim(), "first_name")}
                                    error={
                                        errors?.first_name ? true : false
                                    }
                                    helperText={
                                        errors?.first_name
                                    }
                                    value={updatedUserInfo?.attributes?.first_name}
                                    InputProps={{
                                        style: {
                                            backgroundColor: "#e6eaee",
                                            color: "black",
                                            padding: "15px",
                                            borderBottom: `1px solid ${primaryColor}`,
                                            fontFamily: "Rubik-Regular"
                                        },
                                        disableUnderline: true,
                                    }}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography
                                    variant="h6"
                                    style={{
                                        fontFamily: "Rubik-Regular",
                                        fontSize: "18px",
                                        color: '#353535',
                                        marginBottom: "0.6rem"
                                    }}

                                >
                                    Last Name*
                                </Typography>
                                <TextField
                                    style={{ width: "100%", marginBottom: 20 }}
                                    placeholder="Last Name"
                                    variant="standard"
                                    name="lastName"
                                    className="email_input"
                                    autoComplete="off"
                                    onChange={(e) => handleUserInfoChange(e.target.value.trim(), "last_name")}
                                    error={
                                        errors?.last_name ? true : false
                                    }
                                    helperText={
                                        errors?.last_name
                                    }
                                    value={updatedUserInfo?.attributes?.last_name}
                                    InputProps={{
                                        style: {
                                            backgroundColor: "#e6eaee",
                                            color: "black",
                                            padding: "15px",
                                            borderBottom: `1px solid ${primaryColor}`,
                                            fontFamily: "Rubik-Regular"
                                        },
                                        disableUnderline: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                }
                {
                    (isCreateUserType === "user") ||
                        (updatedUserInfo?.type?.toLocaleLowerCase() === "user" &&
                            updatedUserInfo?.attributes?.relationship?.toLocaleLowerCase() !== "personal") ?
                        (
                            <Grid container spacing={2} alignItems="center" style={{ marginBottom: 10 }}>
                                <Grid item md={8} xs={12}>
                                    <Typography
                                        variant="h6"
                                        style={{
                                            fontFamily: "Rubik-Regular",
                                            fontSize: "18px",
                                            color: '#353535',
                                            marginBottom: "0.6rem"
                                        }}

                                    >
                                        Relationship*
                                    </Typography>
                                    <FormControl style={{ minWidth: "150px", width: "100%" }} >
                                        <Select
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left"
                                                },
                                                PaperProps: {
                                                    className: classes.paper
                                                },
                                                getContentAnchorEl: null
                                            }}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={individualType}
                                            className={classes.select}
                                            label="Sort"
                                            onChange={(e) => handleIndTypeChange(e)}
                                            disableUnderline={true}
                                            IconComponent={KeyboardArrowDown}
                                        >
                                            {/* <MenuItem className={classes.menuItemStyle} value={"Personal"}>Personal</MenuItem> */}
                                            <MenuItem className={classes.menuItemStyle} value={"Spouse"}>Spouse</MenuItem>
                                            <MenuItem className={classes.menuItemStyle} value={"Dependent"}>Dependent</MenuItem>
                                        </Select>
                                        <span className={classes.customArrow}></span>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        ) : null
                }
                <Grid container>
                    <Grid item md={8} xs={12}>
                        <Typography
                            variant="h6"
                            style={{
                                fontFamily: "Rubik-Regular",
                                fontSize: "18px",
                                color: '#353535',
                                marginBottom: "0.6rem"
                            }}

                        >
                            {updatedUserInfo?.type?.toLowerCase() === "client_businesses" ?
                                "Business Email*"
                                :
                                "Email Address*"}

                        </Typography>
                        <TextField
                            style={{ width: "100%", marginBottom: 20 }}
                            placeholder="Email Address"
                            variant="standard"
                            name="email"
                            className="email_input"
                            autoComplete="off"
                            onChange={(e) => handleUserInfoChange(e.target.value.trim(), "email")}
                            error={
                                errors?.email ? true : false
                            }
                            helperText={
                                errors?.email
                            }
                            value={updatedUserInfo?.attributes?.email}
                            InputProps={{
                                style: {
                                    backgroundColor: "#e6eaee",
                                    color: "black",
                                    padding: "15px",
                                    borderBottom: `1px solid ${primaryColor}`,
                                    fontFamily: "Rubik-Regular"
                                },
                                disableUnderline: true,
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item md={8} xs={12}>
                        <Typography
                            variant="h6"
                            style={{
                                fontFamily: "Rubik-Regular",
                                fontSize: "18px",
                                color: '#353535',
                                marginBottom: "0.6rem"
                            }}

                        >
                            Phone Number
                        </Typography>
                        <TextField
                            style={{ width: "100%", marginBottom: 20 }}
                            placeholder="Phone Number"
                            variant="standard"
                            name="phoneNumber"
                            className="email_input"
                            autoComplete="off"
                            onChange={(e) => handleUserInfoChange(e.target.value.trim(), "phone_number")}
                            error={
                                errors?.phone_number ? true : false
                            }
                            helperText={
                                errors?.phone_number
                            }
                            value={updatedUserInfo?.attributes?.phone_number}
                            InputProps={{
                                style: {
                                    backgroundColor: "#e6eaee",
                                    color: "black",
                                    padding: "15px",
                                    borderBottom: `1px solid ${primaryColor}`,
                                    fontFamily: "Rubik-Regular"
                                },
                                disableUnderline: true,
                            }}
                        />
                    </Grid>
                </Grid>
                {
                    updatedUserInfo?.attributes?.addresses?.data?.map((item: AddressType) => {
                        if (item?.attributes?._destroy !== 1) {
                            count++;
                        }
                        return (
                            item?.attributes?._destroy === 1 ?
                                null
                                :
                                (<Grid key={item?.attributes?.id} container alignItems="center">
                                    <Grid item md={8} xs={11}>
                                        <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "0.6rem" }}>
                                            <Typography
                                                variant="h6"
                                                style={{
                                                    fontFamily: "Rubik-Regular",
                                                    fontSize: "18px",
                                                    color: '#353535',
                                                }}

                                            >
                                                Address{count === 0 ? "*" : [count + 1]}
                                            </Typography>
                                            <Typography style={{ fontSize: "14px", fontFamily: "Rubik-Regular" }}>
                                                Set as Primary address <Checkbox checked={item.attributes.primary_address}
                                                    onChange={(e) => changePrimaryAddress(item.attributes.id, e.target.checked)} style={{ color: primaryColor }} /></Typography>
                                        </Box>

                                        <TextField
                                            style={{ width: "100%", marginBottom: 20 }}
                                            placeholder="Address"
                                            variant="standard"
                                            name="address"
                                            className="email_input"
                                            autoComplete="off"
                                            onChange={(e) => handleUserInfoChange(e.target.value, "addresses", item.attributes.id)}
                                            error={
                                                item?.attributes?.error ? true : false
                                            }
                                            helperText={
                                                item?.attributes?.error
                                            }
                                            value={item?.attributes?.address}
                                            multiline={true}
                                            minRows={5}
                                            InputProps={{
                                                style: {
                                                    backgroundColor: "#e6eaee",
                                                    color: "black",
                                                    padding: "15px",
                                                    borderBottom: `1px solid ${primaryColor}`,
                                                    fontFamily: "Rubik-Regular"
                                                },
                                                disableUnderline: true,
                                            }}
                                        />
                                    </Grid>
                                    {
                                        item?.attributes?.primary_address ?
                                            ""
                                            :
                                            <Grid item xs={1} style={{ marginLeft: "20px" }}>
                                                <IconButton
                                                    onClick={() => deleteMoreAddress(item?.attributes?.id)}
                                                    style={{
                                                        height: '45px',
                                                        width: '45px',
                                                        borderRadius: '50%',
                                                        backgroundColor: primaryColor,
                                                        marginRight: "25px"
                                                    }}
                                                >
                                                    <img src={deleteIcon} height={'20px'} />
                                                </IconButton>
                                            </Grid>
                                    }
                                </Grid>
                                )
                        )
                    })
                }
                <Grid container>
                    <Grid item md={8} xs={12}>
                        <Box onClick={() => addMoreAddress()} className={classes.wrapper_view}>
                            <AddCircleOutline style={{ color: primaryColor }} />
                            <Typography style={{ marginLeft: "10px", fontSize: "20px", color: primaryColor, fontFamily: "Rubik-Regular" }}>Add Address</Typography>
                        </Box>
                    </Grid>
                </Grid>
                {
                    !isCreateUserType &&
                        updatedUserInfo?.attributes?.relationship?.toLowerCase() === "spouse" ?
                        <Grid container style={{ marginTop: "20px" }}>
                            <Grid item md={8} xs={12}>
                                <Button style={{ backgroundColor: primaryColor, color: "#ffffff", textTransform: "inherit", width: "100%", fontFamily: "Rubik-Regular" }}
                                    onClick={() => handleProvideAccessBtn(updatedUserInfo?.attributes?.id.toString())}
                                >
                                    Provide {updatedUserInfo?.attributes?.first_name + " " + updatedUserInfo?.attributes?.last_name} access to this platform
                                </Button>
                            </Grid>
                        </Grid>
                        :
                        ""
                }
            </Box>
        </Box>
    )
}
export default EditProfileForm;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper_view: {
            width: "100%",
            // backgroundColor: "#e7eaef",
            border: "1px dashed #a8b0b6",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            // height: "50px",
            padding: "20px 0",
            cursor: "pointer",
        },
        paper: {
            backgroundColor: "#ffffff",
            // height: "110px",
            border: "1px solid #ffffff",
            overflowY: "auto",
        },
        menuItemStyle: {
            textAlign: "right",
            color: '#000 !important',
            backgroundColor: '#ffffff',
            fontFamily: "Rubik-Regular"
        },
        customArrow: {
            position: "absolute",
            top: 0,
            right: 0,
            display: "block",
            backgroundColor: `${localStorage.getItem("primaryColor") || "#67a1a3"}`,
            // borderRight: "1px solid #ffffff",
            // borderRadius: "0 6px 6px 0",
            // height: '100%',
            width: "3rem",
            pointerEvents: 'none',
            color: "#ffffff"
        },
        select: {
            backgroundColor: "#e6eaee",
            color: "black",
            // padding: "15px",
            borderBottom: `1px solid ${localStorage.getItem("primaryColor") || "#67a1a3"}`,
            fontFamily: "Rubik-Regular",
            "& .MuiSelect-icon": {
                zIndex: 1,
                right: ".7rem",
                // color: "#ffffff",
            },
            '& .MuiSelect-select.MuiSelect-select': {
                padding: "15px"
            }
            // "& .MuiSelect-select": {
            //     color: "#fff !important",
            //     backgroundColor: "#67a1a3 !important",
            //     paddingLeft: "10px",
            // },

        },

    })
);
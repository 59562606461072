import { Grid, TextField, } from "@material-ui/core";
import {
    makeStyles,
    createStyles,
    Theme,
} from "@material-ui/core/styles";
import React from "react";
import { UserError } from "../../blocks/user-profile-basic/src/EditUsersController";
import { InputUser } from "./AddUserForm";
export interface NameProps {
    alignment: string;
    errors: UserError,
    userInfo: InputUser,
    handleUserInfo: (value: string, name: string) => void,
}


const AddUserNameForm = ({ alignment, errors, userInfo, handleUserInfo }: NameProps) => {
    const classes = useStyles();
    const fastNameNested = errors?.last_name ? " " : "";
    const fastNameTotal = errors?.first_name ? errors?.first_name : fastNameNested;
    const lastNameNested = errors?.first_name ? " " : "";
    const lastNameTotal = errors?.last_name ? errors?.last_name : lastNameNested;
    return (
        <>
            {
                alignment === "user" ?
                    <Grid container spacing={2} alignItems="center" >
                        <Grid item xs={6}>
                            <TextField
                                style={{ width: "100%", marginBottom: 10 }}
                                placeholder="First Name"
                                variant="standard"
                                name="phoneNumber"
                                className="email_input"
                                autoComplete="off"
                                onChange={(e) => handleUserInfo(e.target.value.trim(), "first_name")}
                                error={
                                    errors?.first_name || errors?.last_name ? true : false
                                }
                                helperText={
                                    fastNameTotal
                                }
                                value={userInfo.first_name}
                                InputProps={{
                                    classes: { root: classes.inputStyle },
                                    disableUnderline: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                style={{ width: "100%", marginBottom: 10 }}
                                placeholder="Last Name"
                                variant="standard"
                                name="phoneNumber"
                                className="email_input"
                                autoComplete="off"
                                onChange={(e) => handleUserInfo(e.target.value.trim(), "last_name")}
                                error={
                                    errors?.last_name || errors?.first_name ? true : false
                                }
                                helperText={
                                    lastNameTotal
                                }
                                value={userInfo.last_name}
                                InputProps={{
                                    classes: { root: classes.inputStyle },
                                    disableUnderline: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                    :
                    <Grid container spacing={2} alignItems="center" >
                        <Grid item xs={12}>
                            <TextField
                                style={{ width: "100%", marginBottom: 10 }}
                                placeholder="Business Name"
                                variant="standard"
                                name="businessName"
                                className="email_input"
                                autoComplete="off"
                                onChange={(e) => handleUserInfo(e.target.value, "business_name")}
                                error={
                                    errors?.business_name ? true : false
                                }
                                helperText={
                                    errors?.business_name
                                }
                                value={userInfo.business_name}
                                InputProps={{
                                    classes: { root: classes.inputStyle },
                                    disableUnderline: true,
                                }}
                            />
                        </Grid>
                    </Grid>
            }
        </>
    )
}
export default AddUserNameForm;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inputStyle: {
            backgroundColor: "#e6eaee",
            color: "black",
            padding: "5px 10px",
            fontFamily: "DMSans-Regular",
            borderRadius: "5px"
        },
    })
);
import React, { Component } from "react";
import {
  Box,
  Typography,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./Theme";

type Props = {
  type: string;
}

export class Footer extends Component<Props> {
  constructor(props: Props)
  { 
    super(props);
  }
  render() {
    const { type } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Box style={{
          width: `calc(100% - 322px)`,
          position: 'fixed',
          backgroundColor: type === "dashboard" ? '#FAFAFA' : "#FFFFFF",
          zIndex: 9999,
          bottom: 0,
        }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          alignSelf="center"
          py={2}
          style={{
            width: type === "dashboard" ?`calc(100% - 80px)` : `calc(100% - 25px)`,
            bottom : 5,
          }}
        >
          <Box display="flex">
            <a
              href="mailto:stacy@gmail.com"
              style={{ textDecoration: "none", color: "black" }}
            >
              <Box pr={1} sx={{ borderRight: "1px solid black" }}>
                <Typography>
                  <Box fontSize={14} fontWeight={600} component="span">
                    stacy@gmail.com
                  </Box>
                </Typography>
              </Box>
            </a>
            <a
              href="https://stacyscpa.com"
              style={{ textDecoration: "none", color: "black" }}
            >
              <Box px={1} sx={{ borderRight: "1px solid black" }}>
                <Typography>
                  <Box fontSize={14} fontWeight={600} component="span">
                    stacyscpa.com
                  </Box>
                </Typography>
              </Box>
            </a>
            <a
              href="tel:19999999999"
              style={{ textDecoration: "none", color: "black" }}
            >
              <Box px={1}>
                <Typography>
                  <Box fontSize={14} fontWeight={600} component="span">
                    {"("}999{")"}999-9999
                  </Box>
                </Typography>
              </Box>
            </a>
          </Box>
          <Box>
            <a
              href="https://smartpath.co"
              style={{ textDecoration: "none", color: "black" }}
            >
              <Box>
                <Typography>
                  <Box
                    fontSize={14}
                    fontWeight={600}
                    color="gray"
                    component="span"
                  >
                    Powered by SmartPath.co
                  </Box>
                </Typography>
              </Box>
            </a>
          </Box>
        </Box>
        </Box>
      </ThemeProvider>
    );
  }
}

export default Footer;

import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    Button,
    Modal,
    Grid,
    IconButton
} from "@material-ui/core"
import DocumentController, { Props } from "../DocumentController";
import { withTheme, withStyles, createStyles, Theme } from "@material-ui/core/styles";
import { uploadIcon } from "../../../dashboard/src/assets";
import UploadDocument from "../../../../components/src/UploadDocument";
import SubmittedDocs from "../../../../components/src/SubmittedDocs";
import DownloadIcon from '@material-ui/icons/GetApp';
import Loader from "../../../../components/src/Loader.web";
import { saveAs } from "file-saver"
import CustomDocViewer from "../../../../components/src/CustomDocViewer";
import { ThemeContext } from "../../../notifications/src/Context/ThemeContext";
import DocumentList from "../../../../components/src/DocumentList";

const drawerWidth = 312;

// Customizable Area End

class Documents extends DocumentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start
    static contextType: any = ThemeContext
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { primaryColor } = this.context;
        const { open, showModal, selectedPdf, submittedDocsResponse, documentsData, foldersData, loading, openStatus, uploadedStatus } = this.state;
        let submittedList: any[] = []

        const downloadFile = () => {
            saveAs(selectedPdf?.link, selectedPdf?.fileName)
        }


        console.log({ submittedDocsResponse })
        // Customizable Area End
        return (
            // Customizable Area Start
            <div>
                <Loader loading={loading} />
                <Modal
                    open={showModal}
                    // onClose={() => this.closeModal()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box>
                        <UploadDocument
                            closeModal={this.closeModal}
                            handleUploadedData={this.handleUploadedData}
                            folderData={foldersData}
                            createDocuments={this.createDocuments}
                        />
                    </Box>
                </Modal>
                <Modal
                    open={openStatus}
                    // onClose={() => this.closeModal()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className={classes.modalStyle}>
                        <Box style={{ textAlign: "center" }}>
                            <Typography style={{
                                fontFamily: "Rubik-Regular",
                                fontSize: "25px", color: primaryColor,
                                textAlign: "center",
                                marginBottom: "10px"
                            }}>
                                {
                                    uploadedStatus
                                }
                            </Typography>
                            <Button
                                data-testid="modalOkBtn"
                                onClick={() => this.closeModal()}
                                style={{
                                    backgroundColor: primaryColor,
                                    color: "#ffffff", textTransform: "inherit",
                                    minWidth: "90px", fontFamily: "Rubik-SemiBold"
                                }}>
                                OK
                            </Button>
                        </Box>
                    </Box>
                </Modal>
                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px", }}>
                    <Typography style={{ fontFamily: "Rubik-Regular", fontSize: "25px", color: primaryColor }}>
                        Documents</Typography>
                    <Button onClick={this.handleShowModal} className={classes.uploadBtn} style={{ backgroundColor: primaryColor }}>
                        <img src={uploadIcon} style={{ height: "20px", width: "20px", marginRight: "5px" }} />
                        Upload Documents</Button>
                </Box>
                <Box style={{ padding: "11px", height: "72vh", border: '1px solid #a8b0b6', borderRadius: "10px" }}>
                    <Box style={{ marginBottom: "5%" }}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={5} style={{ width: "100%" }}>
                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Typography style={{ fontFamily: "Rubik-SemiBold", fontSize: "16px" }}>Name</Typography>
                                    <Typography style={{ fontFamily: "Rubik-Regular", fontSize: "12px", marginRight: "30px" }}>Date Uploaded</Typography>
                                </Box>
                                <Box style={{ height: "66vh", overflowY: "scroll" }}>
                                    <DocumentList
                                        allDocsResponse={documentsData}
                                        handleColapse={this.handleColapse}
                                        handleSelectedUploaded={this.handleSelectedUploaded}
                                        open={open}
                                        selectedPdf={selectedPdf}
                                    />
                                    {
                                        submittedList?.length > 0 &&
                                        <SubmittedDocs
                                            selectedPdf={selectedPdf}
                                            submittedList={submittedList}
                                            open={open}
                                            handleColapse={this.handleColapse}
                                            handleSelectedSubmitted={this.handleSelectedSubmitted}

                                        />
                                    }
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={7} style={{ width: "100%" }}>
                                <Box style={{ border: `1px solid ${primaryColor}`, borderRadius: "10px", height: "69vh", padding: "20px", backgroundColor: "#F6F6F6" }}>
                                    {
                                        selectedPdf?.link &&
                                        <Box style={{ display: "flex", justifyContent: "space-between", width: "100%", height: "3vh" }}>
                                            <Typography style={{ fontFamily: "Rubik-Regular", fontSize: "14px" }}>{selectedPdf?.fileName}</Typography>
                                            <IconButton
                                                onClick={downloadFile}
                                                style={{
                                                    height: '8px',
                                                    width: '8px',
                                                    borderRadius: '4px',
                                                    border: '1px solid #a8b0b6',
                                                    backgroundColor: "#ffffff",
                                                    marginBottom: "3px"
                                                }}
                                            >
                                                <DownloadIcon />
                                            </IconButton>
                                        </Box>
                                    }
                                    <Box style={{ height: "62vh" }}>
                                        {selectedPdf?.link && (
                                            <CustomDocViewer
                                                viewHeight={61}
                                                link={selectedPdf?.link}
                                            />
                                        )}
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// export default withStyles(styles)(ManageUsers);
export default withTheme(
    withStyles((theme: Theme) =>
        createStyles({
            modalStyle: {
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '402px',
                height: "auto",
                backgroundColor: '#ffffff',
                borderRadius: '6px',
                boxShadow: '24px',
                padding: '10px 20px 30px 20px',
            },
            uploadBtn: {
                minWidth: "20vw",
                // backgroundColor: `${localStorage.getItem("primaryColor") || "#67a1a3"}`,
                color: "#ffffff",
                textTransform: "inherit",
                fontFamily: "Rubik-Regular",
                padding: "10px 7px"
            },
            nested: {
                paddingLeft: '80px',
                borderBottom: "1px solid #E6EAE9"
            },
            selectedNested: {
                paddingLeft: '80px',
                border: `1px solid ${localStorage.getItem("primaryColor") || "#67a1a3"}`,
                borderRight: "none",
                borderRadius: "10px 0 0 10px"
            }

        }))(Documents)
)
// Customizable Area End

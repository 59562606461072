import {
    Typography, Box, ListItem,
    Button, Collapse, List
} from "@material-ui/core";
import {
    makeStyles,
    createStyles,
    Theme,
} from "@material-ui/core/styles";
import React from "react";
import { downIcon, rightIcon } from "../../blocks/dashboard/src/assets";
import { CheckListType, SectionType } from "../../blocks/dashboard/src/Interface";
import { CheckCircle, Lens } from "@material-ui/icons"
import { useTheme } from "../../blocks/notifications/src/Context/ThemeContext";
interface CheckListProps {
    loading: boolean;
    selectedItemId: string;
    checkListData: CheckListType[];
    handleColapse: (key: string, checkList_id: string, checkList_name: string) => void;
    open: any;
    hanldeClickChecklistItem: (item: SectionType) => void
}

const DashboardCheckList = ({ loading, selectedItemId, checkListData, handleColapse, open, hanldeClickChecklistItem }: CheckListProps) => {
    const classes = useStyles();
    const { primaryColor } = useTheme()

    const getChecklistICon = (isChecked: string) => isChecked === "completed" ? <CheckCircle style={{ color: primaryColor }} /> : <Lens style={{ color: "#ffffff", borderRadius: "1px solid grey" }} />

    return (
        <Box>
            {
                loading && (
                    <Box width="100%" p={1}>
                        <Typography variant="h6" align="center" color="primary" style={{ fontWeight: 300 }}>
                            Loading...
                        </Typography>
                    </Box>
                )
            }
            {
                !loading &&
                checkListData?.length === 0 && (
                    <Box width="100%" p={1}>
                        <Typography variant="h6" align="center" color="primary" style={{ fontWeight: 300 }}>
                            No Action Items
                        </Typography>
                    </Box>
                )
            }
            {
                !loading &&
                checkListData?.length > 0 &&
                checkListData?.map((folder: CheckListType) => {
                    const fileList = folder?.attributes?.sections;
                    const _key = folder?.attributes?.checklist_name.split(" ").join("_");
                    return (
                        <React.Fragment key={_key}>
                            <ListItem


                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    borderRadius: '6px',
                                    marginTop: '20px',
                                    borderBottom: "1px solid #E6EAE9",
                                    cursor: "pointer"
                                }}


                                onClick={() => handleColapse(_key, folder?.id, folder?.attributes?.checklist_name)}
                            >
                                <Typography
                                    style={{
                                        paddingLeft: "15px",
                                        fontFamily: 'Rubik-Regular',
                                        fontSize: '16px',
                                        marginRight: '40px'
                                    }}
                                >
                                    {folder?.attributes?.checklist_name}
                                </Typography>


                                <Box>
                                    {open[_key] ? <img src={downIcon} height="8px"
                                    />
                                        : <img src={rightIcon} height="14px"
                                        />
                                    }
                                </Box>
                            </ListItem>
                            <Collapse in={open[_key]} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {
                                        fileList?.length > 0 &&
                                        fileList?.map((item: SectionType) => (
                                            <ListItem key={item?.id}
                                                className={item?.id.toString() === selectedItemId ? classes.selectedNested : classes.nested}
                                                onClick={() => hanldeClickChecklistItem(item)}
                                            >
                                                <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <Box style={{
                                                        width: item?.type?.toLowerCase() === "personal"
                                                            || item?.type?.toLowerCase() === "business" ? "70%" : "100%",
                                                        display: "flex",
                                                        gap: "10px"
                                                    }}>
                                                        {getChecklistICon(item?.status)}
                                                        <Typography style={{ fontFamily: "Rubik-Regular", fontSize: "16px" }}>
                                                            {item?.section_name}
                                                        </Typography>
                                                    </Box>
                                                    {
                                                        item?.type?.toLowerCase() === "personal"
                                                            || item?.type?.toLowerCase() === "business" ?
                                                            <Button className={classes.btn_type}>
                                                                {item?.type}
                                                            </Button>
                                                            :
                                                            ""
                                                    }
                                                </Box>
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            </Collapse>
                        </React.Fragment>
                    )
                })
            }
        </Box>
    )
}
export default DashboardCheckList;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        selectedNested: {
            backgroundColor: "#F6F6F6",
            cursor: "pointer",
            border: `1px solid ${localStorage.getItem("primaryColor") ?? "#67a1a3"}`,
            borderRight: "none",
            borderRadius: "6px 0 0 6px"
        },
        nested: {
            backgroundColor: "#E7EAEF",
            cursor: "pointer"
        },
        btn_type: {
            border: `1px solid ${localStorage.getItem("primaryColor") ?? "#67a1a3"}`,
            borderRadius: 5,
            padding: "4px 6px",
            fontSize: "0.7rem",
        },
    })
);
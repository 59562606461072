// Customizable Area Start
import React from "react";
import { ThemeContext } from "./ThemeContext";

import ThemeContextController, {Props} from "./ThemeContextController";

class ThemeContextProvider extends ThemeContextController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {isloading} = this.state
    return (
      <ThemeContext.Provider
        value={{...this.state, ...this}}>
        {this.props?.children }
      </ThemeContext.Provider>
    );
  }
}

export default ThemeContextProvider;
// Customizable Area End
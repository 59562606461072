// Customizable Area Start
import React, { Component } from "react";
import {
    Box,
    Grid,
    Typography,
    Button,
    IconButton,
    Badge,
    Avatar,
    AppBar,
    Toolbar,
    Drawer,
    Modal,
    Menu,
    MenuItem,
    TextField

} from "@material-ui/core"
import ChangePasswordController, { Props } from "./ChangePasswordController";
import { withTheme, withStyles, createStyles, Theme } from "@material-ui/core/styles";
import { getFirstLetters } from "../../../components/src/Utils";
import SideBar from "../../../components/src/SideBar.web";
import { notificationIcon } from "../../dashboard/src/assets";
import { crossEyeIcon, eyeIcon } from "./assets";
import Loader from "../../../components/src/Loader.web";
import { ThemeContext } from "../../notifications/src/Context/ThemeContext";
import UserProfile from "../../../components/src/UserProfileAvatar";
import Footer from "../../../components/src/Footer.web";

const drawerWidth = 312;

const PasswordError = () => {
    return (
        <div>
            <p>Password must contain</p>
            <ul>
                <li>At least 8 characters and a maximum of 16 characters</li>
                <li>One uppercase letter</li>
                <li>One number</li>
                <li>One special character</li>
            </ul>
        </div>
    );
};

// Customizable Area End

class EditUsers extends ChangePasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    static contextType = ThemeContext;

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { primaryColor, logo, userProfileContext } = this.context;
        const { openModal, password,
            changePasswordStatus, emptyValueCheck, anchorEl,
            showPassword_old, showPassword_new, showPassword_renew,
            userProfile, loading } = this.state;

        const showError = emptyValueCheck?.invalidPassword ?
            (<PasswordError />) : "";
        const notEmpty = emptyValueCheck?.notMatch ? emptyValueCheck?.notMatch : showError
        // Customizable Area End
        return (
            // Customizable Area Start
            <div>
                <Loader loading={loading} />
                <AppBar
                    position="fixed"
                    className={classes.appBar}
                    color="transparent"
                >
                    <Toolbar>
                        <Box className={classes.grow} />
                        <Box>
                            <IconButton aria-label="show 4 new mails" color="inherit">
                                <Badge overlap="rectangular" badgeContent={this.context.count} color="error">
                                    <Box className={classes.notification_icon} onClick={this.gotoForgotNotification}>
                                        <img src={notificationIcon} width={18} />
                                    </Box>
                                </Badge>
                            </IconButton>
                            <IconButton onClick={this.handleClick}>
                                <UserProfile />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={this.handleClose}
                            >
                                <MenuItem onClick={this.logout}>Logout</MenuItem>
                            </Menu>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Drawer
                    className={classes.drawer}
                    variant="permanent"
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    anchor="left"
                >
                    <Toolbar className={classes.toolbar}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            px={2}
                            py={3}
                        >
                            <img src={logo} width="55%" />
                        </Box>
                    </Toolbar>
                    <SideBar />
                </Drawer>
                <Modal
                    open={openModal}
                    // onClose={() => this.closeModal()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className={classes.modalStyle}>
                        <Box style={{ textAlign: "center" }}>
                            <Typography style={{
                                fontFamily: "Rubik-Regular",
                                fontSize: "25px", color: primaryColor,
                                textAlign: "center",
                                marginBottom: "10px"
                            }}>
                                {
                                    changePasswordStatus
                                }
                            </Typography>
                            <Button
                                onClick={() => this.closeModal()}
                                style={{
                                    backgroundColor: primaryColor,
                                    color: "#ffffff", textTransform: "inherit",
                                    minWidth: "90px", fontFamily: "Rubik-SemiBold"
                                }}>
                                OK
                            </Button>
                        </Box>
                    </Box>
                </Modal>
                <Box style={{ marginLeft: "312px", marginTop: "64px", padding: "20px", }}>
                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "30px" }}>
                        <Typography style={{ fontFamily: "Rubik-Regular", fontSize: "25px", color: primaryColor }}>Change Password</Typography>
                    </Box>
                    <Box className={classes.mainContainer}>
                        <Grid container justifyContent="center">
                            <Grid item sm={6}>
                                <Box style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    gap: "20px",
                                    marginBottom: "20px"
                                }}>
                                    <Box>
                                        {
                                            userProfile?.avatar ?
                                                <img src={userProfile?.avatar} height="100px" width="100px" style={{ borderRadius: "50%", border: "1px solid #a8b0b6" }} />
                                                :
                                                <Box style={{ height: "100px", width: "100px", borderRadius: "50%", border: "1px solid #a8b0b6", backgroundColor: primaryColor, position: "relative" }}>
                                                    <Typography style={{ position: "absolute", top: "34%", left: "36%", color: "#ffffff", fontSize: "24px" }}>
                                                        {
                                                            getFirstLetters(userProfile?.first_name + " " + userProfile?.last_name)
                                                        }
                                                    </Typography>
                                                </Box>
                                        }
                                    </Box>
                                    <Box className={classes.userDescription}>
                                        {
                                            <Typography style={{ fontFamily: "Rubik-Medium", fontSize: "20px", marginBottom: "5px" }} className={classes.textWidth}>
                                                {userProfile?.first_name + " " + userProfile?.last_name}
                                            </Typography>
                                        }
                                        <a
                                            href={"mailto:" + userProfile?.email}
                                            style={{ textDecoration: "none", color: "black" }}
                                        >
                                            <Typography style={{ fontFamily: "Rubik-Regular", fontSize: "14px" }} className={classes.textWidth}>
                                                {userProfile?.email}
                                            </Typography>
                                        </a>
                                        <a
                                            href={"tel:" + userProfile?.phone_number}
                                            style={{ textDecoration: "none", color: "black" }}
                                        >
                                            <Typography style={{ fontFamily: "Rubik-Regular", fontSize: "14px" }} className={classes.textWidth}>
                                                {userProfile?.phone_number ? "+" + userProfile?.phone_number : ""}
                                            </Typography>
                                        </a>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center">
                            <Grid item sm={6}>
                                <TextField
                                    style={{ width: "100%", marginBottom: 20 }}
                                    placeholder="Old password"
                                    variant="standard"
                                    name="lastName"
                                    className={classes.removeHoverBorder}
                                    type={showPassword_old ? "text" : "password"}
                                    autoComplete="off"
                                    onChange={(e) => this.handlePasswordChange(e, "old_password")}
                                    error={
                                        emptyValueCheck?.old ? true : false
                                    }
                                    helperText={
                                        emptyValueCheck?.old
                                    }
                                    value={password.old_password}
                                    InputProps={{
                                        style: {
                                            backgroundColor: "#e6eaee",
                                            color: "black",
                                            padding: "15px",
                                            borderBottom: `1px solid ${primaryColor}`,
                                            fontFamily: "Rubik-Regular"
                                        },
                                        endAdornment:
                                            <Box style={{ paddingRight: '10px', cursor: "pointer" }}
                                                onClick={() => this.handleShowPassword_old()}>
                                                {showPassword_old ?
                                                    <img src={eyeIcon}
                                                        style={{ height: '20px' }} />
                                                    :
                                                    <img src={crossEyeIcon}
                                                        style={{ height: '20px' }} />}
                                            </Box>,
                                        disableUnderline: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center" alignItems="center">
                            <Grid item sm={6}
                                style={{ display: "flex", justifyContent: "flex-end", marginBottom: "15px" }}>
                                <Typography style={{
                                    fontFamily: "Rubik-Regular",
                                    fontSize: "12px",
                                    color: "#0059C9",
                                    cursor: "pointer"
                                }}
                                    onClick={this.gotoForgotPassword}
                                >
                                    Forgot Password
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center">
                            <Grid item sm={6}>
                                <TextField
                                    style={{ width: "100%", marginBottom: 20 }}
                                    placeholder="Enter new password"
                                    variant="standard"
                                    name="lastName"
                                    className={classes.removeHoverBorder}
                                    type={showPassword_new ? "text" : "password"}
                                    autoComplete="off"
                                    onChange={(e) => this.handlePasswordChange(e, "new_password")}
                                    error={
                                        emptyValueCheck?.new ? true : false
                                    }
                                    helperText={
                                        emptyValueCheck?.new
                                    }
                                    value={password.new_password}
                                    InputProps={{
                                        style: {
                                            backgroundColor: "#e6eaee",
                                            color: "black",
                                            padding: "15px",
                                            borderBottom: `1px solid ${primaryColor}`,
                                            fontFamily: "Rubik-Regular"
                                        },
                                        endAdornment:
                                            <Box style={{ paddingRight: '10px', cursor: "pointer" }}
                                                onClick={() => this.handleShowPassword_new()}>
                                                {showPassword_new ?
                                                    <img src={eyeIcon}
                                                        style={{ height: '20px' }} />
                                                    :
                                                    <img src={crossEyeIcon}
                                                        style={{ height: '20px' }} />}
                                            </Box>,
                                        disableUnderline: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center">
                            <Grid item sm={6}>
                                <TextField
                                    style={{ width: "100%", marginBottom: 20 }}
                                    placeholder="Re-enter password"
                                    variant="standard"
                                    name="re-enter password"
                                    className={classes.removeHoverBorder}
                                    type={showPassword_renew ? "text" : "password"}
                                    autoComplete="off"
                                    onChange={(e) => this.handlePasswordChange(e, "check_new_password")}
                                    error={
                                        emptyValueCheck?.invalidPassword || emptyValueCheck?.notMatch ? true : false
                                    }
                                    helperText={
                                        emptyValueCheck?.renew ? emptyValueCheck?.renew
                                            : notEmpty
                                    }
                                    value={password.check_new_password}
                                    InputProps={{
                                        style: {
                                            backgroundColor: "#e6eaee",
                                            color: "black",
                                            padding: "15px",
                                            borderBottom: `1px solid ${primaryColor}`,
                                            fontFamily: "Rubik-Regular",

                                        },
                                        endAdornment:
                                            <Box style={{ paddingRight: '10px', cursor: "pointer" }}
                                                onClick={() => this.handleShowPassword_renew()}>
                                                {showPassword_renew ?
                                                    <img src={eyeIcon}
                                                        style={{ height: '20px' }} />
                                                    :
                                                    <img src={crossEyeIcon}
                                                        style={{ height: '20px' }} />}
                                            </Box>,

                                        disableUnderline: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center">
                            <Grid item sm={6}>
                                <Button style={{
                                    width: "100%",
                                    fontFamily: "Rubik-SemiBold",
                                    color: "#ffffff",
                                    backgroundColor: primaryColor,
                                    padding: "10px 0"
                                }}
                                    onClick={this.submitChangePassword}
                                >
                                    Change password
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Box style={{ marginLeft: "312px", marginTop: "64px", padding: "20px", }}>
                    <Footer type="changePassword" />
                </Box>
            </div >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// export default withStyles(styles)(EditUsers);
export default withTheme(
    withStyles((theme: Theme) =>
        createStyles({
            modalStyle: {
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '403px',
                height: "auto",
                backgroundColor: '#ffffff',
                borderRadius: '6px',
                boxShadow: '24px',
                padding: '10px 20px 30px 20px',
            },
            accessModalStyle: {
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '600px',
                height: "auto",
                backgroundColor: '#ffffff',
                borderRadius: '6px',
                boxShadow: '24px',
                padding: '20px',
            },
            mainContainer: {
                border: "1px solid #a8b0b6",
                borderRadius: "10px",
                padding: "5%",
                height: "63vh",
                overflowX: "hidden",
                // overflowY: "scroll"
            },
            appBar: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
                boxShadow: "0px 1px 1px #dbd7db",
                padding: 5,
                backgroundColor: "#ffffff",

            },
            grow: {
                flex: 1,
            },
            notification_icon: {
                backgroundColor: "#E7EAEE",
                borderRadius: "50%",
                padding: 10,
            },
            drawer: {
                width: drawerWidth,
                flexShrink: 0,
            },
            drawerPaper: {
                width: drawerWidth,
            },
            toolbar: theme.mixins.toolbar,
            content: {
                flexGrow: 1,
                backgroundColor: theme.palette.background.default,
                padding: theme.spacing(4),
            },
            wrapper_view: {
                width: "100%",
                // backgroundColor: "#e7eaef",
                border: "1px dashed #a8b0b6",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                // height: "50px",
                padding: "20px 0",
                cursor: "pointer",
            },
            removeHoverBorder: {
                '&:hover fieldset': {
                    borderColor: 'grey',
                },
            },
            textWidth: {
                width: "80%",
                wordWrap: "break-word"
            },
            userDescription: {
                width: "calc(100% - 100px)",
                wordWrap: "break-word",
                padding: "20px 10px 20px 10px"
            }

        }))(EditUsers)
)

// Customizable Area End

// Customizable Area Start
import React, { Component } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Drawer,
  IconButton,
  Badge,
  Avatar,
  CssBaseline,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import Fade from '@material-ui/core/Fade';
import {
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import {
  notificationIcon
} from "./assets";
import Footer from "../../../components/src/Footer.web";
import DashboardController, { Props } from "./DashboardController";
import { getThemeFunction } from "../../../components/src/Theme";
import SideBar from "../../../components/src/SideBar.web";
import { Switch, Route } from 'react-router-dom';
import CompletedItems from "./components/CompletedItems/CompletedItems";
import MainDashboard from "./components/MainDashboard.web";
import Payments from "../../Payments/src/Payments.web";
import Documents from "./components/Documents";
import { ThemeContext } from "../../notifications/src/Context/ThemeContext";
import UserProfile from "../../../components/src/UserProfileAvatar";

export const muiStyles = (theme: any) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    boxShadow: "none",
    padding: 5,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  icon: {
    minWidth: "40px",
  },
  notification_icon: {
    backgroundColor: "#E7EAEE",
    borderRadius: "50%",
    padding: 10,
  },
  selected: {
    backgroundColor: "#E7EAEE !important",
  },
  btn_type: {
    border: "1px solid #67a0a3",
    borderRadius: 5,
    padding: "4px 6px",
    fontSize: "0.7rem",
    fontWeight: "700",
  },
  grow: {
    flex: 1,
  },
  filter: {
    cursor: "pointer",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
    borderRadius: 10,
  },
  more_btn: {
    flex: 1,
    padding: "0.5rem",
    backgroundColor: theme.palette.secondary.main,
    color: "white",
  },
  icon_selected: {
    height: 30,
    borderRadius: 10,
    border: "3px solid #FF4E59",
    marginRight: -6,
    zIndex: 9999,
  },
  payments: {
    border: "1px solid #67a0a3",
    borderRadius: 10,
    backgroundColor: "#f1f1f1",
  },
  selected_btn: {
    backgroundColor: "#f1f1f1",
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    border: "1px solid #67a0a3",
  },
  submit_btn: {
    fontSize: "0.9rem",
    background: "#67a0a3",
    width: "50%",
    color: "#fff",
  },
  action_items: {
    background: "#fff",
    margin: "20 0",
    width: "100%",
    borderRadius: 10,
  },
  payment_details: {
    fontSize: "0.9rem",
  },
  payment_amount: {
    font: "bold 2rem Rubik",
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(4),
  },
});

const drawerWidth = 312;


class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
  }

  static contextType = ThemeContext;


  render() {
    const { classes } = this.props as any;
    return (
      // Required for all blocks
      <ThemeProvider theme={getThemeFunction(this.context?.primaryColor)}>
        <Box className={classes.root}>
          <CssBaseline />
          <AppBar
            position="fixed"
            className={classes.appBar}
            color="transparent"
          >
            <Toolbar>
              <Box className={classes.grow} />
              <Box>
                <IconButton aria-label="show 4 new mails" color="inherit">
                  {/* {
                    this.context?.count > 0  && */}
                  <Badge overlap="rectangular" badgeContent={this.context?.count} color="error">
                    {/* } */}
                    <Box className={classes.notification_icon} onClick={() => this.props.history.push("/notifications")}>
                      <img src={notificationIcon} width={18} />
                    </Box>
                  </Badge>
                </IconButton>
                {/* <AvatarMenu logout={this.logout} /> */}
                <IconButton onClick={this.handleClick}>
                  <UserProfile />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleClose}
                >
                  <MenuItem onClick={this.logout}>Logout</MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="left"
          >
            <Toolbar className={classes.toolbar}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                px={2}
                py={3}
              >
                <img src={this.context?.logo} width="55%" />
              </Box>
            </Toolbar>
            <SideBar />
          </Drawer>
          <Box className={classes.content}>
            <div className={classes.toolbar} />
            <Box height="83vh" style={{ overflowY: "scroll" }}>
              <Switch>
                <Route path="/dashboard/main" children={<MainDashboard />} />
                <Route path="/dashboard/items" children={<CompletedItems navigation={undefined} id={""} history={undefined} />} />
                <Route path="/dashboard/documents" children={<Documents navigation={undefined} id={""} history={undefined} />} />
                <Route path="/dashboard/payments" children={<Payments navigation={undefined} id={""} />} />
              </Switch>
            </Box>
            <Footer type="dashboard" />
          </Box>
        </Box>
      </ThemeProvider>
    );
  }
}



interface MenuState {
  anchorEl: any;
}

interface MenuProps {
  logout: () => void
}
class AvatarMenu extends Component<MenuProps, MenuState> {

  constructor(props: MenuProps) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    return (
      <Box component="span">
        <IconButton onClick={this.handleClick}>
          <Avatar
            alt="Remy Sharp"
            src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50"
          />
        </IconButton>
        <Menu
          id="fade-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
          TransitionComponent={Fade}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <MenuItem onClick={this.props.logout}>
            <Typography
              variant="h6"
              style={{ fontWeight: 900, margin: "0 10" }}
            >
              Logout
            </Typography>
          </MenuItem>
        </Menu>
      </Box>
    )
  }
}

// @ts-ignore
export default withStyles(muiStyles)(withRouter(Dashboard));
// Customizable Area End
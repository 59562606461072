import {
    Typography,
    Box,
    IconButton,
    Button,
    Grid,
    TextField,
    FormControl,
    Select,
    MenuItem
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab"
import {
    makeStyles,
    createStyles,
    Theme,
} from "@material-ui/core/styles";
import React, { useContext } from "react";
import Close from '@material-ui/icons/Close';
import * as Yup from 'yup';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import AddUserNameForm from "./AddUserNameForm";
import { showFileName } from "./Utils";
import { ThemeContext } from "../../blocks/notifications/src/Context/ThemeContext";
interface AddUserProps {
    closeModal: () => void,
    handleAddUserData: (name: string, value: string) => void;
    addUserData: () => void;
    fieldValidation: () => void;
    user_type: string;
}
export interface UserError {
    business_name?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    email?: string | null;
    phone_number?: string | null;
}

export interface InputUser {
    business_name: string,
    first_name: string,
    last_name: string,
    phone_number: string,
    email: string
}



const AddUsersForm = ({ user_type, closeModal, handleAddUserData, addUserData, fieldValidation }: AddUserProps) => {
    const classes = useStyles();
    const { primaryColor } = useContext(ThemeContext)
    const [alignment, setAlignment] = React.useState(user_type);
    const [individualType, setIndividualType] = React.useState("Spouse")
    const [filename, setFilename] = React.useState("");
    const [userInfo, setUserInfo] = React.useState<InputUser>({
        business_name: "",
        first_name: "",
        last_name: "",
        phone_number: "",
        email: ""
    })
    const [errors, setErrors] = React.useState<UserError>({})


    const validateInput = () => {
        const phonReg = /^\d+$/
        let userSchema;
        if (alignment === "user") {
            userSchema = Yup.object({
                // business_name: Yup.string().required("Please Enter Business Name").max(50, "Name is too long (maximum is 50 characters)"),
                first_name: Yup.string().required("Please Enter First Name").max(25, "Fist Name is too long (maximum is 25 characters)"),
                last_name: Yup.string().required("Please Enter Last Name").max(25, "Last Name is too long (maximum is 25 characters)"),
                phone_number: Yup.string()
                    .max(14, "Too long (maximum is 10 characters)")
                    .min(14, "Too short (minimum is 10 characters)")
                    // .matches(phonReg, "Must be only digits")
                    .required("Please Enter Phone Number"),

                email: Yup.string().required("Please Enter Email.").email("Invalid Email Address"),
            });
            userSchema.validate({
                // business_name: userInfo.business_name ? userInfo.business_name : "",
                first_name: userInfo.first_name,
                last_name: userInfo.last_name,
                email: userInfo.email,
                phone_number: userInfo.phone_number
            }, { abortEarly: false }).then((value) => {
                setErrors({})
                addUserData()
            }).catch((err) => {
                let newError: any = {};
                err.inner?.forEach((item: any) => {
                    newError[item.path] = item.message
                })
                setErrors(newError)
            })
        } else {
            userSchema = Yup.object({
                business_name: Yup.string().required("Please Enter Business Name").max(50, "Name is too long (maximum is 50 characters)"),
                // first_name: Yup.string().required("Please Enter First Name").max(25, "Fist Name is too long (maximum is 25 characters)"),
                // last_name: Yup.string().required("Please Enter Last Name").max(25, "Last Name is too long (maximum is 25 characters)"),
                phone_number: Yup.string()
                    .max(14, "Too long (maximum is 10 characters)")
                    .min(14, "Too short (minimum is 10 characters)")
                    // .matches(phonReg, "Phone number must be only digits")
                    .required("Please Enter Contact Number"),

                email: Yup.string().required("Please Enter Email.").email("Invalid Email Address"),
            });
            userSchema.validate({
                business_name: userInfo.business_name,
                // first_name: userInfo.first_name,
                // last_name: userInfo.last_name,
                email: userInfo.email,
                phone_number: userInfo.phone_number
            }, { abortEarly: false }).then((value) => {
                setErrors({})
                addUserData()
            }).catch((err) => {
                let newError: any = {};
                err.inner?.forEach((item: any) => {
                    newError[item.path] = item.message
                })
                setErrors(newError)
            })
        }

    }


    React.useEffect(() => {
        setAlignment(user_type)
        handleAddUserData("user_type", user_type)
        handleAddUserData("relationship", individualType)
    }, [])

    const handleFileUpload = (e: any) => {
        if (!e.target.files) {
            return;
        }
        const file = e.target.files[0];
        const { name } = file;
        setFilename(showFileName(name));

        handleAddUserData("image", file)
    }

    const cancelFile = () => {
        setFilename("")
    }

    const handleToggleChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        setAlignment(newAlignment);

        handleAddUserData("user_type", newAlignment)
    };

    const handleIndTypeChange = (event: any) => {
        const userType = event.target.value as string;
        setIndividualType(userType)

        handleAddUserData("relationship", userType)

        if (userType === "Personal") {
            handleAddUserData("primary_user", "true")
        }
    }

    const handleUserInfo = (value: string, name: string) => {
        // const value = event.target.value as string
        if (name === "phone_number") {
            value = value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        }
        setUserInfo((prev_state) => {
            return {
                ...prev_state,
                [name]: value,
            }
        })

        handleAddUserData(name, value)

    }


    return (
        <Box className={classes.modalStyle}>
            <Box onClick={() => closeModal()} className={classes.closeStyle}>
                <Close style={{ color: primaryColor }} />
            </Box>
            <Box>
                <Typography style={{ fontFamily: "DMSans-Bold", fontSize: "23px", color: primaryColor, marginBottom: 10 }}>Add User</Typography>
                <Grid container spacing={2} alignItems="center" style={{ marginBottom: 10 }}>
                    <Grid item xs={6}>
                        <Typography style={{ fontFamily: "DMSans-Regular", fontSize: "16px" }}>Add a Profile Picture</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        {
                            filename ?
                                <Box style={{ height: "35px", backgroundColor: primaryColor, width: "100%", borderRadius: "5px", }}>
                                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "3px 10px" }}>
                                        <Typography style={{ fontFamily: "DMSans-Regular", color: "#fffff" }}>{showFileName(filename)}</Typography>

                                        <IconButton
                                            onClick={() => cancelFile()}
                                            style={{
                                                height: '20px',
                                                width: '20px',
                                                borderRadius: '50%',
                                                backgroundColor: "#ffffff",
                                            }}
                                        >
                                            <Close style={{ color: primaryColor }} />
                                        </IconButton>
                                    </Box>
                                </Box>
                                :
                                <Button
                                    variant="contained"
                                    component="label"
                                    style={{ width: "100%", textTransform: "inherit", fontFamily: "DMSans-Regular", backgroundColor: "#8f92a1", color: "#ffffff" }}
                                >
                                    Select File
                                    <input
                                        type="file"
                                        accept="image/png, image/jpeg"
                                        onChange={handleFileUpload}
                                        hidden
                                    />
                                </Button>
                        }
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center" style={{ marginBottom: 10 }}>
                    <Grid item xs={6}>
                        <Typography style={{ fontFamily: "DMSans-Regular", fontSize: "16px" }}>User Type</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <ToggleButtonGroup
                            color="primary"
                            value={alignment}
                            exclusive
                            onChange={handleToggleChange}
                            aria-label="Platform"
                            style={{ width: "100%" }}
                        >
                            <ToggleButton disabled={alignment === "user" ? true : false} className={classes.toggleButton} value="user">Individual</ToggleButton>
                            <ToggleButton disabled={alignment === "business" ? true : false} className={classes.toggleButton} value="business">Business</ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>
                {
                    alignment === "user" ?
                        <Grid container spacing={2} alignItems="center" style={{ marginBottom: 10 }}>
                            <Grid item xs={6}>
                                <Typography style={{ fontFamily: "DMSans-Regular", fontSize: "16px" }}>Relationship</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl style={{ minWidth: "150px", width: "100%" }} >
                                    <Select
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            PaperProps: {
                                                className: classes.paper
                                            },
                                            getContentAnchorEl: null
                                        }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={individualType}
                                        className={classes.select}
                                        style={{ backgroundColor: primaryColor }}
                                        label="Sort"
                                        onChange={(e) => handleIndTypeChange(e)}
                                        disableUnderline={true}
                                        IconComponent={KeyboardArrowDownIcon}
                                    >
                                        {/* <MenuItem className={classes.menuItemStyle} style={{ fontFamily: "DMSans-Regular" }} value={"Personal"}>Personal</MenuItem> */}
                                        <MenuItem className={classes.menuItemStyle} style={{ fontFamily: "DMSans-Regular" }} value={"Spouse"}>Spouse</MenuItem>
                                        <MenuItem className={classes.menuItemStyle} style={{ fontFamily: "DMSans-Regular" }} value={"Dependent"}>Dependent</MenuItem>
                                    </Select>
                                    <span className={classes.customArrow}></span>
                                </FormControl>
                            </Grid>
                        </Grid>
                        :
                        ""
                }
                <AddUserNameForm alignment={alignment} errors={errors} userInfo={userInfo} handleUserInfo={handleUserInfo} />
                <Grid container spacing={2} alignItems="center">
                    {/* <Grid item xs={3}>
                        <FormControl
                            // variant="filled"
                            className={classes.formControl}
                            style={{
                                marginBottom: errors?.phone_number ? "29px" : "0"
                            }}
                        >
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={countryCode}
                                disableUnderline={true}
                                onChange={(e) =>
                                    handleCountryCode(e.target.value as string)
                                }
                                style={{ textAlign: "center" }}
                            >
                                <MenuItem value={'+1'}>+1</MenuItem>
                                <MenuItem value={'+91'}>+91</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid> */}
                    <Grid item xs={12}>
                        <TextField
                            style={{ width: "100%", marginBottom: 10 }}
                            placeholder="Mobile Number"
                            variant="standard"
                            name="phoneNumber"
                            className="email_input"
                            autoComplete="off"
                            onChange={(e) => handleUserInfo(e.target.value.trim(), "phone_number")}
                            error={
                                errors?.phone_number ? true : false
                            }
                            helperText={
                                errors?.phone_number
                            }
                            value={userInfo.phone_number}
                            InputProps={{
                                classes: { root: classes.inputStyle },
                                disableUnderline: true,
                                style: { borderRadius: "0 10px 10px 0" }
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <TextField
                            style={{ width: "100%", marginBottom: 10 }}
                            placeholder="Email"
                            variant="standard"
                            name="phoneNumber"
                            className="email_input"
                            autoComplete="off"
                            onChange={(e) => handleUserInfo(e.target.value.trim(), "email")}
                            error={
                                errors?.email ? true : false
                            }
                            helperText={
                                errors?.email
                            }
                            value={userInfo.email}
                            InputProps={{
                                classes: { root: classes.inputStyle },
                                disableUnderline: true,
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <Button
                            onClick={() => closeModal()}
                            variant="contained"
                            style={{ width: "100%", textTransform: "inherit", fontFamily: "DMSans-Regular", backgroundColor: "#8f92a1", color: "#ffffff" }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            style={{
                                width: "100%", textTransform: "inherit", fontFamily: "DMSans-Regular",
                                backgroundColor: primaryColor,
                                color: "#ffffff"
                            }}
                            // disabled={!(userInfo.first_name && userInfo.last_name && userInfo.email && userInfo.phone_number)}
                            onClick={validateInput}
                        >
                            Save
                        </Button>

                    </Grid>
                </Grid>
            </Box>

        </Box>
    )
}
export default AddUsersForm;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalStyle: {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '400px',
            height: "auto",
            backgroundColor: '#ffffff',
            borderRadius: '6px',
            boxShadow: '24px',
            padding: '10px 20px 30px 20px',
            // display: "flex",
            // flexDirection: "column",
            // justifyContent: "center",
            // alignItems: "center"
        },
        closeStyle: {
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            cursor: "pointer",
        },
        toggleButton: {
            fontFamily: "DMSans-Bold",
            fontSize: "16px",
            textTransform: "inherit",
            backgroundColor: "#eae8e9",
            '&.MuiToggleButton-root': {
                padding: "3px 6px",
                width: "100%"
            },
            '&.MuiToggleButton-root.Mui-Selected': {
                backgroundColor: `${localStorage.getItem("primaryColor") || "#67a1a3"}`,
                color: "#ffffff"

            }
        },
        inputStyle: {
            backgroundColor: "#e6eaee",
            color: "black",
            padding: "5px 10px",
            fontFamily: "DMSans-Regular",
            borderRadius: "5px"
        },
        paper: {
            backgroundColor: "#ffffff",
            // height: "110px",
            border: "1px solid #ffffff",
            overflowY: "auto",
        },
        menuItemStyle: {
            textAlign: "right",
            color: '#000 !important',
            backgroundColor: '#ffffff',
            fontFamily: "DMSans-Bold"
        },
        customArrow: {
            position: "absolute",
            top: 0,
            right: 0,
            display: "block",
            backgroundColor: `1px solid ${localStorage.getItem("primaryColor") || "#67a1a3"}`,
            // borderRight: "1px solid #ffffff",
            // borderRadius: "0 6px 6px 0",
            // height: '100%',
            width: "3rem",
            pointerEvents: 'none',
            color: "#ffffff"
        },
        select: {
            fontSize: "14px",
            fontFamily: "DMSans-Bold",
            border: "1px solid #ffffff",
            height: "37px",
            borderRadius: "5px 5px 5px 5px",
            backgroundColor: `${localStorage.getItem("primaryColor") || "#67a1a3"}`,
            "& .MuiSelect-icon": {
                zIndex: 1,
                right: ".7rem",
                color: "#ffffff",
            },
            "& .MuiSelect-select": {
                color: "#fff !important",
                backgroundColor: `${localStorage.getItem("primaryColor") || "#67a1a3"} !important`,
                paddingLeft: "10px",
            },

        },
        formControl: {
            backgroundColor: "#e6eaee",
            padding: "5px 10px",
            borderRadius: '10px 0 0 10px',
            marginTop: "-9px",
            width: "100%",
        },

    })
);
import { Button, Box } from "@material-ui/core";
import {
    makeStyles,
    createStyles,
    Theme,
} from "@material-ui/core/styles";
import React, { useContext } from "react";
import { ThemeContext } from "../../blocks/notifications/src/Context/ThemeContext";
import { QuestionModel } from "../../blocks/dashboard/src/Interface";
import ForwardIcon from "@material-ui/icons/ArrowForward"
import Question from "./Question";
interface QuestionProps {
    questionList: QuestionModel[];
    question_rank: number;
    handleOptionChange: (ques_id: string, optionName: string, checked: boolean, multiple: boolean) => void;
    handleStringChange: (ques_id: string, optionName: string) => void;
    submitAnswer: () => void;
    handleQuestionRank: () => void;
}

const QuestionComponent = ({ questionList, question_rank, handleOptionChange, handleStringChange, submitAnswer, handleQuestionRank }: QuestionProps) => {
    const classes = useStyles();
    const { primaryColor } = useContext(ThemeContext)

    return (
        <Box
            width="100%"
            height="60vh"
            className={classes.payments}
            style={{
                border: `1px solid ${primaryColor}`
            }}
            p={2}
            display="flex"
            justifyContent="space-between"
            flex={1}
            flexDirection="column"
        >
            <Box p={2}>
                <Box mt={2} style={{ height: "40vh", overflowY: "scroll", overflowX: "hidden" }}>
                    {
                        questionList?.length > 0 &&
                        <Question
                            questionSingle={questionList[question_rank]}
                            handleOptionChange={handleOptionChange}
                            handleStringChange={handleStringChange}
                        />
                    }
                </Box>
            </Box>
            <Box display="flex" justifyContent="flex-end" p={2}>
                {
                    question_rank === questionList?.length - 1 ?
                        <Button
                            disabled={questionList[question_rank]?.attributes?.current_gathered_details.length > 0
                                ? true : false}
                            onClick={submitAnswer}
                            className={classes.submit_btn}
                            color="primary"
                            variant="contained"
                        >
                            Submit
                        </Button>
                        :
                        <Button
                            onClick={handleQuestionRank}
                            className={classes.submit_btn}
                            color="primary"
                            variant="contained"
                        >
                            Next <ForwardIcon style={{ marginLeft: "20px", color: "white" }} />
                        </Button>
                }
            </Box>
        </Box>
    )
}
export default QuestionComponent;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        payments: {
            borderRadius: 10,
            backgroundColor: "#F6F6F6",
            overflow: 'hidden'
        },
        submit_btn: {
            fontSize: "1rem",
            // background: "#67a0a3",
            width: "40%",
            color: "#fff",
        },

    })
);
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import Api from "../../../components/src/API";
import { UserInfoI } from "./Interface";
// Customizable Area End
export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    viewAll: boolean;
    openAddUser: boolean;
    userData: UserInfoI[];
    businessData: UserInfoI[];
    addUserData: UserInfoI;
    userDataErrMsg: string;
    userDataLoading: boolean;
    inputError: any;
    viewAllData: UserInfoI[];
    responseStatus: boolean;
    responseMsg: string;
    user_type: string;
    anchorEl: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ManageUsersController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getUserDataCallId: string = "";
    createUserDataCallId: string = "";
    getViewAllDataCallId: string = "";
    deleteUserDataCallId: string = "";
    deleteId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            viewAll: false,
            openAddUser: false,
            userData: [],
            businessData: [],
            userDataErrMsg: "",
            userDataLoading: false,
            addUserData: {
                id: "1",
                type: "",
                attributes: {
                    id: 1,
                    business_name: "",
                    first_name: "",
                    last_name: "",
                    email: "",
                    phone_number: "",
                    relationship: "",
                    primary_user: false,
                    user_type: "",
                    client_information_id: 1,
                    image: "",
                    addresses: {
                        data: []
                    },
                    date_added: ""
                }
            },
            inputError: {},
            viewAllData: [],
            responseStatus: false,
            responseMsg: "",
            user_type: "",
            anchorEl: null,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start

        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );


            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId != null) {
                if (apiRequestCallId == this.getUserDataCallId) {
                    this.getUserDataResponse(responseJson, errorReponse)
                }
                if (apiRequestCallId == this.createUserDataCallId) {
                    this.getCreateUserResponse(responseJson, errorReponse)
                }
                if (apiRequestCallId == this.getViewAllDataCallId) {
                    this.getViewAllDataResponse(responseJson, errorReponse)
                }
                if (apiRequestCallId == this.deleteUserDataCallId) {
                    this.setDeleteUserResponse(responseJson, errorReponse)
                }
            }
        }
        // Customizable Area End

    }
    // Customizable Area Start
    async componentDidMount() {
        this.getUserData()
        const _newPath = window.location.pathname.split('/')
        if (_newPath[1] === "ViewAllData") {
            this.getViewAllData(this.props.navigation.getParam("index"))
            this.getUserType(this.props.navigation.getParam("index") === "user_index" ? "user" : "business")
        }

    }

    // Customizable Area End

    // Customizable Area Start

    getUserDataResponse = (responseJson: any, errorReponse: any) => {
        if (responseJson && !responseJson.error) {
            this.setState({
                ...this.state,
                userData: responseJson?.users?.data,
                businessData: responseJson?.businesses?.data,
                userDataErrMsg: "",
                userDataLoading: false,
            });
        } else {
            if (errorReponse === undefined) {
                this.setState({
                    userDataErrMsg: "Sorry something went wrong",
                });
            } else {
                if (errorReponse === undefined) {
                    this.setState({
                        userDataErrMsg: "Sorry something went wrong",
                    });
                } else {
                    this.setState({
                        userDataErrMsg: errorReponse,
                    });
                }

            }

        }
    }

    getViewAllDataResponse = (responseJson: any, errorReponse: any) => {
        if (responseJson && !responseJson.error) {
            this.setState({
                ...this.state,
                viewAllData: responseJson.data,
                userDataErrMsg: "",
                userDataLoading: false,
            });
        } else {
            if (errorReponse === undefined) {
                this.setState({
                    userDataErrMsg: "Sorry something went wrong",
                });
            } else {
                if (errorReponse === undefined) {
                    this.setState({
                        userDataErrMsg: "Sorry something went wrong",
                    });
                } else {
                    this.setState({
                        userDataErrMsg: errorReponse,
                    });
                }

            }

        }
    }


    getCreateUserResponse = (responseJson: any, errorReponse: any) => {
        if (responseJson && !responseJson.errors) {
            this.setState((prev_state) => {
                return {
                    ...prev_state,
                    userDataErrMsg: "",
                    userDataLoading: false,
                    openAddUser: false,
                }
            }, () => this.getManageAndAll());
        } else {
            if (errorReponse === undefined) {
                this.setState({
                    userDataErrMsg: "Sorry something went wrong",
                });
            } else {
                this.setState({
                    userDataErrMsg: errorReponse,
                });
            }

        }
    }
    getManageAndAll = () => {
        this.getUserData();
        const _newPath = window.location.pathname.split('/')
        if (_newPath[1] === "ViewAllData") {
            this.getViewAllData(this.props.navigation.getParam("index"))
        }
    }
    getUserData = () => {
        this.setState({ userDataLoading: true })
        const header = {
            "token": localStorage.getItem("Token"),
            "Content-Type": configJSON.contentTypeApiGetUserProfile,
        };
        const requestMessage = Api({
            header: header,
            endPoint: `${configJSON.apiGetUserData}`,
            method: "GET",
        });
        this.getUserDataCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getViewAllData = (index: string) => {
        this.setState({ userDataLoading: true })
        const header = {
            "token": localStorage.getItem("Token"),
            "Content-Type": configJSON.contentTypeApiGetUserProfile,
        };
        const requestMessage = Api({
            header: header,
            endPoint: `${configJSON.apiViewAllData}/${index}`,
            method: "GET",
        });
        this.getViewAllDataCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    createUserData = () => {
        const { image, business_name, first_name, last_name, email,
            phone_number, relationship, primary_user,
            user_type } = this.state.addUserData.attributes;

        const payload = new FormData();
        if (user_type === "user") {
            first_name && payload.append("user[first_name]", first_name);
            last_name && payload.append("user[last_name]", last_name);
            email && payload.append("user[email]", email);
            phone_number && payload.append("user[phone_number]", phone_number);
            relationship && payload.append("user[relationship]", relationship);
            primary_user && payload.append("user[primary_user]", JSON.stringify(primary_user));
            user_type && payload.append("user[user_type]", user_type);
            image && payload.append("user[image]", image);
        } else {
            business_name && payload.append("user[business_name]", business_name);
            email && payload.append("user[email]", email);
            phone_number && payload.append("user[phone_number]", phone_number);
            user_type && payload.append("user[user_type]", user_type);
            image && payload.append("user[image]", image);
        }

        const header = {
            "token": localStorage.getItem("Token"),
        };


        const requestMessage = Api({
            header: header,
            endPoint: `${configJSON.apiAddUserData}`,
            payload: payload,
            method: "POST",
        });
        this.createUserDataCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    deleteUserData = (userId: string, userType: string) => {
        const header = {
            "token": localStorage.getItem("Token"),
            "Content-Type": configJSON.contentTypeApiGetUserProfile,
        };
        this.deleteId = userId;
        const requestMessage = Api({
            header: header,
            endPoint: `${configJSON.apiAddUserData}/${userId}?user[user_type]=${userType}`,
            method: "DELETE",
        });
        this.deleteUserDataCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    setDeleteUserResponse = (responseJson: any, errorReponse: any) => {
        if (responseJson && !responseJson.error) {
            const filteredData = this.state.viewAllData.filter(user => user.id !== this.deleteId);
            this.setState({
                responseStatus: true,
                responseMsg: "Deleted successfully!",
                viewAllData: filteredData
            })
        } else {
            if (errorReponse === undefined) {
                this.setState({
                    responseStatus: false,
                    responseMsg: "Sorry something went wrong",
                });
            } else {
                this.setState({
                    responseStatus: false,
                    responseMsg: errorReponse,
                });
            }

        }
    }

    fieldValidation = () => {
        const nameRegExp = /^[a-z ,.'-]+$/i;
        const emailRegExp1 = "[a-z0-9._%+-]"
        const emailRegExp2 = "/@[a-z0-9]";
        const emailRegExp3 = ".[a-z]{2,3}$";
        const emailRegExp = new RegExp(emailRegExp1 + emailRegExp2 + emailRegExp3);
        const { first_name, last_name, email } = this.state.addUserData.attributes;
        let error: any = {}
        if (!first_name.match(nameRegExp)) {
            error.first_name = "Enter Your First Name"
        }
        if (!last_name.match(nameRegExp)) {
            error.last_name = "Enter Your Last Name"
        }
        if (!email.match(emailRegExp)) {
            error.email = "Enter a Valid Email"
        }
        if (error && Object.keys(error).length === 0 && Object.getPrototypeOf(error) === Object.prototype) {
            this.setState({ inputError: {} })
        }
        else {
            this.setState({ inputError: error })
        }
    }

    handleAddUserData = (name: string, value: string) => {
        this.setState((prev_state) => {
            return {
                addUserData: {
                    ...prev_state.addUserData,
                    attributes: {
                        ...prev_state.addUserData.attributes,
                        [name]: value,
                    }
                }
            }
        })
    }

    handleOpenAddUser = () => {
        this.setState({ openAddUser: true });
    }

    closeModal = () => {
        this.setState({ openAddUser: false, responseStatus: false });
    }
    viewAllUser = (index: string) => {
        this.props.navigation.navigate("ViewAllData", {
            index: index
        })
    }

    goToEditUsersPage = (user_type: string, id: string) => {
        this.props.navigation.navigate("EditUsers", {
            userType: user_type,
            id: id
        })
    }

    gotoForgotNotification = () => {
        this.props.navigation.navigate("Notifications")
    }

    logout = () => {
        localStorage.removeItem('Token');
        localStorage.removeItem('logo');
        localStorage.removeItem('primaryColor');
        localStorage.removeItem('secondaryColor');
        localStorage.removeItem('email');
        localStorage.removeItem('client_id');
        this.context?.clearThemeContext()
        //@ts-ignore
        this.props.navigation?.navigate("signin");
    }
    getUserType = (value: string) => {
        this.setState({
            user_type: value,
        })
    }

    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            anchorEl: event.currentTarget
        })
    };

    handleClose = () => {
        this.setState({
            anchorEl: null
        })
    };
    // Customizable Area End

}

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


// Customizable Area Start
import Api from "../../../components/src/API";
import { CheckListType, ConfirmDocumentSignI, DocumentSignatureI, DocumentsResponse, PaymentsI, QuestionModel, SectionType, SubmittedInfo } from "./Interface";
import { ThemeContext } from "../../notifications/src/Context/ThemeContext";
import { client } from "../../../components/src/HelloSignClient";
import { staticPaymentsData } from "./staticPaymentsData";
// Customizable Area End


export const configJSON = require("./config.js");


export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    history: any;
    classes: any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    dashboardData: {
        accountingChecklist: any,
        payrollChecklist: any,
        priorities: any,
        payment_details: {
            description: string,
            amount: number
        },
        filterAnchorEl: any,
        avatarMenuEl: any
    };
    open: any,
    selectedChecklist: {
        id: string,
        type: string
    }
    checkListData: CheckListType[];
    questionList: QuestionModel[];
    selectedItemId: string;
    sectionDetailsErrMsg: string;
    sectionDetailsLoading: boolean;
    prioritiesExpand: boolean;
    token: string;
    errorMsg: string;
    loading: boolean;
    isDialogOpen: boolean;
    tesingQuestion: QuestionModel[],
    question_rank: number;
    anchorElFilter: any;
    filterOptions: {
        todo: boolean;
        inProgress: boolean;
        business: boolean;
        personal: boolean;
        question: boolean;
        documentNeeded: boolean;
    };
    filterOptionsValue: any;
    submittedInfo: SubmittedInfo;
    sumittedMsgModal: boolean;
    submittedMsg: string;
    helloSignURL: string;
    documentsResponse: DocumentsResponse;
    selectedDocuments: DocumentSignatureI;
    confirmDocumentSign: ConfirmDocumentSignI;
    confirmViewDocumentURL: string;
    documentsLoading: boolean;
    selectedPayments: PaymentsI | null;
    merchant_key: string;
    paymentGatewayId: string;
    snackBar: {
        show: boolean;
        message?: string;
        type?: "success" | "info" | "warning" | "error";
    };
    // Customizable Area End
}
interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}


export default class DashboardController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    static contextType: any = ThemeContext;
    apiDashboardItemCallId: string = "";
    dashboardApiCallId: string = "";
    apiGetQueryStrinurl: string = "";
    checkListCallId: string = "";
    selectedCheckListCallId: string = "";
    submitQuestionListCallId: string = "";
    getHelloSignURLCallId: string = "";
    configmSignDocumentCallId: string = "";
    configmViewDocumentCallId: string = "";
    paymentGatewayCallId: string = "";
    submitPaymentAPICallId: string = "";
    // Customizable Area End


    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // Customizable Area End
        ];


        this.state = {
            // Customizable Area Start
            dashboardData: {
                accountingChecklist: dummyAccoutingChecklist,
                payrollChecklist: dummyPayrollChecklist,
                priorities: null,
                payment_details: {
                    description: '',
                    amount: 0.00
                },
                filterAnchorEl: null,
                avatarMenuEl: null,
            },
            open: {},
            selectedChecklist: {
                type: '',
                id: ''
            },
            selectedItemId: "",
            checkListData: [],
            questionList: [],
            sectionDetailsErrMsg: "",
            sectionDetailsLoading: false,
            errorMsg: "",
            token: "",
            loading: false,
            prioritiesExpand: false,
            isDialogOpen: false,
            tesingQuestion: questionData.map((item: any) => {
                if (item?.attributes?.question_type === "textfield") {
                    return {
                        ...item,
                        attributes: {
                            ...item?.attributes,
                            answer: ""
                        }
                    }
                } else {
                    return {
                        ...item,
                        attributes: {
                            ...item?.attributes,
                            question_options: item?.attributes?.question_options.map((option: any) => (
                                {
                                    name: option,
                                    isSelect: false,
                                }
                            ))
                        }
                    }
                }
            }),
            question_rank: 0,
            anchorElFilter: null,
            filterOptions: {
                todo: false,
                inProgress: false,
                business: false,
                personal: false,
                question: false,
                documentNeeded: false,
            },
            filterOptionsValue: {
                status: [],
                type: []
            },
            submittedInfo: {
                checkList_id: "",
                checkList_name: "",
                section_id: "",
                section_name: ""
            },
            sumittedMsgModal: false,
            submittedMsg: "",
            helloSignURL: "",
            documentsResponse: {
                client_id: "",
                data: []
            },
            selectedDocuments: {
                document_template_id: 0,
                document_template_title: "",
                document_url: "",
                signature: "",
                status: false,
                survey_id: -11,
                signature_request_id: ""
            },
            confirmDocumentSign: {
                id: 0,
                status: false,
                survey_id: 0,
                document_template_id: 0,
                signature: "",
                package_management_id: 0,
                signature_request_id: 0,
            },
            confirmViewDocumentURL: "",
            documentsLoading: false,
            selectedPayments: null,
            merchant_key: '',
            paymentGatewayId: "",
            snackBar: {
                show: false,
                message: "",
                type: "error",
            }
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area End
    }


    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start

        const apiRequestCallIds = {
            [this.apiDashboardItemCallId]: this.getPrioritiesResponse,
            [this.checkListCallId]: this.checkListResponse,
            [this.selectedCheckListCallId]: this.selectedCheckListResponse,
            [this.submitQuestionListCallId]: this.submitQuestionListResponse,
            [this.getHelloSignURLCallId]: this.getHelloSignURLResponse,
            [this.configmSignDocumentCallId]: this.confirmSignDocumentResponse,
            [this.configmViewDocumentCallId]: this.confirmViewDocumentResponse,
            [this.submitPaymentAPICallId]: this.submitPaymentResponse,
            [this.paymentGatewayCallId]: this.paymentGatewayIdResponse,
        };

        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

        if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
            apiRequestCallIds[apiRequestCallId](responseJson, errorResponse);
        }

        // Customizable Area End

    }

    // Customizable Area Start
    async componentDidMount() {
        console.log("Context in Maindashboard", this.context)
        this.getPriorities();
        this.getChecklistData();
        this.signedHelloSign();
    }

    // Customizable Area End

    // Customizable Area Start


    handleMenuExpand = (e: React.MouseEvent<HTMLButtonElement>) => {
        console.log(e.currentTarget)
        this.setState((prev) => ({
            ...prev,
            dashboardData: {
                ...prev.dashboardData,
                avatarMenuEl: e.currentTarget
            }
        }))
    }

    logout = () => {
        localStorage.removeItem('Token');
        localStorage.removeItem('logo');
        localStorage.removeItem('primaryColor');
        localStorage.removeItem('secondaryColor');
        localStorage.removeItem('email');
        localStorage.removeItem('client_id');
        this.context?.clearThemeContext()
        //@ts-ignore
        this?.props?.history?.push("/signin");
    }


    handleOpenFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState((prev) => ({
            ...prev,
            dashboardData: {
                ...prev.dashboardData,
                filterAnchorEl: event.currentTarget
            }
        }));
    };

    handleMenuClose = () => {
        this.setState((prev) => ({
            ...prev,
            dashboardData: {
                ...prev.dashboardData,
                avatarMenuEl: null
            }
        }))
    };

    handleDialogClose = () => {
        this.setState({ isDialogOpen: false })
    }

    handleDialogOpen = () => {
        this.setState({ isDialogOpen: true })
    }


    handleFilterClose = () => {
        this.setState((prev) => ({
            ...prev,
            dashboardData: {
                ...prev.dashboardData,
                filterAnchorEl: null
            }
        }))
    }


    handleExpand = () => {
        this.setState({ prioritiesExpand: !this.state.prioritiesExpand })
    }



    getPriorities = () => {
        this.setState({
            loading: true
        })
        const header = {
            "token": localStorage.getItem("Token"),
            "Content-Type": configJSON.contentTypeApiGetUserProfile,
        };
        const requestMessage = Api({
            header: header,
            endPoint: configJSON.dashboardGetUrl,
            method: "GET",
        });
        this.apiDashboardItemCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getPrioritiesResponse = (responseJson: any) => {
        if (responseJson && responseJson.priorities) {
            this.setState(prev => ({
                ...prev,
                dashboardData: {
                    ...prev.dashboardData,
                    priorities: responseJson.priorities.data
                }
            }))
        } else {
            this.props.navigation?.navigate('signin');
        }
    }



    getChecklistData = () => {
        const { status, type } = this.state.filterOptionsValue
        const _status = status.length ? status : "";
        const _type = type.length ? type : "";
        this.setState({
            loading: true
        })
        const header = {
            "token": localStorage.getItem("Token"),
            "Content-Type": configJSON.contentTypeApiGetUserProfile,
        };
        const requestMessage = Api({
            header: header,
            endPoint: `${configJSON.checkListAPI}?status=${_status}&type=${_type}`,
            method: "GET",
        });
        this.checkListCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    // Processes document data and payment data into a single array
    getDocumentAndPayment = (documentData: any, paymentData: any) => {
        let documentAndPayment = [];
        if (paymentData?.survey_id > 0) {
            documentAndPayment.push({
                ...paymentData,
                id: paymentData?.survey_id,
                section_name: "Submit Payment",
                status: paymentData?.status ? 'completed' : "to_do",
                type: "payments"
            });
        } else {
            documentAndPayment.push({
                ...paymentData,
                id: new Date(),
                section_name: "Submit Payment",
                status: 'bal',
                type: "payments"
            });
        }
        if (documentData?.length > 0) {
            documentAndPayment.push(...documentData.map((item: any) => ({
                ...item,
                id: item?.document_template_id,
                status: item?.status ? "completed" : "to_do",
                section_name: item?.document_template_title,
                type: "documents"
            })));
        }
        return documentAndPayment;
    };


    checkListResponse = (responseJson: any, errorReponse: any) => {
        const { submittedInfo, selectedDocuments } = this.state;

        if (!responseJson || responseJson.error) {
            this.setState({
                loading: false,
                errorMsg: errorReponse
            });
            return;
        }

        const documentData = responseJson.documents?.data || [];
        const paymentSurveyId = responseJson.documents?.payment?.survey_id;
        const checklistsData = responseJson.checklists?.data || [];

        const documentAndPayment = this.getDocumentAndPayment(documentData, responseJson.documents?.payment);

        const newStaticData = {
            ...staticPaymentsData,
            attributes: {
                ...staticPaymentsData.attributes,
                sections: [...documentAndPayment]
            }
        };

        const documentTemplateId = selectedDocuments.document_template_id;
        const newBase64 = documentTemplateId !== 0
            ? documentData.find((item: any) => item?.document_template_id === documentTemplateId)?.document_url || ""
            : "";

        const newState = {
            loading: false,
            checkListData: documentData.length > 0 || paymentSurveyId
                ? [{ ...newStaticData }, ...checklistsData]
                : checklistsData,
            documentsResponse: responseJson.documents,
            merchant_key: responseJson.documents.payment.public_key,
            errorMsg: "",
            selectedDocuments: {
                ...selectedDocuments,
                document_template_id: (this.state.filterOptionsValue.status.length || this.state.filterOptionsValue.type.length) ? 0 : selectedDocuments.document_template_id,
                document_url: newBase64,
                status: true
            }
        };
        

        const folder = newState.checkListData[0];
        const key = folder.attributes.checklist_name.split(" ").join("_");

        this.setState(newState, () => {
            if (!this.state.selectedItemId) {
                this.handleColapse(key, folder.id, folder.attributes.sections[0]);
                this.hanldeClickChecklistItem(folder.attributes.sections[0]);
            }
            if (checklistsData.find((item: CheckListType) => item.id === submittedInfo.checkList_id)) {
                this.getSelectedChecklistData(this.state.selectedItemId);
            }
        });
    };



    getSelectedChecklistData = (id: any) => {
        this.setState({
            sectionDetailsLoading: true
        })
        const header = {
            "token": localStorage.getItem("Token"),
            "Content-Type": configJSON.contentTypeApiGetUserProfile,
        };
        const requestMessage = Api({
            header: header,
            endPoint: `${configJSON.selectedCheckListAPI}?id=${id}`,
            method: "GET",
        });
        this.selectedCheckListCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }




    selectedCheckListResponse = (responseJson: any, errorResponse: any) => {
        const { data } = responseJson || {};
        const { attributes } = data || {};
        const { questions } = attributes || {};
        const { data: questionData } = questions || {};

        if (!responseJson || responseJson.error) {
            this.setState({
                sectionDetailsLoading: false,
                sectionDetailsErrMsg: errorResponse,
            });
            return;
        }

        const questionList = questionData?.map((item: any) => {
            const { attributes: itemAttributes } = item || {};
            const { question_type, current_gathered_details, question_options } = itemAttributes || {};

            if (question_type === "textfield") {
                return {
                    ...item,
                    attributes: {
                        ...itemAttributes,
                        answer: current_gathered_details || "",
                    },
                };
            }

            return {
                ...item,
                attributes: {
                    ...itemAttributes,
                    question_options: question_options?.map((option: any) => {
                        const isSelect = current_gathered_details.split(",").includes(option);
                        return { name: option, isSelect };
                    }),
                },
            };
        });

        this.setState((prev) => ({
            ...prev,
            sectionDetailsLoading: false,
            sectionDetailsErrMsg: "",
            questionList,
            question_rank: 0,
        }));
    };

    hanldeClickChecklistItem = (item: any) => {
        if (item?.type === "payments") {
            this.setState({
                selectedItemId: item?.id?.toString(),
                selectedPayments: item
            })
            return;
        }
        if (item?.type === "documents") {
            const _selectedDocs = this.state.documentsResponse?.data?.filter((Staticitem: DocumentSignatureI) => Staticitem?.document_template_title === item?.section_name)
            this.setState({
                selectedItemId: item?.id.toString(),
                selectedDocuments: _selectedDocs[0],
                selectedPayments: null
            }, () => console.log("SelectedDocs", this.state.selectedDocuments))
            return;
        }
        this.setState((prev) => {
            return {
                ...prev,
                selectedItemId: item?.id.toString(),
                submittedInfo: {
                    ...prev.submittedInfo,
                    section_id: item?.id.toString(),
                    section_name: item?.section_name
                },
                selectedDocuments: {
                    document_template_id: 0,
                    document_template_title: "",
                    document_url: "",
                    signature: "",
                    status: false,
                    survey_id: -11,
                    signature_request_id: ""
                },
                selectedPayments: null
            }
        }, () => this.getSelectedChecklistData(item?.id.toString()))
    }


    handleColapse = (key: any, id: string, name: string) => {
        this.setState((prev_state) => {
            return {
                ...prev_state,
                open: {
                    ...prev_state.open,
                    [key]: !prev_state.open[key]
                },
                submittedInfo: {
                    ...prev_state.submittedInfo,
                    checkList_id: id,
                    checkList_name: name,
                }
            }
        })
    }

    checkValidation = () => {
        const { questionList, question_rank } = this.state
        let checkError = false;
        const _varifiedQuestion = questionList.map((ques, index) => {
            if (index !== question_rank) return ques;
            if (ques?.attributes?.question_type === "textfield") {
                if (ques?.attributes?.answer?.trim().length) {
                    return {
                        ...ques,
                        attributes: {
                            ...ques?.attributes,
                            isError: "",
                        }
                    }
                } else {
                    checkError = true;
                    return {
                        ...ques,
                        attributes: {
                            ...ques?.attributes,
                            isError: "Please provide an answer before proceeding.",
                        }
                    }
                }
            } else {
                if (ques?.attributes?.question_options.filter((option) => option.isSelect).length > 0) {
                    return {
                        ...ques,
                        attributes: {
                            ...ques?.attributes,
                            isError: "",
                        }
                    }
                } else {
                    checkError = true;
                    return {
                        ...ques,
                        attributes: {
                            ...ques?.attributes,
                            isError: "Please provide an answer before proceeding.",
                        }
                    }
                }
            }
        })
        return {
            _varifiedQuestion,
            checkError
        }
    }

    handleQuestionRank = () => {
        if (this.state.questionList.length > this.state.question_rank) {
            const { checkError, _varifiedQuestion } = this.checkValidation();
            this.setState((prev) => {
                return {
                    ...prev,
                    question_rank: checkError ? prev.question_rank : prev.question_rank + 1,
                    questionList: _varifiedQuestion,
                }
            })
        }
    }

    handleChange = (e: any, filterType: string) => {
        console.log(e);
        e.persist();
        this.setState((prev) => ({
            ...prev,
            filterOptions: {
                ...prev.filterOptions,
                [e.target.name]: e.target.checked,
            },
        }));

        if (e.target.checked) {
            this.setState((prev) => ({
                ...prev,
                filterOptionsValue: {
                    ...prev.filterOptionsValue,
                    [filterType]: [...prev.filterOptionsValue?.[filterType], e.target.value]
                },
            }), () => this.getChecklistData());
        } else {
            const _removeCheck = this.state.filterOptionsValue?.[filterType].filter((item: any) => item !== e.target.value)
            this.setState((prev) => ({
                ...prev,
                filterOptionsValue: {
                    ...prev.filterOptionsValue,
                    [filterType]: _removeCheck
                },
            }), () => this.getChecklistData());
        }

    };

    handleClick = (event: any) => {
        this.setState({ anchorElFilter: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorElFilter: null });
    };

    handleClearfilter = () => {
        this.setState((prev) => ({
            ...prev,
            filterOptions: {
                todo: false,
                inProgress: false,
                business: false,
                personal: false,
                question: false,
                documentNeeded: false,
            },
            filterOptionsValue: {
                status: [],
                type: []
            },
            anchorEl: null
        }), () => this.getChecklistData());
    }

    submitAnswer = () => {
        const { checkList_id, checkList_name, section_id, section_name } = this.state.submittedInfo;
        const { checkError, _varifiedQuestion } = this.checkValidation();
        if (checkError) {
            this.setState({
                questionList: _varifiedQuestion,
            })
            return;
        }
        let responseFormat = {
            checklists: {
                id: Number(checkList_id),
                checklist_name: checkList_name,
                sections_attributes: [
                    {
                        id: Number(section_id),
                        section_name: section_name,
                        questions_attributes: this.state.questionList.map((item: QuestionModel) => {
                            if (item?.attributes?.question_type === "textfield") {
                                return {
                                    id: item?.id,
                                    status: "Submitted",
                                    question_name: item?.attributes?.question_name,
                                    current_gathered_details: item?.attributes?.answer
                                }
                            } else {
                                return {
                                    id: item?.id,
                                    status: "Submitted",
                                    question_name: item?.attributes?.question_name,
                                    current_gathered_details: item?.attributes?.question_options.filter((ans) => ans?.isSelect === true)
                                        .map((ans) => ans.name).toString()
                                }
                            }
                        })
                    }
                ]
            }
        }
        const header = {
            "token": localStorage.getItem("Token"),
            "Content-Type": configJSON.dashboarContentType,
        };
        const requestMessage = Api({
            header: header,
            endPoint: configJSON.submitQuestionListAPI,
            payload: JSON.stringify(responseFormat),
            method: "PATCH",
        });
        this.submitQuestionListCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    submitQuestionListResponse = (responseJson: any, errorReponse: any) => {
        if (responseJson && !responseJson.error) {
            this.setState({
                loading: false,
                sumittedMsgModal: true,
                submittedMsg: responseJson?.message,
                checkListData: [],
                errorMsg: "",
            }, () => this.handleAfterSubmitData())
        } else {
            this.setState({
                loading: false,
                sumittedMsgModal: true,
                submittedMsg: errorReponse?.messsage,
                errorMsg: errorReponse
            })
        }
    }

    handleAfterSubmitData = () => {
        this.getChecklistData()

    }

    isCheckListCompeted = (section: SectionType[]) => {
        const _secLength = section.length;
        const _completedLength = section.filter((sec) => sec.status === "completed").length
    }

    handleOptionChange = (ques_id: string, optionName: string, checked: boolean, multiple: boolean) => {
        this.setState((prev) => {
            return {
                questionList: prev.questionList.map((question: QuestionModel) => {
                    if (question.id === ques_id) {
                        return {
                            ...question,
                            attributes: {
                                ...question.attributes,
                                question_options: question?.attributes?.question_options.map((option) => {
                                    if (option?.name === optionName) {
                                        return {
                                            ...option,
                                            isSelect: checked,
                                        }
                                    }
                                    return {
                                        ...option,
                                        isSelect: multiple ? option?.isSelect : false,
                                    }
                                })
                            }
                        }
                    }
                    return question;
                })
            }
        })
    }

    handleStringChange = (ques_id: string, optionName: string) => {
        this.setState((prev) => {
            return {
                questionList: prev.questionList.map((question: QuestionModel) => {
                    if (question.id === ques_id) {
                        return {
                            ...question,
                            attributes: {
                                ...question.attributes,
                                answer: optionName
                            }
                        }
                    }
                    return question;
                })
            }
        })

    }

    closeModal = () => {
        this.setState({
            sumittedMsgModal: false
        })
    }

    // hello sign code

    getHelloSignURLRequest = () => {
        const signature = this.state.selectedDocuments?.signature
        const header = {
            "token": localStorage.getItem("Token"),
            "Content-Type": configJSON.dashboarContentType,
        };
        const requestMessage = Api({
            header: header,
            endPoint: `${configJSON.getHelloSignURL}?signature=${signature}`,
            method: "GET",
        });
        this.getHelloSignURLCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getHelloSignURLResponse = (responseJson: any, errorReponse: any) => {
        if (responseJson && !responseJson.error) {
            this.setState({
                helloSignURL: responseJson?.data
            }, () => this.launchHelloSign(this.state.helloSignURL))
        }
        else {
            this.setState({ errorMsg: errorReponse })
        }
    }


    launchHelloSign(url: string) {
        client.open(url);
    }

    signedHelloSign = () => {
        client.on("sign", (data) => {
            this.confirmSignDocuments()
        })
    }

    confirmSignDocuments = () => {
        const { survey_id, document_template_id, signature } = this.state.selectedDocuments
        const header = {
            "token": localStorage.getItem("Token"),
            "Content-Type": configJSON.dashboarContentType,
        };
        const requestMessage = Api({
            header: header,
            endPoint: `${configJSON.confrimSignDocuments}?survey_id=${survey_id}&document_template_id=${document_template_id}&signature=${signature}`,
            method: "PUT",
        });
        this.configmSignDocumentCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    confirmSignDocumentResponse = (responseJson: any, errorReponse: any) => {
        if (responseJson && !responseJson.error) {
            this.setState({
                confirmDocumentSign: responseJson?.data,
                documentsLoading: true
            }, () => {
                setTimeout(() => {
                    this.setState({
                        documentsLoading: false
                    }, () => this.confirmViewDocuments())
                }, 10000)
            })
        }
        else {
            this.setState({ errorMsg: errorReponse })
        }
    }

    confirmViewDocuments = () => {
        const { survey_id, document_template_id, signature } = this.state.selectedDocuments
        const header = {
            "token": localStorage.getItem("Token"),
            "Content-Type": configJSON.dashboarContentType,
        };
        const requestMessage = Api({
            header: header,
            endPoint: `${configJSON.confrimViewDocuments}?survey_id=${survey_id}&document_template_id=${document_template_id}&signature=${signature}`,
            method: "GET",
        });
        this.configmViewDocumentCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    confirmViewDocumentResponse = (responseJson: any, errorReponse: any) => {
        if (responseJson && !responseJson.error) {
            this.getChecklistData()
        }
        else {
            this.setState({ errorMsg: errorReponse })
        }
    }

    // dashboard payment code
    closeSnackBarHandler = () => {
        this.setState({
            snackBar: {
                show: false,
                message: "",
                type: undefined,
            },
        });
    };

    openSnackBarHandler = (
        type: "success" | "info" | "warning" | "error" | undefined,
        message: string
    ): void => {
        this.setState({
            snackBar: {
                show: true,
                message: message,
                type,
            },
        });
    };

    paymentGatewayIdResponse = (responseJson: any, errorReponse: any) => {
        if (responseJson && !responseJson.error && !responseJson.messages) {
            this.setState({
                paymentGatewayId: responseJson?.id,
            }, () => this.submitPayment())
        }
        else {
            this.setState({ errorMsg: errorReponse })
            this.openSnackBarHandler("error", "Something went wrong");
            setTimeout(() => {
                this.closeSnackBarHandler();
            }, 2000)
        }
    }

    getPaymentGatewayId = (body: any) => {
        const basicAuthToken = btoa(this.state.merchant_key + ':');
        let header = {
            "Content-Type": "application/json",
            "Authorization": "Basic " + basicAuthToken
        }
        const requestMessage = Api({
            header: header,
            endPoint: configJSON.paymentGatewayIdAPI,
            payload: JSON.stringify(body),
            method: "POST",
        });
        this.paymentGatewayCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    createPaymentRequest = async (values: any, type: "echeck" | "card") => {
        console.log({ values })

        try {
            if (type === "card") {
                const [exp_month, exp_year] = values.expiry.split("/");
                const reqBody = {
                    type: type,
                    number: values.cardNumber,
                    exp_month: exp_month,
                    exp_year: `20${exp_year}`,
                    cvv: values.cvv
                }

                this.getPaymentGatewayId(reqBody);
            } else if (type === "echeck") {
                const reqBody = {
                    'type': 'bank',
                    'routing_number': values.routingNumber,
                    'account_number': values.accountNumber,
                    'account_type': values.accountType === 'Savings Account' ? 'SAVINGS' : 'CHECKING',
                    'account_holder_type': values.accountHolderType === 'Individual Account' ? 'individual' : 'business',
                    'business_type': values.businessName
                }

                this.getPaymentGatewayId(reqBody);
            }
        } catch (err) {
            console.log(err);
        }
    }

    submitPaymentResponse = (responseJson: any, errorReponse: any) => {
        if (responseJson && !responseJson.errors) {
            this.openSnackBarHandler("success", "Success");
            setTimeout(() => {
                this.props?.history?.push({
                    pathname: "/dashboard/payments"
                });
            }, 2000);
            this.openSnackBarHandler("success", "Success");
            setTimeout(() => {
                this.closeSnackBarHandler();
            }, 2000);
        } else if (errorReponse) {
            this.openSnackBarHandler("error", errorReponse);
            setTimeout(() => {
                this.closeSnackBarHandler();
            }, 2000)
        } else {
            this.openSnackBarHandler("error", responseJson.errors);
            setTimeout(() => {
                this.closeSnackBarHandler();
            }, 2000);
        }
    }

    submitPayment = () => {

        let header = {
            "token": localStorage.getItem("Token"),
            "Content-Type": "application/json",
        }

        const body = {
            data: {
                attributes: {
                    'amount': this.state.selectedPayments?.amount,
                    'survey_id': this.state.selectedPayments?.survey_id,
                    'method': this.state.paymentGatewayId,
                    'method_type': 'random'
                }
            }
        }

        const requestMessage = Api({
            header: header,
            endPoint: configJSON.submitPaymentAPI,
            payload: JSON.stringify(body),
            method: "POST",
        });
        this.submitPaymentAPICallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    // Customizable Area End
}

//Mocked Data
const dummyAccoutingChecklist = [
    {
        id: 'c7382939-4772-473d-aedc-1c9030ba01ba',
        title: 'W2 Requested',
        type: 'Business',
        isChecked: 'false',
        payment_details: {
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit voluptatibus a ex magnam neque eligendi ratione voluptate excepturi ad eum facere dolore nemo quia, deleniti sapiente doloribus sit dolorum tempora!',
            amount: '3.22'
        },
    },
    {
        id: '2b304f78-c073-4c19-afe7-fe1487da0d2e',
        title: 'Submit Payment',
        type: 'Personal',
        isChecked: 'false',
        payment_details: {
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit voluptatibus a ex magnam neque eligendi ratione voluptate excepturi ad eum facere dolore nemo quia, deleniti sapiente doloribus sit dolorum tempora!',
            amount: '10.50'
        },
    }, {
        id: '7bd1b6e0-c9f7-490e-870f-aa4b39ad7c63',
        title: 'eSign__Disclosure',
        type: 'Personal',
        isChecked: 'false',
        payment_details: {
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit voluptatibus a ex magnam neque eligendi ratione voluptate excepturi ad eum facere dolore nemo quia, deleniti sapiente doloribus sit dolorum tempora!',
            amount: '32.00'
        },
    }
]

const dummyPayrollChecklist = [
    {
        id: 'c7382939-4772-473d-aedc-1c9030ba01ba',
        title: 'W2 Requested',
        type: 'Personal',
        isChecked: 'true',
        payment_details: {
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magni aperiam maiores illum, at dolores quibusdam voluptate magnam alias fugiat corporis nulla expedita ab veritatis quasi soluta temporibus ea repudiandae Quod eligendi quibusdam nesciunt tempore saepe, tempora, pariatur expedita voluptate eaque distinctio placeat soluta, iste omnis molestias. Tempore soluta ut eum excepturi sint error, vitae quisquam. Sequi a accusamus fugiat!Dignissimos quibusdam suscipit laboriosam cumque expedita sapiente explicabo error sequi quas non mollitia molestias voluptate quos, sed porro magnam reiciendis aliquam ut architecto placeat nam id ab et officiis. Ea.Temporibus, pariatur! Excepturi harum sint iusto officia ratione nihil eos soluta, expedita minus doloribus error iure ad repudiandae est magni. Vero ratione est consequatur consequuntur unde porro repudiandae sit. Veniam.Eius est commodi rerum aut, quasi, quisquam autem unde libero nobis facilis, blanditiis iste! Voluptates voluptate earum dolore quasi? Accusamus voluptas ducimus corporis, doloremque beatae eligendi voluptate nostrum accusantium delectus',
            amount: '21.00'
        },
    },
    {
        id: '2b304f78-c073-4c19-afe7-fe1487da0d2e',
        title: 'eSign__Disclosure',
        type: 'Business',
        isChecked: 'false',
        payment_details: {
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit voluptatibus a ex magnam neque eligendi ratione voluptate excepturi ad eum facere dolore nemo quia, deleniti sapiente doloribus sit dolorum tempora!',
            amount: '34.90'
        },
    },
    {
        id: '7bd1b6e0-c9f7-490e-870f-aa4b39ad7c63',
        title: 'W2 Requested',
        type: 'Personal',
        isChecked: 'true',
        payment_details: {
            description: 'Lorem ipsum dolor sit amet, distinctio placeat soluta, iste omnis molestias. Tempore sint error, vitae quisquam. Sequi a accusamus fugiat!Dignissimos quibusdam suscipit laboriosam cumque expedita sapiente explicabo error sequi quas non mollitia molestias voluptate quos, sed porro magnam reiciendis aliquam ut architecto placeat nam id ab et officiis. Ea.Temporibus, pariatur! Excepturi harum sint iusto officia ratione nihil eos soluta, expedita minus doloribus error iure ad repudiandae est magni. Vero ratione est consequatur consequuntur unde porro repudiandae sit. Veniam.Eius est commodi rerum aut, quasi, quisquam autem unde libero nobis facilis, blanditiis iste! Voluptates voluptate earum dolore quasi? Accusamus voluptas ducimus corporis, doloremque beatae eligendi voluptate nostrum accusantium delectus',
            amount: '21.20'
        },
    },
]

const questionData = [
    {
        id: "27",
        type: "question",
        attributes: {
            id: 27,
            question_name: "Do you currently have any loans against the business?",
            checked: true,
            client_status: "",
            request_document: true,
            status: "Requested/Submitted",
            current_gathered_details: "",
            question_rank: null,
            question_options: [
                "yes", "no"
            ],
            question_type: "radio",
            document: null
        }
    },
    {
        id: "28",
        type: "question",
        attributes: {
            id: 28,
            question_name: "IF YES - How Many?.",
            checked: true,
            client_status: "",
            request_document: true,
            status: "Requested/Submitted",
            current_gathered_details: "",
            question_rank: null,
            question_options: [
                "none", "1", "2", "more than 2"
            ],
            question_type: "dropdown",
            document: null
        }
    },
    {
        id: "28",
        type: "question",
        attributes: {
            id: 28,
            question_name: "IF YES - Date the loan was funded, Organization/Person Owed, Original Loan Amount, Current Balance, Payment, Interest Rate.",
            checked: true,
            client_status: "",
            request_document: true,
            status: "Requested/Submitted",
            current_gathered_details: "",
            question_rank: null,
            question_options: [

            ],
            question_type: "string",
            document: null
        }
    },
    {
        id: "28",
        type: "question",
        attributes: {
            id: 28,
            question_name: "Did you exprience any of these life event? Select all that will apply",
            checked: true,
            client_status: "",
            request_document: true,
            status: "Requested/Submitted",
            current_gathered_details: "",
            question_rank: null,
            question_options: [

                "Purchase or sold real state prperty",

                "Divorsed",

                "Lost properties",

                "Started a Business",

                "Donated to charity",

                "Moved Addresses"

            ],
            question_type: "checkbox",
            document: null
        }
    },
]




import { Typography, Box, ListItem, Collapse, List } from "@material-ui/core";
import {
    makeStyles,
    createStyles,
    Theme,
} from "@material-ui/core/styles";
import React from "react";
import { DocumentsModel, SelctedPdfModel } from "../../blocks/dashboard/src/Interface";
import { downIcon, rightIcon } from "../../blocks/dashboard/src/assets";
import { showPdfName } from "./Utils";
interface DocumentListProps {
    allDocsResponse: any;
    handleColapse: (id: any) => void;
    open: any;
    handleSelectedUploaded: (item: DocumentsModel) => void;
    selectedPdf: SelctedPdfModel;
}

const DocumentList = ({ allDocsResponse, handleColapse, open, handleSelectedUploaded, selectedPdf }: DocumentListProps) => {
    const classes = useStyles();
    let docsData: any[] = []
    docsData = Object.keys(allDocsResponse)

    return (
        <Box>
            {
                docsData?.length > 0 &&
                docsData?.map((folder, index) => {
                    const fileList = allDocsResponse[folder][0]?.data;
                    const _key = folder.split(" ").join("_");
                    console.log({ fileList })
                    return (
                        <React.Fragment key={_key}>
                            <ListItem

                                style={{
                                    borderRadius: '6px',
                                    marginTop: '20px',
                                    borderBottom: "1px solid #E6EAE9"
                                }}
                                button
                                onClick={() => handleColapse(_key)}
                            >
                                {open[_key] ? <img src={downIcon} height="8px"
                                />
                                    : <img src={rightIcon} height="14px"
                                    />
                                }
                                <Typography
                                    style={{
                                        paddingLeft: "15px",
                                        fontFamily: 'Rubik-Regular',
                                        fontSize: '16px',
                                        marginRight: '40px'
                                    }}
                                >
                                    {folder}
                                </Typography>

                            </ListItem>
                            <Collapse in={open[_key]} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {
                                        fileList?.length > 0 &&
                                        fileList?.map((item: any, index: number) => {

                                            const _key = item?.type + index;
                                            if (item?.type === "client_submited_document") {
                                                const documentList = item?.attributes?.document;
                                                return (
                                                    <>
                                                        <ListItem

                                                            style={{
                                                                borderRadius: '6px',
                                                                marginTop: '20px',
                                                                borderBottom: "1px solid #E6EAE9",
                                                                marginLeft: "25px",
                                                                width: "93%"
                                                            }}
                                                            button
                                                            onClick={() => handleColapse(_key)}
                                                        >
                                                            {open[_key] ? <img src={downIcon} height="8px"
                                                            />
                                                                : <img src={rightIcon} height="14px"
                                                                />
                                                            }
                                                            <Typography
                                                                style={{
                                                                    paddingLeft: "15px",
                                                                    fontFamily: 'Rubik-Regular',
                                                                    fontSize: '16px',
                                                                    marginRight: '40px'
                                                                }}
                                                            >
                                                                {item?.attributes?.checklist_name}
                                                            </Typography>

                                                        </ListItem>
                                                        <Collapse in={open[_key]} timeout="auto" unmountOnExit>
                                                            {
                                                                documentList.length > 0 &&
                                                                documentList.map((item: any, index: number) => {
                                                                    return (
                                                                        <ListItem
                                                                            key={item?.id}
                                                                            button
                                                                            className={selectedPdf?.id.toString() === item?.id.toString() ? classes.selectedNested : classes.nested}
                                                                            onClick={() => handleSelectedUploaded(item)}
                                                                        >
                                                                            <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                                <Typography style={{ fontFamily: "Rubik-Regular", fontSize: "16px" }}>
                                                                                    {showPdfName(item?.attributes?.file_name)}
                                                                                </Typography>
                                                                                <Typography style={{ fontFamily: "Rubik-Regular", fontSize: "12px", marginRight: "10px" }}>
                                                                                    {item?.attributes?.date_completed}
                                                                                </Typography>
                                                                            </Box>
                                                                        </ListItem>
                                                                    )
                                                                })
                                                            }
                                                        </Collapse>
                                                    </>
                                                )
                                            }
                                            return (

                                                <ListItem
                                                    key={item?.id}
                                                    button
                                                    className={selectedPdf?.id.toString() === item?.id.toString() ? classes.selectedNested : classes.nested}
                                                    onClick={() => handleSelectedUploaded(item)}
                                                >
                                                    <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                        <Typography style={{ fontFamily: "Rubik-Regular", fontSize: "16px" }}>
                                                            {showPdfName(item?.attributes?.file_name)}
                                                        </Typography>
                                                        <Typography style={{ fontFamily: "Rubik-Regular", fontSize: "12px", marginRight: "10px" }}>
                                                            {item?.attributes?.date_completed}
                                                        </Typography>
                                                    </Box>
                                                </ListItem>
                                            )
                                        })
                                    }
                                </List>
                            </Collapse>
                        </React.Fragment>
                    )
                })
            }
        </Box>
    )
}
export default DocumentList;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        selectedNested: {
            paddingLeft: '80px',
            border: `1px solid ${localStorage.getItem("primaryColor") || "#67a1a3"}`,
            borderRight: "none",
            borderRadius: "10px 0 0 10px"
        },
        nested: {
            paddingLeft: '80px',
            borderBottom: "1px solid #E6EAE9"
        },
    })
);
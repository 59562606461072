import { gauravIcon, aliciaIcon, businessIcon, profileIcon, groupIcon, judyIcon } from "../../blocks/user-profile-basic/src/assets";

export const fakeUserData = [
    {
        id: 1,
        pImg: gauravIcon,
        firstName: "Alexander",
        lastName: "Ljung",
        email: "alenxander123@gmail.com",
        phoneNumber: "(000) 000-0000",
        pIcon: profileIcon,
        status: "Personal",
        address: [{
            id: 1,
            value: "",
            isPrimary: true,
        }],
    },
    {
        id: 2,
        pImg: aliciaIcon,
        firstName: "Alicia",
        lastName: "Stanger",
        email: "alicia123@gmail.com",
        phoneNumber: "(000) 000-0000",
        pIcon: groupIcon,
        status: "Spouse",
        address: [{
            id: 1,
            value: "",
            isPrimary: true,
        }],
    },
    {
        id: 3,
        pImg: "",
        firstName: "Carla",
        lastName: "Wildner",
        email: "carla123@gmail.com",
        phoneNumber: "(000) 000-0000",
        pIcon: groupIcon,
        status: "Dependent",
        address: [{
            id: 1,
            value: "",
            isPrimary: true,
        }],
    },
    {
        id: 4,
        pImg: "",
        firstName: "Beth",
        lastName: "Murfy",
        email: "bethmurfy123@gmail.com",
        phoneNumber: "(000) 000-0000",
        pIcon: groupIcon,
        status: "Dependent",
        address: [{
            id: 1,
            value: "",
            isPrimary: true,
        }],
    },
    {
        id: 5,
        pImg: businessIcon,
        firstName: "Beth",
        lastName: "Murfy",
        email: "bethmurfy123@gmail.com",
        phoneNumber: "(000) 000-0000",
        pIcon: groupIcon,
        status: "Business",
        address: [{
            id: 1,
            value: "",
            isPrimary: true,
        }],
    }
]

export const fakeBusinessData = fakeUserData.filter(data => data.status === "Business");

export const fakeOnlyUserData = fakeUserData.filter(data => data.status !== "Business");

export const fakeAllBusinessData = [
    {
        id: 1,
        img: judyIcon,
        firstName: "Judy",
        email: "judy123@gmail.com",
        dateAdded: "09/03/2011",
        phn: "(000) 000-0000",
        address: [{
            id: 1,
            value: "",
        }],
    },
    {
        id: 2,
        img: judyIcon,
        firstName: "Judy",
        email: "judy123@gmail.com",
        dateAdded: "09/03/2011",
        phn: "(000) 000-0000",
        address: [{
            id: 1,
            value: "",
        }],
    },
    {
        id: 3,
        img: judyIcon,
        firstName: "Judy",
        email: "judy123@gmail.com",
        dateAdded: "09/03/2011",
        phn: "(000) 000-0000",
        address: [{
            id: 1,
            value: "",
        }],
    },
    {
        id: 4,
        img: judyIcon,
        firstName: "Judy",
        email: "judy123@gmail.com",
        dateAdded: "09/03/2011",
        phn: "(000) 000-0000",
        address: [{
            id: 1,
            value: "",
        }],
    },
    {
        id: 5,
        img: judyIcon,
        firstName: "Judy",
        email: "judy123@gmail.com",
        dateAdded: "09/03/2011",
        phn: "(000) 000-0000",
        address: [{
            id: 1,
            value: "",
        }],
    },
    {
        id: 6,
        img: judyIcon,
        firstName: "Judy",
        email: "judy123@gmail.com",
        dateAdded: "09/03/2011",
        phn: "(000) 000-0000",
        address: [{
            id: 1,
            value: "",
        }],
    },
    {
        id: 7,
        img: judyIcon,
        firstName: "Judy",
        email: "judy123@gmail.com",
        dateAdded: "09/03/2011",
        phn: "(000) 000-0000",
        address: [{
            id: 1,
            value: "",
        }],
    }
]

export const pdfList = [
    {
        id: 1,
        name: "CheckList 1"
    },
    {
        id: 2,
        name: "CheckList 2"
    },
    {
        id: 3,
        name: "CheckList 3"
    },
]

export const getFirstLetters = (str: string) => {
    const firstLetters = str
        .split(' ')
        .map(word => word[0])
        .join('');

    return firstLetters.toLocaleUpperCase();
}

export const showFileName = (str: string) => {
    const withoutExt = str.split(".");
    let result;
    if (withoutExt[0].length > 6) {
        result = withoutExt[0].slice(0, 3).concat("...").concat(withoutExt[0].slice(-2).concat("." + withoutExt[1]))
    } else {
        result = withoutExt[0].concat("." + withoutExt[1]);
    }
    if (!result) {
        return "No File";
    } else {
        return result;
    }

}

export const showPdfName = (str: string) => {
    const withoutExt = str.split(".");
    let result;
    if (withoutExt[0].length > 12) {
        result = withoutExt[0].slice(0, 9).concat("...").concat(withoutExt[0].slice(-2).concat("." + withoutExt[1]))
    } else {
        result = withoutExt[0].concat("." + withoutExt[1]);
    }
    if (!result) {
        return "No File";
    } else {
        return result;
    }

}

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

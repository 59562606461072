import React, { Component } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  withStyles,
  Typography
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import { withRouter, Link } from "react-router-dom";
import { ThemeContext } from "../../blocks/notifications/src/Context/ThemeContext";

type MyProps = {
  history: any;
  location: any;
};

type RouteType = "My Dashboard" | "Completed Items" | "Documents" | "Payments" | "";
type MyState = {
  selectedTab: RouteType;
  openSettings: boolean;
  settingOption: boolean;
};

const muiStyles = (theme: any) => ({
  icon: {
    minWidth: "40px",
  },
  icon_selected: {
    height: 30,
    borderRadius: 10,
    border: `3px solid ${localStorage.getItem("secondaryColor")}`,
    marginRight: -6,
    zIndex: 9999,
  },
  selected: {
    backgroundColor: "#e4e7e8 !important",
    // fontFamily: "Rubik-Medium"
  },
  payments_subnav: {
    backgroundColor: "#f8fbfa !important",
    // color: `${localStorage.getItem("secondaryColor")}`,
    fontFamily: "Rubik, san-serif"
  }
});

class SideBar extends Component<MyProps, MyState> {
  static contextType: any = ThemeContext;
  constructor(props: any) {
    super(props);
    this.state = {
      selectedTab: "",
      openSettings: false,
      settingOption: false,
    };
  }

  handleRouteChange = (route: RouteType) => {
    this.setState({
      selectedTab: route,
    });
  };

  toggleSetting = () => {
    this.setState({ openSettings: !this.state.openSettings })
  }

  componentDidUpdate(prevProps: any) {
    const path = this?.props?.location?.pathname;

    if (this.props.location?.pathname !== prevProps.location?.pathname) {
      // Perform update logic here
      // You can use this.props.location to get the current location
      if (path.match(/documents/gi)) {
        this.setState({ selectedTab: "Documents" })
      }else if (path.match(/payments/gi)) {
        this.setState({ selectedTab: "Payments" })
      }
    }

  }

  async componentDidMount() {
    const path = this?.props?.location?.pathname;
    if (path.match(/main/gi)) {
      this.setState({ selectedTab: "My Dashboard" })
    }
    else if (path.match(/payments/gi)) {
      this.setState({ selectedTab: "Payments" })
    }
    else if (path.match(/items/gi)) {
      this.setState({ selectedTab: "Completed Items" })
    }
    else if (path.match(/documents/gi)) {
      this.setState({ selectedTab: "Documents" })
    }
  }

  render() {
    const { classes } = this.props as any;
    const path = this?.props?.location?.pathname;
    return (
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        py={4}
        flex={1}
      >
        <Box>
          <List style={{ width: "100%" }}>
            {routes.map((route, index) => (
              <Link
                to={route.route}
                style={{ textDecoration: "none", color: "#000" }}
                key={index}
              >
                <Box
                  width="80%"
                  margin="0 auto"
                  display="flex"
                  alignItems="center"
                  onClick={() =>
                    this.handleRouteChange(route.title as RouteType)
                  }
                >
                  {this.state.selectedTab === route.title && (
                    <Box className={classes.icon_selected} />
                  )}
                  <ListItem
                    button
                    key={index}
                    selected={
                      this.state.selectedTab === route.title
                    }
                    className={
                      this.state.selectedTab === route.title
                        ? classes.selected
                        : ""
                    }
                    style={{
                      borderRadius: "10px",
                      margin: "5 0",

                      fontFamily: this.state.selectedTab === route.title ? 'Rubik-Medium' : 'Rubik-Regular',
                    }}
                    alignItems="center"
                  >
                    <ListItemText
                      primary={route.title}
                      primaryTypographyProps={{
                        style: {
                          // fontWeight:
                          //   this.state.selectedTab === route.title ? 900 : 700,
                          fontFamily: this.state.selectedTab === route.title ? 'Rubik-Medium' : 'Rubik-Regular',

                        },
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        padding: "0 5px",
                        fontFamily: this.state.selectedTab === route.title ? 'Rubik-Medium' : 'Rubik-Regular',

                      }}
                    />
                  </ListItem>
                </Box>
              </Link>
            ))}
            {
              this.state.selectedTab === "Payments" &&
              <ListItem
                button
                selected={
                  this.state.selectedTab === "Payments"
                }
                disabled={path.match(/auth/gi)}
                className={path.match(/auth/gi) ? classes.selected : classes.payments_subnav}
                style={{
                  borderRadius: "10px",
                  margin: "5px auto",
                  width: "fit-content",
                }}
                alignItems="center"
                onClick={() =>
                  this.props.history.push("/dashboard/payments/auth")
                }
              >
                <ListItemText
                  primary={"Payment Authorization Forms"}
                  primaryTypographyProps={{
                    style: {
                      fontWeight: 900,
                      fontSize: 14,
                    },
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                />
              </ListItem>
            }
          </List>
        </Box>
        <Box>
          {
            this.state.openSettings ?
              <List style={{ width: "100%" }}>
                <Box style={{
                  borderRadius: "10px 10px 0 0",
                  margin: "-5px 31px",
                  backgroundColor: "#E7EAEE",
                  padding: "5px 18px",
                }}>
                  <Link to="/ChangePassword" style={{ textDecoration: "none", color: "black" }}>
                    <Typography style={{ marginTop: "10px", marginBottom: "20px", cursor: "pointer", fontFamily: "Rubik-Regular" }}>Change Password</Typography>
                  </Link>
                  <Typography style={{ marginTop: "10px", marginBottom: "20px", cursor: "pointer", fontFamily: "Rubik-Regular" }}>Request profile update</Typography>
                  <Link to="/ManageUsers" style={{ textDecoration: "none", color: "black" }}><Typography style={{ marginBottom: "10px", cursor: "pointer", fontFamily: "Rubik-Regular", fontWeight: this.state.settingOption ? "bold" : "normal" }}>Users</Typography></Link>
                </Box>
                {["Settings"].map((text, index) => (
                  <Box width="80%" margin="0 auto" key={text}>
                    <ListItem
                      button
                      onClick={() => this.toggleSetting()}
                      key={text}
                      style={{
                        borderTop: "1px solid #67a1a3",
                        borderRadius: "0 0 10px 10px",
                        margin: "5 0",
                      }}
                      alignItems="center"
                      className={index === 0 ? classes.selected : ""}
                    >
                      <ListItemIcon className={classes.icon}>
                        <SettingsIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={text}
                        primaryTypographyProps={{
                          style: { fontFamily: "Rubik-Regular", },
                        }}
                      />
                    </ListItem>
                  </Box>
                ))}
              </List>
              :
              <List style={{ width: "100%" }}>
                {["Settings"].map((text) => (
                  <Box width="80%" margin="0 auto" key={text}>
                    <ListItem
                      button
                      onClick={() => this.toggleSetting()}
                      key={text}
                      style={{
                        borderRadius: "10px",
                        margin: "5 0",
                        fontFamily: "Rubik-Regular",

                      }}
                      alignItems="center"
                    >
                      <ListItemIcon className={classes.icon}>
                        <SettingsIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={text}
                        primaryTypographyProps={{
                          style: { fontFamily: "Rubik-Regular" },

                        }}
                      />
                    </ListItem>
                  </Box>
                ))}
              </List>
          }
        </Box>
      </Box>
    );
  }
}

const routes = [
  {
    title: "My Dashboard",
    route: "/dashboard/main",
  },
  {
    title: "Completed Items",
    route: "/dashboard/items",
  },
  {
    title: "Documents",
    route: "/dashboard/documents",
  },
  {
    title: "Payments",
    route: "/dashboard/payments",
  },
];

//@ts-ignore
export default withStyles(muiStyles)(withRouter(SideBar));

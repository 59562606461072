import { Grid, Box, Typography, } from "@material-ui/core";
import {
    makeStyles,
    createStyles,
    Theme,
} from "@material-ui/core/styles";
import React from "react";
import { AddCircleOutline } from "@material-ui/icons"
import { useTheme } from "../../blocks/notifications/src/Context/ThemeContext";
export interface NameProps {
    dependent?: string;
    business: string;
    handleOpenUsers: () => void;
}


const AddNewEditBtn = ({ dependent, business, handleOpenUsers }: NameProps) => {
    const classes = useStyles();
    const { primaryColor } = useTheme()
    return (
        <>
            {
                business.toLowerCase() === "client_businesses" || business.toLowerCase() === "business" ?
                    <Box style={{ marginTop: "20px" }}>
                        <Box style={{ border: "1px solid #a8b0b6", borderRadius: "10px", padding: "5%" }}>
                            <Grid container justifyContent="center">
                                <Grid item md={8} xs={12}>
                                    <Box className={classes.wrapper_view} onClick={handleOpenUsers}>
                                        <AddCircleOutline style={{color: primaryColor}} />
                                        <Typography style={{ marginLeft: "10px", fontSize: "20px", color: primaryColor, fontFamily: "Rubik-Regular" }}>Add {dependent?.toLowerCase() === "dependent" ? "Dependents" : "Shareholders"}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    :
                    ""
            }
        </>
    )
}
export default AddNewEditBtn;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper_view: {
            width: "100%",
            // backgroundColor: "#e7eaef",
            border: "1px dashed #a8b0b6",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            // height: "50px",
            padding: "20px 0",
            cursor: "pointer",
        },
    })
);
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { AddressType, ShareholderType, UserInfoI } from "./Interface";
import Api from "../../../components/src/API";
import * as Yup from 'yup';

export interface UserError {
    business_name?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    email?: string | null;
    phone_number?: string | null;
}
// Customizable Area End
export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    updatedUserInfo: UserInfoI;
    addUserResponse: string;
    openAddUser: boolean;
    openStatus: boolean;
    openErrorStatus: boolean;
    userId: number;
    userType: string;
    errorMsg: string;
    singleUerErrMsg: string;
    inputError: any;
    previewImg: string;
    errors: UserError;
    accessPermissionId: string;
    accessPermissionStatus: string;
    openAcessPermission: boolean;
    openAccessPermissionResponse: boolean;
    anchorEl: any;
    shareholders: ShareholderType[]
    removeShareholder: boolean;
    removeShareholderId: number;
    setEnableBtn: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CreateUserController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    createUserDataCallId: string = "";
    accessPermissionCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            errorMsg: "",
            updatedUserInfo: {
                id: "1",
                type: "",
                attributes: {
                    id: 1,
                    business_name: "",
                    first_name: "",
                    last_name: "",
                    email: "",
                    phone_number: "",
                    relationship: "",
                    primary_user: false,
                    user_type: "",
                    client_information_id: 1,
                    image: "",
                    addresses: {
                        data: []
                    },
                    date_added: ""
                }
            },
            openAddUser: false,
            openStatus: false,
            addUserResponse: "",
            userId: this.props.navigation.getParam("id"),
            userType: "",
            singleUerErrMsg: "",
            accessPermissionId: "",
            openAcessPermission: false,
            openAccessPermissionResponse: false,
            accessPermissionStatus: "",
            inputError: {},
            previewImg: "",
            errors: {},
            anchorEl: null,
            shareholders: [],
            removeShareholder: false,
            removeShareholderId: 0,
            setEnableBtn: false,
            openErrorStatus: false,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start

        const apiRequestCallIds = {
            [this.createUserDataCallId]: this.getCreateUserResponse,
            [this.accessPermissionCallId]: this.getAccessPermissionResponse
        };

        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

        if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
            apiRequestCallIds[apiRequestCallId](responseJson, errorResponse);
        }
        // Customizable Area End

    }
    // Customizable Area Start
    async componentDidMount() {
        const searchParams = new URLSearchParams(document?.location?.search).get("type")
        if (searchParams) {
            this.setState((prev) => {
                return {
                    userType: searchParams,
                    updatedUserInfo: {
                        ...prev.updatedUserInfo,
                        attributes: {
                            ...prev.updatedUserInfo.attributes,
                            user_type: searchParams,
                            relationship: "Spouse"
                        }
                    }
                }
            })
        }
    }

    // Customizable Area End

    // Customizable Area Start



    getAccessPermissionResponse = (responseJson: any, errorReponse: any) => {
        if (responseJson && !responseJson.error) {
            this.setState({
                ...this.state,
                accessPermissionStatus: responseJson.message,
                openAcessPermission: false,
                openAccessPermissionResponse: true,
            });
        } else {
            this.setState({
                accessPermissionStatus: errorReponse,
                openAcessPermission: false,
                openAccessPermissionResponse: true,
            });
        }
    }

    getAccessPermission = () => {
        const header = {
            "token": localStorage.getItem("Token"),
            "Content-Type": configJSON.contentTypeApiGetUserProfile,
        };
        const requestMessage = Api({
            header: header,
            endPoint: `${configJSON.apiGetAccessPermission}/${this.state.accessPermissionId}`,
            method: "GET",
        });
        this.accessPermissionCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    getCreateUserResponse = (responseJson: any, errorReponse: any) => {
        if (responseJson.data && !responseJson.error) {
            this.setState({
                openAddUser: false,
                openStatus: true,
                addUserResponse: "Added successfully!",
                errorMsg: "",
                updatedUserInfo: {
                    id: "1",
                    type: "",
                    attributes: {
                        id: 1,
                        business_name: "",
                        first_name: "",
                        last_name: "",
                        email: "",
                        phone_number: "",
                        relationship: "",
                        primary_user: false,
                        user_type: "",
                        client_information_id: 1,
                        image: "",
                        addresses: {
                            data: []
                        },
                        date_added: ""
                    }
                },
                shareholders: [],
                setEnableBtn: false,
            })
        } else if (!responseJson.data){
            const dynamicObjectName = Object.keys(responseJson)[0];
            const valueWithoutUnderscores = dynamicObjectName.replace(/_/g, ' '); // 'myvalue'
            const dynamicObjectValue = responseJson[dynamicObjectName][0];
            const finalValue = valueWithoutUnderscores.charAt(0).toUpperCase() + valueWithoutUnderscores.slice(1) + ' ' + dynamicObjectValue;
            console.log({finalValue})
            this.setState({
                addUserResponse: finalValue,
                openErrorStatus: true,
            })
        } else {
            if (errorReponse === undefined) {
                this.setState({
                    openErrorStatus: true,
                    errorMsg: "Sorry something went wrong",
                    addUserResponse: "Sorry something went wrong",
                    updatedUserInfo: {
                        id: "1",
                        type: "",
                        attributes: {
                            id: 1,
                            business_name: "",
                            first_name: "",
                            last_name: "",
                            email: "",
                            phone_number: "",
                            relationship: "",
                            primary_user: false,
                            user_type: "",
                            client_information_id: 1,
                            image: "",
                            addresses: {
                                data: []
                            },
                            date_added: ""
                        }
                    },
                    shareholders: [],
                    setEnableBtn: false
                });
            } else {
                this.setState({
                    errorMsg: errorReponse,
                    addUserResponse: errorReponse,
                    updatedUserInfo: {
                        id: "1",
                        type: "",
                        attributes: {
                            id: 1,
                            business_name: "",
                            first_name: "",
                            last_name: "",
                            email: "",
                            phone_number: "",
                            relationship: "",
                            primary_user: false,
                            user_type: "",
                            client_information_id: 1,
                            image: "",
                            addresses: {
                                data: []
                            },
                            date_added: ""
                        }
                    },
                    shareholders: [],
                    setEnableBtn: false
                });
            }

        }
    }

    appendUserFields = (payload: any, fields: any) => {
        const { first_name, last_name, relationship, primary_user } = fields;
        payload.append("user[first_name]", first_name);
        payload.append("user[last_name]", last_name);
        payload.append("user[relationship]", relationship);
        payload.append("user[primary_user]", JSON.stringify(primary_user));
    }

    appendAddresses = (payload: any, addresses: AddressType[]) => {
        addresses.length && addresses.forEach(async (item, i) => {
            item?.id && payload.append(`user[addresses_attributes][${i + 1}]id`, item?.attributes.id.toString());
            payload.append(`user[addresses_attributes][${i + 1}]address`, item?.attributes?.address);
            payload.append(`user[addresses_attributes][${i + 1}]primary_address`, item?.attributes?.primary_address.toString());
            item?.attributes?._destroy === 1 && payload.append(`user[addresses_attributes][${i + 1}]_destroy`, item?.attributes._destroy.toString());
        })
    }

    appendShareholders = (payload: any, shareholders: ShareholderType[]) => {
        shareholders?.length && shareholders?.forEach(async (item, i) => {
            !item?.attributes?.isNew && payload.append(`user[shareholders_attributes][${i + 1}]id`, item?.attributes?.id?.toString());
            payload.append(`user[shareholders_attributes][${i + 1}]email`, item?.attributes?.email);
            payload.append(`user[shareholders_attributes][${i + 1}]first_name`, item?.attributes?.first_name);
            payload.append(`user[shareholders_attributes][${i + 1}]last_name`, item?.attributes?.last_name);
            payload.append(`user[shareholders_attributes][${i + 1}]phone_number`, item?.attributes?.phone_number);
            item?.attributes?._destroy === 1 && payload.append(`user[shareholders_attributes][${i + 1}]_destroy`, item?.attributes?._destroy?.toString());
        })
    }

    createUserData = () => {
        const { image, business_name, first_name, last_name, email,
            phone_number, relationship, primary_user,
            user_type, addresses } = this.state.updatedUserInfo.attributes;
        const { shareholders } = this.state;

        const payload = new FormData();

        if (user_type === "user") {
            this.appendUserFields(payload, { first_name, last_name, relationship, primary_user });
        } else {
            business_name && payload.append("user[business_name", business_name);
        }

        phone_number && payload.append("user[phone_number]", phone_number);
        email && payload.append("user[email]", email);
        user_type && payload.append("user[user_type]", user_type);
        image?.name && payload.append("user[image]", image);
        this.appendAddresses(payload, addresses.data);
        this.appendShareholders(payload, shareholders);


        const header = {
            "token": localStorage.getItem("Token"),
        };


        const requestMessage = Api({
            header: header,
            endPoint: `${configJSON.apiAddUserData}`,
            payload: payload,
            method: "POST",
        });
        this.createUserDataCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }



    sendNotification = () => {

        const id: string = localStorage.getItem("client_id") ?? "";
        const header = {
            "token": localStorage.getItem("Token"),
        };

        const payload = new FormData();
        payload.append("client_notification[notification_type]", "profile_update");
        payload.append("client_notification[client_information_id]", id)
        const requestMessage = Api({
            header: header,
            endPoint: configJSON.apiUpdateProfileNotification,
            payload: payload,
            method: "POST",
        });
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    gotoForgotNotification = () => {
        this.props.navigation.navigate("Notifications")
    }

    addMoreAddress = () => {
        this.setState((prev_state) => {
            return {
                updatedUserInfo: {
                    ...prev_state.updatedUserInfo,
                    attributes: {
                        ...prev_state.updatedUserInfo.attributes,
                        addresses: {
                            data: [...prev_state.updatedUserInfo.attributes.addresses.data,
                            { id: "", type: "address", attributes: { id: new Date().getTime(), address: "", primary_address: false } }]
                        }
                    }
                },
                setEnableBtn: true
            }
        })
    }

    addShareholders = () => {
        this.setState((prev_state) => {
            return {
                shareholders:
                    [...prev_state.shareholders,
                    { id: "", type: "shareholders", attributes: { id: new Date().getTime(), first_name: "", last_name: "", email: "", phone_number: "", isNew: true, isEdit: true } }]
                ,
                setEnableBtn: true,
            }
        })
    }

    editShareholder = (id: number) => {
        this.setState((prev_state) => {
            const editedShareholder = prev_state.shareholders.map((add: ShareholderType) => {
                if (add?.attributes?.id == id) {
                    return {
                        ...add,
                        attributes: {
                            ...add.attributes,
                            isEdit: true,
                        }
                    }
                }
                return add;
            })
            return {
                shareholders: editedShareholder,
                setEnableBtn: true,
            }

        })
    }

    deleteShareholder = (id: number) => {
        this.setState((prev_state) => {
            const editedShareholder = prev_state.shareholders.map((add: ShareholderType) => {
                if (add?.attributes?.id == id) {
                    return {
                        ...add,
                        attributes: {
                            ...add.attributes,
                            _destroy: 1,
                        }
                    }
                }
                return add;
            })
            return {
                shareholders: editedShareholder
            }

        })
    }

    changeImage = (e: any) => {
        if (!e.target.files) {
            return;
        }
        const file = e.target.files[0];
        const _previewImg = URL.createObjectURL(file);
        this.setState((prev_state) => {
            return {
                ...prev_state,
                updatedUserInfo: {
                    ...prev_state.updatedUserInfo,
                    attributes: {
                        ...prev_state.updatedUserInfo.attributes,
                        image: file,
                    }
                },
                previewImg: _previewImg,
                setEnableBtn: true,
            }
        })
    }

    changePrimaryUser = (event: boolean) => {
        this.setState((prev_state) => {
            return {
                updatedUserInfo: {
                    ...prev_state.updatedUserInfo,
                    attributes: {
                        ...prev_state.updatedUserInfo.attributes,
                        primary_user: event,
                    }
                },
                setEnableBtn: true,
            }
        })
    }

    changePrimaryAddress = (id: number, event: boolean) => {
        this.setState((prev_state) => {
            const editedAddress = prev_state.updatedUserInfo.attributes.addresses.data.map((add: AddressType) => {
                if (add?.attributes?.id == id) {
                    return {
                        ...add, attributes: {
                            ...add.attributes,
                            primary_address: event
                        }
                    };
                } else if (add?.attributes?.id != id) {
                    return {
                        ...add,
                        attributes: {
                            ...add.attributes,
                            primary_address: false
                        }
                    }
                }
                return add;
            })
            return {
                updatedUserInfo: {
                    ...prev_state.updatedUserInfo,
                    attributes: {
                        ...prev_state.updatedUserInfo.attributes,
                        addresses: {
                            data: editedAddress
                        }
                    }
                },
                setEnableBtn: true,
            }
        })
    }

    deleteMoreAddress = (id: number) => {
        this.setState((prev_state) => {
            const editedAddress = prev_state.updatedUserInfo.attributes.addresses.data.map((add: AddressType) => {
                if (add?.attributes?.id == id) {
                    return {
                        ...add, attributes: {
                            ...add.attributes,
                            _destroy: 1,
                        }
                    };
                }
                return add;
            })
            return {
                updatedUserInfo: {
                    ...prev_state.updatedUserInfo,
                    attributes: {
                        ...prev_state.updatedUserInfo.attributes,
                        addresses: {
                            data: editedAddress
                        }
                    }
                },
                setEnableBtn: true,
            }

        })
    }

    removeShareholder = (id: number) => {
        this.setState((prev) => {
            const editedShareholder = prev.shareholders.map((add: ShareholderType) => {
                if (add?.attributes?.id == id) {
                    return {
                        ...add,
                        attributes: {
                            ...add.attributes,
                            _destroy: 1,
                        }
                    };
                }
                return add;
            })
            return {

                shareholders: editedShareholder
            }
        }, () => this.closeModal())
    }


    validateShareholder = () => {
        let isError = false;
        const { shareholders } = this.state;
        const _newData = shareholders?.map((share: ShareholderType) => {
            const nameRegExp = configJSON.nameRegExp;
            const emailRegExp = configJSON.emailRegExp;
            let error: any = {}
            if (share?.attributes?._destroy !== 1) {
                if (!share.attributes?.first_name.match(nameRegExp)) {
                    error.first_name = "Enter Your First Name"
                    isError = true;
                }
                if (!share.attributes?.last_name.match(nameRegExp)) {
                    error.last_name = "Enter Your Last Name"
                    isError = true;
                }
                if (!share.attributes?.email.match(emailRegExp)) {
                    error.email = "Enter a Valid Email"
                    isError = true;
                }

            }
            return {
                ...share,
                attributes: {
                    ...share.attributes,
                    error
                }
            }
        })

        this.setState({
            shareholders: _newData
        })
        return isError
    }

    addShareholderData = (id: number, value: string, name: string) => {
        if (name === "phone_number") {
            value = value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        }
        this.setState((prev) => {
            const editedShareholder = prev.shareholders.map((add: ShareholderType) => {
                if (add?.attributes?.id == id) {
                    return {
                        ...add,
                        attributes: {
                            ...add.attributes,
                            [name]: value
                        }
                    };
                }
                return add;
            })
            return {
                shareholders: editedShareholder,
                setEnableBtn: true,
            }
        })
    }


    handleUserInfoChange = (value: string, name: string, addressId?: number) => {
        if (name === "phone_number") {
            value = value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        }
        if (name === "addresses") {
            this.setState((prev_state) => {
                const newAddress = prev_state.updatedUserInfo.attributes.addresses.data.map((item: AddressType) => {
                    if (item.attributes.id === addressId) {
                        return {
                            ...item,
                            attributes: {
                                ...item.attributes,
                                address: value,
                            }
                        }
                    } else {
                        return item;
                    }
                })
                return {
                    updatedUserInfo: {
                        ...prev_state.updatedUserInfo,
                        attributes: {
                            ...prev_state.updatedUserInfo.attributes,
                            addresses: {
                                data: newAddress
                            }
                        }
                    },
                    setEnableBtn: true,
                }
            })
        } else {
            this.setState((prev_state) => {
                return {
                    updatedUserInfo: {
                        ...prev_state.updatedUserInfo,
                        attributes: {
                            ...prev_state.updatedUserInfo.attributes,
                            [name]: value
                        }
                    },
                    setEnableBtn: true,
                }
            })
        }
    }



    handleOpenAddUser = () => {
        this.setState({ openAddUser: true });
    }

    closeModal = () => {
        this.setState({ openAddUser: false, openStatus: false, openErrorStatus: false, openAcessPermission: false, openAccessPermissionResponse: false, removeShareholder: false });
    }

    closeAddUserResponse = () => {
        this.setState({
            openStatus: false
        }, () => {
            const index = this.state.userType === "user" ? "user_index" : "business_index";
            this.viewAllUser(index);
        })
    }


    private _validationForUser = () => {
        const { updatedUserInfo } = this.state;
        let userSchema;
        userSchema = Yup.object({
            first_name: Yup.string().required("Please Enter First Name").max(25, "Fist Name is too long (maximum is 25 characters)"),
            last_name: Yup.string().required("Please Enter Last Name").max(25, "Last Name is too long (maximum is 25 characters)"),
            phone_number: Yup.string()
            .test('is-valid', 'Phone number is invalid', (value: any) => {
                return !value || (value && value.length === 14);
            }),

            email: Yup.string().required("Please Enter Email.").email("Invalid Email Address"),
        });
        userSchema.validate({
            first_name: updatedUserInfo?.attributes?.first_name,
            last_name: updatedUserInfo?.attributes?.last_name,
            email: updatedUserInfo?.attributes?.email,
            phone_number: updatedUserInfo?.attributes?.phone_number,
            addresses: updatedUserInfo?.attributes?.addresses
        }, { abortEarly: false }).then((value) => {
            this.setState({
                errors: {}
            });
            let addressError = false;
            const newAddress = updatedUserInfo?.attributes?.addresses.data.map((add: AddressType) => {
                if (add?.attributes?.address) {
                    return {
                        ...add,
                        attributes: {
                            ...add.attributes,
                            error: "",
                        }
                    };

                } else {
                    if (add?.attributes?._destroy !== 1) {
                        addressError = true;
                    }
                    return {
                        ...add,
                        attributes: {
                            ...add.attributes,
                            error: "Address Can't be empty",
                        }
                    };
                }
            }) as AddressType[];
            if (addressError) {

                this.setState((prev) => {
                    return {
                        ...prev,
                        updatedUserInfo: {
                            ...prev.updatedUserInfo,
                            attributes: {
                                ...prev.updatedUserInfo.attributes,
                                addresses: {
                                    data: newAddress
                                }
                            }
                        }
                    };
                });
            } else {
                this.createUserData();
            }
        }).catch((err) => {
            let newError: any = {};
            err.inner?.forEach((item: any) => {
                newError[item.path] = item.message;
            });
            this.setState({
                errors: newError
            });
        });
    };
    public get validationForUser() {
        return this._validationForUser;
    }
    public set validationForUser(value) {
        this._validationForUser = value;
    }

    validationForBusiness = () => {
        const { updatedUserInfo } = this.state
        let userSchema
        userSchema = Yup.object({
            business_name: Yup.string().required("Please Enter Business Name").max(50, "Name is too long (maximum is 50 characters)"),
            phone_number: Yup.string()
                .test('is-valid', 'Phone number is invalid', (value: any) => {
                    return !value || (value && value.length === 14);
                }),

            email: Yup.string().required("Please Enter Email.").email("Invalid Email Address"),
        });
        userSchema.validate({
            business_name: updatedUserInfo?.attributes?.business_name,
            email: updatedUserInfo?.attributes?.email,
            phone_number: updatedUserInfo?.attributes?.phone_number
        }, { abortEarly: false }).then((value) => {
            this.setState({
                errors: {}
            })
            let addressError = false;
            const newAddress = updatedUserInfo?.attributes?.addresses.data.map((add: AddressType) => {
                if (add?.attributes?.address) {
                    return {
                        ...add,
                        attributes: {
                            ...add.attributes,
                            error: "",
                        }
                    }

                } else {
                    if (add?.attributes?._destroy !== 1) {
                        addressError = true;
                    }
                    return {
                        ...add,
                        attributes: {
                            ...add.attributes,
                            error: "Address Can't be empty",
                        }
                    }
                }
            }) as AddressType[]
            const isBusinessError = this.validateShareholder()
            if (addressError) {

                this.setState((prev) => {
                    return {
                        ...prev,
                        updatedUserInfo: {
                            ...prev.updatedUserInfo,
                            attributes: {
                                ...prev.updatedUserInfo.attributes,
                                addresses: {
                                    data: newAddress
                                }
                            }
                        }
                    }
                })
            } else if (!isBusinessError && !addressError) {
                this.createUserData()
            }
        }).catch((err) => {
            let newError: any = {};
            err.inner?.forEach((item: any) => {
                newError[item.path] = item.message
            })
            this.setState({
                errors: newError
            })
        })
    }

    validateInput = () => {
        if (this.state.userType === "user") {
            this.validationForUser()
        } else {
            this.validationForBusiness()
        }
    }

    handleProvideAccessBtn = (id: string) => {
        this.setState({
            accessPermissionId: id,
            openAcessPermission: true
        })
    }

    logout = () => {
        localStorage.removeItem('Token');
        localStorage.removeItem('logo');
        localStorage.removeItem('primaryColor');
        localStorage.removeItem('secondaryColor');
        localStorage.removeItem('email');
        localStorage.removeItem('client_id');
        this.context?.clearThemeContext()
        //@ts-ignore
        this.props.navigation?.navigate("signin");
    }

    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            anchorEl: event.currentTarget
        })
    };

    handleClose = () => {
        this.setState({
            anchorEl: null
        })
    };

    openRemoveShareholderPopup = (id: number) => {
        this.setState({
            removeShareholder: true,
            removeShareholderId: id
        })
    }

    viewAllUser = (index: string) => {
        this.props.navigation.navigate("ViewAllData", {
            index: index
        })
    }
    // Customizable Area End

}

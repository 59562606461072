export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const notificationIcon = require("../assets/notification-bing.png");
export const arrowDown = require("../assets/down-arrow.png")
export const arrowUp = require("../assets/up-arrow.png")
export const listChecked = require("../assets/list_checked.png");
export const listUnchecked = require("../assets/list_unchecked.png");
export const filter = require("../assets/filter.png");
export const cancelIcon = require("../assets/cancel.png");
export const rightIcon = require("../assets/right.png");
export const downIcon = require("../assets/down.png");
export const closeIcon = require("../assets/closeIcon.png");
export const documentDownload = require("../assets/document-download.png");
export const uploadIcon = require("../assets/uploadIcon.png");

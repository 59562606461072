import { createTheme } from '@material-ui/core/styles';
export const getThemeFunction = (primaryColor: string = "#67a1a3") => {
    console.log("Theme Primary Color", primaryColor)
    const t: any = {
        palette: {
            primary: {
                main: primaryColor,
            },
            secondary: {
                main: primaryColor,
            },
            info: {
                main: "#ffffff"
            },
            text: {
                primary: "#000",
                secondary: "#a2a1a6"
            },
            background: {
                default: '#fafafa'
            }
        },
        typography: {
            allVariants: {
                fontFamily: "Rubik",
                textTransform: "none"
            },
            loginHeadings: {
                fontFamily: "DM Sans",
            },
            h2: {
                fontFamily: "DM Sans",
                fontSize: "1.4rem",
                fontWeight: '700'
            },
            h4: {
                fontFamily: "Rubik",
                fontSize: "1.6rem",
                fontWeight: "700"
            },
            h5: {
                fontFamily: "Rubik",
                fontSize: '1.3rem'
            },
            body1: {
                fontFamily: "Rubik",
                fontSize: "1.2rem"
            },
            body2: {
                fontFamily: "Rubik",
                fontSize: "1rem"
            },
            caption: {
                lineHeight: "1.5rem"
            },
            h6: {
                fontFamily: "Rubik",
                fontSize: "1rem",
                fontWeight: "700"
            },
            overline: {
                fontFamily: "Roboto-Regular",
                fontSize: "0.9rem",
                lineHeight: "initial"
            },
        },
        overrides: {
            MuiButton: {
                root: {
                    boxShadow: 'none',
                    backgroundColor: 'transparent',
                    '&:hover': {
                        backgroundColor: "none",
                        boxShadow: 'none'
                    },
                    '&:active': {
                        boxShadow: 'none'
                    },
                    borderRadius: 10,
                    padding: "0.8rem 2rem"
                },
                contained: {
                    boxShadow: 'none',
                    backgroundColor: 'transparent',
                    '&:hover': {
                        backgroundColor: "none",
                        boxShadow: 'none'
                    },
                    '&:active': {
                        boxShadow: 'none'
                    }
                }
            },
            MuiCard: {
                root: {
                    borderRadius: "20px",
                    boxShadow: "none"
                }
            },
            MuiAlert: {
                styleOverrides: {
                    success: {
                        backgroundColor: '#67a0a3',
                        color: 'white'
                    },
                    standardError: {
                        backgroundColor: 'red',
                        color: 'white'
                    },
                    standardWarning: {
                        backgroundColor: 'orange',
                        color: 'white'
                    },
                    standardInfo: {
                        backgroundColor: 'grey',
                        color: 'black'
                    }
                }
            },
        }
    };

    return createTheme(t)
}

const t: any = {
    palette: {
        primary: {
            main: "#67a1a3",
        },
        secondary: {
            main: "#67a1a3",
        },
        info: {
            main: "#ffffff"
        },
        text: {
            primary: "#000",
            secondary: "#a2a1a6"
        },
        background: {
            default: '#fafafa'
        }
    },
    typography: {
        allVariants: {
            fontFamily: "Rubik",
            textTransform: "none"
        },
        loginHeadings: {
            fontFamily: "DM Sans",
        },
        h2: {
            fontFamily: "DM Sans",
            fontSize: "1.4rem",
            fontWeight: '700'
        },
        h4: {
            fontFamily: "Rubik",
            fontSize: "1.6rem",
            fontWeight: "700"
        },
        h5: {
            fontFamily: "Rubik",
            fontSize: '1.3rem'
        },
        body1: {
            fontFamily: "Rubik",
            fontSize: "1.2rem"
        },
        body2: {
            fontFamily: "Rubik",
            fontSize: "1rem"
        },
        caption: {
            lineHeight: "1.5rem"
        },
        h6: {
            fontFamily: "Rubik",
            fontSize: "1rem",
            fontWeight: "700"
        },
        overline: {
            fontFamily: "Roboto-Regular",
            fontSize: "0.9rem",
            lineHeight: "initial"
        },
    },
    overrides: {
        MuiButton: {
            root: {
                boxShadow: 'none',
                backgroundColor: 'transparent',
                '&:hover': {
                    backgroundColor: "none",
                    boxShadow: 'none'
                },
                '&:active': {
                    boxShadow: 'none'
                },
                borderRadius: 10,
                padding: "0.8rem 2rem"
            },
            contained: {
                boxShadow: 'none',
                backgroundColor: 'transparent',
                '&:hover': {
                    backgroundColor: "none",
                    boxShadow: 'none'
                },
                '&:active': {
                    boxShadow: 'none'
                }
            }
        },
        MuiCard: {
            root: {
                borderRadius: "20px",
                boxShadow: "none"
            }
        },
        MuiAlert: {
            styleOverrides: {
                success: {
                    backgroundColor: '#67a0a3',
                    color: 'white'
                },
                standardError: {
                    backgroundColor: 'red',
                    color: 'white'
                },
                standardWarning: {
                    backgroundColor: 'orange',
                    color: 'white'
                },
                standardInfo: {
                    backgroundColor: 'grey',
                    color: 'black'
                }
            }
        },
    }
};

let theme: any = createTheme(t)
export default theme
// Customizable Area Start
import React, { useContext } from "react";
export interface ThemeContextType {
  count: number;
  setCount: (newCount: number) => void;
  primaryColor: string;
  setPrimaryColor: (newColor: string) => void;
  secondaryColor: string;
  setSecondaryColor: (newColor: string) => void;
  logo: string;
  setLogo: (newLogo: string) => void;
  userProfileContext: {
    first_name: string,
    last_name: string,
    avatar: string,
  };
}
export const defaultContext: ThemeContextType = {
  count: 0,
  setCount: (_: number) => { },
  primaryColor: "#67a1a3",
  setPrimaryColor: (_: string) => { },
  secondaryColor: "#67a1a3",
  setSecondaryColor: (_: string) => { },
  logo: require("../../../globalAssets/logo.png"),
  setLogo: (_: string) => { },
  userProfileContext: {
    first_name: "",
    last_name: "",
    avatar: ""
  }
};

export const ThemeContext = React.createContext<ThemeContextType>(defaultContext);

export function useTheme() {
  return useContext(ThemeContext);
}
// Customizable Area End
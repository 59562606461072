import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  TextField,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { Link, withRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { Formik } from "formik";
import * as Yup from "yup";
import Footer from "../../../components/src/Footer.web";

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

//Validation Schema for form
const validationSchema = Yup.object({
  email: Yup.string()
    .email("Email is invalid")
    .required("Email is a required field"),
  password: Yup.string()
    .required("Password is a required field")
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,16}$/
    ),
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const PasswordError = () => {
  return (
    <div>
      <p>Password must contain</p>
      <ul>
        <li>At least 8 characters and a maximum of 16 characters</li>
        <li>One uppercase letter</li>
        <li>One number</li>
        <li>One special character</li>
      </ul>
    </div>
  );
};

// Customizable Area End

class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  }

  handlePasswordVisibility = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  render() {
    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Container maxWidth="md">
          <Box
            sx={{
              display: "flex",
              flexDirection: "columm",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "80%",
                padding: "45px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  padding: "10 0",
                }}
              >
                <img
                  src={require("../../globalAssets/logo.png")}
                  width="230px"
                  height="80px"
                  style={{ margin: "0 auto" }}
                />
              </Box>
              <Box
                sx={{
                  margin: "10 0",
                  padding: 20,
                }}
                boxShadow={5}
                borderRadius={10}
              >
                <Box>
                  <Typography align="center">
                    <Box
                      component="span"
                      fontWeight={900}
                      fontSize={22}
                      margin={20}
                      style={{ fontFamily: "DM Sans" }}
                    >
                      Login
                    </Box>
                  </Typography>
                  <Box my={1}>
                    <Typography align="center" variant="body2">
                      <Box color="gray" fontSize={14} component="span">
                        Welcome back, you have been missed!
                      </Box>
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                    }}
                    onSubmit={(values) => {
                      this.doEmailLogIn(values);
                    }}
                    validationSchema={validationSchema}
                  >
                    {(formikProps) => (
                      <form onSubmit={formikProps.handleSubmit}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          my={5}
                        >
                          <Box
                            display="flex"
                            flexDirection="column"
                            width="50%"
                          >
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="center"
                              width="100%"
                            >
                              <TextField
                                style={{ width: "100%", marginBottom: 20 }}
                                placeholder="Your Email"
                                variant="standard"
                                name="email"
                                className="email_input"
                                autoComplete="off"
                                onChange={formikProps.handleChange}
                                onBlur={formikProps.handleBlur}
                                error={Boolean(
                                  formikProps.touched.email &&
                                    formikProps.errors.email
                                )}
                                helperText={
                                  formikProps.touched.email &&
                                  Boolean(formikProps.errors.email) &&
                                  String(formikProps.errors.email)
                                }
                                value={formikProps.values.email}
                                InputProps={{
                                  style: {
                                    backgroundColor: "#e6eaee",
                                    color: "black",
                                    padding: "5 10",
                                    borderRadius: 10,
                                  },
                                  disableUnderline: true,
                                }}
                              />
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <TextField
                                style={{ width: "100%", marginBottom: 20 }}
                                placeholder="Enter Password"
                                type={
                                  this.state.showPassword ? "text" : "password"
                                }
                                name="password"
                                autoComplete="off"
                                onChange={formikProps.handleChange}
                                onBlur={formikProps.handleBlur}
                                error={Boolean(
                                  formikProps.touched.password &&
                                    formikProps.errors.password
                                )}
                                helperText={
                                  formikProps.touched.password &&
                                  Boolean(formikProps.errors.password) && (
                                    <PasswordError />
                                  )
                                }
                                value={formikProps.values.password}
                                InputProps={{
                                  style: {
                                    backgroundColor: "#e6eaee",
                                    color: "black",
                                    padding: "5 10",
                                    borderRadius: 10,
                                  },
                                  disableUnderline: true,
                                  endAdornment: (
                                    <img
                                      width="24px"
                                      onClick={this.handlePasswordVisibility}
                                      style={{
                                        marginRight: 10,
                                        cursor: "pointer",
                                      }}
                                      src={
                                        !this.state.showPassword
                                          ? imgPasswordVisible
                                          : imgPasswordInVisible
                                      }
                                    />
                                  ),
                                }}
                              />
                            </Box>
                            <Box alignSelf="flex-end" mb={2}>
                              <Link
                                to="/ForgotPassword"
                                style={{
                                  textDecoration: "none",
                                }}
                              >
                                <Typography>
                                  <Box
                                    color="#0f53ca"
                                    fontWeight={600}
                                    fontSize={13}
                                    component="span"
                                    className="forgot_password"
                                  >
                                    Forgot Password
                                  </Box>
                                </Typography>
                              </Link>
                            </Box>
                            <Box width="100%">
                              <Button
                                variant="contained"
                                fullWidth
                                type="submit"
                                style={{
                                  backgroundColor: "#67a0a3",
                                  padding: 10,
                                  color: "white",
                                  borderRadius: 10,
                                  textTransform: "none",
                                }}
                              >
                                <Typography>
                                  <Box component="span" fontWeight={900}>
                                    Login
                                  </Box>
                                </Typography>
                              </Button>
                            </Box>
                            <Box display="flex" justifyContent="center" my={3}>
                              <Typography>
                                <Box
                                  color="gray"
                                  fontWeight={600}
                                  fontSize={13}
                                  component="span"
                                >
                                  OR
                                </Box>
                              </Typography>
                            </Box>
                            <Box width="100%">
                              <Link
                                to="/OTPLogin"
                                style={{ textDecoration: "none" }}
                              >
                                <Button
                                  fullWidth
                                  style={{
                                    padding: 10,
                                    color: "white",
                                    borderRadius: 10,
                                    textTransform: "none",
                                    border: "1px solid #67a0a3",
                                  }}
                                  type="button"
                                  className="otp-login-btn"
                                >
                                  <Typography>
                                    <Box
                                      fontWeight={700}
                                      color="black"
                                      fontSize={16}
                                      component="span"
                                      style={{ fontFamily: "DM Sans" }}
                                    >
                                      Sign in with Security Code
                                    </Box>
                                  </Typography>
                                </Button>
                              </Link>
                            </Box>
                          </Box>
                        </Box>
                      </form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </Box>
          </Box>
          <Snackbar
            open={this.state.snackBar.show}
            onClose={this.closeSnackBarHandler}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={this.closeSnackBarHandler}
              severity={this.state.snackBar.type}
              style={{
                backgroundColor:
                  this.state.snackBar.type === "success"
                    ? "#67a0a3"
                    : "#f44336",
              }}
            >
              {this.state.snackBar.message}
            </MuiAlert>
          </Snackbar>
        </Container>
        <Footer type="auth" />
      </ThemeProvider>
    );
  }
}

// @ts-ignore
export default withRouter(EmailAccountLoginBlock);

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import Api from "../../../components/src/API";
import { PasswordType, UserProfileInfo } from "./Interface";
import { passwordRegex } from "../../../components/src/Utils";
// Customizable Area End
export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    openModal: boolean;
    changeStatus: string;
    showPassword_old: boolean;
    showPassword_new: boolean;
    showPassword_renew: boolean;
    userProfile: UserProfileInfo;
    password: PasswordType;
    matchPasswordMsg: string;
    loading: boolean;
    errorMsg: string;
    passwordError: string;
    changePasswordStatus: string;
    openModalForStatus: boolean;
    emptyValueCheck: any;
    showLogout: boolean;
    anchorEl: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ChangePasswordController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getUserProfileCallId: string = "";
    postChangePassCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            openModal: false,
            changeStatus: "",
            showPassword_new: false,
            showPassword_old: false,
            showPassword_renew: false,
            userProfile: {
                first_name: "",
                last_name: "",
                email: "",
                phone_number: "",
                avatar: ""
            },
            password: {
                old_password: "",
                new_password: "",
                check_new_password: ""
            },
            loading: false,
            errorMsg: "",
            passwordError: "",
            matchPasswordMsg: "",
            changePasswordStatus: "",
            openModalForStatus: false,
            emptyValueCheck: {},
            showLogout: false,
            anchorEl: null,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start

        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );


            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId != null) {
                if (apiRequestCallId == this.getUserProfileCallId) {
                    this.userProfileDataResponse(responseJson, errorReponse)
                }
                if (apiRequestCallId == this.postChangePassCallId) {
                    this.changePasswordResponse(responseJson, errorReponse)
                }
            }
        }
        // Customizable Area End

    }
    // Customizable Area Start
    async componentDidMount() {
        this.getUserProfileData()
    }

    // Customizable Area End

    // Customizable Area Start

    changePasswordResponse = (responseJson: any, errorReponse: any) => {
        if (responseJson && !responseJson.errors) {
            this.setState({
                changePasswordStatus: responseJson.message,
                openModal: true,
                password: {
                    old_password: "",
                    new_password: "",
                    check_new_password: ""
                }
            })
        } else {
            this.setState({
                changePasswordStatus: errorReponse
            })
        }
    }

    userProfileDataResponse = (responseJson: any, errorReponse: any) => {
        if (responseJson && !responseJson.errors) {
            const _userData = {
                first_name: responseJson?.data?.attributes?.first_name,
                last_name: responseJson?.data?.attributes?.last_name,
                email: responseJson?.data?.attributes?.primary_email,
                phone_number: responseJson?.data?.attributes?.primary_phone,
                avatar: responseJson?.data?.attributes?.avatar,
            }
            this.setState({
                loading: false,
                userProfile: _userData,
                errorMsg: "",
            });
        } else {
            this.setState({
                loading: false,
                errorMsg: errorReponse,
            });
        }
    }

    getUserProfileData = () => {
        this.setState({
            loading: true,
        })
        const header = {
            "token": localStorage.getItem("Token"),
            "Content-Type": configJSON.contentTypeApiGetUserProfile,
        };
        const requestMessage = Api({
            header: header,
            endPoint: configJSON.getProfileAPI,
            method: "GET",
        });
        this.getUserProfileCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    changePassword = () => {

        const { old_password, new_password } = this.state.password
        const payload = {
            old_password: old_password,
            new_password: new_password,
        }
        const header = {
            "token": localStorage.getItem("Token"),
            "Content-Type": configJSON.validationApiContentType,
        };
        const requestMessage = Api({
            header: header,
            endPoint: configJSON.changePasswordAPI,
            payload: JSON.stringify(payload),
            method: "POST",
        });
        this.postChangePassCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleShowPassword_old = () => {
        this.setState({
            showPassword_old: !this.state.showPassword_old
        })
    }

    handleShowPassword_new = () => {
        this.setState({
            showPassword_new: !this.state.showPassword_new
        })
    }

    handleShowPassword_renew = () => {
        this.setState({
            showPassword_renew: !this.state.showPassword_renew
        })
    }

    closeModal = () => {
        this.setState({
            openModal: false
        })
    }

    gotoForgotPassword = () => {
        this.props.navigation.navigate("ForgotPassword")
    }

    gotoForgotNotification = () => {
        this.props.navigation.navigate("Notifications")
    }

    validatePassword = (password: string) => {
        return passwordRegex.test(password);
    }

    handlePasswordChange = (e: any, name: string) => {
        const password = e.target.value;
        this.setState((prev) => {
            return {
                ...prev,
                password: {
                    ...prev.password,
                    [name]: password
                }
            }
        });

    }

    submitChangePassword = () => {

        const { check_new_password, new_password, old_password } = this.state.password;

        const emptyError: any = {};
        if (old_password.length === 0) {
            emptyError.old = "Old password can't be empty.";
        }
        if (new_password.length === 0) {
            emptyError.new = "New passoword can't be empty.";
        }
        if (check_new_password.length === 0) {
            emptyError.renew = "Re-enter password can't be empty.";
        }
        if (!this.validatePassword(new_password)) {
            emptyError.invalidPassword = "Invalid password";
        }
        if (new_password !== check_new_password) {
            emptyError.notMatch = "Password do not match.";
        }
        if (emptyError && Object.keys(emptyError).length === 0 && Object.getPrototypeOf(emptyError) === Object.prototype) {
            this.setState({ emptyValueCheck: {} })
            this.changePassword();

        }
        else {
            this.setState({ emptyValueCheck: emptyError })
        }
    }


    logout = () => {
        localStorage.removeItem('Token');
        localStorage.removeItem('logo');
        localStorage.removeItem('primaryColor');
        localStorage.removeItem('secondaryColor');
        localStorage.removeItem('email');
        localStorage.removeItem('client_id');
        this.context?.clearThemeContext()
        //@ts-ignore
        this.props.navigation?.navigate("signin");
    }

    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            anchorEl: event.currentTarget
        })
    };

    handleClose = () => {
        this.setState({
            anchorEl: null
        })
    };
    // Customizable Area End

}

// Customizable Area Start
import React from "react";
import {
    Box,
    Grid,
    Typography,
    Button,
    IconButton,
    Checkbox,
    Badge,
    Avatar,
    AppBar,
    Toolbar,
    Drawer,
    Modal,
    Menu,
    MenuItem

} from "@material-ui/core"
import EditUsersController, { Props } from "./EditUsersController";
import { withTheme, withStyles, createStyles, Theme } from "@material-ui/core/styles";
import { getFirstLetters } from "../../../components/src/Utils";
import { editIcon, viewAllBlackIcon } from "./assets";
import EditProfileForm from "../../../components/src/EditProfileForm";
import SideBar from "../../../components/src/SideBar.web";
import { notificationIcon } from "../../dashboard/src/assets";
import AddUsersForm from "../../../components/src/AddUserForm";
import AddNewEditBtn from "../../../components/src/AddNewEditBtn";
import { ThemeContext } from "../../notifications/src/Context/ThemeContext";
import UserProfile from "../../../components/src/UserProfileAvatar";
import Footer from "../../../components/src/Footer.web";
import { ShareholderType } from "./Interface";
import AddShareholder from "../../../components/src/AddShareholder";

const drawerWidth = 312;

// Customizable Area End

class EditUsers extends EditUsersController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    static contextType = ThemeContext;

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { primaryColor, count, logo } = this.context;
        const { updatedUserInfo, addUserResponse, anchorEl,
            openAddUser, openStatus, errorMsg, removeShareholder, removeShareholderId, setEnableBtn,
            openAcessPermission, accessPermissionStatus, openAccessPermissionResponse, shareholders,
            previewImg, errors } = this.state;

        console.log({ shareholders })


        // Customizable Area End
        return (
            // Customizable Area Start
            <div>
                <AppBar
                    position="fixed"
                    className={classes.appBar}
                    color="transparent"
                >
                    <Toolbar>
                        <Box className={classes.grow} />
                        <Box>
                            <IconButton aria-label="show 4 new mails" color="inherit">
                                <Badge overlap="rectangular" badgeContent={count} color="error">
                                    <Box className={classes.notification_icon} onClick={this.gotoForgotNotification}>
                                        <img src={notificationIcon} width={18} />
                                    </Box>
                                </Badge>
                            </IconButton>
                            <IconButton onClick={this.handleClick}>
                                <UserProfile />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={this.handleClose}
                            >
                                <MenuItem onClick={this.logout}>Logout</MenuItem>
                            </Menu>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Drawer
                    className={classes.drawer}
                    variant="permanent"
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    anchor="left"
                >
                    <Toolbar className={classes.toolbar}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            px={2}
                            py={3}
                        >
                            <img src={logo} width="55%" />
                        </Box>
                    </Toolbar>
                    <SideBar />
                </Drawer>
                <Modal
                    open={openStatus}
                    // onClose={() => this.closeModal()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className={classes.modalStyle}>
                        <Box style={{ textAlign: "center" }}>
                            <Typography style={{
                                fontFamily: "Rubik-Regular",
                                fontSize: "25px", color: primaryColor,
                                textAlign: "center",
                                marginBottom: "10px"
                            }}>
                                {
                                    addUserResponse
                                }
                            </Typography>
                            <Button
                                data-testid="modalBtn"
                                onClick={() => this.closeModal()}
                                style={{
                                    backgroundColor: primaryColor,
                                    color: "#ffffff", textTransform: "inherit",
                                    minWidth: "90px", fontFamily: "Rubik-SemiBold"
                                }}>
                                OK
                            </Button>
                        </Box>
                    </Box>
                </Modal>
                <Modal
                    open={openAccessPermissionResponse}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className={classes.modalStyle}>
                        <Box style={{ textAlign: "center" }}>
                            <Typography style={{
                                fontFamily: "Rubik-Regular",
                                textAlign: "center",
                                margin: "15px 0"
                            }}>
                                {
                                    accessPermissionStatus
                                }
                            </Typography>
                            <Button
                                data-testid="accessBtnStatus"
                                onClick={() => this.closeModal()}
                                style={{
                                    backgroundColor: primaryColor,
                                    color: "#ffffff", textTransform: "inherit",
                                    minWidth: "90px", fontFamily: "Rubik-SemiBold"
                                }}>
                                OK
                            </Button>
                        </Box>
                    </Box>
                </Modal>
                <Modal
                    open={openAcessPermission}
                    // onClose={() => this.closeModal()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className={classes.accessModalStyle}>
                        <Grid container spacing={3} justifyContent="center" alignItems="center" style={{ margin: "20px 0" }}>
                            <Grid item xs={9}>
                                <Typography style={{ fontFamily: "Rubik-Regular", textAlign: "center" }}>Completing this step will give this person access to your Dashboard.</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} justifyContent="center" alignItems="center" style={{ marginBottom: "20px" }}>
                            <Grid item xs={5}>
                                <Button
                                    onClick={this.closeModal}
                                    variant="contained"
                                    style={{ width: "100%", textTransform: "inherit", fontFamily: "Rubik-Regular", backgroundColor: "#E7EAEF" }}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs={5}>
                                <Button
                                    variant="contained"
                                    style={{
                                        width: "100%", textTransform: "inherit", fontFamily: "Rubik-Regular",
                                        backgroundColor: primaryColor,
                                        color: "#ffffff"
                                    }}
                                    onClick={this.getAccessPermission}
                                >
                                    Confirm
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                <Modal
                    open={openAddUser}
                    // onClose={() => this.closeModal()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box>
                        <AddUsersForm
                            user_type={updatedUserInfo?.type === "client_businesses" ? "business" : "user"}
                            handleAddUserData={this.handleAddUserData}
                            closeModal={this.closeModal}
                            addUserData={this.createUserData}
                            fieldValidation={this.fieldValidation}
                        />
                    </Box>
                </Modal>
                <Modal
                    open={removeShareholder}
                    // onClose={() => this.closeModal()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className={classes.accessModalStyle}>
                        <Grid container spacing={3} justifyContent="center" alignItems="center" style={{ margin: "20px 0" }}>
                            <Grid item xs={9}>
                                <Typography style={{ fontFamily: "Rubik-Regular", textAlign: "center" }}>Do you want to remove shareholder?</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} justifyContent="center" alignItems="center" style={{ marginBottom: "20px" }}>
                            <Grid item xs={5}>
                                <Button
                                    onClick={this.closeModal}
                                    variant="contained"
                                    style={{ width: "100%", textTransform: "inherit", fontFamily: "Rubik-Regular", backgroundColor: "#E7EAEF" }}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs={5}>
                                <Button
                                    data-testid="removeShareholderBtn"
                                    variant="contained"
                                    style={{
                                        width: "100%", textTransform: "inherit", fontFamily: "Rubik-Regular",
                                        backgroundColor: primaryColor,
                                        color: "#ffffff"
                                    }}
                                    onClick={() => this.removeShareholder(removeShareholderId)}
                                >
                                    Confirm
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                <Box style={{ marginLeft: "312px", marginTop: "64px", marginBottom: "56px", padding: "20px" }}>
                    {/* sticky Box */}
                    <Box style={{position: "sticky", top: '74px', height: '60px', zIndex: 11, backgroundColor: "#ffffff"}}>
                        <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "30px", marginTop: "5px" }}>
                            <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                <Typography style={{ fontFamily: "Rubik-Regular", fontSize: "25px", color: primaryColor }}>Settings</Typography>
                                <img src={viewAllBlackIcon} height="17px" />
                                <Box data-testid="goBackBtn" onClick={() => this.props.navigation.goBack()}>
                                    <Typography
                                        style={{ fontFamily: "Rubik-Regular", fontSize: "17px", cursor: "pointer" }}
                                    >
                                        Users
                                    </Typography>
                                </Box>
                                <img src={viewAllBlackIcon} height="17px" />
                                <Typography style={{ fontFamily: "Rubik-Regular", fontSize: "17px" }}>
                                    {
                                        updatedUserInfo?.type === "user" ?
                                            updatedUserInfo?.attributes?.relationship
                                            :
                                            updatedUserInfo?.attributes?.business_name
                                    }
                                </Typography>
                            </Box>
                            <Button disabled={setEnableBtn ? false : true} onClick={this.validateInput} style={{ marginTop: "7px", backgroundColor: setEnableBtn ? primaryColor : "gray", color: "#ffffff", textTransform: "inherit", minWidth: "150px", fontFamily: "Rubik-SemiBold" }}>Save</Button>
                        </Box>
                    </Box>
                    <Box>
                        {
                            errorMsg ?
                                <Typography style={{ textAlign: "center", fontFamily: "Rubik-Regular" }}>{errorMsg}</Typography>
                                :
                                <Box className={classes.mainContainer}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={2}>
                                            <Box>
                                                <Badge
                                                    overlap="circular"
                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                    badgeContent={
                                                        <label htmlFor="upload-photo">
                                                            <input
                                                                data-testid="inputFile"
                                                                style={{ display: 'none' }}
                                                                id="upload-photo"
                                                                name="upload-photo"
                                                                type="file"
                                                                accept="image/png, image/gif, image/jpeg"
                                                                onChange={(e) => this.changeImage(e)}
                                                            />

                                                            <Button color="secondary" variant="contained" component="span" style={{
                                                                border: '1px solid #a8b0b6',
                                                                height: '45px',
                                                                width: '45px',
                                                                borderRadius: "10px",
                                                                backgroundColor: primaryColor
                                                            }}>
                                                                <img src={editIcon} height={'20px'} />
                                                            </Button>
                                                        </label>

                                                    }
                                                >
                                                    {/* <Avatar alt="Travis Howard" src={updatedUserInfo.pImg} style={{ height: "100%", width: "100%", border: "1px solid #a8b0b6" }} /> */}
                                                    {
                                                        updatedUserInfo?.attributes?.image ?
                                                            <Avatar alt="Travis Howard"
                                                                src={updatedUserInfo?.attributes?.image.name ? previewImg : updatedUserInfo?.attributes?.image}
                                                                style={{ height: "100%", width: "100%", border: "1px solid #a8b0b6" }} />
                                                            :
                                                            <Box style={{ height: "150px", width: "150px", borderRadius: "50%", border: "1px solid #a8b0b6", backgroundColor: primaryColor, position: "relative" }}>
                                                                <Typography style={{ position: "absolute", top: "39%", left: "41%", color: "#ffffff", fontSize: "24px" }}>
                                                                    {
                                                                        updatedUserInfo?.type?.toLowerCase() === "client_businesses" ?
                                                                            getFirstLetters(updatedUserInfo?.attributes?.business_name)
                                                                            :
                                                                            getFirstLetters(updatedUserInfo?.attributes?.first_name + " " + updatedUserInfo?.attributes?.last_name)
                                                                    }
                                                                </Typography>
                                                            </Box>
                                                    }
                                                </Badge>
                                                {
                                                    updatedUserInfo?.type === "user" ?
                                                        <Box>
                                                            <Typography style={{ fontFamily: "Rubik-Regular", fontSize: "14px" }}>
                                                                Set as Primary User <Checkbox checked={updatedUserInfo?.attributes?.primary_user}
                                                                    onChange={(e) => this.changePrimaryUser(e.target.checked)}
                                                                    style={{ color: primaryColor }} /></Typography>
                                                        </Box>
                                                        :
                                                        null
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={10}>
                                            <EditProfileForm handleUserInfoChange={this.handleUserInfoChange}
                                                isCreateUserType={""}
                                                changePrimaryAddress={this.changePrimaryAddress}
                                                updatedUserInfo={updatedUserInfo}
                                                addMoreAddress={this.addMoreAddress}
                                                deleteMoreAddress={this.deleteMoreAddress}
                                                errors={errors}
                                                getAccessPermission={this.getAccessPermission}
                                                handleProvideAccessBtn={this.handleProvideAccessBtn}
                                            />
                                        </Grid>

                                    </Grid>
                                </Box>
                        }
                    </Box>
                    {
                        shareholders?.filter((share: ShareholderType) => share?.attributes?._destroy !== 1).map((share: ShareholderType) => {
                            return (
                                <AddShareholder
                                    key={share?.attributes?.id}
                                    business={updatedUserInfo?.type}
                                    handleShareholderData={this.addShareholderData}
                                    shareholder={share}
                                    removeShareholder={this.openRemoveShareholderPopup}
                                    editShareholder={this.editShareholder}
                                />
                            )
                        })
                    }

                    <AddNewEditBtn
                        dependent={updatedUserInfo?.attributes?.relationship}
                        business={updatedUserInfo?.type}
                        handleOpenUsers={this.addShareholders}
                    />
                    <Footer type="editUser" />
                </Box>
            </div >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// export default withStyles(styles)(EditUsers);
export default withTheme(
    withStyles((theme: Theme) =>
        createStyles({
            modalStyle: {
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '402px',
                height: "auto",
                backgroundColor: '#ffffff',
                borderRadius: '6px',
                boxShadow: '24px',
                padding: '10px 20px 30px 20px',
            },
            accessModalStyle: {
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '600px',
                height: "auto",
                backgroundColor: '#ffffff',
                borderRadius: '6px',
                boxShadow: '24px',
                padding: '20px',
            },
            mainContainer: {
                border: "1px solid #a8b0b6",
                borderRadius: "10px",
                padding: "5%",
                height: "76vh",
                overflowX: "hidden",
                // overflowY: "scroll"
            },
            appBar: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
                boxShadow: "0px 1px 1px #dbd7db",
                padding: 5,
                backgroundColor: "#ffffff",

            },
            grow: {
                flex: 1,
            },
            notification_icon: {
                backgroundColor: "#E7EAEE",
                borderRadius: "50%",
                padding: 10,
            },
            drawer: {
                width: drawerWidth,
                flexShrink: 0,
            },
            drawerPaper: {
                width: drawerWidth,
            },
            toolbar: theme.mixins.toolbar,
            content: {
                flexGrow: 1,
                backgroundColor: theme.palette.background.default,
                padding: theme.spacing(4),
            },
            wrapper_view: {
                width: "100%",
                // backgroundColor: "#e7eaef",
                border: "1px dashed #a8b0b6",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                // height: "50px",
                padding: "20px 0",
                cursor: "pointer",
            },

        }))(EditUsers)
)

// Customizable Area End

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { AddressType, ShareholderType, UserInfoI } from "./Interface";
import { fakeUserData } from "../../../components/src/Utils";
import Api from "../../../components/src/API";
import * as Yup from 'yup';

export interface UserError {
    business_name?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    email?: string | null;
    phone_number?: string | null;
}
// Customizable Area End
export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    viewAll: boolean;
    updatedUserInfo: UserInfoI;
    addUserData: UserInfoI;
    addUserResponse: string;
    openAddUser: boolean;
    openStatus: boolean;
    userId: number;
    userType: string;
    demoUserInfo: any;
    errorMsg: string;
    singleUerErrMsg: string;
    inputError: any;
    previewImg: string;
    errors: UserError;
    accessPermissionId: string;
    accessPermissionStatus: string;
    openAcessPermission: boolean;
    openAccessPermissionResponse: boolean;
    anchorEl: any;
    shareholders: ShareholderType[]
    removeShareholder: boolean;
    removeShareholderId: number;
    setEnableBtn: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class EditUsersController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    editUserDataCallId: string = "";
    updateUserDataCallId: string = "";
    singleUserDataCallId: string = "";
    createUserDataCallId: string = "";
    accessPermissionCallId: string = "";
    removeShareholderCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            viewAll: false,
            demoUserInfo: {},
            errorMsg: "",
            updatedUserInfo: {
                id: "1",
                type: "",
                attributes: {
                    id: 1,
                    business_name: "",
                    first_name: "",
                    last_name: "",
                    email: "",
                    phone_number: "",
                    relationship: "",
                    primary_user: false,
                    user_type: "",
                    client_information_id: 1,
                    image: "",
                    addresses: {
                        data: []
                    },
                    date_added: ""
                }
            },
            openAddUser: false,
            openStatus: false,
            addUserResponse: "",
            addUserData: {
                id: "1",
                type: "",
                attributes: {
                    id: 1,
                    business_name: "",
                    first_name: "",
                    last_name: "",
                    email: "",
                    phone_number: "",
                    relationship: "",
                    primary_user: false,
                    user_type: "",
                    client_information_id: 1,
                    image: "",
                    addresses: {
                        data: []
                    },
                    date_added: ""
                }
            },
            userId: this.props.navigation.getParam("id"),
            userType: this.props.navigation.getParam("userType"),
            singleUerErrMsg: "",
            accessPermissionId: "",
            openAcessPermission: false,
            openAccessPermissionResponse: false,
            accessPermissionStatus: "",
            inputError: {},
            previewImg: "",
            errors: {},
            anchorEl: null,
            shareholders: [],
            removeShareholder: false,
            removeShareholderId: 0,
            setEnableBtn: false,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start

        const apiRequestCallIds = {
            [this.singleUserDataCallId]: this.singleUserDataResponse,
            [this.createUserDataCallId]: this.getCreateUserResponse,
            [this.updateUserDataCallId]: this.getUpdatedUserResponse,
            [this.removeShareholderCallId]: this.afterRemoveShareholderResponse,
            [this.accessPermissionCallId]: this.getAccessPermissionResponse
        };

        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

        if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
            apiRequestCallIds[apiRequestCallId](responseJson, errorResponse);
        }
        // Customizable Area End

    }
    // Customizable Area Start
    async componentDidMount() {
        this.getUserData()

    }

    // Customizable Area End

    // Customizable Area Start

    singleUserDataResponse = (responseJson: any, errorReponse: any) => {
        if (responseJson && !responseJson.error) {
            this.setState({
                ...this.state,
                updatedUserInfo: responseJson.data,
                shareholders: responseJson?.data?.attributes?.shareholders?.data,
                singleUerErrMsg: "",
                setEnableBtn: false
            });
        } else {
            this.setState({
                singleUerErrMsg: errorReponse,
            });
        }
    }

    getUserData = () => {
        const header = {
            "token": localStorage.getItem("Token"),
            "Content-Type": configJSON.contentTypeApiGetUserProfile,
        };
        const requestMessage = Api({
            header: header,
            endPoint: `${configJSON.apiAddUserData}/${this.state.userId}?user[user_type]=${this.state.userType === "client_businesses" ? "business" : "user"}`,
            method: "GET",
        });
        this.singleUserDataCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getAccessPermissionResponse = (responseJson: any, errorReponse: any) => {
        if (responseJson && !responseJson.error) {
            this.setState({
                ...this.state,
                accessPermissionStatus: responseJson.message,
                openAcessPermission: false,
                openAccessPermissionResponse: true,
            });
        } else {
            this.setState({
                accessPermissionStatus: errorReponse,
                openAcessPermission: false,
                openAccessPermissionResponse: true,
            });
        }
    }

    getAccessPermission = () => {
        const header = {
            "token": localStorage.getItem("Token"),
            "Content-Type": configJSON.contentTypeApiGetUserProfile,
        };
        const requestMessage = Api({
            header: header,
            endPoint: `${configJSON.apiGetAccessPermission}/${this.state.accessPermissionId}`,
            method: "GET",
        });
        this.accessPermissionCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getCreateUserResponse = (responseJson: any, errorReponse: any) => {
        if (responseJson && !responseJson.error) {
            this.setState({
                openAddUser: false,
                openStatus: true,
                addUserResponse: "Added successfully!",
                errorMsg: ""
            })
        } else {
            if (errorReponse === undefined) {
                this.setState({
                    errorMsg: "Sorry something went wrong",
                });
            } else {
                this.setState({
                    errorMsg: errorReponse,
                });
            }

        }
    }

    createUserData = () => {
        const { image, business_name, first_name, last_name, email,
            phone_number, relationship, primary_user,
            user_type } = this.state.addUserData.attributes;

        const payload = new FormData();
        if (user_type === "user") {
            first_name && payload.append("user[first_name]", first_name);
            last_name && payload.append("user[last_name]", last_name);
            email && payload.append("user[email]", email);
            phone_number && payload.append("user[phone_number]", phone_number);
            relationship && payload.append("user[relationship]", relationship);
            primary_user && payload.append("user[primary_user]", JSON.stringify(primary_user));
            user_type && payload.append("user[user_type]", user_type);
            image && payload.append("user[image]", image);
        } else {
            business_name && payload.append("user[business_name]", business_name);
            email && payload.append("user[email]", email);
            phone_number && payload.append("user[phone_number]", phone_number);
            user_type && payload.append("user[user_type]", user_type);
            image && payload.append("user[image]", image);
        }

        const header = {
            "token": localStorage.getItem("Token"),
        };


        const requestMessage = Api({
            header: header,
            endPoint: `${configJSON.apiAddUserData}`,
            payload: payload,
            method: "POST",
        });
        this.createUserDataCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    afterRemoveShareholderResponse = (responseJson: any, errorReponse: any) => {
        if (responseJson && !responseJson.error) {
            this.setState((prev) => {
                const _newData = prev.shareholders?.filter(data => data?.attributes?.id !== prev.removeShareholderId)
                return {
                    errorMsg: "",
                    removeShareholder: false,
                    shareholders: _newData,
                    removeShareholderId: 0
                }
            })
        } else {
            if (errorReponse === undefined) {
                this.setState({
                    errorMsg: "Sorry something went wrong",
                });
            } else {
                this.setState({
                    errorMsg: errorReponse,
                });
            }

        }
    }

    getUpdatedUserResponse = (responseJson: any, errorReponse: any) => {
        if (responseJson.data && !responseJson.error) {
            this.setState({
                updatedUserInfo: {
                    id: "1",
                    type: "",
                    attributes: {
                        id: 1,
                        business_name: "",
                        first_name: "",
                        last_name: "",
                        email: "",
                        phone_number: "",
                        relationship: "",
                        primary_user: false,
                        user_type: "",
                        client_information_id: 1,
                        image: "",
                        addresses: {
                            data: []
                        },
                        date_added: ""
                    }
                },
                openStatus: true,
                addUserResponse: "Updated successfully!",
                errorMsg: "",
                setEnableBtn: false,
            }, () => this.getUserData())
        } else if (!responseJson.data) {
            const dynamicObjectName = Object.keys(responseJson)[0];
            const valueWithoutUnderscores = dynamicObjectName.replace(/_/g, ' '); // 'myvalue'
            const dynamicObjectValue = responseJson[dynamicObjectName][0];
            const finalValue = valueWithoutUnderscores.charAt(0).toUpperCase() + valueWithoutUnderscores.slice(1) + ' ' + dynamicObjectValue;
            this.setState({
                addUserResponse: finalValue,
                openStatus: true,
            }, () => this.getUserData())
        } else {
            if (errorReponse === undefined) {
                this.setState({
                    errorMsg: "Sorry something went wrong",
                });
            } else {
                this.setState({
                    errorMsg: errorReponse,
                });
            }

        }
    }


    updateUserData = async () => {
        const { image, business_name, first_name,
            last_name, email, phone_number, relationship,
            primary_user, user_type, addresses
        } = this.state.updatedUserInfo.attributes;
        const { shareholders } = this.state;

        const payload = new FormData();
        if (user_type === "user") {
            payload.append("user[first_name]", first_name);
            payload.append("user[last_name]", last_name);
            payload.append("user[relationship]", relationship);
            payload.append("user[primary_user]", JSON.stringify(primary_user));

        } else {
            business_name && payload.append("user[business_name", business_name)
        }
        phone_number && payload.append("user[phone_number]", phone_number);
        email && payload.append("user[email]", email);
        user_type && payload.append("user[user_type]", user_type);
        image?.name && payload.append("user[image]", image);
        addresses.data.length && addresses.data.forEach(async (item, i) => {
            item?.id && payload.append(`user[addresses_attributes][${i + 1}]id`, item?.attributes.id.toString());
            payload.append(`user[addresses_attributes][${i + 1}]address`, item?.attributes?.address);
            payload.append(`user[addresses_attributes][${i + 1}]primary_address`, item?.attributes?.primary_address.toString());
            item?.attributes?._destroy === 1 && payload.append(`user[addresses_attributes][${i + 1}]_destroy`, item?.attributes._destroy.toString());
        })
        shareholders?.length && shareholders?.forEach(async (item, i) => {
            !item?.attributes?.isNew && payload.append(`user[shareholders_attributes][${i + 1}]id`, item?.attributes?.id?.toString());
            payload.append(`user[shareholders_attributes][${i + 1}]email`, item?.attributes?.email);
            payload.append(`user[shareholders_attributes][${i + 1}]first_name`, item?.attributes?.first_name);
            payload.append(`user[shareholders_attributes][${i + 1}]last_name`, item?.attributes?.last_name);
            payload.append(`user[shareholders_attributes][${i + 1}]phone_number`, item?.attributes?.phone_number);
            item?.attributes?._destroy === 1 && payload.append(`user[shareholders_attributes][${i + 1}]_destroy`, item?.attributes?._destroy?.toString());
        })


        const header = {
            "token": localStorage.getItem("Token"),
        };


        const requestMessage = Api({
            header: header,
            endPoint: `${configJSON.apiUpdateUserData}/${this.state.userId}`,
            payload: payload,
            method: "PUT",
        });
        this.updateUserDataCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    removeShlareholderAPI = async () => {
        const { user_type } = this.state.updatedUserInfo.attributes;
        const { shareholders } = this.state;

        const payload = new FormData();

        user_type && payload.append("user[user_type]", user_type);

        const _removeShareholder = shareholders?.filter((data) => this.state.removeShareholderId === data?.attributes?.id)

        _removeShareholder?.length && _removeShareholder?.forEach(async (item, i) => {
            !item?.attributes?.isNew && payload.append(`user[shareholders_attributes][${i + 1}]id`, item?.attributes?.id?.toString());
            payload.append(`user[shareholders_attributes][${i + 1}]email`, item?.attributes?.email);
            payload.append(`user[shareholders_attributes][${i + 1}]first_name`, item?.attributes?.first_name);
            payload.append(`user[shareholders_attributes][${i + 1}]last_name`, item?.attributes?.last_name);
            payload.append(`user[shareholders_attributes][${i + 1}]phone_number`, item?.attributes?.phone_number);
            item?.attributes?._destroy === 1 && payload.append(`user[shareholders_attributes][${i + 1}]_destroy`, item?.attributes?._destroy?.toString());
        })


        const header = {
            "token": localStorage.getItem("Token"),
        };


        const requestMessage = Api({
            header: header,
            endPoint: `${configJSON.apiUpdateUserData}/${this.state.userId}`,
            payload: payload,
            method: "PUT",
        });
        this.removeShareholderCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    sendNotification = () => {

        const id: string = localStorage.getItem("client_id") ?? "";
        const header = {
            "token": localStorage.getItem("Token"),
        };

        const payload = new FormData();
        payload.append("client_notification[notification_type]", "profile_update");
        payload.append("client_notification[client_information_id]", id)
        const requestMessage = Api({
            header: header,
            endPoint: configJSON.apiUpdateProfileNotification,
            payload: payload,
            method: "POST",
        });
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    gotoForgotNotification = () => {
        this.props.navigation.navigate("Notifications")
    }

    getUserInfo = () => {
        const _singleUser = fakeUserData.filter(user => user.id == this.state.userId)
        this.setState({
            demoUserInfo: _singleUser[0]
        })
    }

    addMoreAddress = () => {
        this.setState((prev_state) => {
            return {
                updatedUserInfo: {
                    ...prev_state.updatedUserInfo,
                    attributes: {
                        ...prev_state.updatedUserInfo.attributes,
                        addresses: {
                            data: [...prev_state.updatedUserInfo.attributes.addresses.data,
                            { id: "", type: "address", attributes: { id: new Date().getTime(), address: "", primary_address: false } }]
                        }
                    }
                },
                setEnableBtn: true
            }
        })
    }

    addShareholders = () => {
        this.setState((prev_state) => {
            return {
                shareholders:
                    [...prev_state.shareholders,
                    { id: "", type: "shareholders", attributes: { id: new Date().getTime(), first_name: "", last_name: "", email: "", phone_number: "", isNew: true, isEdit: true } }]
                ,
                setEnableBtn: true,
            }
        })
    }

    editShareholder = (id: number) => {
        this.setState((prev_state) => {
            const editedShareholder = prev_state.shareholders.map((add: ShareholderType) => {
                if (add?.attributes?.id == id) {
                    return {
                        ...add,
                        attributes: {
                            ...add.attributes,
                            isEdit: true,
                        }
                    }
                }
                return add;
            })
            return {
                shareholders: editedShareholder,
                setEnableBtn: true,
            }

        })
    }

    deleteShareholder = (id: number) => {
        this.setState((prev_state) => {
            const editedShareholder = prev_state.shareholders.map((add: ShareholderType) => {
                if (add?.attributes?.id == id) {
                    return {
                        ...add,
                        attributes: {
                            ...add.attributes,
                            _destroy: 1,
                        }
                    }
                }
                return add;
            })
            return {
                shareholders: editedShareholder
            }

        })
    }

    changeImage = (e: any) => {
        if (!e.target.files) {
            return;
        }
        const file = e.target.files[0];
        const _previewImg = URL.createObjectURL(file);
        this.setState((prev_state) => {
            return {
                ...prev_state,
                updatedUserInfo: {
                    ...prev_state.updatedUserInfo,
                    attributes: {
                        ...prev_state.updatedUserInfo.attributes,
                        image: file,
                    }
                },
                previewImg: _previewImg,
                setEnableBtn: true,
            }
        })
    }

    changePrimaryUser = (event: boolean) => {
        this.setState((prev_state) => {
            return {
                updatedUserInfo: {
                    ...prev_state.updatedUserInfo,
                    attributes: {
                        ...prev_state.updatedUserInfo.attributes,
                        primary_user: event,
                    }
                },
                setEnableBtn: true,
            }
        })
    }

    changePrimaryAddress = (id: number, event: boolean) => {
        this.setState((prev_state) => {
            const editedAddress = prev_state.updatedUserInfo.attributes.addresses.data.map((add: AddressType) => {
                if (add?.attributes?.id == id) {
                    return {
                        ...add, attributes: {
                            ...add.attributes,
                            primary_address: event
                        }
                    };
                } else if (add?.attributes?.id != id) {
                    return {
                        ...add,
                        attributes: {
                            ...add.attributes,
                            primary_address: false
                        }
                    }
                }
                return add;
            })
            return {
                updatedUserInfo: {
                    ...prev_state.updatedUserInfo,
                    attributes: {
                        ...prev_state.updatedUserInfo.attributes,
                        addresses: {
                            data: editedAddress
                        }
                    }
                },
                setEnableBtn: true,
            }
        })
    }

    deleteMoreAddress = (id: number) => {
        this.setState((prev_state) => {
            const editedAddress = prev_state.updatedUserInfo.attributes.addresses.data.map((add: AddressType) => {
                if (add?.attributes?.id == id) {
                    return {
                        ...add, attributes: {
                            ...add.attributes,
                            _destroy: 1,
                        }
                    };
                }
                return add;
            })
            return {
                updatedUserInfo: {
                    ...prev_state.updatedUserInfo,
                    attributes: {
                        ...prev_state.updatedUserInfo.attributes,
                        addresses: {
                            data: editedAddress
                        }
                    }
                },
                setEnableBtn: true,
            }

        })
    }

    removeShareholder = (id: number) => {
        this.setState((prev) => {
            const editedShareholder = prev.shareholders.map((add: ShareholderType) => {
                if (add?.attributes?.id == id) {
                    return {
                        ...add,
                        attributes: {
                            ...add.attributes,
                            _destroy: 1,
                        }
                    };
                }
                return add;
            })
            return {

                shareholders: editedShareholder
            }
        }, () => this.removeShlareholderAPI())
    }


    validateShareholder = () => {
        let isError = false;
        const { shareholders } = this.state;
        const _newData = shareholders?.map((share: ShareholderType) => {
            const nameRegExp = /^(?=.{1,50}$)\w+(?:_\w+)*$/;
            const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            let error: any = {}
            if (share?.attributes?._destroy !== 1) {
                if (!share.attributes?.first_name.match(nameRegExp)) {
                    error.first_name = "Enter Your First Name"
                    isError = true;
                }
                if (!share.attributes?.last_name.match(nameRegExp)) {
                    error.last_name = "Enter Your Last Name"
                    isError = true;
                }
                if (!share.attributes?.email.match(emailRegExp)) {
                    error.email = "Enter a Valid Email"
                    isError = true;
                }

            }
            return {
                ...share,
                attributes: {
                    ...share.attributes,
                    error
                }
            }
        })

        this.setState({
            shareholders: _newData
        })
        return isError
    }

    addShareholderData = (id: number, value: string, name: string) => {
        if (name === "phone_number") {
            value = value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        }
        this.setState((prev) => {
            const editedShareholder = prev.shareholders.map((add: ShareholderType) => {
                if (add?.attributes?.id == id) {
                    return {
                        ...add,
                        attributes: {
                            ...add.attributes,
                            [name]: value
                        }
                    };
                }
                return add;
            })
            return {
                shareholders: editedShareholder,
                setEnableBtn: true,
            }
        })
    }


    handleUserInfoChange = (value: string, name: string, addressId?: number) => {
        if (name === "phone_number") {
            value = value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        }
        if (name === "addresses") {
            this.setState((prev_state) => {
                const newAddress = prev_state.updatedUserInfo.attributes.addresses.data.map((item: AddressType) => {
                    if (item.attributes.id === addressId) {
                        return {
                            ...item,
                            attributes: {
                                ...item.attributes,
                                address: value,
                            }
                        }
                    } else {
                        return item;
                    }
                })
                return {
                    updatedUserInfo: {
                        ...prev_state.updatedUserInfo,
                        attributes: {
                            ...prev_state.updatedUserInfo.attributes,
                            addresses: {
                                data: newAddress
                            }
                        }
                    },
                    setEnableBtn: true,
                }
            })
        } else {
            this.setState((prev_state) => {
                return {
                    updatedUserInfo: {
                        ...prev_state.updatedUserInfo,
                        attributes: {
                            ...prev_state.updatedUserInfo.attributes,
                            [name]: value
                        }
                    },
                    setEnableBtn: true,
                }
            })
        }
    }

    fieldValidation = () => {
        const nameRegExp = /^[a-z ,.'-]+$/i;
        const emailRegExp1 = "[a-z0-9._%+-]"
        const emailRegExp2 = "/@[a-z0-9]";
        const emailRegExp3 = ".[a-z]{2,3}$";
        const emailRegExp = new RegExp(emailRegExp1 + emailRegExp2 + emailRegExp3);
        const { first_name, last_name, email } = this.state.addUserData.attributes;
        let error: any = {}
        if (!first_name.match(nameRegExp)) {
            error.first_name = "Enter Your First Name"
        }
        if (!last_name.match(nameRegExp)) {
            error.last_name = "Enter Your Last Name"
        }
        if (!email.match(emailRegExp)) {
            error.email = "Enter a Valid Email"
        }
        if (error && Object.keys(error).length === 0 && Object.getPrototypeOf(error) === Object.prototype) {
            this.setState({ inputError: {} })
        }
        else {
            this.setState({ inputError: error })
        }
    }


    handleAddUserData = (name: string, value: string) => {
        this.setState((prev_state) => {
            return {
                addUserData: {
                    ...prev_state.addUserData,
                    attributes: {
                        ...prev_state.addUserData.attributes,
                        [name]: value,
                    }
                }
            }
        })
    }

    handleOpenAddUser = () => {
        this.setState({ openAddUser: true });
    }

    closeModal = () => {
        this.setState({ openAddUser: false, openStatus: false, openAcessPermission: false, openAccessPermissionResponse: false, removeShareholder: false });
    }

    viewAllUser = () => {
        this.setState({ viewAll: true });
    }

    validationForUser = () => {
        const { updatedUserInfo } = this.state
        // const phonReg = /^.\d+$/
        let userSchema
        userSchema = Yup.object({
            // business_name: Yup.string().required("Please Enter Business Name").max(50, "Name is too long (maximum is 50 characters)"),
            first_name: Yup.string().required("Please Enter First Name").max(25, "Fist Name is too long (maximum is 25 characters)"),
            last_name: Yup.string().required("Please Enter Last Name").max(25, "Last Name is too long (maximum is 25 characters)"),
            phone_number: Yup.string()
                .test('is-valid', 'Phone number is invalid', (value: any) => {
                    return !value || (value && value.length === 14);
                }),

            email: Yup.string().required("Please Enter Email.").email("Invalid Email Address"),
        });
        userSchema.validate({
            // business_name: updatedUserInfo?.attributes?.business_name,
            first_name: updatedUserInfo?.attributes?.first_name,
            last_name: updatedUserInfo?.attributes?.last_name,
            email: updatedUserInfo?.attributes?.email,
            phone_number: updatedUserInfo?.attributes?.phone_number,
            addresses: updatedUserInfo?.attributes?.addresses
        }, { abortEarly: false }).then((value) => {
            this.setState({
                errors: {}
            })
            let addressError = false;
            const newAddress = updatedUserInfo?.attributes?.addresses.data.map((add: AddressType) => {
                if (add?.attributes?.address) {
                    return {
                        ...add,
                        attributes: {
                            ...add.attributes,
                            error: "",
                        }
                    }

                } else {
                    if (add?.attributes?._destroy !== 1) {
                        addressError = true;
                    }
                    return {
                        ...add,
                        attributes: {
                            ...add.attributes,
                            error: "Address Can't be empty",
                        }
                    }
                }
            }) as AddressType[]
            if (addressError) {

                this.setState((prev) => {
                    return {
                        ...prev,
                        updatedUserInfo: {
                            ...prev.updatedUserInfo,
                            attributes: {
                                ...prev.updatedUserInfo.attributes,
                                addresses: {
                                    data: newAddress
                                }
                            }
                        }
                    }
                })
            } else {
                this.updateUserData()
                this.sendNotification()
            }
        }).catch((err) => {
            let newError: any = {};
            err.inner?.forEach((item: any) => {
                newError[item.path] = item.message
            })
            this.setState({
                errors: newError
            })
        })
    }

    validationForBusiness = () => {
        const { updatedUserInfo } = this.state
        // const phonReg = /^.\d+$/
        let userSchema
        userSchema = Yup.object({
            business_name: Yup.string().required("Please Enter Business Name").max(50, "Name is too long (maximum is 50 characters)"),
            // first_name: Yup.string().required("Please Enter First Name").max(25, "Fist Name is too long (maximum is 25 characters)"),
            // last_name: Yup.string().required("Please Enter Last Name").max(25, "Last Name is too long (maximum is 25 characters)"),
            phone_number: Yup.string()
                .test('is-valid', 'Phone number is invalid', (value: any) => {
                    return !value || (value && value.length === 14);
                }),

            email: Yup.string().required("Please Enter Email.").email("Invalid Email Address"),
        });
        userSchema.validate({
            business_name: updatedUserInfo?.attributes?.business_name,
            // first_name: updatedUserInfo?.attributes?.first_name,
            // last_name: updatedUserInfo?.attributes?.last_name,
            email: updatedUserInfo?.attributes?.email,
            phone_number: updatedUserInfo?.attributes?.phone_number
        }, { abortEarly: false }).then((value) => {
            this.setState({
                errors: {}
            })
            let addressError = false;
            const newAddress = updatedUserInfo?.attributes?.addresses.data.map((add: AddressType) => {
                if (add?.attributes?.address) {
                    return {
                        ...add,
                        attributes: {
                            ...add.attributes,
                            error: "",
                        }
                    }

                } else {
                    if (add?.attributes?._destroy !== 1) {
                        addressError = true;
                    }
                    return {
                        ...add,
                        attributes: {
                            ...add.attributes,
                            error: "Address Can't be empty",
                        }
                    }
                }
            }) as AddressType[]
            const isBusinessError = this.validateShareholder()
            if (addressError) {

                this.setState((prev) => {
                    return {
                        ...prev,
                        updatedUserInfo: {
                            ...prev.updatedUserInfo,
                            attributes: {
                                ...prev.updatedUserInfo.attributes,
                                addresses: {
                                    data: newAddress
                                }
                            }
                        }
                    }
                })
            } else if (!isBusinessError && !addressError) {
                this.updateUserData()
                this.sendNotification()
            }
        }).catch((err) => {
            let newError: any = {};
            err.inner?.forEach((item: any) => {
                newError[item.path] = item.message
            })
            this.setState({
                errors: newError
            })
        })
    }

    validateInput = () => {
        if (this.state.userType === "user") {
            this.validationForUser()
        } else {
            this.validationForBusiness()
        }
    }

    handleProvideAccessBtn = (id: string) => {
        this.setState({
            accessPermissionId: id,
            openAcessPermission: true
        })
    }

    logout = () => {
        localStorage.removeItem('Token');
        localStorage.removeItem('logo');
        localStorage.removeItem('primaryColor');
        localStorage.removeItem('secondaryColor');
        localStorage.removeItem('email');
        localStorage.removeItem('client_id');
        this.context?.clearThemeContext()
        //@ts-ignore
        this.props.navigation?.navigate("signin");
    }

    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            anchorEl: event.currentTarget
        })
    };

    handleClose = () => {
        this.setState({
            anchorEl: null
        })
    };

    openRemoveShareholderPopup = (id: number) => {
        this.setState({
            removeShareholder: true,
            removeShareholderId: id
        })
    }
    // Customizable Area End

}

import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import React from "react";
// Customizable Area Start
import { ThemeContext } from "../../notifications/src/Context/ThemeContext";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  location: any;
  history : any;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  otp: string;
  otpAuthToken: string;
  userAccountID: string;
  labelInfo: string;
  toMessage: string;
  isFromForgotPassword: boolean;
  snackBar: {
    show: boolean;
    message?: string;
    type?: "success" | "info" | "warning" | "error" | undefined;
  };
  enableResendCode: boolean;
  timer : {
    minutes : number,
    seconds : number
  },
  resendCount : number
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OTPInputAuthController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  static contextType: any = ThemeContext;
  otpAuthApiCallId: any;
  resendOTPCallId : any;
  btnTxtSubmitOtp: string;
  placeHolderOtp: string;
  labelInfo: string;
  submitButtonColor: any = configJSON.submitButtonColor;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    console.log(this.props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      otp: "",
      otpAuthToken: "",
      userAccountID: "",
      labelInfo: configJSON.labelInfo,
      toMessage: "",
      isFromForgotPassword: false,
      snackBar: {
        show: false,
        message: "",
      },
      enableResendCode : false,
      timer : {
        minutes: 2,
        seconds : 59
      },
      resendCount : 0
    };

    this.btnTxtSubmitOtp = configJSON.btnTxtSubmitOtp;
    this.placeHolderOtp = configJSON.placeHolderOtp;
    // Customizable Area End
  }

  closeSnackBarHandler = () => {
    this.setState((prev) => ({
      ...prev,
      snackBar: {
        show: false,
        message: "",
        type: undefined,
      },
    }));
  };

  // Open View Details Modal
  openSnackBarHandler = (
    type: "success" | "info" | "warning" | "error" | undefined,
    message: string
  ): void => {
    this.setState((prev) => ({
      ...prev,
      snackBar: {
        show: true,
        message: message,
        type,
      },
    }));
  };


  handleResendCode = () => {
    this.setState((prev) => ({...prev, enableResendCode: true}));
  };


  sendOTP = () => {

    let location_data = this.props.location.state;
    console.log(location_data)

    let attributes = {};

    if (location_data.type === "email") {
      attributes = {
        primary_email : location_data.data.email,
      };
    } else if(location_data.type === "phone") {
      console.log("Phone Type")
      attributes = {
        primary_phone: location_data.data.phone_number.slice(1,location_data.data.phone_number.length),
      };
    }

    const httpBody = {
      data: {
        type: location_data.type === "email" ? "email_account" : "phone_account",
        attributes,
      },
    };

    const header = {
      "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resendOTPCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_clients/send_otp"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if(this.otpAuthApiCallId === apiRequestCallId)
      {
        console.log(responseJson);
        if (responseJson && responseJson.success) {
          this.openSnackBarHandler("success", responseJson.message)
          localStorage.setItem("Token", responseJson.token)
          this.context?.getThemeRequest()
          this.context?.getNotificationRequest()
          this.context?.getUserProfileData()
          setTimeout(()=> {
            this.props.history.push({
              pathname: "/changePassword",
              state: { data: responseJson },
            });
          }, 1200)
          console.log(responseJson);
        } else {
          this.openSnackBarHandler("error", responseJson.message)
          console.log(responseJson);
        }
        this.setState((prev) => ({
          ...prev,
          loader: false,
        }));
      }

      if(this.resendOTPCallId === apiRequestCallId)
      {
        console.log(responseJson);
        if (responseJson && responseJson.success) {
          this.openSnackBarHandler("success", responseJson.message);
          this.setState((prev) => ({
            ...prev,
            enableResendCode : false,
            resendCount : prev.resendCount + 1
          }));
          console.log(responseJson);
        } else {
          this.openSnackBarHandler("error", responseJson.message)
          console.log(responseJson);
        }
        this.setState((prev) => ({
          ...prev,
          loader: false,
        }));
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  submitOtp = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      // "Content-Type": "multipart/form-data",
    };

    const formdata = new FormData();

    if(this.props?.location?.state?.type === "email")
    {
      formdata.append("data[email]", this.props?.location?.state?.data?.email);
    }else {
      const phone_number = this.props?.location?.state?.data?.phone_number;
      formdata.append("data[phone_number]", phone_number.slice(1,phone_number.length));
    }

    formdata.append("data[otp]", this.state.otp);

    console.log(formdata.has("data[email]"));

    this.otpAuthApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiVerifyOtpEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  txtMobilePhoneOTPWebProps = {
    onChangeText: (text: string) => this.setState({ otp: text }),
  };

  txtMobilePhoneOTPMobileProps = {
    ...this.txtMobilePhoneOTPWebProps,
    keyboardType: "numeric",
  };

  txtMobilePhoneOTPProps = this.isPlatformWeb()
    ? this.txtMobilePhoneOTPWebProps
    : this.txtMobilePhoneOTPMobileProps;

  // Customizable Area End
}

import { Typography, Box } from "@material-ui/core";
import {
    makeStyles,
    createStyles,
    Theme,
} from "@material-ui/core/styles";
import React, { useContext } from "react";
import { ThemeContext } from "../../blocks/notifications/src/Context/ThemeContext";
import { Link } from "react-router-dom";
import { PaymentsI } from "../../blocks/dashboard/src/Interface";
import DashboardPayment from "./DashboardPayment";
interface PaymentProps {
    selectedPayments: PaymentsI | null;
    createPayment: (values: any, type: "echeck" | "card") => void;
}

const PaymentComponent = ({ selectedPayments, createPayment }: PaymentProps) => {
    const classes = useStyles();
    const { primaryColor } = useContext(ThemeContext)

    return (
        <Box>
            <Box
                width="100%"
                height="60vh"
                className={classes.payments}
                style={{
                    border: `1px solid ${primaryColor}`,
                }}
                p={2}
                flex={1}
                flexDirection="column"
            >
                {
                    selectedPayments?.status === "bal" ?
                        <Box>
                            <Box style={{ height: "50vh" }}>
                                <Typography variant="h5" align="center" color="primary" style={{ height: '50vh', display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 500 }}>
                                    {selectedPayments?.message}
                                </Typography>
                            </Box>
                            {
                                selectedPayments?.status === "bal" ?
                                    <Box display="flex" justifyContent="center" p={2}>
                                        <Link to="/dashboard/payments" style={{ textDecoration: "none", color: "black" }}>
                                            <Typography
                                                className={classes.submit_btn}
                                                color="primary"
                                                // variant="contained"
                                                style={{
                                                    color: "#ffffff",
                                                    backgroundColor: primaryColor,
                                                    cursor: "pointer",
                                                    padding: '10px',
                                                    borderRadius: "10px",
                                                    width: "100%"
                                                }}
                                            >
                                                View Payment History
                                            </Typography>
                                        </Link>
                                    </Box>

                                    : ""
                            }
                        </Box>
                        :
                        <DashboardPayment submitPayment={createPayment} type="card" />
                }

            </Box>

        </Box>
    )
}
export default PaymentComponent;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        payments: {
            borderRadius: 10,
            backgroundColor: "#F6F6F6",
            overflowX: 'hidden',
            overflowY: "scroll"
        },
        submit_btn: {
            fontSize: "1rem",
            // background: "#67a0a3",
            width: "40%",
            color: "#fff",
        },

    })
);
import {
    Typography,
    Box,
    IconButton,
    Button,
    Grid,
    FormControl,
    Select,
    MenuItem
} from "@material-ui/core";
import {
    makeStyles,
    createStyles,
    Theme,
} from "@material-ui/core/styles";
import React from "react";
import Close from '@material-ui/icons/Close';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { cancelIcon } from "../../blocks/dashboard/src/assets"
import { FolderModel } from "../../blocks/dashboard/src/Interface";
import { showFileName } from "./Utils";
import { useTheme } from "../../blocks/notifications/src/Context/ThemeContext";
interface UploadDocProps {
    closeModal: () => void,
    handleUploadedData: (name: string, value: any) => void,
    folderData: FolderModel[],
    createDocuments: () => void
}

const UploadDocument = ({ closeModal, handleUploadedData, folderData, createDocuments }: UploadDocProps) => {
    const initialFolder = folderData[0]?.id
    const classes = useStyles();
    const { primaryColor } = useTheme();
    const [folderName, setFolderName] = React.useState<number>(initialFolder)
    const [filename, setFilename] = React.useState("");
    const [error, setError] = React.useState("");

    React.useEffect(() => {
        setFolderName(initialFolder)
        handleUploadedData("folderId", initialFolder)
    }, [])

    const handleFileUpload = (e: any, field: string) => {
        if (!e.target.files) {
            return;
        }
        const file = e.target.files[0];
        const { name } = file;
        setFilename(name);
        const ext = name.slice(-3)
        if (ext !== "pdf") {
            setError("Please select pdf file only")
        } else {
            setError("");
            handleUploadedData(field, file)
        }
    }

    const cancelFile = () => {
        setFilename("")
        setError("")
    }


    const handleFolderChange = (event: any, name: string) => {
        const userType = event.target.value;
        setFolderName(userType)
        handleUploadedData(name, userType)
    }


    return (
        <Box className={classes.modalStyle}>
            <Box onClick={closeModal} className={classes.closeStyle}>
                <img src={cancelIcon} height="20px" style={{ color: primaryColor }} />
            </Box>
            <Box>
                <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ marginBottom: 10 }}>
                    <Grid item xs={6}>
                        <Typography style={{ fontFamily: "Rubik-Regular", fontSize: "16px" }}>Securely share a Document with us</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ marginBottom: 10 }}>
                    <Grid item xs={6}>
                        {
                            filename ?
                                <>
                                    <Box style={{ height: "35px", backgroundColor: primaryColor, width: "100%", borderRadius: "5px", }}>
                                        <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "3px 10px" }}>
                                            <Typography style={{ fontFamily: "Rubik-Regular" }}>{showFileName(filename)}</Typography>

                                            <IconButton
                                                onClick={cancelFile}
                                                style={{
                                                    height: '20px',
                                                    width: '20px',
                                                    borderRadius: '50%',
                                                    backgroundColor: "#ffffff",
                                                }}
                                            >
                                                <Close style={{ color: primaryColor }} />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    {error && <Typography style={{ fontFamily: "Rubik-Regular", color: "red", fontSize: "12px" }}>{error}</Typography>}
                                </>
                                :
                                <>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        style={{ width: "100%", textTransform: "inherit", fontFamily: "Rubik-Regular", backgroundColor: "#E7EAEF" }}
                                    >
                                        Select File
                                        <input
                                            type="file"
                                            accept="application/pdf"
                                            onChange={(e) => handleFileUpload(e, "file")}
                                            hidden
                                        />
                                    </Button>
                                    {error && <Typography style={{ fontFamily: "Rubik-Regular", color: "red", fontSize: "12px" }}>{error}</Typography>}
                                </>
                        }
                    </Grid>
                </Grid>
                <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ marginBottom: 10 }}>
                    <Grid item xs={6}>
                        <FormControl style={{ minWidth: "150px", width: "100%" }} >
                            <Select
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    PaperProps: {
                                        className: classes.paper
                                    },
                                    getContentAnchorEl: null
                                }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={folderName}
                                className={classes.select}
                                label="Sort"
                                onChange={(e) => handleFolderChange(e, "folderId")}
                                disableUnderline={true}
                                IconComponent={KeyboardArrowDownIcon}
                            >
                                {
                                    folderData.length > 0 &&
                                    folderData.map((folder: FolderModel) => (
                                        <MenuItem key={folder.id} className={classes.menuItemStyle}
                                            style={{ fontFamily: "Rubik-Regular", fontSize: "14px" }}
                                            value={folder?.id}
                                        >
                                            {folder?.folder_name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                            <span className={classes.customArrow}></span>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={3} justifyContent="center" alignItems="center" style={{ marginBottom: "30px" }}>
                    <Grid item xs={5}>
                        <Button
                            onClick={closeModal}
                            variant="contained"
                            style={{ width: "100%", textTransform: "inherit", fontFamily: "Rubik-Regular", backgroundColor: "#E7EAEF" }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={5}>
                        <Button
                            variant="contained"
                            style={{
                                width: "100%", textTransform: "inherit", fontFamily: "Rubik-Regular",
                                backgroundColor: filename && !error ? primaryColor : "#E7EAEF",
                                color: filename && !error ? "#ffffff" : "#000"
                            }}
                            disabled={filename && !error ? false : true}
                            onClick={() => createDocuments()}
                        >
                            Upload Documents
                        </Button>
                    </Grid>
                </Grid>
            </Box>

        </Box>
    )
}
export default UploadDocument;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalStyle: {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '600px',
            height: "auto",
            backgroundColor: '#ffffff',
            borderRadius: '6px',
            boxShadow: '24px',
            padding: '10px 20px 30px 20px',
            // display: "flex",
            // flexDirection: "column",
            // justifyContent: "center",
            // alignItems: "center"
        },
        closeStyle: {
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            cursor: "pointer",
        },
        inputStyle: {
            backgroundColor: "#e6eaee",
            color: "black",
            padding: "5px 10px",
            fontFamily: "Rubik-Regular",
            borderRadius: "5px"
        },
        paper: {
            backgroundColor: "#ffffff",
            height: "110px",
            border: "1px solid #ffffff",
            overflowY: "auto",
        },
        menuItemStyle: {
            textAlign: "right",
            color: '#000 !important',
            backgroundColor: '#ffffff',
            fontFamily: "Rubik-Bold"
        },
        customArrow: {
            position: "absolute",
            top: 0,
            right: 0,
            display: "block",
            backgroundColor: `${localStorage.getItem("primaryColor") || "#67a1a3"}`,
            // borderRight: "1px solid #ffffff",
            // borderRadius: "0 6px 6px 0",
            // height: '100%',
            width: "3rem",
            pointerEvents: 'none',
            color: "#ffffff"
        },
        select: {
            fontSize: "14px",
            fontFamily: "Rubik-Regular",
            // border: "1px solid #ffffff",
            height: "37px",
            borderRadius: "5px 5px 5px 5px",
            backgroundColor: `${localStorage.getItem("primaryColor") || "#67a1a3"}`,
            padding: "5px 10px",
            "& .MuiSelect-icon": {
                zIndex: 1,
                right: ".7rem",
                color: "#ffffff",
            },
            "& .MuiSelect-select": {
                color: "#fff !important",
                backgroundColor: "#67a1a3 !important",
                paddingLeft: "10px",
            },

        },
        formControl: {
            backgroundColor: "#e6eaee",
            padding: "5px 10px",
            borderRadius: '10px 0 0 10px',
            marginTop: "-9px",
            width: "100%",
        },

    })
);
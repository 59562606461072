import React from 'react';
import { Box } from "@material-ui/core"
import { Document, Page } from 'react-pdf';
import "./pdfStyle.css"
interface Base64I {
  base64: string
}

const Base64PDFViewer = ({ base64 }: Base64I) => {
  const [numPages, setNumPages] = React.useState(null);
  const [pdfUrl, setPdfUrl] = React.useState("");

  //   @ts-ignore
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  React.useEffect(() => {

    function base64ToPDF(base64: string) {
      // decode the base64 string
      const decodedData = atob(base64);
      // create a Uint8Array from the decoded data
      const byteArray = new Uint8Array(decodedData.length);
      for (let i = 0; i < decodedData.length; i++) {
        byteArray[i] = decodedData.charCodeAt(i);
      }
      // create a Blob from the Uint8Array
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      // create a URL for the Blob
      const url = URL.createObjectURL(blob);
      // open the PDF in a
      console.log({ url });
      setPdfUrl(url)

    }
    base64ToPDF(base64)
    return () => {
      URL.revokeObjectURL(pdfUrl);
    };
  }, [base64]);

  console.log({ pdfUrl })

  return (
    <div>
      {pdfUrl ? (
        <Box style={{ height: "50vh", overflowY: "scroll", overflowX: "auto", width: "100%" }}>
          <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Box key={index + 1} style={{ marginBottom: "7px" }}>
                <Page key={`page_${index + 1}`} pageNumber={index + 1} children={el} />
              </Box>
            ))}
          </Document>
        </Box>
      ) : (
        <div>Loading PDF...</div>
      )}
    </div>
  );
};

export default Base64PDFViewer;
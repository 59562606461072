import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import Notifications from "../../notifications/src/NotificationsController";

// Customizable Area Start
import { DocumentSignatureI, DocumentsResponse, SectionType } from "./Interface";
import { staticPaymentsData } from "./staticPaymentsData";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  history : any
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    accountingChecklist : any,
    payrollChecklist : any,
    priorities : any,
    payment_details : {
      description : string,
      amount : number
    },
    filterAnchorEl : any,
    avatarMenuEl : any
  };
  selectedChecklist : {
    id : string,
    type : string
  };
  open: any;
  completedTaskSelectedId: string;
  completedTask: {
    checklists: {
      data: [{
        id: string;
        type: string;
        attributes: {
          id: string;
          checklist_name: string;
          account_id: string;
          is_completed: boolean;
          sections: any;
        }
      }]
    },
    documentsResponse: DocumentsResponse
  };
  completedTaskSectionData: {
    id: string;
    type: string;
    attributes: {
      id: string;
      section_name: string;
      section_rank: number;
      questions: {
        data: [{
          id: string;
          type: string;
          attributes: {
            id: string;
            question_name: string;
            checked: boolean,
            client_status: string,
            request_document: boolean,
            status: string,
            current_gathered_details: string,
            question_rank: number,
            question_type: string,
            question_options: [],
            document: string | null,
          },
        }]
      }
    }
  };
  prioritiesExpand : boolean;
  token: string;
  errorMsg: string;
  loading: boolean;
  isDialogOpen : boolean;
  anchorEl: any;
  selectedDocuments: DocumentSignatureI;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  completedTasksCallId: string = "";
  completedTaskSectionId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start

    this.subScribedMessages = [ 
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      dashboardData: {
        accountingChecklist : dummyAccoutingChecklist,
        payrollChecklist : dummyPayrollChecklist,
        priorities : null,
        payment_details : {
          description : '',
          amount : 0.00
        },
        filterAnchorEl : null,
        avatarMenuEl : null,
      },
      selectedChecklist : {
        type : '',
        id : ''
      },
      open: {},
      completedTaskSelectedId: '',
      completedTask: {
        checklists: {
          data: [{
            id: '',
            type: '',
            attributes: {
              id: '',
              account_id: '',
              is_completed: false,
              checklist_name: '',
              sections: [],
            },
          }]
        },
        documentsResponse: {
          client_id: "",
          data: []
      },
      },
      completedTaskSectionData: {
        "id": "",
        "type": "",
        "attributes": {
            "id": "",
            "section_name": "",
            "section_rank": 0,
            "questions": {
                "data": [
                    {
                        "id": "",
                        "type": "",
                        "attributes": {
                            "id": "",
                            "question_name": "",
                            "checked": false,
                            "client_status": "",
                            "request_document": false,
                            "status": "",
                            "current_gathered_details": "",
                            "question_rank": 0,
                            "question_type": "",
                            "question_options": [],
                            "document": null
                        }
                    }
                ]
            }
        }
      },
      errorMsg: "",
      token: "",
      loading: false,
      prioritiesExpand : false,
      isDialogOpen : false,
      anchorEl: null,
      selectedDocuments: {
        document_template_id: 0,
        document_template_title: "",
        document_url: "",
        signature: "",
        status: false,
        survey_id: -11,
        signature_request_id: ""
    },
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  componentDidUpdate(prevProps: Props, prevState: S, snapshot: SS): void {
    if(prevState.selectedChecklist.id !== this.state.selectedChecklist.id || prevState.selectedChecklist.type !== this.state.selectedChecklist.type){
      let details : any;
      if(this.state.selectedChecklist.type === "account")
      { 
        details = this.state.dashboardData.accountingChecklist.filter((item: any)=> item.id === this.state.selectedChecklist.id)
      }else {
        details = this.state.dashboardData.payrollChecklist.filter((item: any)=> item.id === this.state.selectedChecklist.id)
      }
      
      
      this.setState((prev) => ({
        ...prev,
        dashboardData : {
          ...prev.dashboardData,
          payment_details : {
            description : details[0].payment_details.description,
            amount  : details[0].payment_details.amount
          }
        }
      }))
    }
  }

  // Customizable Area Start
  
  handleMenuExpand = (e: React.MouseEvent<HTMLButtonElement>) => {
    console.log(e.currentTarget)
    this.setState((prev) => ({
      ...prev,
      dashboardData : {
        ...prev.dashboardData,
        avatarMenuEl : e.currentTarget
      }
    }))
    e.persist();
  }

  logout = () => {
    localStorage.removeItem('Token');
    localStorage.removeItem('logo');
    localStorage.removeItem('primaryColor');
    localStorage.removeItem('secondaryColor');
    localStorage.removeItem('email');
    localStorage.removeItem('client_id');
    this.context?.clearThemeContext()
    //@ts-ignore
    this?.props?.history?.push("/signin");
}
handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  this.setState({
      anchorEl: event.currentTarget
  })
};

handleClose = () => {
  this.setState({
      anchorEl: null
  })
};


  handleMenuClose = () => {
    this.setState((prev) => ({
      ...prev,
      dashboardData : {
        ...prev.dashboardData,
        avatarMenuEl : null
      }
    }))
  };

  handleDialogClose = () => {
    this.setState({ isDialogOpen : false})
  }

  handleDialogOpen = () => {
    this.setState({ isDialogOpen : true})
  }

  handleOpenFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState((prev) => ({
      ...prev,
      dashboardData : {
        ...prev.dashboardData,
        filterAnchorEl : event.currentTarget
      }
    }));
  };

  handleFilterClose = () => {
    this.setState((prev) => ({
      ...prev,
      dashboardData : {
        ...prev.dashboardData,
        filterAnchorEl : null
      }
    }))
  }

  handleExpand = () => {
    this.setState({ prioritiesExpand : !this.state.prioritiesExpand})
  }

  handleSelect = (id: string, type: "account" | "payroll") => {
    this.setState(prev => ({
      ...prev,
      selectedChecklist : {
        type,
        id
      }
    }))
  }

  handleCompleteTask = (item: SectionType) => {
    console.time()
    if (item?.type === "documents") {
      const _selectedDocs = this.state.completedTask?.documentsResponse?.data?.filter((Staticitem: DocumentSignatureI) => Staticitem?.document_template_title === item?.section_name)
      this.setState({
        completedTaskSelectedId: item?.id.toString(),
        selectedDocuments: _selectedDocs[0],
      }, () => console.timeEnd())
      return;
    }
    this.setState((prev) => {
      return {
        ...prev,
        completedTaskSelectedId: item?.id.toString(),
        selectedDocuments: {
          document_template_id: 0,
          document_template_title: "",
          document_url: "",
          signature: "",
          status: false,
          survey_id: -11,
          signature_request_id: ""
        }
      }
    }, () => console.timeEnd())
    this.getSectionData(item?.id.toString());
  }

  handleCollapse = (key: string) => {
    this.setState((prev) => {
      return {
        ...prev,
        open: {
          ...prev.open,
          [key]: !prev.open[key]
        }
      }
    })
  }


  handleChecklistChange = (id : string, type: "account" | "payroll", e: any) => {
    e.stopPropagation();
    const newChecklist = type === "account" ? [...this.state.dashboardData.accountingChecklist] : [...this.state.dashboardData.payrollChecklist];
    newChecklist.forEach((chklist: any) => {
      if(chklist.id === id)
      {
        chklist.isChecked = !chklist.isChecked;
      }
    });
    if(type === "account")
    {
      this.setState((prev) => ({
          ...prev,
          dashboardData : {
            ...prev.dashboardData,
            accountingChecklist : newChecklist
          }
      }))
    }
    else {
      this.setState((prev) => ({
        ...prev,
        dashboardData : {
          ...prev.dashboardData,
          payrollChecklist : newChecklist
        }
    }))
    }
  }

  getPriorities = async () => {

    const token = await localStorage.getItem('Token');
    
    const header = {
      "Content-Type" : configJSON.dashboarContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getDashboardData(): boolean {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getSectionData(id: string) {
    const token = localStorage.getItem('Token');
    
    if(!token)
    {
      this.props.history.push("/signin");
      return;
    }

    const header = {
      "token": token
    };

    const url = `${configJSON.completedTaskSection}?id=${id}`;
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.completedTaskSectionId = requestMessage.messageId;
    console.log("Working the section data API");
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getCompletedTasks() {

    this.setState({
      loading: true
    })

    const token = localStorage.getItem('Token');
    
    if(!token)
    {
      this.props.history.push("/signin");
      return;
    }

    const header = {
      "token": token,
      "Content-Type": configJSON.dashboarContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.completedTasksCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.completedTask
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  setCompletedTaskResponseData = (responseJson: any, errorResponse: any) => {
    if(responseJson && !responseJson.error) {
      let _document = responseJson?.documents?.data?.map((item: DocumentSignatureI) => {
        return {
            ...item,
            id: item?.document_template_id,
            status: "completed",
            section_name: item?.document_template_title,
            type: "documents"
        }
    })
    staticPaymentsData.attributes.sections = _document;
      this.setState(prev => ({
        ...prev,
        completedTask: {
          checklists: {
            data: responseJson?.documents?.data?.length > 0 ||
            responseJson?.documents?.payment?.survey_id ? [
            { ...staticPaymentsData },
            ...responseJson?.checklists?.data
        ] : responseJson?.checklists?.data,
          },
          documentsResponse: responseJson?.documents,
        },
        loading: false
      }))
    }else{
      this.setState({
        loading: false,
        errorMsg: errorResponse
      })
    }
  }

  setCompletedTaskSectionId = (responseJson: any) => {
    if(responseJson) {
      this.setState(prev => ({
        ...prev,
        completedTaskSectionData: responseJson.data
      }))
    }
  }
  
  // Customizable Area End

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    const token = localStorage.getItem('Token');
    if(!token)
    {
      //@ts-ignore
      this.props?.history?.push('/signin');
      return;
    }
    else{
      this.getCompletedTasks();
      this.getPriorities();
      new Notifications({ navigation : "", history : "", id : "", context : this.context}).getNotificationsAPI(1)
    }

    const selectedCheckList = {
      type : 'account',
      id : this.state.dashboardData.accountingChecklist[0].id
    }

    const payment_details = this.state.dashboardData.accountingChecklist[0].payment_details;

    this.setState((prev)=> ({
      ...prev,
      dashboardData : {
        ...prev.dashboardData,
        payment_details : payment_details,
      },
      selectedChecklist : selectedCheckList
    }))

    
    // Customizable Area End
  }
  
  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token, loading: true }, () => {
        this.getDashboardData();
      });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if(this.apiDashboardItemCallId === apiRequestCallId) {
        if(responseJson && responseJson.priorities)
        {
          this.setState(prev => ({
            ...prev,
            dashboardData : {
              ...prev.dashboardData,
              priorities : responseJson.priorities.data
            }
          }))
        }
        else {
          this.props.history.push('/signin'); 
        }
      }
      
      if(this.completedTasksCallId === apiRequestCallId) {
        this.setCompletedTaskResponseData(responseJson, errorReponse);
      } 
      if(this.completedTaskSectionId === apiRequestCallId) {
        this.setCompletedTaskSectionId(responseJson);
      }
    }
  // Customizable Area End
  }
}

  // Customizable Area Start
  // Customizable Area End

//Mocked Data
const dummyAccoutingChecklist = [
  {
    id : 'c7382939-4772-473d-aedc-1c9030ba01ba',
    title : 'W2 Requested',
    type : 'Business',
    isChecked : 'false',
    payment_details : {
      description : 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit voluptatibus a ex magnam neque eligendi ratione voluptate excepturi ad eum facere dolore nemo quia, deleniti sapiente doloribus sit dolorum tempora!',
      amount : '3.22'
    },
  },
  {
    id : '2b304f78-c073-4c19-afe7-fe1487da0d2e',
    title : 'Submit Payment',
    type : 'Personal',
    isChecked : 'false',
    payment_details : {
      description : 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit voluptatibus a ex magnam neque eligendi ratione voluptate excepturi ad eum facere dolore nemo quia, deleniti sapiente doloribus sit dolorum tempora!',
      amount : '10.50'
    },
  },{
    id : '7bd1b6e0-c9f7-490e-870f-aa4b39ad7c63',
    title : 'eSign__Disclosure',
    type : 'Personal',
    isChecked : 'false',
    payment_details : {
      description : 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit voluptatibus a ex magnam neque eligendi ratione voluptate excepturi ad eum facere dolore nemo quia, deleniti sapiente doloribus sit dolorum tempora!',
      amount : '32.00'
    },
  }
]

const dummyPayrollChecklist = [
  {
    id : 'c7382939-4772-473d-aedc-1c9030ba01ba',
    title : 'W2 Requested',
    type : 'Personal',
    isChecked : 'true',
    payment_details : {
      description : 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magni aperiam maiores illum, at dolores quibusdam voluptate magnam alias fugiat corporis nulla expedita ab veritatis quasi soluta temporibus ea repudiandae Quod eligendi quibusdam nesciunt tempore saepe, tempora, pariatur expedita voluptate eaque distinctio placeat soluta, iste omnis molestias. Tempore soluta ut eum excepturi sint error, vitae quisquam. Sequi a accusamus fugiat!Dignissimos quibusdam suscipit laboriosam cumque expedita sapiente explicabo error sequi quas non mollitia molestias voluptate quos, sed porro magnam reiciendis aliquam ut architecto placeat nam id ab et officiis. Ea.Temporibus, pariatur! Excepturi harum sint iusto officia ratione nihil eos soluta, expedita minus doloribus error iure ad repudiandae est magni. Vero ratione est consequatur consequuntur unde porro repudiandae sit. Veniam.Eius est commodi rerum aut, quasi, quisquam autem unde libero nobis facilis, blanditiis iste! Voluptates voluptate earum dolore quasi? Accusamus voluptas ducimus corporis, doloremque beatae eligendi voluptate nostrum accusantium delectus',
      amount : '21.00'
    },
  },
  {
    id : '2b304f78-c073-4c19-afe7-fe1487da0d2e',
    title : 'eSign__Disclosure',
    type : 'Business',
    isChecked : 'false',
    payment_details : {
      description : 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit voluptatibus a ex magnam neque eligendi ratione voluptate excepturi ad eum facere dolore nemo quia, deleniti sapiente doloribus sit dolorum tempora!',
      amount : '34.90'
    },
  },
  {
    id : '7bd1b6e0-c9f7-490e-870f-aa4b39ad7c63',
    title : 'W2 Requested',
    type : 'Personal',
    isChecked : 'true',
    payment_details : {
      description : 'Lorem ipsum dolor sit amet, distinctio placeat soluta, iste omnis molestias. Tempore sint error, vitae quisquam. Sequi a accusamus fugiat!Dignissimos quibusdam suscipit laboriosam cumque expedita sapiente explicabo error sequi quas non mollitia molestias voluptate quos, sed porro magnam reiciendis aliquam ut architecto placeat nam id ab et officiis. Ea.Temporibus, pariatur! Excepturi harum sint iusto officia ratione nihil eos soluta, expedita minus doloribus error iure ad repudiandae est magni. Vero ratione est consequatur consequuntur unde porro repudiandae sit. Veniam.Eius est commodi rerum aut, quasi, quisquam autem unde libero nobis facilis, blanditiis iste! Voluptates voluptate earum dolore quasi? Accusamus voluptas ducimus corporis, doloremque beatae eligendi voluptate nostrum accusantium delectus',
      amount : '21.20'
    },
  },
]

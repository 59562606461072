import { Grid, Box, Typography, TextField, IconButton } from "@material-ui/core";
import {
    makeStyles,
    createStyles,
    Theme,
} from "@material-ui/core/styles";
import React from "react";
import { useTheme } from "../../blocks/notifications/src/Context/ThemeContext";
import { deleteIcon } from "../../blocks/user-profile-basic/src/assets";
import { ShareholderType } from "../../blocks/user-profile-basic/src/Interface";
import ShowShareholder from "./ShowShareholder";
export interface NameProps {
    business: string;
    handleShareholderData: (id: number, value: string, name: string) => void;
    shareholder: ShareholderType;
    removeShareholder: (id: number) => void;
    editShareholder: (id: number) => void;
}


const AddShareholder = ({ business, handleShareholderData, shareholder, removeShareholder, editShareholder }: NameProps) => {
    const { primaryColor } = useTheme()
    if (!shareholder?.attributes?.isEdit) {
        return (
            <ShowShareholder
                business={business}
                shareholder={shareholder}
                removeShareholder={removeShareholder}
                editShareholder={editShareholder}
            />
        )
    }
    return (
        <>
            {
                business.toLowerCase() === "client_businesses" || business.toLowerCase() === "business" ?
                    <Box style={{ marginTop: "20px" }}>
                        <Box style={{ border: "1px solid #a8b0b6", borderRadius: "10px", padding: "5%" }}>
                            <Grid container justifyContent="center">
                                <Grid container justifyContent="center">
                                    <Grid item md={8} xs={10} style={{marginBottom: "20px"}}>
                                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography style={{ fontFamily: "Rubik-Regular", fontSize: '20px' }}>Shareholder Details</Typography>
                                            <IconButton
                                                onClick={() => removeShareholder(shareholder?.attributes?.id)}
                                                style={{
                                                    height: '45px',
                                                    width: '45px',
                                                    borderRadius: '50%',
                                                    backgroundColor: primaryColor,
                                                }}
                                            >
                                                <img src={deleteIcon} height={'20px'} />
                                            </IconButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={4} justifyContent="center">
                                    <Grid item md={4} xs={12}>
                                        <Typography
                                            variant="h6"
                                            style={{
                                                fontFamily: "Rubik-Regular",
                                                fontSize: "18px",
                                                color: '#353535',
                                                marginBottom: "0.6rem"
                                            }}

                                        >
                                            First Name*
                                        </Typography>
                                        <TextField
                                            style={{ width: "100%", marginBottom: 20 }}
                                            placeholder="First Name"
                                            variant="standard"
                                            name="firstName"
                                            className="email_input"
                                            autoComplete="off"
                                            onChange={(e) => handleShareholderData(shareholder?.attributes?.id, e.target.value.trim(), "first_name")}
                                            error={
                                                shareholder?.attributes?.error?.first_name ? true : false
                                            }
                                            helperText={
                                                shareholder?.attributes?.error?.first_name
                                            }
                                            value={shareholder?.attributes?.first_name}
                                            InputProps={{
                                                style: {
                                                    backgroundColor: "#e6eaee",
                                                    color: "black",
                                                    padding: "15px",
                                                    borderBottom: `1px solid ${primaryColor}`,
                                                    fontFamily: "Rubik-Regular"
                                                },
                                                disableUnderline: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        <Typography
                                            variant="h6"
                                            style={{
                                                fontFamily: "Rubik-Regular",
                                                fontSize: "18px",
                                                color: '#353535',
                                                marginBottom: "0.6rem"
                                            }}

                                        >
                                            Last Name*
                                        </Typography>
                                        <TextField
                                            style={{ width: "100%", marginBottom: 20 }}
                                            placeholder="Last Name"
                                            variant="standard"
                                            name="lastName"
                                            className="email_input"
                                            autoComplete="off"
                                            onChange={(e) => handleShareholderData(shareholder?.attributes?.id, e.target.value.trim(), "last_name")}
                                            error={
                                                shareholder?.attributes?.error?.last_name ? true : false
                                            }
                                            helperText={
                                                shareholder?.attributes?.error?.last_name
                                            }
                                            value={shareholder?.attributes?.last_name}
                                            InputProps={{
                                                style: {
                                                    backgroundColor: "#e6eaee",
                                                    color: "black",
                                                    padding: "15px",
                                                    borderBottom: `1px solid ${primaryColor}`,
                                                    fontFamily: "Rubik-Regular"
                                                },
                                                disableUnderline: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="center">
                                    <Grid item md={8} xs={12}>
                                        <Typography
                                            variant="h6"
                                            style={{
                                                fontFamily: "Rubik-Regular",
                                                fontSize: "18px",
                                                color: '#353535',
                                                marginBottom: "0.6rem"
                                            }}

                                        >
                                            Email*

                                        </Typography>
                                        <TextField
                                            style={{ width: "100%", marginBottom: 20 }}
                                            placeholder="Email Address"
                                            variant="standard"
                                            name="email"
                                            className="email_input"
                                            autoComplete="off"
                                            onChange={(e) => handleShareholderData(shareholder?.attributes?.id, e.target.value.trim(), "email")}
                                            error={
                                                shareholder?.attributes?.error?.email ? true : false
                                            }
                                            helperText={
                                                shareholder?.attributes?.error?.email
                                            }
                                            value={shareholder?.attributes?.email}
                                            InputProps={{
                                                style: {
                                                    backgroundColor: "#e6eaee",
                                                    color: "black",
                                                    padding: "15px",
                                                    borderBottom: `1px solid ${primaryColor}`,
                                                    fontFamily: "Rubik-Regular"
                                                },
                                                disableUnderline: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="center">
                                    <Grid item md={8} xs={12}>
                                        <Typography
                                            variant="h6"
                                            style={{
                                                fontFamily: "Rubik-Regular",
                                                fontSize: "18px",
                                                color: '#353535',
                                                marginBottom: "0.6rem"
                                            }}

                                        >
                                            Phone Number
                                        </Typography>
                                        <TextField
                                            style={{ width: "100%", marginBottom: 20 }}
                                            placeholder="Phone Number"
                                            variant="standard"
                                            name="phoneNumber"
                                            className="email_input"
                                            autoComplete="off"
                                            onChange={(e) => handleShareholderData(shareholder?.attributes?.id, e.target.value.trim(), "phone_number")}
                                            error={
                                                shareholder?.attributes?.error?.phone_number ? true : false
                                            }
                                            helperText={
                                                shareholder?.attributes?.error?.phone_number
                                            }
                                            value={shareholder?.attributes?.phone_number}
                                            InputProps={{
                                                style: {
                                                    backgroundColor: "#e6eaee",
                                                    color: "black",
                                                    padding: "15px",
                                                    borderBottom: `1px solid ${primaryColor}`,
                                                    fontFamily: "Rubik-Regular"
                                                },
                                                disableUnderline: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Box>
                    </Box>
                    :
                    ""
            }
        </>
    )
}
export default AddShareholder;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper_view: {
            width: "100%",
            // backgroundColor: "#e7eaef",
            border: "1px dashed #a8b0b6",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            // height: "50px",
            padding: "20px 0",
            cursor: "pointer",
        },
    })
);
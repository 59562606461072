// Customizable Area Start
import React from "react";
import {
  Container,
  Box,
  Button,
  Typography,
  Snackbar,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import OTPInput from "react-otp-input";
import Footer from "../../../components/src/Footer.web";
import { withRouter } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

import OTPInputAuthController, { Props } from "./OTPInputAuthController";
import Timer from "./Timer";
export class OTPInputAuth extends OTPInputAuthController {
  constructor(props: Props) {
    super(props);
  }

  handleChange = (OTP: any) => {
    this.setState({ otp: OTP });
  };

  render() {
    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Container maxWidth="md">
          <Box
            sx={{
              display: "flex",
              flexDirection: "columm",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "80%",
                padding: "50px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  padding: "10 0",
                }}
              >
                <img
                  src={require("../../globalAssets/logo.png")}
                  width="230px"
                  height="80px"
                  style={{ margin: "0 auto" }}
                />
              </Box>
              <Box
                sx={{
                  margin: "10 0",
                  padding: 20,
                }}
                boxShadow={5}
                borderRadius={10}
              >
                <Box>
                  <Typography align="center">
                    <Box
                      fontWeight={900}
                      fontSize={22}
                      margin="10"
                      component="span"
                      style={{ fontFamily: "DM Sans" }}
                    >
                      Enter Security Code
                    </Box>
                  </Typography>
                  <Box my={1}>
                    <Typography align="center" variant="body2">
                      <Box color="gray" fontSize={14} component="span">
                        A security code has been sent to your Email / Phone
                      </Box>
                    </Typography>
                  </Box>
                </Box>
                <form>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    my={5}
                  >
                    <Box display="flex" flexDirection="column" width="50%">
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        width="100%"
                      >
                        <Box>
                          <OTPInput
                            onChange={this.handleChange}
                            value={this.state.otp}
                            numInputs={5}
                            inputStyle={{
                              height: "2.5rem",
                              width: "2.5rem",
                              margin: 10,
                              backgroundColor: "#e6eaee",
                              border: "none",
                              borderRadius: 5,
                              fontWeight: "700",
                              fontSize: "1.3rem",
                            }}
                            shouldAutoFocus={true}
                            isInputNum={true}
                          />
                        </Box>
                        {
                          this.state.resendCount < 3
                           ? <Box>
                            <Button
                              style={{color : this.state.enableResendCode ? "#007bff" : "grey"}}
                              disabled={
                                this.state.enableResendCode ? false : true
                              }
                              onClick={this.sendOTP}
                            >
                              <Box
                                fontSize="0.8rem"
                                display="flex"
                                alignItems="center"
                                component="span"
                              >
                                Resend Code
                              </Box>
                            </Button>
                            <Box mx={1} color="blue" component="span" style={{ fontFamily : "DM Sans"}}>
                                <Timer
                                  initialMinute={this.state.timer.minutes}
                                  initialSeconds={this.state.timer.seconds}
                                  codeHandler={this.handleResendCode}
                                  resendCode={this.state.enableResendCode}
                                />
                            </Box>
                            </Box>
                          : <Typography variant="body2" style={{ color : "gray", padding : 10}}>No attempts left to resend code.</Typography>
                        }
                      </Box>
                      <Box width="100%" mt={5}>
                        <Button
                          variant="contained"
                          fullWidth
                          style={{
                            backgroundColor:
                              this.state.otp.trim().length === 5
                                ? "#67a0a3"
                                : "#c6c9c9",
                            padding: 10,
                            color: "white",
                            borderRadius: 10,
                            textTransform: "none",
                          }}
                          disabled={
                            this.state.otp.trim().length === 5 ? false : true
                          }
                          onClick={this.submitOtp}
                          type="submit"
                        >
                          <Typography>
                            <Box fontWeight={900} component="span">
                              Next
                            </Box>
                          </Typography>
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </form>
              </Box>
            </Box>
          </Box>
        </Container>
        <Footer type="auth" />
        <Snackbar
          open={this.state.snackBar.show}
          onClose={this.closeSnackBarHandler}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.closeSnackBarHandler}
            severity={this.state.snackBar.type}
            style={{
              backgroundColor:
                this.state.snackBar.type === "success" ? "#67a0a3" : "#f44336",
            }}
          >
            {this.state.snackBar.message}
          </MuiAlert>
        </Snackbar>
      </ThemeProvider>
    );
  }
}

//@ts-ignore
export default withRouter(OTPInputAuth);
// Customizable Area End

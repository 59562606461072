import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgBell } from "./assets";
import { NotificationData } from "./Interface";
import NotificationContext from "./Context/NotificationContext.web";


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history : any;
  context : any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  
  notification : {
    loading : boolean;
    data : NotificationData[],
    totalCount : number
  }
  selectedData: any;
  token: any;
  anchorEl: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataCallId: string = "";
  markAsReadCallId: string = "";
  markAllAsReadCallId: string = "";
  deleteCallId: string = "";
  notificationAPIcallId = "";
  // Customizable Area End
  static contextType = NotificationContext;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      notification : {
        loading : false,
        data : [],
        totalCount : 0
      },
      selectedData: null,
      token: "",
      anchorEl: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getNotificationsAPI(1);
    // Customizable Area Start
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if(apiRequestCallId === this.notificationAPIcallId)
    {
      if(responseJson && responseJson.data.data && responseJson.data.data.length !== 0)
      {
        console.log(responseJson);
        const newData = this.state.notification.data.concat(responseJson.data.data);
        this.setState((prev) => ({
          ...prev,
          notification : {
            loading : false,
            data : newData,
            totalCount : responseJson.total_notification
          }
        }))

        const unseenCount = (() => {
          let count = 0;
          newData.forEach((entry: NotificationData) => {
            if(!entry.attributes.seen) count++;
          })
          return count
        })()

        
        
        if(this.props.context)
        {
          this.props.context.setCount(unseenCount)
        }
        else {
          this.context.setCount(unseenCount)
        }
      }else{
        this.setState((prev) => ({
          ...prev,
          notification : {
            ...prev.notification,
            loading : false,
          }
        }))
        console.log(responseJson.error)
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  iconBellProps = {
    source: imgBell,
  };

  markAllasRead = () => {
    const token = localStorage.getItem('Token');
    
    if(!token)
    {
      this.props.history.push("/signin");
      return;
    }

    const header = {
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAllAsReadCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_client_notification/read_all"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    const newNotifications = [...this.state.notification.data];

    newNotifications.forEach((noti) => {
      noti.attributes.seen = true;
    })

    this.setState((prev) => ({ ...prev, notification : { ...prev.notification, data : [...newNotifications]}}));
    this.context.setCount(0);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  } 


  loadMore_notification = () => {
    console.log(this.state.notification.data.length,this.state.notification.totalCount)
    const currentPAgeNumber = Math.ceil(this.state.notification.data.length/10);
    this.getNotificationsAPI(currentPAgeNumber + 1);
  }

  getNotifications() {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getDataCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPoint
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  markAsRead(id: number) {
    const markAsReadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAsReadCallId = markAsReadMsg.messageId;

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );
    
    runEngine.sendMessage(markAsReadMsg.id, markAsReadMsg);
  }

  deleteNotifications(id: number) {
    console.log(id);
    const deletedMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.deleteCallId = deletedMsg.messageId;

    deletedMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deletedMsg.id, deletedMsg);
  }

  getNotificationsAPI = (page_number : number) => {
    const token = localStorage.getItem('Token');
    
    if(!token)
    {
      this.props.history.push("/signin");
      return;
    }

    const header = {
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationAPIcallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_client_notification/client_notifications?page=${page_number}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.setState((prev) => ({...prev, notification : {
      ...prev.notification,
      loading : true
    }}))
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  

  logout = () => {
    localStorage.removeItem('Token');
    //@ts-ignore
    this?.props.navigation.navigate("signin");
  }

  timeSince(date: string) {
    var seconds = Math.floor(
      (new Date().valueOf() - new Date(date).valueOf()) / 1000
    );
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }
  convertDate(inputFormat: string) {
    function pad(s: any) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("-");
  }

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
        anchorEl: event.currentTarget
    })
  };
  
  handleClose = () => {
    this.setState({
        anchorEl: null
    })
  };
  // Customizable Area End
}

// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import HomeScreen from '../../components/src/HomeScreen';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import BreadcrumbNavigation from '../../blocks/BreadcrumbNavigation/src/BreadcrumbNavigation';
import MobileAccountLoginBlock from '../../blocks/mobile-account-login/src/MobileAccountLoginBlock';
import Download from '../../blocks/Download/src/Download';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth.web';
import RolesPermissions2 from '../../blocks/RolesPermissions2/src/RolesPermissions2';
import Payments from '../../blocks/Payments/src/Payments';
import Pushnotifications from '../../blocks/pushnotifications/src/Pushnotifications';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword.web';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP.web';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import CfCpaCharge from '../../blocks/CfCpaCharge/src/CfCpaCharge';
import ActivityFeed from '../../blocks/ActivityFeed/src/ActivityFeed';
import Notifications from '../../blocks/notifications/src/Notifications.web';
import DocumentOpener from '../../blocks/documentopener/src/DocumentOpener';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import FormApprovalWorkflow from '../../blocks/FormApprovalWorkflow/src/FormApprovalWorkflow';
import Settings5 from '../../blocks/Settings5/src/Settings5';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
import DataStorage from '../../blocks/DataStorage/src/DataStorage';
import BulkUploading from '../../blocks/BulkUploading/src/BulkUploading';
import DigitalSignatures from '../../blocks/DigitalSignatures/src/DigitalSignatures';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import ApiIntegration from '../../blocks/apiintegration/src/ApiIntegration';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import EducationalUserProfile from '../../blocks/educational-user-profile/src/EducationalUserProfile';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import Dashboard from '../../blocks/dashboard/src/Dashboard.web';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web';
import ResetPassword from '../../blocks/email-account-login/src/components/reset-password/ResetPassword.web';
import RolesPermissions from '../../blocks/RolesPermissions/src/RolesPermissions';
import OTPLogin from '../../blocks/email-account-login/src/components/otp-login/OTPLogin.web';
import MangeUsers from "../../blocks/user-profile-basic/src/ManageUsers.web";
import EditUsers from "../../blocks/user-profile-basic/src/EditUsers.web";
import CreateUser from "../../blocks/user-profile-basic/src/CreateUser.web";
import ViewAllData from "../../blocks/user-profile-basic/src/ViewAllData.web";
import ChangePassword from "../../blocks/Settings5/src/ChangePassword.web"
import { NotificationProvider } from '../../blocks/notifications/src/Context/NotificationContext.web'
import ThemeContextProvider from "../../blocks/notifications/src/Context/ThemeContextProvider.web"

const routeMap = {
  RolesPermissions: {
    component: RolesPermissions,
    path: '/RolesPermissions'
  },

  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  BreadcrumbNavigation: {
    component: BreadcrumbNavigation,
    path: '/BreadcrumbNavigation'
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: '/MobileAccountLoginBlock'
  },
  Download: {
    component: Download,
    path: '/Download'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: '/RolesPermissions2'
  },
  Payments: {
    component: Payments,
    path: '/Payments'
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  CfCpaCharge: {
    component: CfCpaCharge,
    path: '/CfCpaCharge'
  },
  ActivityFeed: {
    component: ActivityFeed,
    path: '/ActivityFeed'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  DocumentOpener: {
    component: DocumentOpener,
    path: '/DocumentOpener'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  FormApprovalWorkflow: {
    component: FormApprovalWorkflow,
    path: '/FormApprovalWorkflow'
  },
  Settings5: {
    component: Settings5,
    path: '/Settings5'
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },
  DataStorage: {
    component: DataStorage,
    path: '/DataStorage'
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading'
  },
  DigitalSignatures: {
    component: DigitalSignatures,
    path: '/DigitalSignatures'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: '/ApiIntegration'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: '/EducationalUserProfile'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  signin: {
    component: EmailAccountLoginBlock,
    path: '/signin'
  },
  ResetPassword: {
    component: ResetPassword,
    path: '/ResetPassword'
  },
  OTPLogin: {
    component: OTPLogin,
    path: '/OTPLogin'
  },
  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  Dashboard: {
    component: Dashboard,
    path: '/dashboard'
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  MangeUsers: {
    component: MangeUsers,
    path: '/ManageUsers'
  },
  ViewAllData: {
    component: ViewAllData,
    path: '/ViewAllData/:index'
  },
  EditUsers: {
    component: EditUsers,
    path: "/EditUsers/:userType/:id"
  },
  CreateUser: {
    component: CreateUser,
    path: "/CreateUser"
  },
  ChangePassword: {
    component: ChangePassword,
    path: "/ChangePassword"
  },
  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

import theme from '../../components/src/Theme';
import { ThemeProvider } from '@material-ui/core/styles';
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUserLoggedIn: true,
      openContactModal: false,
      notificationCount: 0
    };
  }

  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <ThemeProvider theme={theme}>
        <View
          style={{
            width: '100%',
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <ThemeContextProvider>
            <NotificationProvider>
              {WebRoutesGenerator({ routeMap })}
            </NotificationProvider>
          </ThemeContextProvider>
        </View>
      </ThemeProvider>
    );
  }
}

export default App;

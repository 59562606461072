import { Typography, Box, Button } from "@material-ui/core";
import {
    makeStyles,
    createStyles,
    Theme,
} from "@material-ui/core/styles";
import React, { useContext } from "react";
import { ThemeContext } from "../../blocks/notifications/src/Context/ThemeContext";
import { Link } from "react-router-dom";
import { DocumentSignatureI } from "../../blocks/dashboard/src/Interface";
import Base64PDFViewer from "./Base64PDFViewer";
interface DocumentProps {
    selectedDocuments: DocumentSignatureI;
    getHelloSignURLRequest: () => void;
}

const DocumentComponent = ({ selectedDocuments, getHelloSignURLRequest }: DocumentProps) => {
    const classes = useStyles();
    const { primaryColor } = useContext(ThemeContext)

    return (
        <Box>
            <Box
                width="100%"
                height="60vh"
                className={classes.payments}
                style={{
                    border: `1px solid ${primaryColor}`
                }}
                p={2}
                flex={1}
                flexDirection="column"
            >
                {
                    selectedDocuments?.status && selectedDocuments?.document_url ?
                        <Base64PDFViewer base64={selectedDocuments?.document_url?.split(",")[1]} />
                        :
                        selectedDocuments?.document_url?.length > 0
                        &&
                        <iframe style={{ height: '50vh', width: '100%' }}
                            src={'https://docs.google.com/viewer?url=' + selectedDocuments?.document_url + '&embedded=true'} />
                }
                <Box display="flex" justifyContent="center" p={2}>
                    {
                        selectedDocuments?.status ?
                            <Link to={`/dashboard/documents?id=${selectedDocuments?.signature_request_id}`} style={{ textDecoration: "none", color: "black" }}>
                                <Typography
                                    className={classes.submit_btn}
                                    color="primary"
                                    // variant="contained"
                                    style={{
                                        color: "#ffffff",
                                        backgroundColor: primaryColor,
                                        cursor: "pointer",
                                        padding: '10px',
                                        borderRadius: "10px",
                                        width: "100%"
                                    }}
                                >
                                    View in Document
                                </Typography>
                            </Link>

                            :
                            <Button
                                onClick={getHelloSignURLRequest}
                                className={classes.submit_btn}
                                color="primary"
                                variant="contained"
                            >
                                Sign Document
                            </Button>
                    }
                </Box>
            </Box>
        </Box>
    )
}
export default DocumentComponent;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        payments: {
            borderRadius: 10,
            backgroundColor: "#F6F6F6",
            overflow: 'hidden'
        },
        submit_btn: {
            fontSize: "1rem",
            width: "40%",
            color: "#fff",
        },

    })
);
Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json"
exports.endPoint = "notifications/notifications";
exports.getDataMethod = "GET";
exports.markAsReadMethod = "PUT";
exports.deleteMethod = "DELETE";

exports.notifications = "Notifications";
exports.deleteText = "DELETE"
exports.okText = "OK"
exports.deleteMessage = "Notifications deleted!"
exports.themeAPI = "/bx_block_client_dashboard/show_theme"
exports.notificationsAPI = "/bx_block_client_notification/client_notifications"
exports.getProfileAPI = "bx_block_client_dashboard/show_profile";
// Customizable Area End
// Customizable Area Start
import { Typography, Box, Button, Dialog, IconButton } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import {
  closeIcon,
  documentDownload
} from "../../assets";
import React from "react";
import CustomDocViewer from '../../../../../components/src/CustomDocViewer';

const styles = (theme: any) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props: any) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <img src={closeIcon} width="20px" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

interface DialogProps {
    open: boolean;
    onClose: (value: string) => void;
    link: string;
}

const DialogContent = withStyles((theme: any) => ({
  root: {
    padding: theme.spacing(2),
    minHeight : '35vh',
    width : 'auto'
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: any) => ({
  root: {
    margin: "0 auto",
    padding: theme.spacing(2),
  },
}))(MuiDialogActions);

export default function DocumentDialog({open , onClose, link} : DialogProps) {

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
          <Typography align="center" variant="body2" style={{ fontWeight : 900, marginTop : 5}}>Tax Returns</Typography>
      </DialogTitle>
      <DialogContent>


      <Box style={{ width: "auto" }}>
          {link && (
            <CustomDocViewer
                viewHeight={69}
                link={link}
            />
          )}
      </Box>
      </DialogContent>
      <DialogActions>
        <Button style={{ background : '#67a0a3' , padding : '10px 35px', color : 'white'}}>
          <img src={documentDownload} width="24px" style={{ marginRight : 5}}/>
          <Typography variant="body2" style={{ fontSize : 13, fontWeight : 900, color :'white'}}>Download Document</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
// Customizable Area End
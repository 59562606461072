export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const editIcon = require("../assets/edit.png");
export const editBlackIcon = require("../assets/editBlack.png");
export const addIcon = require("../assets/add.png");
export const deleteIcon = require("../assets/delete.png");
export const viewAllIcon = require("../assets/viewAll.png");
export const viewAllBlackIcon = require("../assets/viewAllBlack.png");
export const profileIcon = require("../assets/Profile.png");
export const groupIcon = require("../assets/Group.png");
export const buildingIcon = require("../assets/Building.png");
export const gauravIcon = require("../assets/gaurav.png");
export const aliciaIcon = require("../assets/alicia.png");
export const businessIcon = require("../assets/business.png");
export const judyIcon = require("../assets/judy.png");

import React, { useState } from "react";

type NotificationContextType = {
    count : number;
    setCount : (newCount : number) => void;
}
const NotificationContext = React.createContext<NotificationContextType>({
    count : 0,
    setCount : (_ : number) => {}
});

export const NotificationConsumer = NotificationContext.Consumer;


export const NotificationProvider: React.FC = (props) => {
    const [count, setCount] = useState(0);

    return (
        <NotificationContext.Provider value={{count , setCount}}>
            {props.children}
        </NotificationContext.Provider>
    );
}

export default NotificationContext;
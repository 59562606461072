import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  TextField,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { imgPasswordInVisible, imgPasswordVisible } from "../../assets";
import { Formik } from "formik";
import * as Yup from "yup";
import Footer from "../../../../../components/src/Footer.web";
import { withRouter } from "react-router-dom";
import ResetPasswordController, { Props } from "./ResetPasswordController";

const validationSchema = Yup.object({
  password: Yup.string()
    .required("Password is a required field")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/
    ),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Password must match")
    .required("This is a required field"),
});

const PasswordError = () => {
  return (
    <div>
      <p>Password must contain</p>
      <ul>
        <li>At least 8 characters and a maximum of 16 characters</li>
        <li>One uppercase letter</li>
        <li>One number</li>
        <li>One special character</li>
      </ul>
    </div>
  );
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

class ResetPassword extends ResetPasswordController {
  constructor(props: Props) {
    super(props);
  }

  handlePasswordVisibility = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleResetPasswordVisibility = () => {
    this.setState({
      enableResetPasswordField: !this.state.enableResetPasswordField,
    });
  };

  render() {
    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Container maxWidth="md">
          <Box
            sx={{
              display: "flex",
              flexDirection: "columm",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "80%",
                padding: "50px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  padding: "10 0",
                }}
              >
                <img
                  src={require("../../../../globalAssets/logo.png")}
                  width="230px"
                  height="80px"
                  style={{ margin: "0 auto" }}
                />
              </Box>
              <Box
                sx={{
                  margin: "10 0",
                  padding: 20,
                }}
                boxShadow={5}
                borderRadius={10}
              >
                <Box mb={3}>
                  <Typography align="center">
                    <Box
                      fontWeight={900}
                      fontSize={20}
                      margin="10"
                      component="span"
                      style={{ fontFamily: "DM Sans" }}
                    >
                      Reset Password
                    </Box>
                  </Typography>
                </Box>
                <Formik
                  initialValues={{
                    password: "",
                    confirm_password: "",
                  }}
                  onSubmit={(values) => {
                    this.resetPassword(values);
                  }}
                  validationSchema={validationSchema}
                >
                  {(formikProps) => (
                    <form onSubmit={formikProps.handleSubmit}>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        my={5}
                      >
                        <Box display="flex" flexDirection="column" width="50%">
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <TextField
                              style={{ width: "100%", marginBottom: 20 }}
                              placeholder="Enter Password"
                              name="password"
                              onChange={formikProps.handleChange}
                              onBlur={formikProps.handleBlur}
                              error={Boolean(
                                formikProps.touched.password &&
                                  formikProps.errors.password
                              )}
                              helperText={
                                formikProps.touched.password &&
                                Boolean(formikProps.errors.password) && (
                                  <PasswordError />
                                )
                              }
                              type={
                                this.state.showPassword ? "text" : "password"
                              }
                              InputProps={{
                                style: {
                                  backgroundColor: "#e6eaee",
                                  color: "black",
                                  padding: "5 10",
                                  borderRadius: 10,
                                },
                                disableUnderline: true,
                                endAdornment: (
                                  <img
                                    width="24px"
                                    onClick={this.handlePasswordVisibility}
                                    style={{
                                      marginRight: 10,
                                      cursor: "pointer",
                                    }}
                                    src={
                                      !this.state.showPassword
                                        ? imgPasswordVisible
                                        : imgPasswordInVisible
                                    }
                                  />
                                ),
                              }}
                            />
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            mb={3}
                          >
                            <TextField
                              style={{ width: "100%", marginBottom: 20 }}
                              placeholder="Re-Enter Password"
                              name="confirm_password"
                              onChange={formikProps.handleChange}
                              error={Boolean(
                                formikProps.touched.confirm_password &&
                                  formikProps.errors.confirm_password
                              )}
                              helperText={
                                formikProps.touched.confirm_password &&
                                Boolean(formikProps.errors.confirm_password) &&
                                formikProps.errors.confirm_password
                              }
                              onBlur={formikProps.handleBlur}
                              type={
                                this.state.enableResetPasswordField
                                  ? "text"
                                  : "password"
                              }
                              InputProps={{
                                style: {
                                  backgroundColor: "#e6eaee",
                                  color: "black",
                                  padding: "5 10",
                                  borderRadius: 10,
                                },
                                disableUnderline: true,
                                endAdornment: (
                                  <img
                                    width="24px"
                                    onClick={this.handleResetPasswordVisibility}
                                    style={{
                                      marginRight: 10,
                                      cursor: "pointer",
                                    }}
                                    src={
                                      !this.state.enableResetPasswordField
                                        ? imgPasswordVisible
                                        : imgPasswordInVisible
                                    }
                                  />
                                ),
                              }}
                            />
                          </Box>
                          <Box width="100%">
                            <Button
                              variant="contained"
                              fullWidth
                              style={{
                                backgroundColor: "#67a0a3",
                                padding: 10,
                                color: "white",
                                borderRadius: 10,
                                textTransform: "none",
                              }}
                              type="submit"
                            >
                              <Typography>
                                <Box fontWeight={900} component="span">
                                  Save Password
                                </Box>
                              </Typography>
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Box>
        </Container>
        <Footer type="auth" />
        <Snackbar
          open={this.state.snackBar.show}
          onClose={this.closeSnackBarHandler}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.closeSnackBarHandler}
            severity={this.state.snackBar.type}
            style={{
              backgroundColor:
                this.state.snackBar.type === "success" ? "#67a0a3" : "#f44336",
            }}
          >
            {this.state.snackBar.message}
          </MuiAlert>
        </Snackbar>
      </ThemeProvider>
    );
  }
}

//@ts-ignore
export default withRouter(ResetPassword);

// Customizable Area Start
import React, { Component } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  AppBar,
  Toolbar,
  Drawer,
  Badge,
  Avatar,
  Menu,
  MenuItem,
  List,
  ListItemText,
} from "@material-ui/core";
import Fade from '@material-ui/core/Fade';
import ListItem from "@material-ui/core/ListItem";
import NotificationsController, { Props } from "./NotificationsController";
import {
  withTheme,
  withStyles,
  createStyles,
  Theme,
  ThemeProvider,
} from "@material-ui/core/styles";
import SideBar from "../../../components/src/SideBar.web";
import { notificationIcon } from "../../dashboard/src/assets";
import Loader from "../../../components/src/Loader.web";
import Footer from "../../../components/src/Footer.web";
import theme from "../../../components/src/Theme";
import { withRouter } from "react-router-dom";
import { NotificationData } from "./Interface";
import UserProfile from "../../../components/src/UserProfileAvatar";
import { ThemeContext } from "./Context/ThemeContext";
const drawerWidth = 312;

// Customizable Area End

class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  static contextType: any = ThemeContext;
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props as any;
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box>
          <AppBar
            position="fixed"
            className={classes.appBar}
            color="transparent"
          >
            <Toolbar>
              <Box className={classes.grow} />
              <Box>
                <IconButton aria-label="show 4 new mails" color="inherit">
                  <Badge overlap="rectangular" badgeContent={this.context?.count} color="error">
                    <Box style={{backgroundColor: this.context?.primaryColor}} className={classes.notification_icon}>
                      <img src={notificationIcon} width={18} />
                    </Box>
                  </Badge>
                </IconButton>
                <IconButton onClick={this.handleClick}>
                  <UserProfile />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleClose}
                >
                  <MenuItem onClick={this.logout}>Logout</MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="left"
          >
            <Toolbar className={classes.toolbar}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                px={2}
                py={3}
              >
                <img src={this.context?.logo} width="55%" />
              </Box>
            </Toolbar>
            <SideBar />
          </Drawer>
          <Box className={classes.content} style={{ position : "relative"}}>
            <div className={classes.toolbar} />
            {
              this.state.notification.loading
              ? <Box minHeight="80vh" width={`100%-${312}`} style={{margin : 30}}><Loader loading={true} /></Box>
              : <Box
                  height="83vh"
                  style={{ marginLeft: "312px", overflowY: "scroll" }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={"space-between"}
                  >
                    <Typography variant="h4" style={{color: this.context?.primaryColor}}>
                      Notifications
                    </Typography>
                    {
                      this.state.notification.data.length > 0 && !this.state.notification.loading && !this.state.notification.data[0].attributes.seen &&
                      <Button style={{backgroundColor: this.context?.primaryColor}} className={classes.clearAll_btn} onClick={this.markAllasRead}>Mark all as read</Button>
                    }
                  </Box>
                  
                  <Box py={2} display="flex" flexDirection={"column"}>
                    <List>
                      {
                        this.state.notification.data.length > 0 &&
                        this.state.notification.data.map((entry: NotificationData)=> (
                          <Box display='flex' alignItems="center" style={{ position : "relative"}} key={entry.id}>
                            {
                              !entry.attributes.seen && <Box className={classes.icon_selected} style={{ position : "absolute"}} />
                            }
                            <ListItem className={entry.attributes.seen ? classes.inactiveNotification : classes.activeNotification}>
                              <ListItemText>
                                <Box display="flex" alignItems={"center"} justifyContent={"space-between"}>
                                  <Box>
                                    <Typography variant="body1">
                                      {entry.attributes.message}&nbsp;&nbsp;&nbsp;
                                      {
                                        entry.attributes.file && 
                                        <a href={entry.attributes.file}>
                                          File Link
                                        </a>
                                      }
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography variant="body1" style={{ fontSize : '14px', color : '#7e7f80', fontWeight : 900}}>
                                        {entry.attributes.date}{" | "}{entry.attributes.time}
                                    </Typography>
                                  </Box>
                                </Box>
                              </ListItemText>
                            </ListItem>
                          </Box>
                        ))
                      }
                    </List>
                    {
                      this.state.notification.totalCount > this.state.notification.data.length && <Button className={classes.loadMore_btn} onClick={this.loadMore_notification}>Load More</Button>
                    }
                    <Box display="flex" alignItems={"center"} justifyContent={"center"} height="50%">
                      {
                        this.state.notification.data.length === 0 && !this.state.notification.loading &&
                        <Typography variant="h5" style={{color : "gray"}}>No notifications</Typography>
                      }
                    </Box>
                  </Box>
                  <Footer type="dashboard" />
                </Box>
            }
          </Box>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}


const dummyNotifications = [
  {
    isActive : true,
    text : "You've been assigned a new task.",
    link : {
      text : 'Upload W2',
      url : ''
    },
    date : "10-09-22",
    time : '12:00PM'
  },
  {
    isActive : true,
    text : "You've been assigned a new task.",
    link : {
      text : 'Upload 2022 Tax Returns',
      url : ''
    },
    date : "10-09-22",
    time : '12:00PM'
  },
  {
    isActive : true,
    text : "You've been sent a new document",
    link : {
      text : 'Payment Authorization Form',
      url : ''
    },
    date : "10-09-22",
    time : '12:00PM'
  },
  {
    isActive : false,
    text : "Your Profile update has been approved.",
    link : {
      text : 'View Updated Profile',
      url : ''
    },
    date : "10-09-22",
    time : '12:00PM'
  },
]

// Customizable Area Start
// export default withStyles(styles)(Notifications);
const withThemeComp =  withTheme(
  withStyles((theme: Theme) =>
    createStyles({
      divider: {
        height: "2px",
        width: "100%",
        backgroundColor: "#67a1a3",
      },
      appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        padding: 5,
        boxShadow: "none",
        backgroundColor: "#fafafa",
      },
      grow: {
        flex: 1,
      },
      notification_icon: {
        borderRadius: "50%",
        padding: 10,
        // backgroundColor : '#67a0a3'
      },
      drawer: {
        width: drawerWidth,
        flexShrink: 0,
      },
      drawerPaper: {
        width: drawerWidth,
      },
      toolbar: theme.mixins.toolbar,
      content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(4),
      },
      clearAll_btn: {
        fontSize: "0.8rem",
        // background: "#67a0a3",
        padding: "10px 50px",
        color: "#fff",
        fontWeight: 900,
        borderRadius: 5,
      },
      loadMore_btn : {
        fontSize: "0.8rem",
        background: "#ff",
        border : '1px solid #67a0a3',
        padding: "10px",
        color: "#67a0a3",
        fontWeight: 900,
        borderRadius: 5,
        width : '10%',
        margin : '0 auto'
      },
      activeNotification : {
        padding : "10px 20px",
        border: "1px solid #dcdcdc",
        background : "#fff",
        marginBottom : '15px',
        borderRadius : 10
      },
      inactiveNotification :{
        padding : "10px 20px",
        background : "#e6eaee",
        borderRadius : 10,
        marginBottom : '15px'
      },
      icon_selected: {
        height: 20,
        borderRadius: 10,
        zIndex : 3,
        border: "2.5px solid #FF4E59",
        left : 0,
        top : "25%",
      },
    })
  )(Notifications)
);

// @ts-ignore
export default withRouter(withThemeComp);

interface MenuState {
  anchorEl: any;
}

interface MenuProps {
  logout: () => void;
}
class AvatarMenu extends Component<MenuProps, MenuState> {
  constructor(props: MenuProps) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    return (
      <Box component="span">
        <IconButton onClick={this.handleClick}>
          <Avatar
            alt="Remy Sharp"
            src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50"
          />
        </IconButton>
        <Menu
          id="fade-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
          TransitionComponent={Fade}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <MenuItem onClick={this.props.logout}>
            <Typography
              variant="h6"
              style={{ fontWeight: 900, margin: "0 10" }}
            >
              Logout
            </Typography>
          </MenuItem>
        </Menu>
      </Box>
    );
  }
}
// Customizable Area End

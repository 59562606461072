import React, { useEffect, useState } from "react";
import { Box } from '@material-ui/core'


const Timer = (props:any) => {
    const {initialMinute = 0,initialSeconds = 0, codeHandler, resendCode} = props;
    const [minutes, setMinutes ] = useState(initialMinute);
    const [seconds, setSeconds ] =  useState(initialSeconds);

    const resetTimer = ()  => {
        setMinutes(initialMinute);
        setSeconds(initialSeconds);
    }
    useEffect(()=>{
    let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval);
                    codeHandler();
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            } 
        }, 1000)
        return ()=> {
            clearInterval(myInterval);
        };
    }, [minutes, seconds]);

    useEffect(()=> {
        if(!resendCode)
        {
            resetTimer();
        }
    }, [resendCode]);

    return (
        <Box component="span">
        {  
            minutes === 0 && seconds === 0
            ? ""
            : <span><u style={{ color : '#3762c1'}}>{minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</u></span> 
        }
        </Box>
    )
}

export default Timer;
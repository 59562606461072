import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import Api from "../../../components/src/API";
import { DocumentsModel, FolderModel, SelctedPdfModel, SubmittedDocsModel, SubmittedDocsResponseI, UploadedDataModel } from "./Interface";
// Customizable Area End
export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    open: any;
    showModal: boolean;
    toggleBtn: boolean;
    selectedPdf: SelctedPdfModel;
    documentsData: any;
    foldersData: FolderModel[];
    uploadedData: UploadedDataModel;
    errorMsg: string;
    loading: boolean;
    uploadedStatus: string;
    openStatus: boolean;
    submittedDocsResponse: SubmittedDocsResponseI[];
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class DocumentController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getUserDataCallId: string = "";
    getDocumentsCallId: string = "";
    createDocumentsCallId: string = "";
    getFoldersCallId: string = "";
    getSearchDocuments: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            open: {},
            showModal: false,
            toggleBtn: false,
            selectedPdf: {
                id: -1,
                link: "",
                fileName: ""
            },
            documentsData: {},
            foldersData: [],
            uploadedData: {
                file: "",
                folderId: "",
            },
            errorMsg: "",
            uploadedStatus: "",
            openStatus: false,
            loading: false,
            submittedDocsResponse: []
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start

        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );


            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId != null) {
                if (apiRequestCallId == this.getDocumentsCallId) {
                    this.getDocumentsResponse(responseJson, errorReponse)
                }
                if (apiRequestCallId == this.getFoldersCallId) {
                    this.getFoldersResponse(responseJson, errorReponse)
                }
                if (apiRequestCallId == this.createDocumentsCallId) {
                    this.createDocumentResponse(responseJson, errorReponse)
                }
                if (apiRequestCallId == this.getSearchDocuments) {
                    this.searchDocumentsResponse(responseJson, errorReponse)
                }
            }
        }
        // Customizable Area End

    }
    // Customizable Area Start
    async componentDidMount() {
        this.getFolders();
        this.getDocuments();
    }

    // Customizable Area End

    // Customizable Area Start

    createDocumentResponse = (responseJson: any, errorReponse: any) => {
        if (responseJson && !responseJson.error) {
            this.setState({
                ...this.state,
                showModal: false,
                openStatus: true,
                uploadedStatus: "Uploaded Successfully!",
                errorMsg: "",
                loading: false,
            }, () => this.getDocuments());
        } else {
            this.setState({
                uploadedStatus: "",
                errorMsg: errorReponse,
                loading: false,
            });
        }
    }

    getDocumentsResponse = (responseJson: any, errorReponse: any) => {
        if (responseJson && !responseJson.error) {
            this.setState({
                ...this.state,
                documentsData: responseJson,
                submittedDocsResponse: responseJson?.submitted?.data?.map((item: any) => item.attributes),
                errorMsg: "",
                loading: false,
            }, () => {
                const searchParams = new URLSearchParams(document?.location?.search).get("id")
                if (searchParams) {
                    this.searchDocuments(searchParams)
                }
            });
        } else {
            this.setState({
                errorMsg: errorReponse,
                loading: false,
            });
        }
    }

    getFoldersResponse = (responseJson: any, errorReponse: any) => {
        if (responseJson && !responseJson.error) {
            this.setState({
                ...this.state,
                foldersData: responseJson,
                errorMsg: "",
                loading: false,
            });
        } else {
            this.setState({
                errorMsg: errorReponse,
                loading: false,
            });
        }
    }

    searchDocumentsResponse = (responseJson: any, errorReponse: any) => {
        if (responseJson && !responseJson.error) {
            this.setState((prev) => {
                const _filterDocuments = prev.documentsData["Submitted Document"][0]?.data?.find((item: any) => item?.id === responseJson?.data?.id)
                const _key = "Submitted Document".split(" ").join("_");
                return {
                    selectedPdf: {
                        id: _filterDocuments?.id,
                        link: _filterDocuments?.attributes?.client_file,
                        fileName: _filterDocuments?.attributes?.file_name
                    },
                    open: {
                        ...prev.open,
                        [_key]: !prev.open[_key]
                    }
                }
            });
        } else {
            this.setState({
                uploadedStatus: "",
                errorMsg: errorReponse,
                loading: false,
            });
        }
    }

    searchDocuments = (id: string) => {
        const header = {
            "token": localStorage.getItem("Token"),
            "Content-Type": configJSON.dashboarContentType,
        };
        const requestMessage = Api({
            header: header,
            endPoint: `bx_block_client_documents/show_document?signature_request_id=${id}`,
            method: "GET",
        });
        this.getSearchDocuments = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    createDocuments = () => {
        const { uploadedData } = this.state;

        const payload = new FormData();
        uploadedData?.folderId && payload.append("client_documents[document_folder_id]", uploadedData?.folderId);
        uploadedData?.file && payload.append("client_documents[client_file]", uploadedData?.file);

        const header = {
            "token": localStorage.getItem("Token"),
        };
        const requestMessage = Api({
            header: header,
            endPoint: configJSON.createDocsAPI,
            payload: payload,
            method: "POST",
        });
        this.createDocumentsCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getDocuments = () => {
        this.setState({
            loading: true,
        })
        const header = {
            "token": localStorage.getItem("Token"),
            "Content-Type": configJSON.dashboarContentType,
        };
        const requestMessage = Api({
            header: header,
            endPoint: configJSON.doucmentsAPI,
            method: "GET",
        });
        this.getDocumentsCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getFolders = () => {
        const header = {
            "token": localStorage.getItem("Token"),
            "Content-Type": configJSON.dashboarContentType,
        };
        const requestMessage = Api({
            header: header,
            endPoint: configJSON.folderAPI,
            method: "GET",
        });
        this.getFoldersCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }



    handleColapse = (id: any) => {
        this.setState((prev_state) => {
            return {
                open: {
                    ...prev_state.open,
                    [id]: !prev_state.open[id]
                }
            }
        })
    }

    handleShowModal = () => {
        this.setState({
            showModal: true,
        })
    }

    closeModal = () => {
        this.setState({
            showModal: false,
            openStatus: false,
        })
    }

    handleUploadedData = (name: string, value: any) => {
        this.setState((prev) => {
            return {
                uploadedData: {
                    ...prev.uploadedData,
                    [name]: value
                }
            }
        })
    }

    handleToggleBtn = () => {
        this.setState((prev_state) => {
            return {
                toggleBtn: !this.state.toggleBtn,
            }
        })
    }

    handleSelectedUploaded = (item: DocumentsModel) => {
        this.setState((prev_state) => {
            return {
                selectedPdf: {
                    ...prev_state.selectedPdf,
                    id: item?.attributes?.id,
                    link: item?.attributes?.client_file,
                    fileName: item?.attributes?.file_name
                }
            }
        })
    }
    handleSelectedSubmitted = (item: SubmittedDocsModel, index: number) => {
        this.setState((prev_state) => {
            return {
                selectedPdf: {
                    ...prev_state.selectedPdf,
                    id: index,
                    link: item?.attributes?.document[index],
                    fileName: item?.attributes?.file_name[index]
                }
            }
        })
    }

    // Customizable Area End

}

// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

import { ThemeContextType, defaultContext } from "./ThemeContext";
import Api from "../../../../components/src/API";
import { NotificationData } from "../Interface";

export const configJSON = require("../config");

export interface Props {
    navigation?: any;
    id?: string;
    children?: JSX.Element | JSX.Element[] | string | string[];
}

interface S extends ThemeContextType {
    isloading: boolean;
    errorMsg: string;
}

interface SS {
    id: any;
}

export default class ThemeContextController extends BlockComponent<
    Props,
    S,
    SS
> {
    getThemeCallId: string = "";
    getNotificationsCallId: string = "";
    getUserProfilePicCallId: string = ""
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];

        this.state = {
            ...defaultContext,
            isloading: true,
            errorMsg: ""
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);


        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );


            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId != null) {

                if (apiRequestCallId == this.getThemeCallId) {
                    this.getThemeResponse(responseJson, errorReponse)
                }
                if (apiRequestCallId == this.getNotificationsCallId) {
                    this.getNotificationResponse(responseJson, errorReponse)
                }
                if (apiRequestCallId == this.getUserProfilePicCallId) {
                    this.userProfileDataResponse(responseJson, errorReponse)
                }

            }
        }

    }



    async componentDidMount() {
        this.getThemeRequest()
        this.getNotificationRequest()
        this.getUserProfileData()
    }


    getThemeRequest = () => {
        const header = {
            "token": localStorage.getItem("Token"),
            "Content-Type": configJSON.apiContentType,
        };
        const requestMessage = Api({
            header: header,
            endPoint: configJSON.themeAPI,
            method: "GET",
        });
        this.getThemeCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getThemeResponse = (responseJson: any, errorReponse: any) => {
        if (responseJson && !responseJson.errors) {
            if (responseJson?.data !== null) {
                this.setState(prev => ({
                    ...prev,
                    primaryColor: responseJson?.data?.attributes?.primary_color,
                    secondaryColor: responseJson?.data?.attributes?.secondary_color,
                    logo: responseJson?.data?.attributes?.logo,
                    isloading: false,
                }), () => {
                    localStorage.setItem('logo', (responseJson?.data?.attributes?.logo));
                    localStorage.setItem('primaryColor', (responseJson?.data?.attributes?.primary_color));
                    localStorage.setItem('secondaryColor', (responseJson?.data?.attributes?.secondary_color));
                })
            }
        }
        else {
            this.setState({ isloading: false, errorMsg: errorReponse })
        }
    }
    getNotificationRequest = () => {
        const header = {
            "token": localStorage.getItem("Token"),
            "Content-Type": configJSON.apiContentType,
        };
        const requestMessage = Api({
            header: header,
            endPoint: configJSON.notificationsAPI,
            method: "GET",
        });
        this.getNotificationsCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getNotificationResponse = (responseJson: any, errorReponse: any) => {
        if (responseJson && !responseJson.errors) {
            console.log("Notification response", responseJson)
            if (responseJson?.data?.data.length !== 0) {
                const newData = responseJson?.data?.data;

                const unseenCount = (() => {
                    let count = 0;
                    newData.forEach((entry: NotificationData) => {
                        if (!entry.attributes.seen) count++;
                    })
                    return count
                })()

                console.log("unseenCount", unseenCount)

                this.setState(prev => ({
                    ...prev,
                    count: unseenCount,
                    isloading: false,
                }))
            }
        }
        else {
            this.setState({ isloading: false, errorMsg: errorReponse })
        }
    }

    getUserProfileData = () => {
        const header = {
            "token": localStorage.getItem("Token"),
            "Content-Type": configJSON.apiContentType,
        };
        const requestMessage = Api({
            header: header,
            endPoint: configJSON.getProfileAPI,
            method: "GET",
        });
        this.getUserProfilePicCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    userProfileDataResponse = (responseJson: any, errorReponse: any) => {
        console.log("Checking res", responseJson)
        if (responseJson && !responseJson.errors) {
            const _userData = {
                first_name: responseJson?.data?.attributes?.first_name,
                last_name: responseJson?.data?.attributes?.last_name,
                avatar: responseJson?.data?.attributes?.avatar,
            }
            console.log("Response setting", _userData)
            this.setState({
                userProfileContext: _userData,
                errorMsg: "",
            });
        } else {
            this.setState({
                errorMsg: errorReponse,
            });
        }
    }

    clearThemeContext = () => {
        this.setState({
            ...defaultContext,
            isloading: false,
        })
    }

}

 // Customizable Area End
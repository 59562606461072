import { Grid, Box, Typography, IconButton } from "@material-ui/core";
import {
    makeStyles,
    createStyles,
    Theme,
} from "@material-ui/core/styles";
import React from "react";
import { useTheme } from "../../blocks/notifications/src/Context/ThemeContext";
import { deleteIcon, editIcon } from "../../blocks/user-profile-basic/src/assets";
import { ShareholderType } from "../../blocks/user-profile-basic/src/Interface";
import { getFirstLetters } from "./Utils";
interface NameProps {
    business: string;
    shareholder: ShareholderType;
    removeShareholder: (id: number) => void;
    editShareholder: (id: number) => void;
}


const ShowShareholder = ({ business, editShareholder, shareholder, removeShareholder }: NameProps) => {
    const classes = useStyles();
    const { primaryColor } = useTheme()
    return (
        <>
            {
                business.toLowerCase() === "client_businesses" ?
                    <Box style={{ marginTop: "20px" }}>
                        <Box style={{ border: "1px solid #a8b0b6", borderRadius: "10px", padding: "5%" }}>
                            <Grid container justifyContent="center">
                                <Grid item sm={6}>
                                    <Box style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        gap: "20px",
                                        marginBottom: "20px"
                                    }}>
                                        <Box>
                                            <Box style={{ height: "100px", width: "100px", borderRadius: "50%", border: "1px solid #a8b0b6", backgroundColor: primaryColor, position: "relative" }}>
                                                <Typography style={{ position: "absolute", top: "34%", left: "36%", color: "#ffffff", fontSize: "24px" }}>
                                                    {
                                                        getFirstLetters(shareholder?.attributes?.first_name + " " + shareholder?.attributes?.last_name)
                                                    }
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box className={classes.userDescription}>
                                            {
                                                <Typography style={{ fontFamily: "Rubik-Medium", fontSize: "20px", marginBottom: "5px" }} className={classes.textWidth}>
                                                    {shareholder?.attributes?.first_name + " " + shareholder?.attributes?.last_name}
                                                </Typography>
                                            }
                                            <a
                                                href={"mailto:" + shareholder?.attributes?.email}
                                                style={{ textDecoration: "none", color: "black" }}
                                            >
                                                <Typography style={{ fontFamily: "Rubik-Regular", fontSize: "14px" }} className={classes.textWidth}>
                                                    {shareholder?.attributes?.email}
                                                </Typography>
                                            </a>
                                            <a
                                                href={"tel:" + shareholder?.attributes?.phone_number}
                                                style={{ textDecoration: "none", color: "black" }}
                                            >
                                                <Typography style={{ fontFamily: "Rubik-Regular", fontSize: "14px" }} className={classes.textWidth}>
                                                    {shareholder?.attributes?.phone_number ? shareholder?.attributes?.phone_number : ""}
                                                </Typography>
                                            </a>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item sm={2}>
                                    <Box style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        gap: "20px",
                                        marginBottom: "20px"
                                    }}>
                                        <IconButton
                                            onClick={() => editShareholder(shareholder?.attributes?.id)}
                                            style={{
                                                height: '45px',
                                                width: '45px',
                                                borderRadius: '50%',
                                                backgroundColor: primaryColor,
                                            }}
                                        >
                                            <img src={editIcon} height={'20px'} />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => removeShareholder(shareholder?.attributes?.id)}
                                            style={{
                                                height: '45px',
                                                width: '45px',
                                                borderRadius: '50%',
                                                backgroundColor: primaryColor,
                                            }}
                                        >
                                            <img src={deleteIcon} height={'20px'} />
                                        </IconButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    :
                    ""
            }
        </>
    )
}
export default ShowShareholder;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        userDescription: {
            width: "calc(100% - 100px)",
            wordWrap: "break-word",
            padding: "20px 10px 20px 10px"
        },
        textWidth: {
            width: "80%",
            wordWrap: "break-word"
        },
    })
);
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  location: any;
  match: any;
  history: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  paymentType: "check" | "card";
  payments: {
    loading: boolean;
    data: any;
    is_cpa_authenticate: boolean;
  };
  authDoc: string,
  uploadDocLoading: boolean;
  merchant_key: {
    loading: boolean,
    key: string,
    survey_id: number,
    amount: number
  };
  getPaymentGatewayId: string,
  snackBar: {
    show: boolean;
    message?: string;
    type?: "success" | "info" | "warning" | "error";
  };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PaymentsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  allPaymentsAPICallId: string = "";
  uploadDocAPICallId: string = "";
  docsAPICallId: string = "";
  merchantKeyCallId: string = "";
  paymentGatewayAPICallId: string = "";
  submitPaymentAPICallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      paymentType: "card",
      payments: {
        loading: true,
        data: [],
        is_cpa_authenticate: false
      },
      authDoc: "",
      uploadDocLoading: false,
      merchant_key: {
        loading: false,
        key: '',
        survey_id: 0,
        amount: 0
      },
      getPaymentGatewayId: '',
      snackBar: {
        show: false,
        message: "",
        type: "error",
      }

      // Customizable Area End

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    switch (apiRequestCallId) {
      case this.allPaymentsAPICallId: {
        this.handlePaymentsAPI(responseJson);
        break;
      }
      case this.docsAPICallId: {
        this.handleDocsAPI(responseJson);
        break;
      }
      case this.uploadDocAPICallId: {
        this.handleUploadDocAPI(responseJson);
        break;
      }
      case this.merchantKeyCallId: {
        this.handleMerchantKeyAPI(responseJson);
        break;
      }
      case this.paymentGatewayAPICallId: {
        this.handlePaymentGatewayAPI(responseJson);
        break;
      }
      case this.submitPaymentAPICallId: {
        this.handleSubmitPaymentAPI(responseJson);
      }
    }
    // Customizable Area End
  }


  // Customizable Area Start

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  closeSnackBarHandler = () => {
    this.setState({
      snackBar: {
        show: false,
        message: "",
        type: undefined,
      },
    });
  };

  // Open View Details Modal
  openSnackBarHandler = (
    type: "success" | "info" | "warning" | "error" | undefined,
    message: string
  ): void => {
    this.setState({
      snackBar: {
        show: true,
        message: message,
        type,
      },
    });
  };

  handlePaymentType = (type: "card" | "check") => {
    this.setState((prev: S) => ({ ...prev, paymentType: type }))
  }


  handlePaymentsAPI = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ payments: { loading: false, data: responseJson.data, is_cpa_authenticate: responseJson?.is_cpa_authenticate } });
    } else {
      console.log(responseJson.error)
    }
  }

  handleDocsAPI = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState({ authDoc: responseJson.uploaded_payment_document || responseJson.downloadable_form });
    } else {
      console.log(responseJson.error)
    }
  }

  handleUploadDocAPI = (responseJson: any) => {
    if (responseJson && responseJson.uploaded_payment_document) {
      this.setState({ authDoc: responseJson.uploaded_payment_document, uploadDocLoading: false })
    } else {
      alert("Something went wrong, please try after sometime.")
    }
  }

  handleMerchantKeyAPI = (responseJson: any) => {
    if (responseJson && responseJson.public_key && !responseJson.errors) {
      this.setState({
        merchant_key: {
          loading: false,
          key: responseJson.public_key,
          survey_id: responseJson.survey_id,
          amount: responseJson.amount
        }
      })
      console.log(responseJson.public_key);

    } else {
      this.openSnackBarHandler("error", "Your CPA manager need to authenticate with CPA");
      setTimeout(() => {
        this.props.history.push("/dashboard/payments")
      }, 1200)
      console.log("Something went wrong, please try after sometime.")
    }
  }

  handlePaymentGatewayAPI = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState({ getPaymentGatewayId: responseJson.id });
      this.submitPayment();
    } else {
      console.log(responseJson.errors);
    }
  }

  handleSubmitPaymentAPI = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.openSnackBarHandler("success", "Success");
      setTimeout(() => {
        this.props.history.push({
          pathname: "/dashboard/payments"
        });
      }, 2000);
      this.getAllPayments();
      this.openSnackBarHandler("success", "Success");
      setTimeout(() => {
        this.closeSnackBarHandler();
      }, 2000);
    } else {
      this.openSnackBarHandler("error", responseJson.errors);
      setTimeout(() => {
        this.closeSnackBarHandler();
      }, 2000);
    }
  }

  getMerchantKey = () => {
    const token = localStorage.getItem('Token');

    if (!token) {
      this.props.history.push("/signin");
      return;
    }

    const header = {
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.merchantKeyCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_client_cpa_charge/merchant_key"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({
      merchant_key: {
        loading: true,
        key: "",
        survey_id: 0,
        amount: 0
      }
    })
    return true;
  }

  getAllPayments = () => {

    const token = localStorage.getItem('Token');

    if (!token) {
      this.props.history.push("/signin");
      return;
    }

    const header = {
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.allPaymentsAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_client_cpa_charge/client_payments"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getAuthDoc = () => {

    const token = localStorage.getItem('Token');

    if (!token) {
      this.props.history.push("/signin");
      return;
    }

    const header = {
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.docsAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_client_cpa_charge/payment_forms"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  uploadDoc = (fileData: any) => {

    const token = localStorage.getItem("Token");

    if (!token) {
      localStorage.clear();
      this.props.history.push("/signin");
      return;
    }

    const header = {
      "token": token,
    }

    const formData = new FormData();
    formData.append("payment_document", fileData, fileData.name);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.uploadDocAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_client_cpa_charge/client_payments/upload_payment_document"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );


    this.setState({ uploadDocLoading: true, authDoc: "" })

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getPaymentGatewayId = (requestData: any) => {
    const api = 'https://api.chargeio.com/v1/tokens';

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.paymentGatewayAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      api
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      requestData.headers
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      requestData.body
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      requestData.method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  submitPayment = () => {
    const token = localStorage.getItem('Token');

    if (!token) {
      this.props.history.push("/signin");
      return;
    }

    const header = {
      "token": token,
      "Content-Type": "application/json"
    };

    const body = {
      data: {
        attributes: {
          'amount': this.state.merchant_key?.amount,
          'survey_id': this.state.merchant_key?.survey_id,
          'method': this.state.getPaymentGatewayId,
          'method_type': 'random'
        }
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submitPaymentAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_client_cpa_charge/client_payments"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  createPayment = async (values: any, type: "echeck" | "card") => {

    // 1. Create gateway token 
    // 2. send card values and token to backend 
    // 3. show message in snackbar
    const basicAuthToken = btoa(this.state.merchant_key.key + ':');
    let myHeaders = {
      "Content-Type": "application/json",
      "Authorization": "Basic " + basicAuthToken
    }

    try {
      if (type === "card") {
        const [exp_month, exp_year] = values.expiry.split("/");
        const reqBody = {
          type: type,
          number: values.cardNumber,
          exp_month: exp_month,
          exp_year: `20${exp_year}`,
          cvv: values.cvv
        }

        let requestOptions = {
          method: 'POST',
          headers: JSON.stringify(myHeaders),
          body: JSON.stringify(reqBody),
        };

        this.getPaymentGatewayId(requestOptions);
      } else if (type === "echeck") {
        const reqBody = {
          'type': 'bank',
          'routing_number': values.routingNumber,
          'account_number': values.accountNumber,
          'account_type': values.accountType === 'Savings Account' ? 'SAVINGS' : 'CHECKING',
          'account_holder_type': values.accountHolderType === 'Individual Account' ? 'individual' : 'business',
          'business_type': values.businessName
        }

        let requestOptions = {
          method: 'POST',
          headers: JSON.stringify(myHeaders),
          body: JSON.stringify(reqBody),
        };

        this.getPaymentGatewayId(requestOptions);
      }
    } catch (err) {
      console.log(err);
    }
    return true;
  }

  async componentDidMount() {
    this.getAllPayments();
    this.getAuthDoc();
  }

  // Customizable Area End
}

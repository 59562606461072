// Customizable Area Start

import React from "react";
import {
  Container,
  Box,
  Button,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Snackbar
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import * as Yup from "yup";
import { Formik } from "formik";
import Footer from "../../../components/src/Footer.web";
import { withRouter } from "react-router-dom"

const validationSchema = Yup.object({
  email_or_phone : Yup.string()
  .required('Email / Phone is a required field')
  .test('email_or_phone', 'Email / Phone is invalid', (value) => {
     return validateEmail(value) || validatePhone(parseInt(value ?? '0'));
  }),
});

const validateEmail = (email: string | undefined) => {
  return Yup.string().email().isValidSync(email)
};

const validatePhone = (phone: number | undefined) => {
  const USregex = /^\d{3}\d{3}\d{4}$/;
  const INregex = /^[6-9]\d{9}$/;
  return Yup.number().integer().positive().test(
     (phone) => {
       return (phone && phone.toString().length <= 10 && (USregex.test(String(phone)) || INregex.test(String(phone)))) ? true : false;
     }
   ).isValidSync(phone);
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export const muiStyles = () => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: "0 10 0 0",
    width: 70,
    backgroundColor: "white",
    border: "1px solid #67a0a3",
    padding: 5,
    borderRadius: 10,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  activeBtn: {
    background: "#67a0a3",
    color: "white",
    border: "none",
    textTransform: "none",
    "&:hover": { backgroundColor: "#67a0a3" },
  },
});

export class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props as any;
    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Container maxWidth="md">
          <Box
            sx={{
              display: "flex",
              flexDirection: "columm",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "80%",
                padding: "50px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  padding: "10 0",
                }}
              >
                <img
                  src={require("../../globalAssets/logo.png")}
                  width="230px"
                  height="80px"
                  style={{ margin: "0 auto" }}
                />
              </Box>
              <Box
                sx={{
                  margin: "10 0",
                  padding: 20,
                }}
                boxShadow={5}
                borderRadius={10}
              >
                <Box>
                  <Typography align="center">
                    <Box
                      fontWeight={900}
                      fontSize={20}
                      margin="10"
                      component="span"
                      style={{ fontFamily: "DM Sans" }}
                    >
                      Forgot Password
                    </Box>
                  </Typography>
                  <Box my={1}>
                    <Typography align="center" variant="body2" component="span">
                      <Box color="gray" fontSize={14}>
                        Enter your Email or Phone Number
                      </Box>
                    </Typography>
                  </Box>
                </Box>
                <Formik
                  initialValues={{
                    email_or_phone : "",
                  }}
                  onSubmit={(values) => {
                    console.log(JSON.stringify(values));
                    this.sendOTP(values);
                  }}
                  validationSchema={validationSchema}
                >
                  {(formikProps) => (
                    <form onSubmit={formikProps.handleSubmit}>
                      <Box style={{ textAlign: "center" }}>
                        <Button
                          variant="outlined"
                          className={
                            this.state.accountType === "email"
                              ? classes.activeBtn
                              : ""
                          }
                          style={{ border: "none", textTransform: "none" }}
                          onClick={() => {
                            this.handleAccountType("email");
                            formikProps.resetForm({
                              values: { email_or_phone: "" },
                            });
                          }}
                        >
                          Email
                        </Button>
                        <Button
                          className={
                            this.state.accountType === "phone"
                              ? classes.activeBtn
                              : ""
                          }
                          style={{ border: "none", textTransform: "none" }}
                          onClick={() => {
                            this.handleAccountType("phone");
                            formikProps.resetForm({
                              values: { email_or_phone: "" },
                            });
                          }}
                        >
                          Phone
                        </Button>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        my={5}
                      >
                        <Box display="flex" flexDirection="column" width="50%">
                          <Box
                            display="flex"
                            // flexDirection="column"
                            justifyContent="center"
                            alignItems="flex-start"
                            width="100%"
                          >
                            {this.state.accountType === "phone" && (
                              <FormControl
                                // variant="filled"
                                className={classes.formControl}
                              >
                                <Select
                                  labelId="demo-simple-select-filled-label"
                                  id="demo-simple-select-filled"
                                  value={this.state.countryCode}
                                  disableUnderline={true}
                                  onChange={(e) =>
                                    this.setState({
                                      countryCode: e.target.value as string,
                                    })
                                  }
                                  style={{ textAlign: "center" }}
                                >
                                  <MenuItem value={1}>+1</MenuItem>
                                  <MenuItem value={91}>+91</MenuItem>
                                </Select>
                              </FormControl>
                            )}
                            <TextField
                              style={{ width: "100%", marginBottom: 20 }}
                              placeholder={
                                this.state.accountType === "phone"
                                  ? "Enter your Phone Number"
                                  : "Enter your Email"
                              }
                              variant="standard"
                              name="email_or_phone"
                              value={formikProps.values.email_or_phone}
                              onChange={formikProps.handleChange}
                              onBlur={formikProps.handleBlur}
                              error={Boolean(
                                formikProps.touched.email_or_phone &&
                                  formikProps.errors.email_or_phone
                              )}
                              helperText={
                                formikProps.touched.email_or_phone &&
                                Boolean(formikProps.errors.email_or_phone) &&
                                String(formikProps.errors.email_or_phone)
                              }
                              InputProps={{
                                style: {
                                  backgroundColor: "#e6eaee",
                                  color: "black",
                                  padding: "5 10",
                                  borderRadius: 10,
                                },
                                disableUnderline: true,
                              }}
                            />
                          </Box>
                          <Box width="100%" mt={5}>
                            <Button
                              variant="contained"
                              fullWidth
                              style={{
                                backgroundColor: "#67a0a3",
                                padding: 10,
                                color: "white",
                                borderRadius: 10,
                                textTransform: "none",
                              }}
                              type="submit"
                            >
                              <Typography>
                                <Box fontWeight={900} component="span">
                                  Next
                                </Box>
                              </Typography>
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Box>
        </Container>
        <Footer type="auth" />
        <Snackbar
          open={this.state.snackBar.show}
          onClose={this.closeSnackBarHandler}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.closeSnackBarHandler}
            severity={this.state.snackBar.type}
            style={{
              backgroundColor:
                this.state.snackBar.type === "success" ? "#67a0a3" : "#f44336",
            }}
          >
            {this.state.snackBar.message}
          </MuiAlert>
        </Snackbar>
      </ThemeProvider>
    );
  }
}


//@ts-ignore
export default withStyles(muiStyles)(withRouter(ForgotPassword));
// Customizable Area End

Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "bx_block_client_dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.completedTask = "bx_block_client_dashboard/completed_task";
exports.completedTaskSection = "bx_block_client_dashboard/show_section";
exports.doucmentsAPI = "bx_block_client_documents/client_documents";
exports.folderAPI = "bx_block_client_documents/folder_list"
exports.createDocsAPI = "bx_block_client_documents/client_documents"
exports.checkListAPI = "/bx_block_client_dashboard/dashboards";
exports.selectedCheckListAPI = "/bx_block_client_dashboard/show_section";
exports.submitQuestionListAPI = "bx_block_pricingcalculator/client_information_onboardings/1/update_checklist"
exports.getHelloSignURL = "bx_block_client_dashboard/sign_url"
exports.confrimSignDocuments = "bx_block_client_dashboard/sign_document"
exports.confrimViewDocuments = "bx_block_client_dashboard/view_document"
exports.paymentGatewayIdAPI = "https://api.chargeio.com/v1/tokens";
exports.marchantKeyAPI = "bx_block_client_cpa_charge/merchant_key";
exports.submitPaymentAPI = "bx_block_client_cpa_charge/client_payments";
// Customizable Area End
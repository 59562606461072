import {
    Typography, Box, Grid,
    TextField, Button, MenuItem, Select,
    FormControl, FormHelperText
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import {
    makeStyles,
    createStyles,
    Theme,
} from "@material-ui/core/styles";
import React, { useContext } from "react";
import { ThemeContext } from "../../blocks/notifications/src/Context/ThemeContext";
import { Formik } from "formik";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import * as Yup from 'yup';
import { cpaCharge } from "../../blocks/Payments/src/assets";
interface DashboardPaymentProps {
    submitPayment: (values: any, type: "echeck" | "card") => void;
    type: "echeck" | "card";
}

const CardvalidationSchema = Yup.object({
    name: Yup
        .string()
        .required("This is a required field"),
    cardNumber: Yup
        .string()
        .required("This is a required field").min(16, "Invalid Card number"),
    cvv: Yup
        .number()
        .typeError('CVV must be a number')
        .required("This is a required field"),
    // .test(),
    postalCode: Yup
        .number()
        .typeError('Postal Code must be a number')
        .required("This is a required field"),
    expiry: Yup
        .string()
        .matches(/^(0\d|1[0-2])\/?(\d{2})$/, "Invalid expiry date")
        .required("This is a required field").min(5, "Invalid expiry date"),
})

const CheckvalidationSchema = Yup.object({
    accountHolderType: Yup
        .string()
        .required("This is a required field"),
    businessName: Yup
        .string()
        .min(2, "Invalid Business name")
        .required("This is a required field"),
    routingNumber: Yup
        .number()
        .typeError("This should be a number")
        .required("This is a required field"),
    accountNumber: Yup
        .number()
        .typeError("This should be a number")
        .required("This is a required field"),
    accountType: Yup
        .string()
        .required("This is a required field"),
})

const CardForm = ({ submitPayment }: { submitPayment: DashboardPaymentProps["submitPayment"] }) => {
    const classes = useStyles()
    return (
        <Box display="flex" flexDirection={"column"} p={3} style={{ height: "50vh", overflowY: "scroll" }}>
            <Box>
                <Formik
                    onSubmit={(values) => submitPayment(values, "card")}
                    validationSchema={CardvalidationSchema}
                    initialValues={{
                        name: "",
                        cardNumber: "",
                        cvv: "",
                        postalCode: "",
                        expiry: ""
                    }}
                >
                    {
                        (formikProps => (
                            <form onSubmit={formikProps.handleSubmit}>
                                <Box py={1}>
                                    <Grid container style={{ width: "100%" }}>
                                        <Grid item md={12} style={{ width: "100%" }}>
                                            <Typography
                                                variant="h6"
                                                style={{
                                                    fontSize: "16px",
                                                    color: '#353535',
                                                }}
                                            >
                                                Card Holder Name <span style={{ color: '#67a0a3' }}>*</span>
                                            </Typography>
                                            <TextField
                                                style={{ width: "100%", marginBottom: 15 }}
                                                placeholder="Enter Name"
                                                variant="standard"
                                                name="name"
                                                autoComplete="off"
                                                onChange={formikProps.handleChange}
                                                onBlur={formikProps.handleBlur}
                                                error={Boolean(
                                                    formikProps.touched.name &&
                                                    formikProps.errors.name
                                                )}
                                                helperText={
                                                    formikProps.touched.name &&
                                                    Boolean(formikProps.errors.name) &&
                                                    String(formikProps.errors.name)
                                                }
                                                value={formikProps.values.name}
                                                InputProps={{
                                                    style: {
                                                        backgroundColor: "#f5f6f8",
                                                        color: "black",
                                                        padding: "10px",
                                                        borderBottom: `1px solid ${localStorage.getItem("primaryColor") || "#67a1a3"}`,
                                                        fontWeight: 900,
                                                        fontSize: '16px'
                                                    },
                                                    disableUnderline: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item md={12} style={{ width: "100%" }}>
                                            <Typography
                                                variant="h6"
                                                style={{
                                                    fontSize: "16px",
                                                    color: '#353535',
                                                }}
                                            >
                                                Card Number <span style={{ color: '#67a0a3' }}>*</span>
                                            </Typography>
                                            <TextField
                                                style={{ width: "100%", marginBottom: 15 }}
                                                placeholder="Enter Card Number"
                                                variant="standard"
                                                name="cardNumber"
                                                autoComplete="off"
                                                onChange={formikProps.handleChange}
                                                onBlur={formikProps.handleBlur}
                                                error={formikProps.touched.cardNumber && Boolean(formikProps.errors.cardNumber)}
                                                helperText={formikProps.touched.cardNumber && formikProps.errors.cardNumber}
                                                value={formikProps.values.cardNumber
                                                    .replace(/\s/g, "")
                                                    .replace(/(\d{4})/g, "$1 ")
                                                    .trim()
                                                }

                                                InputProps={{
                                                    style: {
                                                        backgroundColor: "#f5f6f8",
                                                        color: "black",
                                                        padding: "10px",
                                                        borderBottom: `1px solid ${localStorage.getItem("primaryColor") || "#67a1a3"}`,
                                                        fontWeight: 900,
                                                        fontSize: '16px'
                                                    },
                                                    disableUnderline: true,
                                                }}
                                                inputProps={{
                                                    maxLength: 19
                                                }}
                                            />
                                        </Grid>
                                        <Grid item md={5} style={{ width: "100%" }}>
                                            <Typography
                                                variant="h6"
                                                style={{
                                                    fontSize: "16px",
                                                    color: '#353535',
                                                }}
                                            >
                                                Expiration Month & Year <span style={{ color: '#67a0a3' }}>*</span>
                                            </Typography>
                                            <TextField
                                                style={{ width: "100%", marginBottom: 15 }}
                                                placeholder="MM/YY"
                                                variant="standard"
                                                name="expiry"
                                                autoComplete="off"
                                                onChange={(text) => {
                                                    if (text.target.value.length == 3 && text.target.value.includes('/')) {
                                                        text.target.value = text.target.value.slice(0, 2);
                                                        formikProps.handleChange(text)
                                                    } else if (text.target.value.length == 3 && !text.target.value.includes('/')) {
                                                        text.target.value = text.target.value.slice(0, 2) + "/" + text.target.value.slice(2, 3);
                                                        formikProps.handleChange(text)
                                                    } else {
                                                        formikProps.handleChange(text)
                                                    }
                                                    formikProps.handleChange(text)
                                                }}
                                                onBlur={formikProps.handleBlur}
                                                error={Boolean(
                                                    formikProps.touched.expiry &&
                                                    formikProps.errors.expiry
                                                )}
                                                helperText={
                                                    formikProps.touched.expiry &&
                                                    Boolean(formikProps.errors.expiry) &&
                                                    String(formikProps.errors.expiry)
                                                }
                                                value={formikProps.values.expiry}
                                                InputProps={{
                                                    style: {
                                                        backgroundColor: "#f5f6f8",
                                                        color: "black",
                                                        padding: "10px",
                                                        borderBottom: `1px solid ${localStorage.getItem("primaryColor") || "#67a1a3"}`,
                                                        fontWeight: 900,
                                                        fontSize: '16px'
                                                    },
                                                    disableUnderline: true,
                                                }}
                                                inputProps={{
                                                    maxLength: 5
                                                }}
                                            />
                                        </Grid>
                                        <Grid item md={2}></Grid>
                                        <Grid item md={5} style={{ width: "100%" }}>
                                            <Typography
                                                variant="h6"
                                                style={{
                                                    fontSize: "16px",
                                                    color: '#353535',
                                                }}
                                            >
                                                CVV <span style={{ color: '#67a0a3' }}>*</span>
                                            </Typography>
                                            <TextField
                                                style={{ width: "100%", marginBottom: 15 }}
                                                placeholder="CVV"
                                                variant="standard"
                                                name="cvv"
                                                className="email_input"
                                                autoComplete="off"
                                                onChange={formikProps.handleChange}
                                                onBlur={formikProps.handleBlur}
                                                error={Boolean(
                                                    formikProps.touched.cvv &&
                                                    formikProps.errors.cvv
                                                )}
                                                helperText={
                                                    formikProps.touched.cvv &&
                                                    Boolean(formikProps.errors.cvv) &&
                                                    String(formikProps.errors.cvv)
                                                }
                                                value={formikProps.values.cvv}
                                                InputProps={{
                                                    style: {
                                                        backgroundColor: "#f5f6f8",
                                                        color: "black",
                                                        padding: "10px",
                                                        borderBottom: `1px solid ${localStorage.getItem("primaryColor") || "#67a1a3"}`,
                                                        fontWeight: 900,
                                                        fontSize: '16px'
                                                    },
                                                    disableUnderline: true,
                                                }}
                                                inputProps={{
                                                    maxLength: 3,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item md={5} style={{ width: "100%" }}>
                                            <Typography
                                                variant="h6"
                                                style={{
                                                    fontSize: "16px",
                                                    color: '#353535',
                                                }}
                                            >
                                                Postal Code <span style={{ color: '#67a0a3' }}>*</span>
                                            </Typography>
                                            <TextField
                                                style={{ width: "100%", marginBottom: 15 }}
                                                placeholder="Enter Code"
                                                variant="standard"
                                                name="postalCode"
                                                className="email_input"
                                                autoComplete="off"
                                                onChange={formikProps.handleChange}
                                                onBlur={formikProps.handleBlur}
                                                error={Boolean(
                                                    formikProps.touched.postalCode &&
                                                    formikProps.errors.postalCode
                                                )}
                                                helperText={
                                                    formikProps.touched.postalCode &&
                                                    Boolean(formikProps.errors.postalCode) &&
                                                    String(formikProps.errors.postalCode)
                                                }
                                                value={formikProps.values.postalCode}
                                                InputProps={{
                                                    style: {
                                                        backgroundColor: "#f5f6f8",
                                                        color: "black",
                                                        padding: "10px",
                                                        borderBottom: `1px solid ${localStorage.getItem("primaryColor") || "#67a1a3"}`,
                                                        fontWeight: 900,
                                                        fontSize: '16px'
                                                    },
                                                    disableUnderline: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item md={12} style={{ width: "100%", margin: "20px 0 0 0" }}>
                                            <Box display="flex" justifyContent={"flex-end"} style={{ gap: 10 }}>
                                                <Button className={classes.cancel_btn} type="button" onClick={() => formikProps.resetForm({
                                                    values: {
                                                        name: "",
                                                        cardNumber: "",
                                                        cvv: "",
                                                        postalCode: "",
                                                        expiry: ""
                                                    }
                                                })}>
                                                    Cancel
                                                </Button>
                                                <Button className={classes.submit_btn} type="submit">Submit Payment</Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </form>
                        ))
                    }
                </Formik>
            </Box>
        </Box>
    )
}

const EcheckForm = ({ submitPayment }: { submitPayment: DashboardPaymentProps["submitPayment"] }) => {
    const classes = useStyles()
    return (
        <Box display="flex" flexDirection={"column"} p={3} style={{ height: "50vh", overflowY: "scroll" }}>
            <Box>
                <Formik
                    validationSchema={CheckvalidationSchema}
                    onSubmit={(values) => submitPayment(values, "echeck")}
                    initialValues={{
                        accountHolderType: "",
                        businessName: "",
                        routingNumber: "",
                        accountNumber: "",
                        accountType: ""
                    }}
                >
                    {
                        (formikProps => (
                            <form onSubmit={formikProps.handleSubmit}>
                                {console.log("echeck values", formikProps.values)}
                                <Box py={1}>
                                    <Grid container style={{ width: "100%" }}>
                                        <Grid item md={12} style={{ width: "100%" }}>
                                            <Typography
                                                variant="h6"
                                                style={{
                                                    fontSize: "16px",
                                                    color: '#353535',
                                                }}
                                            >
                                                Account Holder Type <span style={{ color: '#67a0a3' }}>*</span>
                                            </Typography>
                                            <FormControl style={{ minWidth: "150px", width: "100%" }} >
                                                <Select
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left"
                                                        },
                                                        PaperProps: {
                                                            className: classes.menuPaper
                                                        },
                                                        MenuListProps: {
                                                            className: classes.listItem
                                                        },
                                                        getContentAnchorEl: null
                                                    }}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={formikProps.values.accountHolderType}
                                                    className={classes.select}
                                                    onChange={formikProps.handleChange}
                                                    disableUnderline={true}
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    name="accountHolderType"
                                                >
                                                    <MenuItem className={classes.menuItemStyle} selected value={""} disabled={true}>Select</MenuItem>
                                                    <MenuItem className={classes.menuItemStyle} value={"Individual Account"}>Individual Account</MenuItem>
                                                    <MenuItem className={classes.menuItemStyle} value={"Business Account"}>Business Account</MenuItem>
                                                </Select>
                                                <FormHelperText style={{ color: '#f44336', margin: '-10px 0 10px' }}>
                                                    {
                                                        formikProps.touched.accountHolderType &&
                                                        formikProps.errors.accountHolderType
                                                    }
                                                </FormHelperText>
                                                <span className={classes.customArrow}></span>
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={12} style={{ width: "100%" }}>
                                            <Typography
                                                variant="h6"
                                                style={{
                                                    fontSize: "16px",
                                                    color: '#353535',
                                                }}
                                            >
                                                Business Name <span style={{ color: '#67a0a3' }}>*</span>
                                            </Typography>
                                            <TextField
                                                style={{ width: "100%", marginBottom: 15 }}
                                                placeholder="Enter Business Name"
                                                variant="standard"
                                                name="businessName"
                                                value={formikProps.values.businessName}
                                                autoComplete="off"
                                                onChange={formikProps.handleChange}
                                                error={
                                                    formikProps.touched.businessName &&
                                                    Boolean(formikProps.errors.businessName)
                                                }
                                                helperText={
                                                    formikProps.touched.businessName &&
                                                    formikProps.errors.businessName
                                                }
                                                InputProps={{
                                                    style: {
                                                        backgroundColor: "#f5f6f8",
                                                        color: "black",
                                                        padding: "10px",
                                                        borderBottom: `1px solid ${localStorage.getItem("primaryColor") || "#67a1a3"}`,
                                                        fontWeight: 900,
                                                        fontSize: '16px'
                                                    },
                                                    disableUnderline: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item md={12} style={{ width: "100%" }}>
                                            <Typography
                                                variant="h6"
                                                style={{
                                                    fontSize: "16px",
                                                    color: '#353535',
                                                }}
                                            >
                                                Routing Number <span style={{ color: '#67a0a3' }}>*</span>
                                            </Typography>
                                            <TextField
                                                style={{ width: "100%", marginBottom: 15 }}
                                                placeholder="Enter Routing Number"
                                                variant="standard"
                                                name="routingNumber"
                                                value={formikProps.values.routingNumber}
                                                autoComplete="off"
                                                onChange={formikProps.handleChange}
                                                error={
                                                    formikProps.touched.routingNumber &&
                                                    Boolean(formikProps.errors.routingNumber)
                                                }
                                                helperText={
                                                    formikProps.touched.routingNumber &&
                                                    formikProps.errors.routingNumber
                                                }
                                                InputProps={{
                                                    style: {
                                                        backgroundColor: "#f5f6f8",
                                                        color: "black",
                                                        padding: "10px",
                                                        borderBottom: `1px solid ${localStorage.getItem("primaryColor") || "#67a1a3"}`,
                                                        fontWeight: 900,
                                                        fontSize: '16px'
                                                    },
                                                    disableUnderline: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item md={12} style={{ width: "100%" }}>
                                            <Typography
                                                variant="h6"
                                                style={{
                                                    fontSize: "16px",
                                                    color: '#353535',
                                                }}
                                            >
                                                Account Number <span style={{ color: '#67a0a3' }}>*</span>
                                            </Typography>
                                            <TextField
                                                style={{ width: "100%", marginBottom: 15 }}
                                                placeholder="Enter Account Number"
                                                variant="standard"
                                                name="accountNumber"
                                                value={formikProps.values.accountNumber}
                                                autoComplete="off"
                                                onChange={formikProps.handleChange}
                                                error={
                                                    formikProps.touched.accountNumber &&
                                                    Boolean(formikProps.errors.accountNumber)
                                                }
                                                helperText={
                                                    formikProps.touched.accountNumber &&
                                                    formikProps.errors.accountNumber
                                                }
                                                InputProps={{
                                                    style: {
                                                        backgroundColor: "#f5f6f8",
                                                        color: "black",
                                                        padding: "10px",
                                                        borderBottom: `1px solid ${localStorage.getItem("primaryColor") || "#67a1a3"}`,
                                                        fontWeight: 900,
                                                        fontSize: '16px'
                                                    },
                                                    disableUnderline: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item md={12} style={{ width: "100%" }}>
                                            <Typography
                                                variant="h6"
                                                style={{
                                                    fontSize: "16px",
                                                    color: '#353535',
                                                }}
                                            >
                                                Account Holder Type <span style={{ color: '#67a0a3' }}>*</span>
                                            </Typography>
                                            <FormControl style={{ minWidth: "150px", width: "100%" }} >
                                                <Select
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left"
                                                        },
                                                        PaperProps: {
                                                            className: classes.menuPaper
                                                        },
                                                        getContentAnchorEl: null
                                                    }}
                                                    value={formikProps.values.accountType}
                                                    className={classes.select}
                                                    name="accountType"
                                                    onChange={formikProps.handleChange}
                                                    disableUnderline={true}
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    error={
                                                        formikProps.touched.accountType &&
                                                        Boolean(formikProps.errors.accountType)
                                                    }

                                                >
                                                    <MenuItem className={classes.menuItemStyle} selected value={""} disabled={true}>Select</MenuItem>
                                                    <MenuItem className={classes.menuItemStyle} value={"Checking Account"} selected>Checking Account</MenuItem>
                                                    <MenuItem className={classes.menuItemStyle} value={"Savings Account"}>Savings Account</MenuItem>
                                                </Select>
                                                <FormHelperText style={{ color: '#f44336', margin: '-10px 0 10px' }}>
                                                    {
                                                        formikProps.touched.accountType &&
                                                        formikProps.errors.accountType
                                                    }
                                                </FormHelperText>
                                                <span className={classes.customArrow}></span>
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={12} style={{ width: "100%" }}>
                                            <Box display="flex" justifyContent={"flex-end"} style={{ gap: 10 }}>
                                                <Button className={classes.cancel_btn} type="reset">
                                                    Cancel
                                                </Button>
                                                <Button className={classes.submit_btn} type="submit">Submit Payment</Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </form>
                        ))
                    }
                </Formik>
            </Box>
        </Box>
    )
}


const DashboardPayment = ({ submitPayment, type }: DashboardPaymentProps) => {
    const classes = useStyles();
    const { primaryColor } = useContext(ThemeContext)
    const [alignment, setAlignment] = React.useState(type);

    React.useEffect(() => {
        setAlignment(alignment)
    }, [])

    const handleToggleChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: "echeck" | "card",) => {
        setAlignment(newAlignment);

    };


    return (
        <Box style={{ height: '100%' }}>
            <Grid item xs={12}>
                <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleToggleChange}
                    aria-label="Platform"
                    style={{ width: "100%", color: primaryColor, border: `1px solid ${primaryColor}`, borderRadius: '20px' }}
                >
                    <ToggleButton style={{
                        width: '100%',
                        fontFamily: "Rubik-Bold",
                        color: alignment === "card" ? '#ffffff' : '#000000',
                        backgroundColor: alignment === "card" ? primaryColor : '#F6F6F6',
                        borderRadius: alignment === "card" ? '20px' : '20px 0 0  20px'
                    }} disabled={alignment === "card" ? true : false}
                        className={alignment === "card" ? classes.selectedToggleButton : classes.toggleButton} value="card">Credit Card</ToggleButton>
                    <ToggleButton style={{
                        width: '100%',
                        fontFamily: "Rubik-Bold",
                        color: alignment === "echeck" ? '#ffffff' : '#000000',
                        backgroundColor: alignment === "echeck" ? primaryColor : '#F6F6F6',
                        borderRadius: alignment === "echeck" ? '20px' : '0 20px 20px 0'
                    }} disabled={alignment === "echeck" ? true : false}
                        className={alignment === "echeck" ? classes.selectedToggleButton : classes.toggleButton} value="echeck">Echecks</ToggleButton>
                </ToggleButtonGroup>
            </Grid>
            {
                alignment === "card" ?
                    <CardForm submitPayment={submitPayment} />
                    :
                    <EcheckForm submitPayment={submitPayment} />
            }
            <Box display="flex" p={4} alignItems="center" justifyContent="space-between" style={{ gap: 30, borderTop: '1px solid #ebebeb' }}>
                <Box width="30%">
                    <img src={cpaCharge} width="80%" style={{ borderTop: "1px solid #bbb" }} />
                </Box>
                <Box width="60%">
                    <Typography variant="body2" style={{ fontWeight: 900, textAlign: 'right' }}>
                        Our system uses SSL encryption technology, which is the same technology banks use to protect sensitive information.
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}
export default DashboardPayment;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cancel_btn: {
            fontSize: 16,
            fontWeight: 900,
            border: "1px solid #cecece",
            borderRadius: 5,
            padding: '5 20'
        },
        submit_btn: {
            fontSize: 16,
            fontWeight: 900,
            borderRadius: 5,
            padding: '5 20',
            background: `${localStorage.getItem("primaryColor") || "#67a1a3"}`,
            color: 'white'
        },
        selectedToggleButton: {
            backgroundColor: `${localStorage.getItem("primaryColor") || "#67a1a3"}`,
            borderRadius: "15px"
        },
        toggleButton: {
            fontFamily: "DMSans-Bold",
            fontSize: "16px",
            textTransform: "inherit",
            borderRadius: "15px",
            backgroundColor: "#eae8e9",
            '&.MuiToggleButton-root': {
                padding: "3px 6px",
                width: "100%"
            },
            '&.MuiToggleButton-root.Mui-Selected': {
                backgroundColor: `${localStorage.getItem("primaryColor") || "#67a1a3"}`,
                color: "#ffffff",
                borderRadius: '10px'

            }
        },
        menuPaper: {
            border: `1px solid ${localStorage.getItem("primaryColor") || "#67a1a3"}`,
            marginTop: 10,
        },
        listItem: {
            padding: 0,
            margin: 0,
        },
        select: {
            backgroundColor: "#f5f6f8",
            color: "black",
            padding: "10px",
            fontSize: "16px",
            borderBottom: `1px solid ${localStorage.getItem("primaryColor") || "#67a1a3"}`,
            marginBottom: 10,
            "& .MuiSelect-icon": {
                zIndex: 1,
                right: ".7rem",
            },
            '& .MuiSelect-select.MuiSelect-select': {
                padding: "15px"
            },
            '& .MuiSelect-select:focus': {
                backgrounnd: "red"
            }
        },
        menuItemStyle: {
            textAlign: "right",
            color: '#000 !important',
            backgroundColor: '#ffffff',
            fontSize: "16px"
        },
        customArrow: {
            position: "absolute",
            top: 0,
            right: 0,
            display: "block",
            backgroundColor: "#f5f6f8",
            width: "3rem",
            pointerEvents: 'none',
            color: "#ffffff"
        },

    })
)

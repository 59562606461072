import React from "react";

import {
  // Customizable Area Start
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem, 
  FormHelperText,
  Snackbar
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {getThemeFunction} from "../../../components/src/Theme";
import { ThemeProvider, makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Switch, Route, Link, withRouter } from "react-router-dom";
import { listChecked, listUnchecked, cpaCharge } from "./assets";
import Loader from "../../../components/src/Loader.web";
import { AllPaymentProp, AuthFormProps, Payment, NewPaymentProps } from './Interface';
import MuiAlert from "@material-ui/lab/Alert";


import { Formik } from 'formik';
import * as Yup from 'yup';
// Customizable Area End

import PaymentsController, { Props } from "./PaymentsController";
import { ThemeContext } from "../../notifications/src/Context/ThemeContext";

class Payments extends PaymentsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  static contextType: any = ThemeContext
  // Customizable Area End

  render() {
    const {path} = this.props.match;
    return (
      // Customizable Area Start
      <ThemeProvider theme={getThemeFunction(this.context?.primaryColor)}>
        <Box display="flex" flexDirection="column" flex={1}>
          <Switch>
            <Route path={path} exact >
              <AllPayments loading={this.state.payments.loading} 
              payments={this.state.payments.data} 
              is_cpa_authenticate={this.state.payments?.is_cpa_authenticate }
              getDocs={this.getAuthDoc}
              uploadDoc={this.uploadDoc}
              uploadDocLoading={this.state.uploadDocLoading}
              />
            </Route>
            <Route path={`${path}/new`} >
              <NewPayment handlePayment={this.handlePaymentType} paymentType={this.state.paymentType} getMerchantKey={this.getMerchantKey} merchantKey={this.state.merchant_key} createPayment={this.createPayment} />
            </Route>
            <Route path={`${path}/auth`} >
              <AuthForm authDoc={this.state.authDoc} getDocs={this.getAuthDoc} uploadDoc={this.uploadDoc} uploadDocLoading={this.state.uploadDocLoading} />
            </Route>
          </Switch>
          <Snackbar
            open={this.state.snackBar.show}
            onClose={this.closeSnackBarHandler}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={this.closeSnackBarHandler}
              severity={this.state.snackBar.type}
              style={{
                backgroundColor:
                  this.state.snackBar.type === "success"
                    ? "#67a0a3"
                    : "#f44336",
              }}
            >
              {this.state.snackBar.message}
            </MuiAlert>
          </Snackbar>
        </Box>
      </ThemeProvider>
    );
  }
}

export const useStyles = makeStyles((theme: any) =>
    createStyles({
        paper: {
          minHeight: "75vh",
          overflowY: "scroll",
          margin: `${theme.spacing(1)} 0`,
          display : 'flex'
        },
        form_paper : {
          minHeight: "70vh",
          overflowY: "scroll",
          marginRight: 30,
          width : "80%"
        },
        table_head: {
          fontWeight: 900,
          borderColor : '#67a0a3 !important',
        },
        table_body : {
          margin : '10px 10px'
        },
        table_row : {
          borderColor : '#67a0a3 !important',
          borderWidth : '0.7px'
        },
        new_payment_btn: {
          fontSize: "0.9rem",
          background: `${localStorage.getItem("primaryColor") || "#67a1a3"}`,
          padding: "5 10",
          color: "#fff",
          fontWeight: 900,
        },
        replace_form_btn: {
          fontSize: "0.9rem",
          background: `${localStorage.getItem("primaryColor") || "#67a1a3"}`,
          padding: "10 15",
          color: "#fff",
          fontWeight: 900,
          borderRadius : 5
        },
        checkbox_GridContainer : {
          margin : "1rem",
          height : "100%",
          width : '100%',
        },
        cancel_btn : {
          fontSize : 16,
          fontWeight : 900,
          border : "1px solid #cecece",
          borderRadius: 5,
          padding : '5 20'
        },
        submit_btn : {
          fontSize : 16,
          fontWeight : 900,
          borderRadius: 5,
          padding : '5 20',
          background : `${localStorage.getItem("primaryColor") || "#67a1a3"}`,
          color: 'white'
        },
        menuPaper : {
          border : `1px solid ${localStorage.getItem("primaryColor") || "#67a1a3"}`,
          marginTop : 10,
        },
        listItem :{
          padding : 0,
          margin : 0,
        },
        select: {
          backgroundColor: "#f5f6f8",
          color: "black",
          padding: "10px",
          fontSize : "16px",
          borderBottom: `1px solid ${localStorage.getItem("primaryColor") || "#67a1a3"}`,
          marginBottom : 10,
          "& .MuiSelect-icon": {
              zIndex: 1,
              right: ".7rem",
          },
          '& .MuiSelect-select.MuiSelect-select': {
              padding: "15px"
          },
          '& .MuiSelect-select:focus' :{
            backgrounnd : "red"
          }
        },
        menuItemStyle: {
          textAlign: "right",
          color: '#000 !important',
          backgroundColor: '#ffffff',
          fontSize : "16px"
        },
        customArrow: {
          position: "absolute",
          top: 0,
          right: 0,
          display: "block",
          backgroundColor: "#f5f6f8",
          width: "3rem",
          pointerEvents: 'none',
          color: "#ffffff"
        },
    })
);


const StyledTableCell = withStyles(() => ({
  body: {
    padding : '25px 0px',
    margin : '0px 10px',
    borderColor :'#d4e7e8',
    borderWidth : '0.5px'
  }
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
  root: {},
  body : {
    padding : "0px 20px",
  }
}))(TableRow);

export const AllPayments = ({loading, payments, uploadDoc, uploadDocLoading, getDocs, is_cpa_authenticate}: AllPaymentProp) => {
  const classes = useStyles();
  const fileInputRef = React.useRef<HTMLInputElement | any>(null);
  const handleBtnClick = () => {
    fileInputRef.current.click();
  }
  const handleFileChange = (e: any) => {
    const authFile = e.target.files[0];
    if(authFile)
    {
      uploadDoc(authFile);
    }
  }
 
 
  React.useEffect(()=> {
    getDocs()
  }, [])
 
  return (
      <>
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
        >
          <Box>
            <Typography variant="h4" color="secondary">
              Payments
            </Typography>
          </Box>
        {
          is_cpa_authenticate ?
            <Link to="/dashboard/payments/new" style={{ textDecoration: 'none' }}>
              <Button className={classes.new_payment_btn} variant="contained">
                + Submit New Payment
              </Button>
            </Link>
            :
            <>
              <input
                accept=".doc,.docx,.pdf"
                style={{ display: 'none' }}
                id="upload_btn"
                ref={fileInputRef}
                onChange={handleFileChange}
                type="file"
              />
              <Button className={classes.new_payment_btn} onClick={handleBtnClick} variant="contained" component="span" disabled={uploadDocLoading}>
                {
                  uploadDocLoading ? "Uploading..." : "+ Submit New Payment Authorization Form"
                }
              </Button>
            </>
        }

        </Box>
        <Paper className={classes.paper} variant="outlined" style={{ position : "relative"}}>
            {
              loading 
              ? 
                <Loader loading={loading}/>
              :
                <Box width="100%" mx="auto">
                  {
                    payments.length > 0
                    ?
                      <TableContainer>
                        <Table aria-label="payment-table">
                          <TableHead className={classes.table_head}>
                            <TableRow>
                              <TableCell className={classes.table_head} align="center">Date</TableCell>
                              <TableCell className={classes.table_head} align="center">Time</TableCell>
                              <TableCell className={classes.table_head} align="center">Paid Amount</TableCell>
                              <TableCell className={classes.table_head} align="center">Method</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody style={{ padding : "0px 10px"}}>
                            {payments.map((payment: Payment) => (
                              <StyledTableRow key={payment.attributes?.id} className={classes.table_row}>
                                <StyledTableCell align="center">
                                  {payment.attributes?.date}
                                </StyledTableCell>
                                <StyledTableCell align="center">{payment.attributes?.time}</StyledTableCell>
                                <StyledTableCell align="center">$&nbsp;{payment.attributes?.amount}</StyledTableCell>
                                <StyledTableCell align="center">{payment.attributes?.method_type || "NA"}</StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    :
                    <Box display="flex" height="100%" alignItems="center" justifyContent="center">
                      <Typography variant="h6" style={{color : "gray"}}>Empty Payment history</Typography>
                    </Box>

                  }
                </Box>
            }
        </Paper>
      </>
  )
}


const CardvalidationSchema = Yup.object({
  name : Yup
        .string()
        .required("This is a required field"),
  cardNumber : Yup
              .string()
              .required("This is a required field").min(16, "Invalid Card number"),
  cvv : Yup
        .number()
        .typeError('CVV must be a number')
        .required("This is a required field"),
        // .test(),
  postalCode : Yup
              .number()
              .typeError('Postal Code must be a number')
              .required("This is a required field"),
  expiry : Yup
          .string()
          .matches(/^(0\d|1[0-2])\/?(\d{2})$/, "Invalid expiry date")
          .required("This is a required field").min(5, "Invalid expiry date"),
})

const CheckvalidationSchema = Yup.object({
  accountHolderType : Yup
        .string()
        .required("This is a required field"),
  businessName : Yup
              .string()
              .min(2, "Invalid Business name")
              .required("This is a required field"),
  routingNumber : Yup
                  .number()
                  .typeError("This should be a number")
                  .required("This is a required field"),
  accountNumber : Yup
                  .number()
                  .typeError("This should be a number")
                  .required("This is a required field"),
  accountType : Yup
                .string()
                .required("This is a required field"),
})


export const NewPayment = ({handlePayment, paymentType, getMerchantKey, createPayment, merchantKey}: NewPaymentProps) => {
    const classes = useStyles();
    React.useEffect(() => {
      getMerchantKey()
    }, [])
    return (
      <>
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
        >
          <Box>
            <Typography variant="h4" color="secondary">
              Payments - Submit New Payment
            </Typography>
          </Box>
        </Box>
        <Paper style={{ display : 'flex', flexDirection : "column", position : 'relative' }} className={classes.paper} variant="outlined">
            <Loader loading={merchantKey.loading} />
            <Box flex={1} p={2}>
              <Grid container style={{ height : "100%"}}>
                <Grid item xs>
                 <Grid container direction={"column"} className={classes.checkbox_GridContainer}>
                  <Grid xs={7} item>
                    <Box display="flex" style={{ gap : 10, cursor : "pointer", color : paymentType === "card" ? "black" : "gray"}} alignItems="center" onClick={() => handlePayment("card")}>
                      <img src={paymentType === "card" ? listChecked : listUnchecked} width="20px" height="20px" />
                      <Typography variant="h5" style={{fontWeight : 900}}>Credit/Debit Card</Typography>
                    </Box>
                  </Grid>
                  <Grid xs item>
                    <Box display="flex" style={{ gap : 10, cursor : "pointer", color : paymentType === "check" ? "black" : "gray"}} alignItems="center" onClick={() => handlePayment("check")}>
                      <img src={paymentType === "check" ? listChecked : listUnchecked} width="20px" height="20px" />
                      <Typography variant="h5" style={{fontWeight : 900}}>E-Check</Typography>
                    </Box>
                  </Grid>
                 </Grid>
                </Grid>
                <Grid item xs={8}>
                  <Paper className={classes.form_paper}>
                    {
                      paymentType === "card"
                      ?
                        <CardForm submitPayment={createPayment}/>
                      : 
                        <CheckForm submitPayment={createPayment}/>  
                    }
                  </Paper>
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" p={4} alignItems="center" justifyContent="space-between" style={{ gap : 30, borderTop : '1px solid #ebebeb'}}>
                <Box width="30%">
                    <img src={cpaCharge} width="80%" style={{ borderTop : "1px solid #bbb"}}/>
                </Box>
                <Box width="60%">
                    <Typography variant="body2" style={{ fontWeight : 900, textAlign : 'right'}}>
                      Our system uses SSL encryption technology, which is the same technology banks use to protect sensitive information.
                    </Typography>
                </Box>
            </Box>
        </Paper>
      </>
    )
}


export const CardForm = ({ submitPayment } : { submitPayment : NewPaymentProps["createPayment"]}) => {
  const classes = useStyles();
  return  (
    <Box display="flex" flexDirection={"column"} p={3}>
      <Typography align="center" variant="h5" style={{ fontWeight : 900}}>Credit/Debit Card</Typography>
      <Box>
        <Formik
          onSubmit={(values) => submitPayment(values, "card")}
          validationSchema={CardvalidationSchema}
          initialValues={{
            name : "",
            cardNumber : "",
            cvv : "",
            postalCode : "",
            expiry : ""
          }}
        >
          {
            (formikProps => (
              <form onSubmit={formikProps.handleSubmit}>
                <Box py={1}>
                  <Grid container style={{ width : "100%"}}>
                    <Grid item md={12} style={{ width : "100%"}}>
                      <Typography
                          variant="h6"
                          style={{
                              fontSize: "16px",
                              color: '#353535',  
                          }}
                      >
                          Card Holder Name <span style={{ color : '#67a0a3'}}>*</span>
                      </Typography>
                      <TextField
                          style={{ width: "100%", marginBottom: 15 }}
                          placeholder="Enter Name"
                          variant="standard"
                          name="name"
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          error={Boolean(
                            formikProps.touched.name &&
                              formikProps.errors.name
                          )}
                          helperText={
                            formikProps.touched.name &&
                            Boolean(formikProps.errors.name) &&
                            String(formikProps.errors.name)
                          }
                          value={formikProps.values.name}
                          InputProps={{
                              style: {
                                  backgroundColor: "#f5f6f8",
                                  color: "black",
                                  padding: "10px",
                                  borderBottom: `1px solid ${localStorage.getItem("primaryColor") || "#67a1a3"}`,
                                  fontWeight : 900,
                                  fontSize : '16px'
                              },
                              disableUnderline: true,
                          }}
                      />
                    </Grid>
                    <Grid item md={12} style={{ width : "100%"}}>
                      <Typography
                          variant="h6"
                          style={{
                              fontSize: "16px",
                              color: '#353535', 
                          }}
                      >
                          Card Number <span style={{ color : '#67a0a3'}}>*</span>
                      </Typography>
                      <TextField
                          style={{ width: "100%", marginBottom: 15 }}
                          placeholder="Enter Card Number"
                          variant="standard"
                          name="cardNumber"
                          autoComplete="off"
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          error={Boolean(
                            formikProps.touched.cardNumber &&
                              formikProps.errors.cardNumber
                          )}
                          helperText={
                            formikProps.touched.cardNumber &&
                            Boolean(formikProps.errors.cardNumber) &&
                            String(formikProps.errors.cardNumber)
                          }
                          value={formikProps.values.cardNumber
                            .replace(/\s/g, "")
                            .replace(/(\d{4})/g, "$1 ")
                            .trim()
                          }
                          
                          InputProps={{
                              style: {
                                  backgroundColor: "#f5f6f8",
                                  color: "black",
                                  padding: "10px",
                                  borderBottom: `1px solid ${localStorage.getItem("primaryColor") || "#67a1a3"}`,
                                  fontWeight : 900,
                                  fontSize : '16px'
                              },
                              disableUnderline: true,
                          }}
                          inputProps={{
                            maxLength : 19
                          }}
                      />
                    </Grid>
                    <Grid item md={5} style={{ width : "100%"}}>
                      <Typography
                          variant="h6"
                          style={{
                            fontSize: "16px",
                            color: '#353535',
                          }}
                      >
                          Expiration Month & Year <span style={{ color : '#67a0a3'}}>*</span>
                      </Typography>
                      <TextField
                          style={{ width: "100%", marginBottom: 15 }}
                          placeholder="MM/YY"
                          variant="standard"
                          name="expiry"
                          autoComplete="off"
                          onChange={(text) => {
                            if(text.target.value.length == 3 && text.target.value.includes('/')){
                              text.target.value = text.target.value.slice(0,2);
                              formikProps.handleChange(text)
                            } else if(text.target.value.length == 3 && !text.target.value.includes('/')){
                              text.target.value = text.target.value.slice(0,2) + "/" + text.target.value.slice(2,3);
                              formikProps.handleChange(text)
                            } else {
                              formikProps.handleChange(text)
                            }
                            formikProps.handleChange(text)
                          }}
                          onBlur={formikProps.handleBlur}
                          error={Boolean(
                            formikProps.touched.expiry &&
                              formikProps.errors.expiry
                          )}
                          helperText={
                            formikProps.touched.expiry &&
                            Boolean(formikProps.errors.expiry) &&
                            String(formikProps.errors.expiry)
                          }
                          value={formikProps.values.expiry}
                          InputProps={{
                            style: {
                              backgroundColor: "#f5f6f8",
                              color: "black",
                              padding: "10px",
                              borderBottom: `1px solid ${localStorage.getItem("primaryColor") || "#67a1a3"}`,
                              fontWeight : 900,
                              fontSize : '16px'
                            },
                            disableUnderline: true,
                          }}
                          inputProps={{
                            maxLength : 5
                          }}
                      />
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item md={5} style={{ width : "100%"}}>
                      <Typography
                          variant="h6"
                          style={{
                              fontSize: "16px",
                              color: '#353535',  
                          }}
                      >
                          CVV <span style={{ color : '#67a0a3'}}>*</span>
                      </Typography>
                      <TextField
                          style={{ width: "100%", marginBottom: 15 }}
                          placeholder="CVV"
                          variant="standard"
                          name="cvv"
                          className="email_input"
                          autoComplete="off"
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          error={Boolean(
                            formikProps.touched.cvv &&
                              formikProps.errors.cvv
                          )}
                          helperText={
                            formikProps.touched.cvv &&
                            Boolean(formikProps.errors.cvv) &&
                            String(formikProps.errors.cvv)
                          }
                          value={formikProps.values.cvv}
                          InputProps={{
                              style: {
                                  backgroundColor: "#f5f6f8",
                                  color: "black",
                                  padding: "10px",
                                  borderBottom: `1px solid ${localStorage.getItem("primaryColor") || "#67a1a3"}`,
                                  fontWeight : 900,
                                  fontSize : '16px'
                              },
                              disableUnderline: true,
                          }}
                          inputProps={{
                            maxLength : 3,
                          }}
                      />
                    </Grid>
                    <Grid item md={5} style={{ width : "100%"}}>
                      <Typography
                          variant="h6"
                          style={{
                              fontSize: "16px",
                              color: '#353535', 
                          }}
                      >
                          Postal Code <span style={{ color : '#67a0a3'}}>*</span>
                      </Typography>
                      <TextField
                          style={{ width: "100%", marginBottom: 15 }}
                          placeholder="Enter Code"
                          variant="standard"
                          name="postalCode"
                          className="email_input"
                          autoComplete="off"
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          error={Boolean(
                            formikProps.touched.postalCode &&
                              formikProps.errors.postalCode
                          )}
                          helperText={
                            formikProps.touched.postalCode &&
                            Boolean(formikProps.errors.postalCode) &&
                            String(formikProps.errors.postalCode)
                          }
                          value={formikProps.values.postalCode}
                          InputProps={{
                              style: {
                                  backgroundColor: "#f5f6f8",
                                  color: "black",
                                  padding: "10px",
                                  borderBottom: `1px solid ${localStorage.getItem("primaryColor") || "#67a1a3"}`,
                                  fontWeight : 900,
                                  fontSize : '16px'
                              },
                              disableUnderline: true,
                          }}
                      />
                    </Grid>
                    <Grid item md={12} style={{ width : "100%", margin : "20px 0 0 0"}}>
                      <Box display="flex" justifyContent={"flex-end"} style={{ gap : 10}}>
                          <Button className={classes.cancel_btn} type="button" onClick={() => formikProps.resetForm({
                            values : {
                              name : "",
                              cardNumber : "",
                              cvv : "",
                              postalCode : "",
                              expiry : ""
                            }
                          })}>
                            Cancel
                          </Button>
                          <Button className={classes.submit_btn} type="submit">Submit Payment</Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            ))
          }
        </Formik>
      </Box>
    </Box>
  )
}

export const CheckForm = ({ submitPayment } : { submitPayment : NewPaymentProps["createPayment"]}) => {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection={"column"} p={3}>
      <Typography align="center" variant="h5" style={{ fontWeight : 900}}>E-Check</Typography>
      <Box>
        <Formik
          validationSchema={CheckvalidationSchema}
          onSubmit={(values) => submitPayment(values, "echeck")}
          initialValues={{
            accountHolderType : "",
            businessName : "",
            routingNumber : "",
            accountNumber : "",
            accountType : ""
          }}
        >
          {
            (formikProps => (
              <form onSubmit={formikProps.handleSubmit}>
                <Box py={1}>
                  <Grid container style={{ width : "100%"}}>
                    <Grid item md={12} style={{ width : "100%"}}>
                      <Typography
                          variant="h6"
                          style={{
                              fontSize: "16px",
                              color: '#353535', 
                          }}
                      >
                          Account Holder Type <span style={{ color : '#67a0a3'}}>*</span>
                      </Typography>
                      <FormControl style={{ minWidth: "150px", width: "100%" }} >
                              <Select
                                  MenuProps={{
                                      anchorOrigin: {
                                          vertical: "bottom",
                                          horizontal: "left"
                                      },
                                      PaperProps: {
                                        className : classes.menuPaper
                                      },
                                      MenuListProps: {
                                        className : classes.listItem
                                      },
                                      getContentAnchorEl: null
                                  }}
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={formikProps.values.accountHolderType}
                                  className={classes.select}
                                  onChange={formikProps.handleChange}
                                  disableUnderline={true}
                                  IconComponent={KeyboardArrowDownIcon}
                                  name="accountHolderType"
                              >
                                  <MenuItem className={classes.menuItemStyle} selected value={""} disabled={true}>Select</MenuItem>
                                  <MenuItem className={classes.menuItemStyle} value={"Individual Account"}>Individual Account</MenuItem>
                                  <MenuItem className={classes.menuItemStyle} value={"Business Account"}>Business Account</MenuItem>
                              </Select>
                              <FormHelperText style={{ color: '#f44336', margin : '-10px 0 10px'}}>
                                {
                                  formikProps.touched.accountHolderType &&
                                  formikProps.errors.accountHolderType
                                }
                              </FormHelperText>
                              <span className={classes.customArrow}></span>
                      </FormControl>
                    </Grid>
                    <Grid item md={12} style={{ width : "100%"}}>
                      <Typography
                          variant="h6"
                          style={{
                              fontSize: "16px",
                              color: '#353535', 
                          }}
                      >
                          Business Name <span style={{ color : '#67a0a3'}}>*</span>
                      </Typography>
                      <TextField
                          style={{ width: "100%", marginBottom: 15 }}
                          placeholder="Enter Business Name"
                          variant="standard"
                          name="businessName"
                          value={formikProps.values.businessName}
                          autoComplete="off"
                          onChange={formikProps.handleChange}
                          error={
                              formikProps.touched.businessName &&
                              Boolean(formikProps.errors.businessName)
                          }
                          helperText={
                              formikProps.touched.businessName &&
                              formikProps.errors.businessName
                          }
                          InputProps={{
                              style: {
                                  backgroundColor: "#f5f6f8",
                                  color: "black",
                                  padding: "10px",
                                  borderBottom: `1px solid ${localStorage.getItem("primaryColor") || "#67a1a3"}`,
                                  fontWeight : 900,
                                  fontSize : '16px'
                              },
                              disableUnderline: true,
                          }}
                      />
                    </Grid>
                    <Grid item md={12} style={{ width : "100%"}}>
                      <Typography
                          variant="h6"
                          style={{
                            fontSize: "16px",
                            color: '#353535', 
                          }}
                      >
                          Routing Number <span style={{ color : '#67a0a3'}}>*</span>
                      </Typography>
                      <TextField
                          style={{ width: "100%", marginBottom: 15 }}
                          placeholder="Enter Routing Number"
                          variant="standard"
                          name="routingNumber"
                          value={formikProps.values.routingNumber}
                          autoComplete="off"
                          onChange={formikProps.handleChange}
                          error={
                              formikProps.touched.routingNumber &&
                              Boolean(formikProps.errors.routingNumber)
                          }
                          helperText={
                              formikProps.touched.routingNumber &&
                              formikProps.errors.routingNumber
                          }
                          InputProps={{
                            style: {
                              backgroundColor: "#f5f6f8",
                              color: "black",
                              padding: "10px",
                              borderBottom: `1px solid ${localStorage.getItem("primaryColor") || "#67a1a3"}`,
                              fontWeight : 900,
                              fontSize : '16px'
                            },
                            disableUnderline: true,
                          }}
                      />
                    </Grid>
                    <Grid item md={12} style={{ width : "100%"}}>
                      <Typography
                          variant="h6"
                          style={{
                              fontSize: "16px",
                              color: '#353535',
                          }}
                      >
                          Account Number <span style={{ color : '#67a0a3'}}>*</span>
                      </Typography>
                      <TextField
                          style={{ width: "100%", marginBottom: 15 }}
                          placeholder="Enter Account Number"
                          variant="standard"
                          name="accountNumber"
                          value={formikProps.values.accountNumber}
                          autoComplete="off"
                          onChange={formikProps.handleChange}
                          error={
                              formikProps.touched.accountNumber &&
                              Boolean(formikProps.errors.accountNumber)
                          }
                          helperText={
                              formikProps.touched.accountNumber &&
                              formikProps.errors.accountNumber
                          }
                          InputProps={{
                              style: {
                                  backgroundColor: "#f5f6f8",
                                  color: "black",
                                  padding: "10px",
                                  borderBottom: `1px solid ${localStorage.getItem("primaryColor") || "#67a1a3"}`,
                                  fontWeight : 900,
                                  fontSize : '16px'
                              },
                              disableUnderline: true,
                          }}
                      />
                    </Grid>
                    <Grid item md={12} style={{ width : "100%"}}>
                      <Typography
                          variant="h6"
                          style={{
                              fontSize: "16px",
                              color: '#353535', 
                          }}
                      >
                          Account Holder Type <span style={{ color : '#67a0a3'}}>*</span>
                      </Typography>
                      <FormControl style={{ minWidth: "150px", width: "100%" }} >
                              <Select
                                  MenuProps={{
                                      anchorOrigin: {
                                          vertical: "bottom",
                                          horizontal: "left"
                                      }, 
                                      PaperProps: {
                                        className : classes.menuPaper
                                      },
                                      getContentAnchorEl: null
                                  }}
                                  value={formikProps.values.accountType}
                                  className={classes.select}
                                  name="accountType"
                                  onChange={formikProps.handleChange}
                                  disableUnderline={true}
                                  IconComponent={KeyboardArrowDownIcon}
                                  error={
                                    formikProps.touched.accountType &&
                                    Boolean(formikProps.errors.accountType)
                                  }
                                  
                              >
                                  <MenuItem className={classes.menuItemStyle} selected value={""} disabled={true}>Select</MenuItem>
                                  <MenuItem className={classes.menuItemStyle} value={"Checking Account"} selected>Checking Account</MenuItem>
                                  <MenuItem className={classes.menuItemStyle} value={"Savings Account"}>Savings Account</MenuItem>
                              </Select>
                              <FormHelperText style={{ color: '#f44336', margin : '-10px 0 10px'}}>
                                {
                                  formikProps.touched.accountType &&
                                  formikProps.errors.accountType
                                }
                              </FormHelperText>
                              <span className={classes.customArrow}></span>
                          </FormControl>
                    </Grid>
                    <Grid item md={12} style={{ width : "100%"}}>
                      <Box display="flex" justifyContent={"flex-end"} style={{ gap : 10}}>
                          <Button className={classes.cancel_btn} type="reset">
                            Cancel
                          </Button>
                          <Button className={classes.submit_btn} type="submit">Submit Payment</Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            ))
          }
        </Formik>
      </Box>
    </Box> 
  )
}

export const AuthForm = ({ authDoc, getDocs, uploadDoc , uploadDocLoading } : AuthFormProps) => {
  const classes = useStyles();
  const fileInputRef = React.useRef<HTMLInputElement | any>(null);
  const handleBtnClick = () => {
    fileInputRef.current.click();
  }
  const handleFileChange = (e: any) => {
    const authFile = e.target.files[0];
    if(authFile)
    {
      uploadDoc(authFile);
    }
  }

  React.useEffect(()=> {
    getDocs()
  }, [])
  return (
      <>
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
        >
          <Box display="flex" width="100%" justifyContent="space-between">
            <Typography variant="h4" color="secondary">
              Payments
            </Typography>
              <input
                accept=".doc,.docx,.pdf"
                style={{ display : 'none'}}
                id="upload_btn"
                ref={fileInputRef}
                onChange={handleFileChange}
                type="file"
              />
              <Button className={classes.new_payment_btn} onClick={handleBtnClick} variant="contained" component="span" disabled={uploadDocLoading}>
                {
                  uploadDocLoading ? "Uploading..." :  "+ Replace Payment Auth Form"
                }
              </Button>
          </Box>
        </Box>
        <Paper className={classes.paper} style={{ display : "flex", flexDirection : "column"}} variant="outlined">
            <Box display="flex" flex={1} px={3} py={2} flexDirection="column">
              <Typography variant="h6" align="center">Existing Payment Authorization Form</Typography>
              <Box display="flex" flex={1} alignItems="center" justifyContent="center" py={1} style={{ position : 'relative'}}>
                {
                  authDoc.length  > 0
                  ? 
                  // <DocViewer
                  //   style={{ height : '65vh', width : '70%'}}
                  //   pluginRenderers={DocViewerRenderers}
                  //   documents={[{ uri : `https://docs.google.com/viewer?url=${authDoc}&embedded=true`}]}
                  // />
                   <iframe style={{ height : '65vh', width : '70%'}} src={'https://docs.google.com/viewer?url=' + authDoc +'&embedded=true'} />
                  :
                    <Loader loading={true} />
                }
              </Box>
            </Box>
          </Paper>
      </>
  )
}
// Customizable Area End



// Customizable Area Start
//@ts-ignore
export default withRouter(Payments);

// Customizable Area End
// Customizable Area Start
import MainDashboardController from "../MainDashboardController";
import React from "react";
import {
    Box,
    Typography,
    Grid,
    Button,
    Paper,
    Popover,
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Modal,
    Snackbar
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import {
    arrowDown,
    arrowUp,
    filter,
} from "../assets";
import { ThemeProvider, withStyles } from "@material-ui/core/styles";
import { getThemeFunction } from "../../../../components/src/Theme";
import DashboardCheckList from "../../../../components/src/DashboardCheckList";
import { ThemeContext } from "../../../notifications/src/Context/ThemeContext";
import DocumentComponent from "../../../../components/src/DocumentComponent";
import QuestionComponent from "../../../../components/src/QuestionComponent";
import Loader from "../../../../components/src/Loader.web";
import PaymentComponent from "../../../../components/src/PaymentComponent";
import { withRouter } from "react-router-dom";


const drawerWidth = 312;
export const muiStyles = (theme: any) => ({
    root: {
        display: "flex",
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        boxShadow: "none",
        padding: 5,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    icon: {
        minWidth: "40px",
    },
    notification_icon: {
        backgroundColor: "#E7EAEE",
        borderRadius: "50%",
        padding: 10,
    },
    selected: {
        backgroundColor: "#E7EAEE !important",
    },
    btn_type: {
        border: "1px solid #67a0a3",
        borderRadius: 5,
        padding: "4px 6px",
        fontSize: "0.7rem",
        fontWeight: "700",
    },
    grow: {
        flex: 1,
    },
    filter: {
        cursor: "pointer",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.primary,
        borderRadius: 10,
    },
    more_btn: {
        flex: 1,
        padding: "0.5rem",
        backgroundColor: theme.palette.secondary.main,
        color: "white",
    },
    icon_selected: {
        height: 30,
        borderRadius: 10,
        border: "3px solid #FF4E59",
        marginRight: -6,
        zIndex: 9999,
    },
    payments: {
        // border: "1px solid #67a0a3",
        borderRadius: 10,
        backgroundColor: "#F6F6F6",
        overflow: 'hidden'
    },
    selected_btn: {
        backgroundColor: "#f1f1f1",
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        border: "1px solid #67a0a3",
        marginRight: '-5px'
    },
    submit_btn: {
        fontSize: "1rem",
        // background: "#67a0a3",
        width: "40%",
        color: "#fff",
    },
    action_items: {
        background: "#fff",
        margin: "20 0",
        width: "100%",
        borderRadius: 10,
    },
    payment_details: {
        fontSize: "0.9rem",
    },
    payment_amount: {
        font: "bold 2rem Rubik",
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(4),
    },
    modalStyle: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '402px',
        height: "auto",
        backgroundColor: '#ffffff',
        borderRadius: '6px',
        boxShadow: '24px',
        padding: '10px 20px 30px 20px',
    },
});




const Priorties = ({ classes, prioritiesExpand, prioritiesData }: any) => {
    return (
        prioritiesExpand
            ?
            prioritiesData.map(
                (priority: any, index: number) => (
                    <Grid item xs={4} key={index}>
                        <Paper className={classes.paper} variant="outlined">
                            <Box display="flex" justifyContent="center">
                                <Typography variant="body2">
                                    {priority.attributes.name}
                                </Typography>
                            </Box>
                        </Paper>
                    </Grid>
                )
            )
            :
            prioritiesData
                .slice(0, 3)
                .map((priority: any, index: number) => (
                    <Grid item xs={4} key={index}>
                        <Paper className={classes.paper} variant="outlined">
                            <Box display="flex" justifyContent="center">
                                <Typography variant="body2">
                                    {priority.attributes.name}
                                </Typography>
                            </Box>
                        </Paper>
                    </Grid>
                ))
    )
}


const PrioritiesConditional = ({ priorityData, classes, prioritiesExpand }: { priorityData: any[], classes: any, prioritiesExpand: boolean }) => {
    return (
        priorityData.length === 0 ? (
            <Box width="100%" p={1}>
                <Typography variant="h6" align="center" color="primary" style={{ fontWeight: 300 }}>
                    No Priorities
                </Typography>
            </Box>
        ) : <Priorties classes={classes} prioritiesData={priorityData} prioritiesExpand={prioritiesExpand} />
    )
}

class MainDashboard extends MainDashboardController {
    static contextType = ThemeContext;

    expandImage = (prioritiesExpand: boolean) => prioritiesExpand ? arrowUp : arrowDown;


    expandText = (prioritiesExpand: boolean) => prioritiesExpand ? "Less" : "More";


    getClassName = (list_id: any, classes: any) => this.state.selectedChecklist.type === "payroll" && this.state.selectedChecklist.id === list_id ? classes.selected_btn : ""

    render() {
        const { classes } = this.props as any;
        const { checkListData, open, questionList, sumittedMsgModal, submittedMsg,
            selectedItemId, anchorElFilter, selectedPayments, selectedDocuments,
            documentsLoading, loading, snackBar, merchant_key, paymentGatewayId
        } = this.state;
        console.log({ merchant_key })
        console.log({paymentGatewayId})
        const { primaryColor } = this.context;


        return (
            <ThemeProvider theme={getThemeFunction(primaryColor)}>
                <Loader loading={documentsLoading} />
                <Snackbar
                    open={this.state.snackBar.show}
                    onClose={this.closeSnackBarHandler}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={this.closeSnackBarHandler}
                        severity={snackBar.type}
                        style={{
                            backgroundColor:
                                this.state.snackBar.type === "success"
                                    ? "#67a0a3"
                                    : "#f44336",
                        }}
                    >
                        {this.state.snackBar.message}
                    </MuiAlert>
                </Snackbar>
                <Modal
                    open={sumittedMsgModal}
                    // onClose={() => this.closeModal()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className={classes.modalStyle}>
                        <Box style={{ textAlign: "center" }}>
                            <Typography style={{
                                fontFamily: "Rubik-Regular",
                                fontSize: "25px", color: primaryColor,
                                textAlign: "center",
                                marginBottom: "10px"
                            }}>
                                {
                                    submittedMsg
                                }
                            </Typography>
                            <Button
                                data-testid="modalOkBtn"
                                onClick={() => this.closeModal()}
                                style={{
                                    backgroundColor: primaryColor,
                                    color: "#ffffff", textTransform: "inherit",
                                    minWidth: "90px", fontFamily: "Rubik-SemiBold"
                                }}>
                                OK
                            </Button>
                        </Box>
                    </Box>
                </Modal>
                <Box display="flex" flexDirection="column" flex={1} height="100%">
                    {/* Priorities */}
                    <Box display="flex" flexDirection="column">
                        <Typography variant="h4" color="secondary">
                            My Priorities
                        </Typography>
                        <Box py={2} display="flex" alignItems="flex-start">
                            <Box width="95%" py={1} mr={2}>
                                <Grid container spacing={2}>
                                    {this.state.dashboardData.priorities
                                        ? <PrioritiesConditional prioritiesExpand={this.state.prioritiesExpand} priorityData={this.state.dashboardData.priorities} classes={classes} />
                                        : (
                                            <Box width="100%" p={1}>
                                                <Typography variant="h6" align="center" color="primary" style={{ fontWeight: 300 }}>
                                                    Loading...
                                                </Typography>
                                            </Box>
                                        )}
                                </Grid>
                            </Box>
                            <Box display="flex" alignItems="center" width="auto" py={1}>
                                {
                                    this.state.dashboardData.priorities && this.state.dashboardData.priorities.length > 3 &&
                                    <Button
                                        className={classes.more_btn}
                                        onClick={this.handleExpand}
                                    >
                                        <Box>
                                            <Typography variant="h6">
                                                {this.expandText(this.state.prioritiesExpand)}
                                            </Typography>
                                            <img
                                                src={this.expandImage(this.state.prioritiesExpand)}
                                                width="16px"
                                            />
                                        </Box>
                                    </Button>
                                }
                            </Box>
                        </Box>
                    </Box>
                    {/* Action Items */}
                    <Box display="flex" flexDirection="column" flex={1}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Box>
                                <Typography variant="h4" color="secondary">
                                    Action Items
                                </Typography>
                            </Box>
                            {/* <FilterPopOver /> */}
                            <Box>
                                <Box style={{ display: "flex", justifyContent: "center" }}>
                                    <Button onClick={this.handleClick} className={"actionButton"}>
                                        <Typography
                                            variant="h6"
                                            style={{ fontWeight: 600, margin: "0 10" }}
                                        >
                                            Filter
                                        </Typography>
                                        <img src={filter} width="20px" />
                                    </Button>
                                </Box>
                                <Popover
                                    open={Boolean(anchorElFilter)}
                                    anchorEl={anchorElFilter}
                                    onClose={this.handleClose}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                    }}
                                >
                                    <Box
                                        width={250}
                                        p={1}
                                        display="flex"
                                        flexDirection="column"
                                        border={`1px solid ${primaryColor}`}
                                        borderRadius={5}
                                        style={{ backgroundColor: "transparent" }}
                                    >
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            py={1}
                                            px={2}
                                            borderBottom={`1px solid ${primaryColor}`}
                                        >
                                            <Typography variant="h5" style={{ fontWeight: 600 }}>
                                                Filter
                                            </Typography>
                                            <img style={{ cursor: "pointer" }} onClick={this.handleClose} src={filter} width="20px" height="15px" />
                                        </Box>
                                        <Box display="flex" flexDirection="column" p={1}>
                                            <Box display="flex" flexDirection="column" mb={1}>
                                                <Typography variant="h6">Status</Typography>
                                                <Box>
                                                    <FormControl component="fieldset">
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={this.state.filterOptions.todo}
                                                                        value={"to_do,in_progress"}
                                                                        onChange={(e) => this.handleChange(e, "status")}
                                                                        name="todo"
                                                                    />
                                                                }
                                                                label={
                                                                    <Typography variant="h6" style={{ color: "#636363" }}>
                                                                        To-Do
                                                                    </Typography>
                                                                }
                                                            />
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={this.state.filterOptions.inProgress}
                                                                        value={"completed"}
                                                                        onChange={(e) => this.handleChange(e, "status")}
                                                                        name="inProgress"
                                                                    />
                                                                }
                                                                label={
                                                                    <Typography variant="h6" style={{ color: "#636363" }}>
                                                                        In Progress
                                                                    </Typography>
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                </Box>
                                            </Box>
                                            <Box mb={1}>
                                                <Typography variant="h6">Type</Typography>
                                                <Box>
                                                    <FormControl component="fieldset">
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={this.state.filterOptions.personal}
                                                                        value="personal"
                                                                        onChange={(e) => this.handleChange(e, "type")}
                                                                        name="personal"
                                                                    />
                                                                }
                                                                label={
                                                                    <Typography variant="h6" style={{ color: "#636363" }}>
                                                                        Personal
                                                                    </Typography>
                                                                }
                                                            />
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={this.state.filterOptions.business}
                                                                        onChange={(e) => this.handleChange(e, "type")}
                                                                        name="business"
                                                                        value="business"
                                                                    />
                                                                }
                                                                label={
                                                                    <Typography variant="h6" style={{ color: "#636363" }}>
                                                                        Business
                                                                    </Typography>
                                                                }
                                                            />
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={this.state.filterOptions.question}
                                                                        onChange={(e) => this.handleChange(e, "type")}
                                                                        name="question"
                                                                        value="question"
                                                                    />
                                                                }
                                                                label={
                                                                    <Typography variant="h6" style={{ color: "#636363" }}>
                                                                        Question
                                                                    </Typography>
                                                                }
                                                            />
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={this.state.filterOptions.documentNeeded}
                                                                        onChange={(e) => this.handleChange(e, "type")}
                                                                        name="documentNeeded"
                                                                        value="document_needed"
                                                                    />
                                                                }
                                                                label={
                                                                    <Typography variant="h6" style={{ color: "#636363" }}>
                                                                        Document Needed
                                                                    </Typography>
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Button fullWidth style={{ backgroundColor: primaryColor }} onClick={this.handleClearfilter}>
                                            <Typography variant="h6" style={{ color: "white" }}>
                                                Clear filters
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Popover>
                            </Box>
                        </Box>
                        <Box display="flex" flex={1} style={{ height: "62vh" }}>
                            <Box className={classes.action_items}>
                                <Box display="flex" p={2}>
                                    <Box display="flex" flexDirection="column" py={1} width="40%" style={{ height: "60vh", overflowY: "auto", overflowX: "hidden" }}>
                                        <DashboardCheckList
                                            loading={loading}
                                            selectedItemId={selectedItemId}
                                            checkListData={checkListData}
                                            handleColapse={this.handleColapse}
                                            open={open}
                                            hanldeClickChecklistItem={this.hanldeClickChecklistItem}
                                        />
                                    </Box>
                                    <Box style={{ width: "100%" }}>
                                        {loading && (
                                            <Box
                                                width="100%"
                                                height="60vh"
                                                className={classes.payments}
                                                style={{
                                                    border: `1px solid ${primaryColor}`
                                                }}
                                                p={2}
                                                flex={1}
                                                flexDirection="column"
                                            >
                                                <Typography variant="h6" align="center" color="primary" style={{ fontWeight: 300 }}>
                                                    Loading...
                                                </Typography>
                                            </Box>
                                        )}

                                        {!loading && selectedPayments && (
                                            <PaymentComponent selectedPayments={selectedPayments} createPayment={this.createPaymentRequest} />
                                        )}

                                        {!loading && !selectedPayments && selectedDocuments?.document_template_id !== 0 && (
                                            <DocumentComponent
                                                selectedDocuments={selectedDocuments}
                                                getHelloSignURLRequest={this.getHelloSignURLRequest}
                                            />
                                        )}

                                        {!loading && !selectedPayments && questionList?.length > 0 && !selectedDocuments?.document_template_id && (
                                            <QuestionComponent
                                                questionList={questionList}
                                                question_rank={this.state.question_rank}
                                                handleOptionChange={this.handleOptionChange}
                                                handleStringChange={this.handleStringChange}
                                                submitAnswer={this.submitAnswer}
                                                handleQuestionRank={this.handleQuestionRank}

                                            />
                                        )}

                                        {!loading && !selectedPayments && !selectedDocuments?.document_template_id && questionList?.length === 0 && (
                                            <Box
                                                width="100%"
                                                height="60vh"
                                                className={classes.payments}
                                                style={{
                                                    border: `1px solid ${primaryColor}`
                                                }}
                                                p={2}
                                                flex={1}
                                                flexDirection="column"
                                            >
                                                <Typography variant="h6" align="center" color="primary" style={{ fontWeight: 300 }}>
                                                    {loading ? "Loading..." : ""}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </ThemeProvider>
        )
    }
}

//@ts-ignore
export default withRouter(withStyles(muiStyles)(MainDashboard));
// Customizable Area End


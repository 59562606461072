import {
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    TableBody,
    Typography,
    Box,
    IconButton
} from "@material-ui/core";
import {
    makeStyles,
    createStyles,
    Theme,
} from "@material-ui/core/styles";
import React, { useContext } from "react";
import { ThemeContext } from "../../blocks/notifications/src/Context/ThemeContext";
import { viewAllBlackIcon, deleteIcon, editBlackIcon } from "../../blocks/user-profile-basic/src/assets";
import { UserInfoI } from "../../blocks/user-profile-basic/src/Interface";
import Loader from "./Loader.web";
import { getFirstLetters } from "./Utils";
import { Link } from "react-router-dom"
export interface AllUsersProps {
    users: UserInfoI[],
    errorMsg: string;
    handleOpenAddUser: () => void;
    goToEditUserPage: (type: string, id: string) => void;
    delteUserData: (userId: string, userType: string) => void;
    loading: boolean;
}


const ViewAll = ({ users, handleOpenAddUser, goToEditUserPage, delteUserData, errorMsg, loading }: AllUsersProps) => {
    const classes = useStyles();
    const { primaryColor } = useContext(ThemeContext)
    const nameType = users[0]?.type === "user" ? "Users" : "Business";
    const userType = users[0]?.type === "user" ? "user" : "business"
    return (
        <>
            <Loader loading={loading} />
            {
                errorMsg ?
                    <Typography style={{ fontFamily: "Rubik-Regular", textAlign: "center", marginTop: "40px" }}>{errorMsg}</Typography>
                    :
                    <Box style={{ padding: "20px", marginLeft: "312px", marginTop: "64px", height: "83vh", overflowY: "scroll" }}>
                        <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "30px" }}>
                            <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                <Typography style={{ fontSize: "25px", color: primaryColor, fontFamily: "Rubik-Regular" }}>View All</Typography>
                                <img src={viewAllBlackIcon} height="17px" />
                                <Typography style={{ fontSize: "17px", fontFamily: "Rubik-Regular" }}>{errorMsg ? errorMsg : "All " + nameType}</Typography>
                            </Box>
                            <Link to={`/CreateUser?type=${userType}`}
                                style={{ textDecoration: "none", color: "black" }}
                            >
                            <Button style={{ backgroundColor: primaryColor, color: "#ffffff", textTransform: "inherit", fontFamily: "Rubik-Regular" }}>{"+ Add New " + nameType}</Button>
                            </Link>
                        </Box>
                        <TableContainer className={classes.tableStyle} component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow >
                                        <TableCell style={{ fontFamily: "Rubik-Regular" }} align="left">
                                            {
                                                nameType + " Name"
                                            }
                                        </TableCell>
                                        <TableCell style={{ fontFamily: "Rubik-Regular" }} align="left">{"Date Added"}</TableCell>
                                        <TableCell style={{ fontFamily: "Rubik-Regular" }} align="left">{"Phone Number"}</TableCell>
                                        <TableCell style={{ fontFamily: "Rubik-Regular" }} align="left">{""}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        errorMsg ?
                                            <Typography style={{ fontFamily: "Rubik-Regular", textAlign: "center" }}>{errorMsg}</Typography>
                                            :
                                            users
                                                ?.map((row: UserInfoI) => (
                                                    <TableRow
                                                        key={row.id}
                                                    >
                                                        <TableCell style={{ fontFamily: "Rubik-Regular", display: "flex", gap: "25px", alignItems: "center" }} align="left">
                                                            {
                                                                row?.attributes?.image ?
                                                                    <img src={row.attributes.image} height="60px" width="60px" style={{ borderRadius: "50%", border: "1px solid #a8b0b6" }} />
                                                                    :
                                                                    <Box style={{ height: "60px", width: "60px", borderRadius: "50%", border: "1px solid #a8b0b6", backgroundColor: primaryColor, position: "relative" }}>
                                                                        <Typography style={{ position: "absolute", top: "29%", left: "32%", color: "#ffffff", fontSize: "17px" }}>
                                                                            {
                                                                                row?.type?.toLowerCase() === "client_businesses" ?
                                                                                    getFirstLetters(row?.attributes?.business_name)
                                                                                    :
                                                                                    getFirstLetters(row?.attributes?.first_name + " " + row?.attributes?.last_name)
                                                                            }
                                                                        </Typography>
                                                                    </Box>
                                                            }
                                                            <Box>
                                                                <Typography style={{ fontSize: "18px", fontFamily: "Rubik-Bold" }}>
                                                                    {
                                                                        row.attributes.user_type === "user" ?
                                                                            row.attributes.first_name + " " + row.attributes.last_name
                                                                            :
                                                                            row.attributes.business_name
                                                                    }
                                                                </Typography>
                                                                <Typography style={{ fontSize: "14px", fontFamily: "Rubik-Regular" }}>{row.attributes.email}</Typography>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell style={{ fontFamily: "Rubik-Regular" }} align="left">
                                                            <Typography style={{ fontSize: "16px", fontFamily: "Rubik-Regular" }}>{row.attributes.date_added}</Typography>
                                                        </TableCell>
                                                        <TableCell style={{ fontFamily: "Rubik-Regular" }} align="left">
                                                            <Typography style={{ fontSize: "14px", fontFamily: "Rubik-Regular" }}>{row.attributes.phone_number}</Typography>
                                                        </TableCell>
                                                        <TableCell style={{ fontFamily: "Rubik-Regular", }} align="left">
                                                            <IconButton
                                                                onClick={() => delteUserData(row.id, row.attributes.user_type)}
                                                                style={{
                                                                    height: '45px',
                                                                    width: '45px',
                                                                    borderRadius: '50%',
                                                                    backgroundColor: "#e7eaef",
                                                                    marginRight: "25px"
                                                                }}
                                                            >
                                                                <img src={deleteIcon} height={'20px'} />
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={() => goToEditUserPage(row?.type, row.id)}
                                                                style={{
                                                                    height: '45px',
                                                                    width: '45px',
                                                                    borderRadius: '50%',
                                                                    backgroundColor: "#e7eaef"
                                                                }}
                                                            >
                                                                <img src={editBlackIcon} height={'20px'} />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
            }
        </>
    )
}
export default ViewAll;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableStyle: {
            minWidth: 450,
            border: "1px solid #d8d7da",
            borderRadius: "10px",
            minHeight: 'calc(100vh - 400px)',
            maxHeight: "calc(100vh - 200px)",
            overflowY: 'auto',
            overflowX: 'auto',
        },
    })
);
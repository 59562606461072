import {
    ListItem,
    List,
    Collapse,
    Typography
} from "@material-ui/core";
import {
    makeStyles,
    createStyles,
    Theme,
} from "@material-ui/core/styles";
import React from "react";
import { SelctedPdfModel, SubmittedDocsModel } from "../../blocks/dashboard/src/Interface";
import { rightIcon, downIcon } from "../../blocks/dashboard/src/assets";
export interface SubmittedProps {
    selectedPdf: SelctedPdfModel,
    submittedList: any[],
    open: any,
    handleColapse: (value: any) => void;
    handleSelectedSubmitted: (item: SubmittedDocsModel, index: number) => void;

}


const ViewAll = ({ submittedList, selectedPdf, open, handleColapse, handleSelectedSubmitted }: SubmittedProps) => {
    const classes = useStyles();

    return (
        <>
            <ListItem
                style={{
                    borderRadius: '6px',
                    marginTop: '20px',
                    borderBottom: "1px solid #E6EAE9"
                }}
                button
                onClick={() => handleColapse("submitted")}
            >
                {open["submitted"] ? <img src={downIcon} height="8px"
                />
                    : <img src={rightIcon} height="14px"
                    />
                }
                <Typography
                    style={{
                        paddingLeft: "15px",
                        fontFamily: 'Rubik-Regular',
                        fontSize: '16px',
                        marginRight: '40px'
                    }}
                >
                    {"Submitted Documents"}
                </Typography>

            </ListItem>
            <Collapse in={open["submitted"]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>

                    {

                        submittedList?.length > 0 &&
                        submittedList.map((data: SubmittedDocsModel) => {
                            const _key = data?.attributes?.checklist_name.split(" ").join("_")
                            return (
                                <>
                                    <ListItem
                                        key={data?.id}
                                        style={{
                                            borderRadius: '6px',
                                            marginTop: '20px',
                                            borderBottom: "1px solid #E6EAE9"
                                        }}
                                        button
                                        onClick={() => handleColapse(_key)}
                                    >
                                        {open[_key] ? <img src={downIcon} height="8px"
                                        />
                                            : <img src={rightIcon} height="14px"
                                            />
                                        }
                                        <Typography
                                            style={{
                                                paddingLeft: "15px",
                                                fontFamily: 'Rubik-Regular',
                                                fontSize: '16px',
                                                marginRight: '40px'
                                            }}
                                        >
                                            {data?.attributes?.checklist_name}
                                        </Typography>

                                    </ListItem>
                                    <Collapse in={open[_key]} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {
                                                data?.attributes?.file_name.length > 0 &&
                                                data?.attributes?.document.map((item: string, index: number) => (
                                                    <ListItem key={item} button
                                                        className={selectedPdf?.id === index ? classes.selectedNested : classes.nested}
                                                        onClick={() => handleSelectedSubmitted(data, index)}
                                                    >
                                                        <Typography
                                                            style={{
                                                                fontSize: '16px',
                                                                fontFamily: 'Rubik-Regular'
                                                            }}
                                                        >
                                                            {item}
                                                        </Typography>
                                                    </ListItem>
                                                ))
                                            }
                                        </List>
                                    </Collapse>
                                </>
                            )
                        })

                    }
                </List>
            </Collapse>
        </>
    )
}
export default ViewAll;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        nested: {
            paddingLeft: '80px',
            borderBottom: "1px solid #E6EAE9"
        },
        selectedNested: {
            paddingLeft: '80px',
            border: "1px solid #67a1a3",
            borderRight: "none",
            borderRadius: "10px 0 0 10px"
        }
    })
);
// Customizable Area Start
import DashboardController from "../../DashboardController";
import { Box, Typography, Paper, 
  Button, Checkbox, ListItemText, 
  FormControlLabel, Input, FormControl, 
  Select, Radio, MenuItem, Collapse, List, ListItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { CheckCircle } from "@material-ui/icons"
import { downIcon, rightIcon } from "../../assets";
import DocumentDialog from './Dialog';
import React from "react";
import NotificationContext from "../../../../notifications/src/Context/NotificationContext.web";
import CustomDocViewer from "../../../../../components/src/CustomDocViewer";
import Loader from "../../../../../components/src/Loader.web";


export const muiStyles = (theme: any) => ({
  paper: {
    minHeight: "75vh",
    overflowY: "scroll",
    margin: `${theme.spacing(1)} 0`,
    display: 'flex'
  },
  selectedNested: {
      backgroundColor: "#F6F6F6",
      cursor: "pointer",
      border: `1px solid ${localStorage.getItem("primaryColor") || "#67a1a3"}`,
      borderRight: "none",
      borderRadius: "6px 0 0 6px"
  },
  nested: {
      backgroundColor: "#E7EAEF",
      cursor: "pointer"
  },
  selected_btn: {
    backgroundColor: "#f1f1f1",
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    border: `1px solid ${localStorage.getItem("primaryColor") || "#67a1a3"}`,
    borderRight: 'none',
  },
  btn_type: {
    border: `1px solid ${localStorage.getItem("primaryColor") || "#67a1a3"}`,
    borderRadius: 5,
    padding: "4px 6px",
    fontSize: "0.7rem",
    fontWeight: "700",
  },
  payments: {
    border: `1px solid ${localStorage.getItem("primaryColor") || "#67a1a3"}`,
    borderRadius: 10,
    backgroundColor: "#F6F6F6",
    overflow: 'hidden'
  },
});


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CompletedListItems = ({listItem, completedTaskSelectedId, classes, handleCompleteTask}: any) => {
  return (
    listItem?.attributes?.sections?.map((item: any) => {
      return <ListItem key={item?.id}
        className={completedTaskSelectedId == item?.id ? classes.selectedNested : classes.nested}
        onClick={() => handleCompleteTask(item)}
      >
        <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
            <Box style={{width: "70%", display: "flex", gap: "10px"}}>
              <CheckCircle color="primary" />
              <Typography style={{ fontFamily: "Rubik-Regular", fontSize: "16px" }}>
                {item?.section_name}
              </Typography>
            </Box>
            <Typography style={{ fontFamily: "Rubik-Regular", fontSize: "12px", marginRight: "10px" }}>
              {item?.completed_date}
            </Typography>
          </Box>
          {
            item?.type?.toLowerCase() === "personal"
              || item?.type?.toLowerCase() === "business" ?
              <Button className={classes.btn_type}>
                {item?.type}
              </Button>
              :
              ""
          }
        </Box>
      </ListItem>
    })
  )
}

const CompletedTaskChecklist = ({completedTask, open, handleCollapse, classes, handleCompleteTask, completedTaskSelectedId}: any) => {
  return (
    completedTask?.checklists?.data?.length > 0 ? completedTask.checklists.data.map((listItem: any) => {
      const _key = listItem?.attributes?.checklist_name.split(" ").join("_");

      return <>  
        <ListItem
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: '6px',
              marginTop: '20px',
              borderBottom: "1px solid #E6EAE9",
              cursor: "pointer"
            }}
            onClick={() => handleCollapse(_key)}
        >
        <Typography
            style={{
                fontFamily: 'Rubik-Regular',
                fontSize: '16px',
                marginRight: '40px'
            }}
        >
            {listItem?.attributes?.checklist_name}
        </Typography>
        <Box>
            { open[_key] 
                          ? <img src={downIcon} height="8px"/>
                          : <img src={rightIcon} height="14px" />
            }
        </Box>
        </ListItem>

      <Collapse in={open[_key]} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <CompletedListItems 
              listItem={listItem} 
              completedTaskSelectedId={completedTaskSelectedId} 
              classes={classes}
              handleCompleteTask={handleCompleteTask}
          />
        </List>
    </Collapse>
    </>
    }) : (
      <Box width="100%" p={1}>
          <Typography variant="h6" align="center" color="primary" style={{ fontWeight: 300 }}>
              No Completed Items
          </Typography>
      </Box>
    )
  )
}

class CompletedItems extends DashboardController {
  static contextType = NotificationContext;
  render() {
    const { classes } = this.props as any;
    const { selectedDocuments, loading } = this.state;
    return (
      <Box>
        <Loader loading={loading} />
        <Typography variant="h4" color="secondary">
          Completed Items
        </Typography>
        <Paper className={classes.paper} variant="outlined" style={{ position: "relative" }}>
          <Box display="flex" flexDirection="column" flex={1}>
            <Box display="flex" flex={1}>
              <Box display="flex" p={2} flex={1}>
                <Box display="flex" flexDirection="column" py={1} width="45%">
                  <Box>
                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <Typography style={{ fontFamily: "Rubik-SemiBold", fontSize: "16px" }}>Completed Items</Typography>
                      <Typography style={{ fontFamily: "Rubik-Regular", fontSize: "12px", marginRight: "30px" }}>Date Completed</Typography>
                    </Box>
                    <Box py={2} style={{ height: '70vh', overflowY: "scroll" }}>
                      <CompletedTaskChecklist 
                        completedTask={this.state.completedTask}
                        completedTaskSelectedId={this.state.completedTaskSelectedId} 
                        open={this.state.open}
                        handleCollapse={this.handleCollapse}
                        handleCompleteTask={this.handleCompleteTask}
                        classes={classes}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  width="55%"
                  className={classes.payments}
                  p={2}
                  display="flex"
                  justifyContent="space-between"
                  flex={1}
                  flexDirection="column"
                >
                  <Box>
                  {selectedDocuments?.document_template_id !== 0 && selectedDocuments?.document_url?.length > 0 && (
                      <CustomDocViewer viewHeight={69} link={selectedDocuments?.document_url} />
                    )}
                    {!selectedDocuments?.document_template_id &&
                    this.state.completedTaskSectionData?.attributes?.questions?.data?.length > 0 && this.state.completedTaskSectionData.attributes.questions.data.map((item: any) => {
                      return <Box key={item?.id} style={{ margin: 15 }} display="flex" flexDirection={"column"}>
                        { item.attributes.document &&
                          <Button onClick={this.handleDialogOpen} style={{ alignSelf: 'flex-end', background: 'white', padding: '10px', border: '0.5px solid black' }}>
                            <Typography variant="h6">View in Documents</Typography>
                          </Button>
                        }
                        <Box>
                         <div dangerouslySetInnerHTML={{ __html: item?.attributes?.question_name }} />
                        </Box>

                        { item.attributes.question_type === 'checkbox' && 
                          <Box>
                            {item.attributes.question_options.map((question: string) => {
                              return <Box key={question}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox 
                                        checked={item?.attributes?.current_gathered_details?.includes(question)} 
                                      />}
                                    label={question}
                                  />
                                </Box>
                            })}
                          </Box>
                        }

                        { item.attributes.question_type === 'multiselect' && 

                                <FormControl className={classes.formControl}>
                                <Select
                                  labelId="demo-mutiple-checkbox-label"
                                  id="demo-mutiple-checkbox"
                                  multiple
                                  value={item.attributes.current_gathered_details.split(', ')}
                                  input={<Input />}
                                  renderValue={(selected: any) => selected.join(', ')}
                                  MenuProps={MenuProps}
                                >
                                  {item.attributes.question_options.map((name: string) => {

                                    const answers = item.attributes.current_gathered_details.split(', ');
                                    return <MenuItem key={name} value={name}>
                                      <Checkbox checked={answers.includes(name)} />
                                      <ListItemText primary={name} />
                                    </MenuItem>
                                  })}
                                </Select>
                                </FormControl>
                        }
                        
                        { item.attributes.question_type === 'radio' && 
                          <Box>
                            {item.attributes.question_options.map((question: string) => {
                              return <>
                                  <FormControlLabel 
                                    control={<Radio checked={item.attributes.current_gathered_details == question} />} 
                                    label={question} 
                                  />
                                  <br />
                                  </>
                            })}
                          </Box>
                        }
                      
                      { (item.attributes.question_type === 'string' || item.attributes.question_type === 'textfield') && 
                          <Box>
                             Answer: {item.attributes.current_gathered_details} 
                           </Box>
                          }


                         <DocumentDialog open={this.state.isDialogOpen} onClose={this.handleDialogClose} link={item.attributes.document}/>
                        </Box>
                      })} 
                      {!selectedDocuments?.document_template_id &&
                       this.state.completedTaskSectionData?.attributes?.questions?.data[0]?.id === "" && ( 
                          <Typography variant="h6" align="center" color="primary" style={{ fontWeight: 300 }}>
                                No Questions
                          </Typography>
                      )
                    }

                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    )
  }
}

//@ts-ignore
export default withStyles(muiStyles)(CompletedItems);

// Customizable Area End
import { Typography, Box, IconButton } from "@material-ui/core";
import {
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core/styles";
import React, { useContext } from "react";
import { ThemeContext } from "../../blocks/notifications/src/Context/ThemeContext";
import { editIcon, profileIcon, groupIcon, buildingIcon } from "../../blocks/user-profile-basic/src/assets";
import { UserInfoI } from "../../blocks/user-profile-basic/src/Interface";
import { getFirstLetters } from "./Utils";
export interface UserDetailsProps {
  user: UserInfoI,
  goToEditUserPage: (user_type: string, id: string) => void;
}


const UserDetailsCard = ({ user, goToEditUserPage }: UserDetailsProps) => {
  const classes = useStyles();
  const { primaryColor } = useContext(ThemeContext)
  const userIcons = user?.attributes?.primary_user ?
    <img src={profileIcon} height='28px' />
    :
    <img src={groupIcon} height='28px' />
  return (
    <Box className={classes.wrapper}>
      {
        user?.attributes?.primary_user ?
          <Box className={classes.primary}>
            <Typography style={{ fontFamily: "Rubik-Regular", color: primaryColor }}>Primary</Typography>
          </Box>
          :
          ""
      }
      <Box className={classes.main}>
        <Box style={{ padding: "20px 0 20px 10px", width: "100px" }}>
          {
            user?.attributes?.image ?
              <img src={user?.attributes?.image} height="100px" width="100px" style={{ borderRadius: "50%", border: "1px solid #a8b0b6" }} />
              :
              <Box style={{ height: "100px", width: "100px", borderRadius: "50%", border: "1px solid #a8b0b6", backgroundColor: primaryColor, position: "relative" }}>
                <Typography style={{ position: "absolute", top: "34%", left: "36%", color: "#ffffff", fontSize: "24px" }}>
                  {
                    user?.type?.toLowerCase() === "client_businesses" ?
                      getFirstLetters(user?.attributes?.business_name)
                      :
                      getFirstLetters(user?.attributes?.first_name + " " + user?.attributes?.last_name)
                  }
                </Typography>
              </Box>
          }
        </Box>
        <Box className={classes.userDescription}>
          {
            user?.attributes.user_type === "user" ?
              <Typography style={{ fontFamily: "Rubik-Medium", fontSize: "20px", marginBottom: "5px" }} className={classes.textWidth}>{user?.attributes?.first_name + " " + user?.attributes?.last_name}</Typography>
              :
              <Typography style={{ fontFamily: "Rubik-Medium", fontSize: "20px", marginBottom: "5px" }} className={classes.textWidth}>{user?.attributes?.business_name}</Typography>
          }
          <a
            href={"mailto:" + user?.attributes?.email}
            style={{ textDecoration: "none", color: "black" }}
          >
            <Typography style={{ fontFamily: "Rubik-Regular", fontSize: "14px" }} className={classes.textWidth}>{user?.attributes?.email}</Typography>
          </a>
          <a
            href={"tel:" + user?.attributes?.phone_number}
            style={{ textDecoration: "none", color: "black" }}
          >
            <Typography style={{ fontFamily: "Rubik-Regular", fontSize: "14px" }} className={classes.textWidth}>{user?.attributes?.phone_number}</Typography>
          </a>
        </Box>
      </Box>
      <Box className={classes.footer} style={{ backgroundColor: primaryColor }}>
        <Box style={{ paddingLeft: "10px" }}>
          <Box style={{ display: "flex", alignItems: "center", gap: "12px" }}>
            {
              user?.type === "user" ?
                userIcons
                :
                <img src={buildingIcon} height='28px' />
            }
            <Typography style={{ color: "#ffffff", fontFamily: "DMSans-Bold", fontSize: "19px" }}>
              {
                user?.attributes?.user_type === "user" ?
                  user?.attributes?.relationship
                  :
                  "Business"
              }
            </Typography>
          </Box>
        </Box>
        <Box style={{ paddingRight: "10px" }}>
          <IconButton
            onClick={() => goToEditUserPage(user?.type, user?.id)}
            style={{
              // border: '1px solid #bdbdbd',
              height: '40px',
              width: '40px',
              // color: "#ffffff"
            }}
          >
            <img src={editIcon} height={'25px'} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}
export default UserDetailsCard;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: "100%",
      border: "1px solid #a8b0b6",
      borderRadius: "10px",
      height: "250px",
      position: "relative"
    },
    main: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      gap: "10px",
      height: 'calc(100% - 3rem)'

    },
    primary: {
      borderRadius: "0 10px 0 10px",
      padding: "10px",
      backgroundColor: "#E7EAEF",
      position: "absolute",
      top: 0,
      right: 0
    },
    footer: {
      height: "3rem",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      // backgroundColor: "#67a1a3",
      borderRadius: "0 0 10px 10px"
    },
    textWidth: {
      width: "80%",
      wordWrap: "break-word"
    },
    userDescription: {
      width: "calc(100% - 100px)",
      wordWrap: "break-word",
      padding: "20px 10px 20px 10px"
    }
  })
);
import {
    Box,
} from "@material-ui/core";
import React, { useState } from "react";
import { Document, Page } from 'react-pdf';
import "./pdfStyle.css"
interface CustomDocsProps {
    link: string;
    viewHeight: number;
}


const CustomDocViewer = ({ link, viewHeight }: CustomDocsProps) => {
    const [numPages, setNumPages] = useState(null);

    // @ts-ignore
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    return (
        <Box style={{ height: `${viewHeight}vh`, overflowY: "scroll", overflowX: "auto", width: "100%" }}>
            <Document file={link} onLoadSuccess={onDocumentLoadSuccess}>
                {Array.from(new Array(numPages), (el, index) => (
                    <Box key={index + 1} style={{ marginBottom: "7px" }}>
                        <Page key={`page_${index + 1}`} pageNumber={index + 1} children={el}/>
                    </Box>
                ))}
            </Document>
        </Box>
    )
}
export default React.memo(CustomDocViewer);


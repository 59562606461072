import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { useTheme } from "../../blocks/notifications/src/Context/ThemeContext";


const UserProfile = () => {
    const { primaryColor, userProfileContext } = useTheme()
    const { first_name: firstName, last_name: lastName, avatar: profilePicUrl } = userProfileContext;

    let avatarContent;

    if (profilePicUrl) {
        avatarContent = <Avatar alt="Profile Pic" src={profilePicUrl} />;
    } else {
        const initials = firstName.charAt(0) + lastName.charAt(0);
        avatarContent = <Avatar style={{ backgroundColor: primaryColor, fontFamily: "Rubik-Regular" }}>{initials.toLocaleUpperCase()}</Avatar>;
    }

    return <div>{avatarContent}</div>;
};

export default UserProfile;